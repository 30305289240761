import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { FiPaperclip } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { PiPlusBold } from "react-icons/pi";
import { PiMinusBold } from "react-icons/pi";
import pdf from "../../../../../images/pdf.png";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
const MAX_FILES = 10;
const MAX_FILE_SIZE = 10 * 1024 * 1024;

const Engagement = ({
    setEventType,
    // handleFormSubmit,
    eventTypePhone,
    setEventTypePhone,
    setEventData,
    setFile,
    eventData,
    edit,
    editEventData,
    setEditEventData,
    editFile,
    setShowForm,
    setShowData,
    back,
  }) => {
    const [phone, setPhone] = useState();
    const [thumbs, setThumbs] = useState([]);
    const [reservation, setReservation] = useState();
    const [finalFileData, setFinalFileData] = useState([]);
    const [showFileList, setShowFileList] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    // console.log(editEventData, 'eee');
    useEffect(() => {
      if (!back) {
        setEventData({
          eventType: "Engagement",
          clientName: "",
          clientPhone: "",
          address: "",
          noOfGuests: 0,
          noOfFreeGuests: 0,
          reservationStatus: "",
          notes: "",
          groomName: "",
          brideName: "",
          typeOfWedding: "",
          companyName: "",
          companyID: "",
          taxNo: "",
          singersNames1: "",
          singersNames2: "",
          singersNames3: "",
          eventCategory: "",
        });
      }
    }, []);
  
    useEffect(() => {
      if (edit) {
        setEventData(editEventData);
  
        // console.log("useEffevtforweddddddddddddddddddddddddddddddddd");
        var newThumbs = [];
        const arrayOfStrings = JSON.parse(editFile);
  
        const convertToArrayOfArrays = (arr) => {
          if (!Array.isArray(arr) || arr.length === 0) {
            return [];
          }
          return arr.map((string) => {
            return [string];
          });
        };
        const arrayOfArrays = convertToArrayOfArrays(arrayOfStrings);
        // console.log(arrayOfArrays,"arrayOfArrays");
        const abc = Object.values(arrayOfArrays);
        // console.log(abc, "arrayOfArrays");
        setFinalFileData(arrayOfArrays);
        setFile(arrayOfArrays);
        // console.log(arrayOfStrings, 'abc');
      }
    }, [editEventData]);
  
    // console.log(eventData, "eventData");
  
    const options = [
      { value: "Wedding", label: "Wedding" },
      { value: "Engagement", label: "Engagement" },
      { value: "Corporate", label: "Corporate" },
      { value: "Concert", label: "Concert" },
      { value: "Private", label: "Private" },
      { value: "Charity", label: "Charity" },
    ];
    const customStyles = {
      backgroundColor: "#4d4d4d",
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#4d4d4d" : "transparent",
        color: state.isSelected ? "white" : "#333",
        ":hover": {
          backgroundColor: "#676767 ",
          color: "white",
          cursor: "pointer",
        },
      }),
    };
  
    const [num, setNum] = useState(0);
    const [FreeGuests, setFreeGuests] = useState(0);
  
    const incNum = () => {
      setEventData((prevData) => ({
        ...prevData,
        noOfGuests: prevData.noOfGuests + 1,
      }));
    };
  
    const decNum = () => {
      setEventData((prevData) => ({
        ...prevData,
        noOfGuests: Math.max(0, prevData.noOfGuests - 1),
      }));
    };
  
    const incFreeGuests = () => {
      setEventData((prevData) => ({
        ...prevData,
        noOfFreeGuests: prevData.noOfFreeGuests + 1,
      }));
    };
  
    const decFreeGuests = () => {
      // setFreeGuests((prevNum) => Math.max(0, prevNum - 1));
  
      setEventData((prevData) => ({
        ...prevData,
        noOfFreeGuests: Math.max(0, prevData.noOfFreeGuests - 1),
      }));
    };
    const handleChange2 = (e) => {
      const name = e.target.name;
      let value = e.target.value;
  
  
      if (name === "noOfGuests" || name === "noOfFreeGuests") {
        value = parseInt(value) || 0;
      }
     
  
      setEventData({ ...eventData, [name]: value });
    };
    const handleChange = (e) => {
      const name = e?.name;
      const value = e?.value;
      // console.log(value, 'value');
  
      setEventType(value);
      if (edit) {
        setEditEventData({ ...editEventData, eventType: value });
      }
  
      setEventData({ ...eventData, eventType: value });
    };
  
    const handlePhone = (phone) => {
      setEventData({ ...eventData, clientPhone: phone });
    };
  
    const { getRootProps, getInputProps } = useDropzone({
      accept: { "application/pdf": [".pdf"] },
      onDrop: (acceptedFiles, rejectedFiles) => {
        const largeFiles = [];
        const newThumbs = [];
        acceptedFiles.forEach((file) => {
          if (file.size > MAX_FILE_SIZE) {
            largeFiles.push(file.name);
          } else {
            newThumbs.push(
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            );
          }
        });
  
        // Append the additional names
  
        if (newThumbs.length === 0) {
          // setErrorMessage("Debe seleccionar al menos un archivo válido.");
        } else if (newThumbs.length + thumbs.length > MAX_FILES) {
          // setErrorMessage(`Puede cargar un máximo de ${MAX_FILES} archivos.`);
        } else {
          // setErrorMessage("");
        }
  
        if (largeFiles.length > 0) {
          // setErrorMessage(`Los siguientes archivos exceden el ${MAX_FILE_SIZE / (1024 * 1024)} Límite de tamaño de MB: ${largeFiles.join(", ")}`);
        }
  
        const mergedThumbs = [...thumbs, ...newThumbs];
        setFinalFileData([]);
        setThumbs(mergedThumbs);
        setFile(mergedThumbs);
      },
    });
  
    useEffect(() => {
      return () => thumbs.forEach((thumb) => URL.revokeObjectURL(thumb.preview));
    }, [thumbs]);
    // const removeThumb = (index) => {
    //   const newThumbs = [...thumbs];
    //   newThumbs.splice(newThumbs.indexOf(index), 1);
    //   setThumbs(newThumbs);
    //   // setErrorMessage("");
    // };
  
    // const removeFinalValue = (index) => {
    //   const newThumbs = [...finalFileData];
    //   newThumbs.splice(index, 1);
    //   setFinalFileData(newThumbs);
    //   // setErrorMessage("");
    // };
  
    // console.log(editFile, thumbs, 'new');
    const resetData = () => {
      setEventData({
        eventType: "Engagement",
        clientName: "",
        clientPhone: "962",
        address: "",
        noOfGuests: 0,
        noOfFreeGuests: 0,
        reservationStatus: "",
        notes: "",
        groomName: "",
        brideName: "",
        typeOfWedding: "",
        companyName: "",
        companyID: "",
        taxNo: "",
        singersNames1: "",
        singersNames2: "",
        singersNames3: "",
        eventCategory: "",
      });
      setThumbs([]);
  
      if (edit) {
        setEventData(editEventData);
  
        // console.log("useEffevtforweddddddddddddddddddddddddddddddddd");
        var newThumbs = [];
        const arrayOfStrings = JSON.parse(editFile);
  
        const convertToArrayOfArrays = (arr) => {
          if (!Array.isArray(arr) || arr.length === 0) {
            return [];
          }
          return arr.map((string) => {
            return [string];
          });
        };
        const arrayOfArrays = convertToArrayOfArrays(arrayOfStrings);
        // console.log(arrayOfArrays,"arrayOfArrays");
        const abc = Object.values(arrayOfArrays);
        // console.log(abc, "arrayOfArrays");
        setFinalFileData(arrayOfArrays);
        // console.log(arrayOfStrings, 'abc');
      }
    };
  
    const backData = () => {
      setShowForm(false);
      setEventTypePhone(false);
    };
    // console.log(eventData?.typeOfWedding,"sdfffffffffff");
    const handleNext = ()=>{
      if (eventData?.clientName === "" || eventData?.clientName === null) {
        const notify = () =>
          toast.error("Client Name is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else if (eventData?.clientPhone === "" || eventData?.clientPhone === null) {
        const notify = () =>
          toast.error("Client Phone is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else if (eventData?.address === "" || eventData?.address === null) {
        const notify = () =>
          toast.error("Address is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else if (eventData?.groomName === "" || eventData?.groomName === null) {
        const notify = () =>
          toast.error("Groom Name is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else if (eventData?.brideName === "" || eventData?.brideName === null) {
        const notify = () =>
          toast.error("Bride Name is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else if (eventData?.noOfGuests === 0 || eventData?.noOfGuests === null) {
        const notify = () =>
          toast.error("No. of Guests is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else if (eventData?.reservationStatus === "" || eventData?.reservationStatus === null) {
        const notify = () =>
          toast.error("reservation is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else{

        setShowData(2)
      }
        }
    //   }
  return (
    <>
       <Toaster/>
      <div
        className={
          eventTypePhone ? "next-btn-event-true" : "next-btn-event-false"
        }
      >
        <div className="padding-left-17 form-margin-768 position-relative">
          {/* {showForm && ( */}
          <div className="">
            <h2 className="fw-bold h2-768">Info & Contact</h2>
            <form
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   setEventTypePhone(false);

            //   if (edit) {
            //     handleFormSubmit(eventData.eventID);
            //   } else {
            //     handleFormSubmit();
            //   }
            // }}
            >
              <div className="row d-flex  pt-4 pt-lg-5 mx-2 mx-md-auto">
                <div className="col-12 col-xxl-7">
                  <div className="row mb-3">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label label">
                        EVENT TYPE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <Select
                        defaultValue={{
                          value: "Engagement",
                          label: "Engagement",
                        }}
                        onChange={handleChange}
                        options={options}
                        className=" inpt cursor-pointer inpt-768 "
                        placeholder="Event Type"
                        name="eventType"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12  col-xxl-auto  ">
                  <div className=" row mb-3">
                    <div className="col-12 col-lg-4  col-xxl-12 d-sm-block d-xxl-none">
                      <label for="basic-url" className="form-label  label">
                        {" "}
                        UPLOAD
                      </label>
                    </div>
                    <div className="col-12 col-lg-8  col-xxl-12">
                      <div className="row d-flex">
                        <div className="col-auto">
                          <div
                            {...getRootProps({ className: "dropzone " })}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="input-group jod_width_cal price cal_width price-upload-768"
                              id="upload"
                            >
                              <input
                                type="file"
                                className="d-none"
                                id="upload"
                                accept=".pdf"
                              />
                              <input {...getInputProps()} />
                              <input
                                className="form-control inpt-border-trans text-info text-decoration-underline "
                                value={"Attach File"}
                                style={{ cursor: "pointer" }}
                                readOnly
                                //   disabled
                                onChange={(e) => {
                                  setNum(parseInt(e.target.value) || 0);
                                }}
                              />
                              <span className="input-group-text inpt-border-trans inpt-lable justify-content-center">
                                <FiPaperclip
                                  className="fs-5"
                                  // onClick={incNum}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        {thumbs.length || finalFileData.length ? (
                          <div className="col-auto cursor-pontor mt-sm-auto mt-3">
                            <img
                              src={pdf}
                              onClick={() => setShowFileList(!showFileList)}
                              className=""
                              alt="profile_image"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {showFileList &&
                      (thumbs.length || finalFileData.length) ? (
                        <div className=" card-file position-absolute">
                          <ul className="list-group list-group-flush">
                            {finalFileData.map((file, index) => {
                              // console.log(file, "file");
                              return (
                                <li
                                  className="list-group-item p-1 bg-transparant li-border"
                                  key={index}
                                >
                                  <div className="form-check  p-0">
                                    <div className="row d-flex">
                                      <Link
                                        to={`http://whitehall.website/api/uploads/event/${file}`}
                                        target="_blank"
                                        onClick={() => setShowFileList(false)}
                                        className="text-black"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div className="col-12 mx-4 p-1 ">
                                          <label
                                            className="form-check-label form_check_size"
                                            for="checkbox1"
                                            style={{ cursor: "pointer" }}
                                          >
                                            {file}
                                          </label>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                            {thumbs.map((file, index) => (
                              <li className="list-group-item p-1 bg-transparant li-border">
                                <div className="form-check  p-0">
                                  <div className="row d-flex">
                                    <Link
                                      to={file.preview}
                                      target="_blank"
                                      onClick={() => setShowFileList(false)}
                                      className="text-black"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="col-12 mx-4 p-1 ">
                                        <label
                                          className="form-check-label form_check_size"
                                          for="checkbox1"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {file.name}
                                        </label>
                                      </div>
                                      {/* <div className="col-2 d-flex justify-content-end pt-1">
                                      <span className="">
                                      <FaEye className="fs-3 ps-2  text-black" />
                                      </span>
                                      <span>
                                      {" "}
                                      <MdDelete
                                      style={{ cursor: "pointer" }}
                                      className="fs-4 ps-1  text-black"
                                      onClick={() => removeThumb(file)}
                                      />
                                      </span>
                                    </div> */}
                                    </Link>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3">
                      <div className="col-lg-4 col-12 ">
                        <label for="basic-url" className="form-label  label">
                          CLIENT NAME
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <input
                          type="text"
                          className="form-control inpt inpt-768 "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange2}
                          name="clientName"
                          autoComplete="off"
                          value={eventData.clientName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          CLIENT PHONE
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={eventData.clientPhone}
                          country={"jo"}
                          className=" inpt inpt-768 "
                          onChange={handlePhone}
                          inputProps={{
                            required: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3">
                      <div className="col-lg-4 col-12 ">
                        <label for="basic-url" className="form-label  label">
                          ADDRESS
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <input
                          type="text"
                          className="form-control inpt inpt-768 "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange2}
                          name="address"
                          autoComplete="off"
                          value={eventData.address}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3">
                      <div className="col-lg-4 col-12 ">
                        <label for="basic-url" className="form-label  label">
                          GROOM NAME
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <input
                          type="text"
                          className="form-control inpt inpt-768 "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleChange2}
                          name="groomName"
                          autoComplete="off"
                          value={eventData.groomName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3 ">
                      <div className="col-lg-4 col-12 ">
                        <label for="basic-url" className="form-label  label">
                          BRIDE NAME
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <input
                          type="text"
                          className="form-control inpt inpt-768 "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="brideName"
                          autoComplete="off"
                          value={eventData.brideName}
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-sm-6 col-lg-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row ">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          NO. OF GUESTS
                        </label>
                      </div>
                      <div className="col-12 col-lg-8 ">
                        <div className=" guest-768">
                          <div className="input-group mb-3 jod_width_cal price cal_width">
                            <input
                              type=""
                              className="form-control inpt-border-trans "
                              value={eventData.noOfGuests}
                              name="noOfGuests"
                              autoComplete="off"
                              onChange={
                                // (e) =>
                                // setNum(parseInt(e.target.value) || 0)
                                handleChange2
                              }
                            />
                            <span className="input-group-text inpt-border-trans inpt-lable">
                              <i
                                className="bi bi-caret-up-fill drop_up"
                                onClick={incNum}
                              ></i>
                              <i
                                className="bi bi-caret-down-fill drop_down"
                                onClick={decNum}
                              ></i>
                            </span>
                          </div>
                        </div>
                        <div className="guest1-768">
                          <div className="input-group  price price-768">
                            <span
                              className="input-group-text inpt-border-trans inpt-lable-light justify-content-center cursor-pontor"
                              onClick={decNum}
                            >
                              <PiMinusBold className="fs-5" />
                            </span>
                            <input
                              type=""
                              className="form-control inpt-border-trans text-center"
                              value={eventData.noOfGuests}
                              autoComplete="off"
                              name="noOfGuests"
                              onChange={handleChange2}
                            />
                            <span
                              className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor "
                              onClick={incNum}
                            >
                              <PiPlusBold className="fs-5" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row ">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          NO. OF FREE GUESTS
                        </label>
                      </div>
                      <div className="col-12 col-lg-8 ">
                        <div className=" guest-768">
                          <div className="input-group mb-3 jod_width_cal price cal_width">
                            <input
                              type=""
                              className="form-control inpt-border-trans "
                              value={eventData.noOfFreeGuests}
                              name="noOfFreeGuests"
                              autoComplete="off"
                              onChange={
                                // (e) =>
                                // setFreeGuests(parseInt(e.target.value) || 0)
                                handleChange2
                              }
                            />
                            <span className="input-group-text inpt-border-trans inpt-lable">
                              <i
                                className="bi bi-caret-up-fill drop_up"
                                onClick={incFreeGuests}
                              ></i>
                              {/* <RiArrowDropUpFill
                            className="fs-2 drop_up"
                            onClick={incFreeGuests}
                          /> */}
                              <i
                                className="bi bi-caret-down-fill drop_down"
                                onClick={decFreeGuests}
                              ></i>
                              {/* <RiArrowDropDownFill
                            className="fs-2 drop_down"
                            onClick={decFreeGuests}
                          /> */}
                            </span>
                          </div>
                        </div>
                        <div className="guest1-768">
                          <div className="input-group price price-768">
                            <span
                              className="input-group-text inpt-lable-light inpt-border-trans  justify-content-center cursor-pontor"
                              onClick={decFreeGuests}
                            >
                              <PiMinusBold className="fs-5" />
                            </span>
                            <input
                              type=""
                              autoComplete="off"
                              name="noOfFreeGuests"
                              className="form-control inpt-border-trans text-center"
                              value={eventData.noOfFreeGuests}
                              onChange={handleChange2}
                            />
                            <span
                              className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor "
                              onClick={incFreeGuests}
                            >
                              <PiPlusBold className="fs-5" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-2 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label label mt-3"
                        >
                          RESERVATION
                        </label>
                      </div>
                      <div className="col-lg-8 col-12 ">
                        <div className="my-2">
                          <div className="form-check form-check-inline ps-0">
                            <input
                              className="form-check-input cursor-pointer"
                              type="radio"
                              name="reservationStatus"
                              id="inlineRadio1"
                              autoComplete="off"
                              value="Tentative"
                              onClick={
                                //   (e) => {
                                //   setReservation(e.target.value);
                                // }
                                handleChange2
                              }
                              checked={
                                eventData.reservationStatus === "Tentative"
                              }
                            />
                            <label
                              className="form-check-label cash_lable"
                              for="inlineRadio1"
                            >
                              Tentative
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input cursor-pointer"
                              type="radio"
                              autoComplete="off"
                              name="reservationStatus"
                              id="inlineRadio1"
                              value="FinalReserve"
                              onClick={
                                // (e) => {
                                // setReservation(e.target.value);
                                handleChange2
                              }
                              checked={
                                eventData.reservationStatus === "FinalReserve"
                              }
                            />
                            <label
                              className="form-check-label cash_lable"
                              for="inlineRadio1"
                            >
                              Final Reserve
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label label mt-3"
                        >
                          TYPE OF WEDDING
                        </label>
                      </div>
                      <div className="col-lg-8 col-12 ">
                        <div className="my-2">
                          <div className="form-check form-check-inline ps-0">
                            <input
                              className="form-check-input cursor-pointer "
                              type="radio"
                              name="typeOfWedding"
                              id="inlineRadio1"
                              value="Mixed"
                              autoComplete="off"
                              checked={eventData.typeOfWedding === "Mixed"}
                              onChange={handleChange2}
                            />
                            <label
                              className="form-check-label cash_lable"
                              for="inlineRadio1"
                            >
                              Mixed
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input cursor-pointer"
                              type="radio"
                              name="typeOfWedding"
                              id="inlineRadio1"
                              value="NotMixed"
                              autoComplete="off"
                              onChange={handleChange2}
                              checked={eventData.typeOfWedding === "NotMixed"}
                            />
                            <label
                              className="form-check-label cash_lable"
                              for="inlineRadio1"
                            >
                              Not Mixed
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row pe-0 pe-sm-auto">
                  <div className="col-12 col-xxl-7 pe-0 pe-sm-auto">
                    <div className="row mb-3">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          NOTES
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group  price-textarea w-textarea">
                          <textarea
                            className=" form-control inpt-border-trans"
                            id="exampleFormControlTextarea1"
                            rows="6"
                            placeholder=""
                            autoComplete="off"
                            name="notes"
                            value={eventData.notes}
                            onChange={handleChange2}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
                <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={backData}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={resetData}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handleNext}
                >
                  NEXT
                  <RiArrowRightDoubleLine className="fs-5" />
                </button>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-for-768 position-relative">
                <div
                  className={`next-btn mt-5 fixed-bottom ${
                    eventTypePhone ? "next-btn-false" : "next-btn-true"
                  }`}
                >
                  <div
                    className=" d-flex justify-content-between p-2 "
                    style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
                  >
                    <div className="d-flex">
                      <RiArrowLeftSLine className=" fs-2 mt-1" />
                      <p
                        className="  text-dark pt-1 mb-0"
                        onClick={() => setEventTypePhone(false)}
                      >
                        PREVIOUS
                      </p>
                    </div>
                    <div className="d-flex">
                      <p
                        className=" text-dark pt-1 mb-0"
                        onClick={handleNext}
                      >
                        NEXT
                      </p>
                      <RiArrowRightSLine className="fs-2 mt-1" />
                    </div>
                  </div>
                </div>
                {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={resetData}
                >
                  RESET
                </button> */}
                {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={backData}
                >
                  BACK
                </button> */}
              </div>
            </form>
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  )
}

export default Engagement
