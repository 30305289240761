import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Createstation, getToken } from "../../../Store/Action/useFetchData";

const CreateStation = ({ dispatch, res_user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [createstation, setCreatestation] = useState({
    station_name: "",
    station_price: "",
  });
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setCreatestation({ ...createstation, [name]: value });
  };
  const handlesubmit = () => {
    const submitData = {
      sname: createstation?.station_name,
      sprice: createstation?.station_price,
    };
    dispatch(Createstation(submitData, setLoading));
  };
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    if (res_user?.data?.status === true) {
      const notify = () =>
        toast.success(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
        navigate("/station");
      }, 1500);
    } else if (res_user?.data?.status === false) {
      const notify = () =>
        toast.error(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
      }, 1500);
    }
  }, [res_user, navigate]);
  const handleReset = () => {
    setCreatestation({
      station_name: "",
      station_price: "",
    });
  };
  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className=" form-margin-768 padding-left-21 ">
          <h3 className="mb-4 mt-3 pt-5 fw-bold">Create Station</h3>
          <div className="form-in-768">
            <div className="row ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  STATION NAME
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <input
                  type="text"
                  autoComplete="off"
                  name="station_name"
                  onChange={handleChange}
                  value={createstation.station_name}
                  className="form-control inpt inpt-768 "
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  STATION PRICE
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <div className="input-group mb-3 price-jod price-jod-768">
                  <input
                    type="number"
                    name="station_price"
                    autoComplete="off"
                    onChange={handleChange}
                    value={createstation.station_price}
                    className="form-control inpt-border-trans"
                  />
                  <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                    JOD
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768 ">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={() => {
                  res_user.loading = true;
                  res_user.data = {};
                  navigate("/station");
                }}
              >
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={handleReset}
              >
                RESET
              </button>

              {loading ? (
                <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SAVE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handlesubmit}
                >
                  SAVE
                </button>
              )}
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768">
              {loading ? (
                <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SAVE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handlesubmit}
                >
                  SAVE
                </button>
              )}
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={handleReset}
              >
                RESET
              </button>
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={() => {
                  res_user.loading = true;
                  res_user.data = {};
                  navigate("/station");
                }}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user: state?.Create_station_reducer,
});
export default connect(mapStateToProps)(CreateStation);
