import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../Loader";
import {
  GetMenuTypeData,
  GetStationData,
  UpdateAllMenuData,
  getToken,
  getViewAllMenuData,
} from "../../../Store/Action/useFetchData";
import { RiArrowRightDoubleLine } from "react-icons/ri";

const EditAllMenu = ({
  dispatch,
  res_get_menu,
  res_edit_menu,
  res_station,
  res_menu_type,
}) => {
  const id = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOption1, setSelectedOption1] = useState();
  const [addMenuData, setAddMenuData] = useState({
    charge: "",
    notes: "",
  });
  const [state, setState] = useState(false);
  const [selection, setSelection] = useState("Manu Type...");
  const [state1, setState1] = useState(false);
  const [selection1, setSelection1] = useState("Station...");
  const [showAll, setShowAll] = useState(false);
  const [selectedStations, setSelectedStations] = useState([]);


  useEffect(() => {
    dispatch(getToken());
    dispatch(GetMenuTypeData());
    dispatch(GetStationData());
  }, [dispatch]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getViewAllMenuData(id?.id));
  }, [id?.id,dispatch]);
  useEffect(() => {
    if (id.id && res_get_menu?.data?.status === true) {
      setAddMenuData({
        charge: Number(res_get_menu?.data?.data?.service_charge),
        notes: res_get_menu?.data?.data?.kitchen_notes,
      });

           var stations = res_get_menu?.data?.data?.station_id;
           const stationsArray = stations.split(",").map(id => +id);
// console.log(stationsArray,'stationsArray');

      setSelectedStations(stationsArray)
      setSelectedOption(res_get_menu?.data?.data?.menu_type_id);
      setSelectedOption1(res_get_menu?.data?.data?.station_id);
      setSelection(res_get_menu?.data?.data?.m_name);
      setSelection1(res_get_menu?.data?.data?.s_name);
    }
  }, [id.id, res_get_menu]);
  const sidebarRef = useRef(null);
  const sidebarRef1 = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
      if (sidebarRef1.current && !sidebarRef1.current.contains(event.target)) {
        setState1(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setState1, setState]);
  const handleClose = (e) => {
    setState(false);
    if (e.m_name !== selection && e.m_name !== "") {
      setSelection(e?.m_name);
      setSelectedOption(e?.menu_id);
    }
  };
  const handleClose1 = (e) => {
    setState1(false);
    if (e.s_name !== selection1 && e.s_name !== "") {
      setSelection1(e?.s_name);
      setSelectedOption1(e?.s_id);
    }
  };
  const handlesubmit = () => {
    const {  charge, notes } = addMenuData;
    var submitData;
  
      submitData = {
        menu_type: selectedOption,
        // station:  selectedStations.length > 0 && JSON.stringify(selectedStations).replace(/[\[\]']+/g,''),
        // service_charge: addMenuData?.charge,
        kitchen_notes: addMenuData?.notes,
      };
      dispatch(UpdateAllMenuData(submitData, id?.id, setLoading));
    
  };
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAddMenuData({ ...addMenuData, [name]: value });
  };
  useEffect(() => {
    if (res_edit_menu?.data?.status === true) {
      const notify = () =>
        toast.success(res_edit_menu?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_edit_menu.loading = true;
        res_edit_menu.data = {};
        res_get_menu.loading = true;
        res_get_menu.data = {};
        navigate("/menu");
      }, 1500);
    } else if (res_edit_menu?.data?.status === false) {
      const notify = () =>
        toast.error(res_edit_menu?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_edit_menu.loading = true;
        res_edit_menu.data = {};
      }, 1500);
    }
  }, [res_edit_menu,navigate,res_get_menu]);



  const handleCheckboxChange = (event, stationId) => {
    const isChecked = event.target.checked;
  
    if (isChecked) {
      // Check if the maximum limit of 5 selections has been reached
      if (selectedStations.length < 5) {
        setSelectedStations((prevSelected) => [...prevSelected, stationId]);
      } else {
        const notify = () =>
        toast.error("Maximum limit of 5 selections reached!", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
        // console.log("Maximum limit of 5 selections reached!");
      }
    } else {
      // Remove the station ID from the selectedStations array
      setSelectedStations((prevSelected) =>
        prevSelected.filter((id) => id !== stationId)
      );
    }
  };
  const itemsToShow = showAll ? res_station?.data?.data.length : 10;
  // console.log(selectedStations,'selectedStations');
  
  return (
    <>
      <div className="">
        <Toaster />
        {res_get_menu.loading ? (
          <Loader />
        ) : (
          <div className="container-fluid">
            <div className="  form-margin-768 padding-left-21 form-margin">
              <div className="form-in-768 position-relative">
                <div className="row mb-3 mt-3 mt-sm-5">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      MENU TYPE
                    </label>
                  </div>
                  <div className="col-12 col-lg-8 ">
                    <div className="" ref={sidebarRef}>
                      <div
                        className="form-select inpt inpt-768 select-ios-div"
                        onClick={() => setState(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="overflow-hidden select-ios"
                          style={{ padding: "6px" }}
                        >
                          {selection}
                        </option>
                      </div>
                      <div
                        className={`${
                          state
                            ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                            : "d-none"
                        }`}
                      >
                        {res_menu_type?.data?.data?.length ? (
                          res_menu_type?.data?.data?.map((item, index) => {
                            return (
                              <div key={index} className="p-1 py-0">
                                <div
                                  onClick={(e) => handleClose(item)}
                                  className="select-box-option text-white"
                                >
                                  {item.m_name}
                                </div>
                                <div className="" />
                              </div>
                            );
                          })
                        ) : (
                          <div className="p-1 py-0">
                            <div
                              className="select-box-option-nodata text-center"
                              style={{ cursor: "default" }}
                            >
                             No Data Found
                            </div>
                            <div className="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-3  ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      ADD STATION
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                   <div className="row" style={{width:"80%"}}>
                  {res_station?.data?.data && res_station?.data?.data.slice(0, itemsToShow).map((item, index) => (
                    <div className="col-12 col-lg-6 mb-3" key={index}>
                      <div className="form-check form-check-inline ps-0">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          name={`station-${item.s_id}`}
                          checked={selectedStations.includes(item.s_id)}
                          onChange={(e) => handleCheckboxChange(e, item.s_id)}
                          // disabled={selectedStations.length >= 5}
                        />
                        <label
                          className="form-check-label check-label"
                          htmlFor={`inlineCheckbox${index}`}
                        >
                          {item.s_name}
                        </label>
                      </div>
                    </div>
                  ))}
                   {res_station?.data?.data?.length > 10 && !showAll && (
                    <span
                      className="fw-bold text-end more-pading border-0 price-textarea"
                      style={{cursor:"pointer"}}
                      onClick={() => setShowAll(true)}
                    >
                      MORE <RiArrowRightDoubleLine className="fs-5" />
                    </span>
                  )}
                  </div>
                  </div>

                </div> */}
                {/* <div className="row  mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label label">
                      SERVICE CHARGE
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="input-group price-jod price-jod-768">
                      <input
                       autoComplete="off"
                        type="number"
                        name="charge"
                        value={addMenuData.charge}
                        onChange={handleChange}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div> */}

                <div className="row mb-3  ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      KITCHEN NOTES
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="input-group mb-3   price-textarea">
                      <textarea
                       autoComplete="off"
                        className=" form-control inpt-border-trans"
                        id="exampleFormControlTextarea1"
                        rows="6"
                        value={addMenuData.notes}
                        name="notes"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section  display-above-768">
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_edit_menu.loading = true;
                      res_edit_menu.data = {};
                      res_get_menu.loading = true;
                      res_get_menu.data = {};
                      navigate("/menu");
                    }}
                  >
                    BACK
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SUBMIT
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handlesubmit}
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-for-768">
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SUBMIT
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handlesubmit}
                    >
                      SUBMIT
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_edit_menu.loading = true;
                      res_edit_menu.data = {};
                      res_get_menu.loading = true;
                      res_get_menu.data = {};
                      navigate("/menu");
                    }}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_menu: state?.Get_view_all_menu_reducer,
  res_edit_menu: state?.Update_all_menu_reducer,
  res_menu_type: state?.Get_menu_type_data_reducer,
  res_station: state?.Get_station_data_reducer,
});
export default connect(mapStateToProps)(EditAllMenu);
