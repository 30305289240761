import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  GetEditBillingData,
  getToken,
} from "../../../Store/Action/useFetchData";

const ViewBillDetails = ({
  dispatch,
  res_get_data,
  viewBillTable,
  setViewBillTable,
  viewBillTableID,
}) => {
  const [billingData, setBillingData] = useState();
  const [rowsData, setRowsData] = useState([]);
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    dispatch(getToken());
    dispatch(GetEditBillingData(viewBillTableID));
  }, [viewBillTableID, dispatch]);

  useEffect(() => {
    if (viewBillTableID && res_get_data?.data?.status === true) {
      const installmentPayments =
        res_get_data?.data?.data?.bill_data[0]?.installment_payments;
        
        const arrayData = JSON.parse(installmentPayments)
      if (Array.isArray(JSON.parse(arrayData))) {
        
        setRowsData(JSON.parse(arrayData));
      } else {
        setRowsData([]);
      }

      setBillingData({
        event_id: viewBillTableID,
        bill_id: res_get_data?.data?.data?.bill_data[0]?.bill_id,
        menu_price: Number(res_get_data?.data?.data?.bill_data[0]?.menu_price),
        m_price: Number(res_get_data?.data?.data?.bill_data[0]?.m_price),
        s_price: Number(res_get_data?.data?.data?.bill_data[0]?.s_price),
        discount_per_user: Number(
          res_get_data?.data?.data?.bill_data[0]?.discount_per_user
        ),
        food_extra_cost: Number(
          res_get_data?.data?.data?.bill_data[0]?.food_extra_cost
        ),
        noOfGuests: Number(res_get_data?.data?.data?.bill_data[0]?.noOfGuests),
        food_notes: res_get_data?.data?.data?.bill_data[0]?.food_notes,
        sound_light_payment: Number(
          res_get_data?.data?.data?.bill_data[0]?.sound_light_payment
        ),
        sound_extra_cost: Number(
          res_get_data?.data?.data?.bill_data[0]?.sound_extra_cost
        ),
        sound_notes: res_get_data?.data?.data?.bill_data[0]?.sound_notes,
        service_charge: res_get_data?.data?.data?.bill_data[0]?.service_charge,
        whitehall_payment: Number(
          res_get_data?.data?.data?.bill_data[0]?.white_hall_payment
        ),
        payment_type: res_get_data?.data?.data?.bill_data[0]?.payment_type,
        spacial_dis: res_get_data?.data?.data?.bill_data[0]?.spacial_dis,
        tax_mode: res_get_data?.data?.data?.bill_data[0]?.tax_mode,
        gst_mode: res_get_data?.data?.data?.bill_data[0]?.gst_mode,
        total_gst: res_get_data?.data?.data?.bill_data[0]?.total_gst,

        paid: Number(res_get_data?.data?.data?.bill_data[0]?.paid),
        unpaid: Number(res_get_data?.data?.data?.bill_data[0]?.unpaid),
        grand_total: Number(
          res_get_data?.data?.data?.bill_data[0]?.grand_total
        ),
      });
    }
  }, [viewBillTableID, res_get_data]);

  const handleClose = () => {
    res_get_data.loading = true;
    res_get_data.data = {};
    setViewBillTable(false);
  };
  return (
    <>
      <div
        className={`modal${viewBillTable ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">View Bill</h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_data?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd px-auto px-md-5">
                <div className="row mb-3 ">
                  <div className="col-lg-4 col-12 ">
                    <label
                      for="basic-url"
                      className="form-label d-block text-start label"
                    >
                      FOOD AND DRINKS
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control inpt-border-trans"
                        value={
                          role === "3"
                            ? Number(
                                Number(billingData?.m_price) +
                                  Number(billingData?.s_price)
                              )?.toFixed(5)
                            : billingData?.menu_price
                        }
                        readOnly
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable justify-content-center ">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12 ">
                    <label
                      for="basic-url"
                      className="form-label d-block text-start label "
                    >
                      DISCOUNT PER USER
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        type="number"
                        autoComplete="off"
                        className="form-control inpt-border-trans"
                        name="discount_per_user"
                        value={billingData?.discount_per_user}
                        readOnly
                        //   onChange={handleChange}
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label
                      for="basic-url"
                      className="form-label label text-start d-block "
                    >
                      EXTRA COST
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group  price-jod price-jod-768">
                      <input
                        type="number"
                        className="form-control inpt-border-trans"
                        name="extra_cost"
                        autoComplete="off"
                        value={billingData?.food_extra_cost}
                        // onChange={handleChange}
                        readOnly
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label
                      for="basic-url"
                      className="form-label label text-start d-block "
                    >
                      SERVICE CHARGE
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group  price-jod price-jod-768">
                      <input
                        type="number"
                        className="form-control inpt-border-trans"
                        name="extra_cost"
                        autoComplete="off"
                        value={billingData?.service_charge}
                        // onChange={handleChange}
                        readOnly
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>
                {role === "3" && (
                  <div className="row mb-3 width-100-768 ">
                    <div className="col-lg-4 col-12">
                      <label
                        for="basic-url"
                        className="form-label label text-start d-block "
                      >
                        NUMBER OF GUESTS
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group  price-jod price-jod-768">
                        <input
                          type="number"
                          min={0}
                          className="form-control inpt-border-trans"
                          name="noOfGuests"
                          value={billingData?.noOfGuests}
                          readOnly
                          // onChange={handleChange}
                          autoComplete="off"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      NOTES
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group  price-textarea">
                      <textarea
                        className=" form-control inpt-border-trans"
                        rows="6"
                        autoComplete="off"
                        name="notes"
                        placeholder="Why..."
                        readOnly
                        // onChange={handleChange}
                        value={billingData?.food_notes}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row mb-3 ">
                  <div className="col-lg-4 col-12 ">
                    <label
                      for="basic-url"
                      className="form-label d-block text-start label"
                    >
                      SOUND AND LIGHT COST
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control inpt-border-trans"
                        value={billingData?.sound_light_payment?.toFixed(5)}
                        readOnly
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable justify-content-center ">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label
                      for="basic-url"
                      className="form-label label text-start d-block "
                    >
                      EXTRA COST
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group  price-jod price-jod-768">
                      <input
                        type="number"
                        className="form-control inpt-border-trans"
                        name="extra_cost"
                        autoComplete="off"
                        value={billingData?.sound_extra_cost}
                        // onChange={handleChange}
                        readOnly
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      NOTES
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group  price-textarea">
                      <textarea
                        className=" form-control inpt-border-trans"
                        rows="6"
                        autoComplete="off"
                        name="notes"
                        placeholder="Why..."
                        readOnly
                        // onChange={handleChange}
                        value={billingData?.sound_notes}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h3 style={{ fontWeight: 600 }} className="mb-4">
                    Payment
                  </h3>
                  <div className="form-in-768">
                    <h6>INSTALLMENT PAYMENT</h6>

                    {rowsData?.map((data, index) => {
                      return (
                        <div key={index}>
                          <hr className="d-block d-lg-none" />
                          <div className="d-flex align-items-center">
                            <div className="col-10 col-lg-8 col-xl-6">
                              <div className="d-block d-lg-flex ">
                                <div className="col-12 col-lg-6 pe-sm-2 pe-0">
                                  <label
                                    for="basic-url"
                                    className="form-label payment-lable"
                                  >
                                    {rowsData[index]?.f_v_name} PAYMENT
                                  </label>
                                  <div className="input-group mb-3 price-payment price-payment-768 ">
                                    <input
                                      type="number"
                                      autoComplete="off"
                                      className="form-control inpt-border-trans "
                                      name="price"
                                      value={rowsData[index]?.price}
                                      //   onChange={(e) =>
                                      //     paymentHandleChange(e, index)
                                      //   }
                                    />
                                    <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                      JOD
                                    </span>
                                  </div>
                                </div>

                                <div className=" col-12 col-lg-6 pe-sm-2 pe-0">
                                  <label
                                    for="basic-url"
                                    className="form-label pe-5  payment-lable"
                                  >
                                    DATE
                                  </label>
                                  <div className="input-group mb-3 jod_width price-payment price-payment-768 ">
                                    <input
                                      type=""
                                      className="form-control inpt-border-trans "
                                      readOnly
                                      value={
                                        rowsData[index]?.date === null
                                          ? ""
                                          : rowsData[index]?.date
                                      }
                                    />
                                    <DatePicker
                                      selected={
                                        !rowsData[index]?.formatdate === null
                                          ? rowsData[index].formatdate
                                          : null
                                      }
                                      //   onChange={(date) =>
                                      //     paymentHandleChange(
                                      //       {
                                      //         target: {
                                      //           name: "formatdate",
                                      //           value: date,
                                      //         },
                                      //       },
                                      //       index
                                      //     )
                                      //   }
                                      className="form-control inpt-border-trans"
                                      showPopperArrow={false}
                                      name="formatdate"
                                      dateFormat="dd-MM-yyyy"
                                      customInput={
                                        <span>
                                          <FaCalendarDays className="cursor-pointer" />
                                        </span>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* {rowsData[index]?.paymentIndex === "0" ? (
                          <div className="col-2 col-sm-1 col-xl-6">
                            <div className=" plus-icon ">
                              <FaCirclePlus
                                onClick={addTableRows}
                                className="cursor-pointer"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="col-2 col-sm-4 col-xl-6">
                            <div className="fs-1 ">
                              <IoMdCloseCircle
                                className="cursor-pointer"
                                onClick={() => deleteTableRows(index)}
                              />
                            </div>
                          </div>
                        )} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className=" mt-3 p-3 payment-box w-100 ">
                  <div className="form-in-768">
                    <div className="">
                      <div className="row mb-3 ">
                        <div className="col-12 col-lg-4 ">
                          <label
                            for="basic-url"
                            className="form-label d-block text-start label "
                          >
                            WHITE HALL PAYMENT
                          </label>
                        </div>
                        <div className="col-12 col-lg-8">
                          <div className="input-group price-jod price-jod-768">
                            <input
                              type="number"
                              className="form-control inpt-border-trans"
                              value={billingData?.whitehall_payment?.toFixed(5)}
                              readOnly
                            />
                            <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                              JOD
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 ">
                      <div className="col-lg-4 col-12">
                        <label
                          for="basic-url"
                          className="form-label label text-start d-block "
                        >
                          SPECIAL DISCOUNT
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            className="form-control inpt-border-trans"
                            value={billingData?.spacial_dis}
                            readOnly
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 ">
                      <div className="col-lg-4 col-12">
                        <label
                          for="basic-url"
                          className="form-label label text-start d-block "
                        >
                          TAX
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            className="form-control inpt-border-trans"
                            value={billingData?.total_gst}
                            readOnly
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-4 col-12">
                        <label
                          for="basic-url"
                          className="form-label text-start d-block  label"
                        >
                          GRAND TOTAL
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            className="form-control inpt-border-trans"
                            value={billingData?.grand_total?.toFixed(5)}
                            readOnly
                          />
                          <span className="input-group-text    input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex  mt-2 align-items-center  ">
                  <div className="col-12 col-lg-3">
                    <label
                      for="basic-url"
                      className="form-label me-xxl-5 me-0   label"
                    >
                      PAYMENT TYPE
                    </label>
                  </div>
                  <div className="col-12 col-lg-9">
                    <div className="mt-2 row d-flex ms-2 ms-sm-auto">
                      <div className="col-12 col-md-6 form-check form-check-inline m-0 d-flex">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="1"
                          checked={billingData?.payment_type === "1"}
                          readOnly
                          // onChange={handleChange}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio1"
                        >
                          Cash
                        </label>
                      </div>
                      <div className="col-12 col-md-6 form-check form-check-inline m-0 d-flex ">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="2"
                          readOnly
                          // onChange={handleChange}
                          checked={billingData?.payment_type === "2"}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio2"
                        >
                          Cheque
                        </label>
                      </div>
                      <div className="col-12 col-md-6 form-check form-check-inline m-0 d-flex ">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="3"
                          readOnly
                          // onChange={handleChange}
                          checked={billingData?.payment_type === "3"}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio3"
                        >
                          Bank Transfer
                        </label>
                      </div>
                      <div className="col-12 col-md-6 form-check form-check-inline m-0 d-flex ">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="4"
                          readOnly
                          // onChange={handleChange}
                          checked={billingData?.payment_type == "4"}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio3"
                        >
                          E-Payment
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_data: state?.Get_Edit_Billing_reducer,
});
export default connect(mapStateToProps)(ViewBillDetails);
