import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import ViewAllMenu from "../Forms/View/ViewAllMenu";
import DeleteAllMenu from "../Forms/Delete/DeleteAllMenu";

const MenuTable = ({ dispatch, res_token }) => {
  const [viewMenuModal, setViewMenuModal] = useState(false);
  const [viewMenuModalId, setViewMenuModalId] = useState();
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [deleteMenuModalId, setDeleteMenuModalId] = useState();
  const navigate = useNavigate();
  const [length, setLength] = useState(0);
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    dispatch(getToken());
  }, []);
  useEffect(() => {
    TableDatatablesManaged.init();
  }, [deleteMenuModal]);
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showmenu");
      var table1 = $("#showmenu").DataTable({
        columnDefs: [{ orderable: false, targets: 2 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_menu_data",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          // data:
          //   searchData === ""
          //     ? { submit: "" }
          //     : { submit: "custom", status: searchData },
          dataSrc: "menu_data",
        },
        columns: [
          {
            data: "id",
            width: "25%",
          },
          {
            data: "m_name",
            width: "80%",
          },

          {
            data: null,
            width: "18%",
            render: function (data) {
              if (role === "0") {
                return (
                  '<div class="d-flex  line-height"><span  id="viewMenu" value="' +
                  data.id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editMenu" value="' +
                  data.id +
                  '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteMenu" value="' +
                  data.id +
                  '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
                );
              } else {
                return (
                  '<div class="d-flex line-height"><span  id="viewMenu" value="' +
                  data.id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editMenu" value="' +
                  data.id +
                  '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
                );
              }
            },
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },

        order: [0, "desc"],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showmenu tbody").on("click", "#deleteMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setDeleteMenuModal(true);
          setDeleteMenuModalId(rowData.id);
        }
      });
      $("#showmenu tbody").on("click", "#viewMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setViewMenuModal(true);
          setViewMenuModalId(rowData.id);
        }
      });
      $("#showmenu tbody").on("click", "#editMenu", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row) && table1.row(row).data();
        if (id && id.id) {
          id && navigate(`/edit_all_menu/${id.id}`);
        }
      });
      $("#showmenu_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });

  return (
    <>
      {viewMenuModal && (
        <ViewAllMenu
          viewMenuModal={viewMenuModal}
          setViewMenuModal={setViewMenuModal}
          viewMenuModalId={viewMenuModalId}
        />
      )}
      {deleteMenuModal && (
        <DeleteAllMenu
          deleteMenuModal={deleteMenuModal}
          setDeleteMenuModal={setDeleteMenuModal}
          deleteMenuModalId={deleteMenuModalId}
        />
      )}
      <style>
        {`


          @media only screen and (max-width: 767px) {
          
            .table td:nth-child(1) {
              background-color: #cccccc !important;
              height: 100%;
              top: 0;
              left: 0;
              font-weight: bold;
              ${length === 0 ? `padding-left:9px!important;` : ``}
          }
        
          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          td:nth-of-type(2):before {
            content: "Menu Type";
           
          }
          td:nth-of-type(3):before {
            content: "Station1";
          }
          td:nth-of-type(4):before {
            content: "Station2";
          }
          td:nth-of-type(5):before {
            content: "Station3";
          }
          td:nth-of-type(6):before {
            content: "Station4";
          }
          td:nth-of-type(7):before {
            content: "Station5";
          }
          td:nth-of-type(8):before {
            content: "Action";
          }
          }
        `}
      </style>
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="float-right add-role-btn-768  mt-3 mt-lg-5 mt-xl-0">
                <Link to="/add_menu">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                  >
                    + ADD MENU
                  </button>
                </Link>
              </div>
              <div className="role-table mb-5">
                <table
                  id="showmenu"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Menu Type
                      </th>
                      {/* <th className="thead-color" scope="col">
                        Station1
                      </th>
                      <th className="thead-color" scope="col">
                        Station2
                      </th>
                      <th className="thead-color" scope="col">
                        Station3
                      </th>
                      <th className="thead-color" scope="col">
                        Station4
                      </th>
                      <th className="thead-color" scope="col">
                        Station5
                      </th> */}
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(MenuTable);
