import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ExtraData, getToken } from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
const Extras = ({ dispatch, res_user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [extras, setExtras] = useState({
    n_name: "",
    content_name: "",
    stream_name: "",
    band_name: "",
    singer_name: "",
    usher_name: "",
  });
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setExtras({ ...extras, [name]: value });
  };
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    if (res_user?.data?.status === true) {
      const notify = () =>
        toast.success(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
        navigate("/extras");
      }, 1500);
    } else if (res_user?.data?.status === false) {
      const notify = () =>
        toast.error(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
      }, 1500);
    }
  }, [res_user, navigate]);
  const handlesubmit = () => {
    const {
      n_name,
      content_name,
      stream_name,
      band_name,
      singer_name,
      usher_name,
    } = extras;
    var submitData;

    submitData = {
      napkin_name: n_name,
      content_of_screen_name: content_name,
      live_stream_name: stream_name,
      band_name: band_name,
      singer_name: singer_name,
      usher_name: usher_name,
    };
    dispatch(ExtraData(submitData, setLoading));
  };
  const handleReset = () => {
    setExtras({
      n_name: "",
      content_name: "",
      stream_name: "",
      band_name: "",
      singer_name: "",
      usher_name: "",
    });
  };

  return (
    <>
      <div className="">
        <Toaster />
        {/* {/ {/ -----------------------Information of the event------------------- /} /} */}

        <div className="container-fluid ">
          <div className=" padding-left-21 form-margin form-margin-768">
            <div className=" ">
              {/* {/ {/ Napkins /} /} */}
              <h3 className="mb-4 mt-5 ms-md-0 ms-sm-3 fw-bold">Napkins</h3>
              <div className="form-in-768">
                <div className="row mb-3  ">
                  <div className="col-12 col-lg-4 ">
                    <label for="basic-url" className="form-label  label">
                      NAPKIN NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="n_name"
                      value={extras.n_name}
                      onChange={handleChange}
                      className="form-control inpt inpt-768 mb-lg-0 mb-4  "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>

              {/* {/ {/ Content of Screen /} /} */}
              <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                Content of Screen
              </h3>
              <div className="form-in-768">
                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      CONTENT OF SCREEN NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      className="form-control inpt inpt-768 mb-lg-0 mb-4"
                      autoComplete="off"
                      name="content_name"
                      value={extras.content_name}
                      onChange={handleChange}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>

              {/* {/ {/ Live Stream Type /} /} */}

              <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                Live Stream Type
              </h3>
              <div className="form-in-768">
                <div className="row mb-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      LIVE STREAM NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      autoComplete="off"
                      type="text"
                      name="stream_name"
                      value={extras.stream_name}
                      onChange={handleChange}
                      className="form-control inpt inpt-768 mb-lg-0 mb-4"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
              {/*Band  */}
              <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                Band
              </h3>
              <div className="form-in-768">
                <div className="row mb-3  ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      BAND NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      autoComplete="off"
                      name="band_name"
                      value={extras.band_name}
                      onChange={handleChange}
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      SINGER NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      name="singer_name"
                      autoComplete="off"
                      value={extras.singer_name}
                      onChange={handleChange}
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      USHER NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      name="usher_name"
                      autoComplete="off"
                      value={extras.usher_name}
                      onChange={handleChange}
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>

              <div className="form-in-768">
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative  display-above-768">
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_user.loading = true;
                      res_user.data = {};
                      navigate("/extras");
                    }}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={handleReset}
                  >
                    RESET
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handlesubmit}
                    >
                      SAVE
                    </button>
                  )}
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-m d-5 centerText bottom-section display-for-768">
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_user.loading = true;
                      res_user.data = {};
                      navigate("/extras");
                    }}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={handleReset}
                  >
                    RESET
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handlesubmit}
                    >
                      SAVE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user: state?.Extra_data_reducer,
});
export default connect(mapStateToProps)(Extras);
