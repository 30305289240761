import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import { GetStationData, getToken, getViewAllMenuData } from "../../../Store/Action/useFetchData";
import { RiArrowRightDoubleLine } from "react-icons/ri";

const ViewAllMenu = ({dispatch,viewMenuModal,setViewMenuModal,viewMenuModalId,res_get_menu,res_station}) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedStations, setSelectedStations] = useState([]);
    const [addMenuData, setAddMenuData] = useState({
        charge: "",
        notes: "",
        m_name:"",
        s_name:"",
      });
    useEffect(() => {
      dispatch(getToken());
    dispatch(GetStationData());

    }, [dispatch]);
    useEffect(() => {
      dispatch(getViewAllMenuData(viewMenuModalId));
    }, [viewMenuModalId,dispatch]);
    useEffect(() =>  {
      if (viewMenuModalId && res_get_menu?.data?.status === true) {
        setAddMenuData({
            charge: res_get_menu?.data?.data?.service_charge,
            notes: res_get_menu?.data?.data?.kitchen_notes,
            m_name:res_get_menu?.data?.data?.m_name,
            s_name:res_get_menu?.data?.data?.s_name,
          });
          var stations = res_get_menu?.data?.data?.station_id;
           const stationsArray = stations.split(",").map(id => +id);
// console.log(stationsArray,'stationsArray');

      setSelectedStations(stationsArray)
      }
    }, [viewMenuModalId, res_get_menu]);
    const handleClose = () => {
      setViewMenuModal(false);
      res_get_menu.loading = true;
      res_get_menu.data = {};
    };

  const itemsToShow = showAll ? res_station?.data?.data.length : 10;

  return (
    <>
        <div
        className={`modal${viewMenuModal ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">View Menu</h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_menu?.loading ? (
              <MiniLoader />
            ) : (   
              <div className="modal-body modal-padd">
               <div className="row mb-2 mt-3">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    MENU TYPE
                  </label>
                </div>
                <div className="col-12 col-lg-8 ">
                   <input
                        type="text"
                        name="n_name"
                        value={addMenuData?.m_name}
                        readOnly
                        className="form-control inpt inpt-768 mb-lg-0 mb-3  "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                </div>
              </div>

              {/* <div className="row mb-2  ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    ADD STATION
                  </label>
                </div>
               
                   <div className="col-12 col-lg-8">
                   <div className="row" style={{width:"80%"}}>
                  {res_station?.data?.data && res_station?.data?.data.slice(0, itemsToShow).map((item, index) => (
                    <div className="col-12  mb-3" key={index}>
                      <div className="form-check form-check-inline ps-0">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          name={`station-${item.s_id}`}
                          checked={selectedStations.includes(item.s_id)}
                          // onChange={(e) => handleCheckboxChange(e, item.s_id)}
                          disabled={true}
                        />
                        <label
                          className="form-check-label check-label"
                          htmlFor={`inlineCheckbox${index}`}
                        >
                          {item.s_name}
                        </label>
                      </div>
                    </div>
                  ))}
                   {res_station?.data?.data?.length > 10 && !showAll && (
                    <span
                      className="fw-bold text-end more-pading border-0 price-textarea"
                      style={{cursor:"pointer"}}
                      onClick={() => setShowAll(true)}
                    >
                      MORE <RiArrowRightDoubleLine className="fs-5" />
                    </span>
                  )}
                  </div>
                  </div>
              </div> */}

{/* 
              <div className="row  mb-2">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label label">
                    SERVICE CHARGE
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="input-group price-jod price-jod-768">
                    <input
                      type="number"
                      name="charge"
                      value={addMenuData?.charge}
                      readOnly
                      className="form-control inpt-border-trans"
                    />
                    <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                      JOD
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="row mb-2  ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    KITCHEN NOTES
                  </label>
                </div>

                <div className="col-12 col-lg-8">
                  <div className="input-group mb-3   price-textarea">
                    <textarea
                      className=" form-control inpt-border-trans"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      autoComplete="off"
                      readOnly
                      value={addMenuData?.notes}
                      name="notes"
                    ></textarea>
                  </div>
                </div>
              </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
    res_token: state?.CrcToken_reducer,
    res_get_menu: state?.Get_view_all_menu_reducer,
  res_station: state?.Get_station_data_reducer,

  });
  export default connect(mapStateToProps)(ViewAllMenu);
