import React from "react";
import logo from "../../images/pdf_logo.png";
const GetEventContractPDF = ({ getData }) => {
  const WeddingData = [
    {
      key: "Date of the event:",
      value: getData?.startDate,
      eventType: ["Wedding"],
    },
    {
      key: "Event Category:",
      value: "Wedding",
      eventType: ["Wedding"],
    },
    {
      key: "Name of the reserve:",
      value: getData?.clientName,
      eventType: ["Wedding"],
    },
    // { key: "Wedding type:", value: getData?.eventType, eventType: ["Wedding"] },
    {
      key: "Phone number:",
      value: getData?.clientPhone,
      eventType: ["Wedding"],
    },
    { key: "Groom name:", value: getData?.groomName, eventType: ["Wedding"] },
    { key: "Bride name:", value: getData?.brideName, eventType: ["Wedding"] },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests,
      eventType: ["Wedding"],
    },
    {
      key: "Number of Free guests:",
      value: getData?.FreeGuests,
      eventType: ["Wedding"],
    },
    {
      key: "Mixed/not mixed:",
      value: getData?.typeOfWedding,
      eventType: ["Wedding"],
    },
  ];
  const EngagementData = [
    {
      key: "Date of the event:",
      value: getData?.startDate,
      eventType: ["Engagement"],
    },
    {
      key: "Event Category:",
      value: "Engagement",
      eventType: ["Engagement"],
    },
    {
      key: "Name of the reserve:",
      value: getData?.clientName,
      eventType: ["Engagement"],
    },
    {
      key: "Wedding type:",
      value: getData?.eventType,
      eventType: ["Engagement"],
    },
    {
      key: "Phone number:",
      value: getData?.clientPhone,
      eventType: ["Engagement"],
    },
    {
      key: "Groom name:",
      value: getData?.groomName,
      eventType: ["Engagement"],
    },
    {
      key: "Bride name:",
      value: getData?.brideName,
      eventType: ["Engagement"],
    },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests,
      eventType: ["Engagement"],
    },
  ];
  const CorporateData = [
    {
      key: "Date of the event:",
      value: getData?.startDate,
      eventType: ["Corporate"],
    },
    {
      key: "Event Category:",
      value:
      "Corporate",
      eventType: ["Corporate"],
    },
    {
      key: "Name of the reserve:",
      value: getData?.clientName,
      eventType: ["Corporate"],
    },
    {
      key: "Company Name:",
      value: getData?.companyName,
      eventType: ["Corporate"],
    },
    {
      key: "Phone number:",
      value: getData?.clientPhone,
      eventType: ["Corporate"],
    },
    {
      key: "Company ID:",
      value: getData?.companyID,
      eventType: ["Corporate"],
    },
    {
      key: "Tax No:",
      value: getData?.taxNo,
      eventType: ["Corporate"],
    },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests,
      eventType: ["Corporate"],
    },
  ];
  const ConcertData = [
    {
      key: "Date of the event:",
      value: getData?.startDate,
      eventType: ["Concert"],
    },
    {
      key: "Event Category:",
      value:
      "Concert",
      eventType: ["Concert"],
    },
    {
      key: "Name of the reserve:",
      value: getData?.clientName,
      eventType: ["Concert"],
    },
    {
      key: "Phone number:",
      value: getData?.clientPhone,
      eventType: ["Concert"],
    },
    {
      key: "Company Name:",
      value: getData?.companyName,
      eventType: ["Concert"],
    },
    {
      key: "Company ID:",
      value: getData?.companyID,
      eventType: ["Concert"],
    },
    {
      key: "Tax No:",
      value: getData?.taxNo,
      eventType: ["Concert"],
    },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests,
      eventType: ["Concert"],
    },
  ];
  const PrivateData = [
    {
      key: "Date of the event:",
      value: getData?.startDate,
      eventType: ["Private"],
    },
    {
      key: "Event Category:",
      value:
      "Private",
      eventType: ["Private"],
    },
    {
      key: "Name of the reserve:",
      value: getData?.clientName,
      eventType: ["Private"],
    },
    {
      key: "Phone number:",
      value: getData?.clientPhone,
      eventType: ["Private"],
    },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests,
      eventType: ["Private"],
    },
  ];
  const CharityData = [
    {
      key: "Date of the event:",
      value: getData?.startDate,
      eventType: ["Charity"],
    },
    {
      key: "Event Category:",
      value: "Charity",
      eventType: ["Charity"],
    },
    {
      key: "Name of the Charity:",
      value: getData?.clientName,
      eventType: ["Charity"],
    },
    {
      key: "Phone number:",
      value: getData?.clientPhone,
      eventType: ["Charity"],
    },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests,
      eventType: ["Charity"],
    },
  ];
  const FoodAndDrinkData = [
    {
      key: "Food source:",
      value: "White Hall",
    },
    {
      key: "Drinks Source:",
      value: "White Hall",
    },
    {
      key: "Food and drinks Notes:",
      value:
        getData?.foodnotes === "" || getData?.foodnotes === null
          ? "-"
          : getData?.foodnotes,
    },
  ];
  const SoundAndLightData = [
    {
      key: "Sound and light :",
      value: "White Hall",
    },
    {
      key: "Sound and light package name:",
      value: "White Hall",
    },
    {
      key: "Sound and light Notes:",
      value:
        getData?.soundnotes === "" || getData?.soundnotes === null
          ? "-"
          : getData?.soundnotes,
    },
  ];

  const EventDetail =
    getData?.eventType === "Wedding"
      ? WeddingData
      : getData?.eventType === "Engagement"
      ? EngagementData
      : getData?.eventType === "Corporate"
      ? CorporateData
      : getData?.eventType === "Concert"
      ? ConcertData
      : getData?.eventType === "Private"
      ? PrivateData
      : getData?.eventType === "Charity"
      ? CharityData
      : "";
  return (
    <>
      <style>
        {`
    .wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        background-color: white !important;
        font-family: auto !important;
      }
      
      .receipt-box {
        width: 760px;
        display: flex;
        flex-direction: column;
        margin: 0 35px;
      }
      
     .th,.td{
        width:50%;
      }
      
      .p{
        margin :0 !important;
        color: black !important;
        padding:2px 5px!important;
        // font-size:15px;
      }
      .p-td{
        margin :0 !important;
        padding:2px 5px!important;
        // font-size:15px;
      }

    .home-image {
        background-color: #ffffff;
      
        background-repeat: no-repeat;
        background-size: 93% 45% !important;
        overflow: hidden;
        /* min-height: 44vh; */
        background-position: center;
        /* opacity: 0.2; */
      }

      h6{
        font-size:18px;
      }
    `}
      </style>
      <div className="wrapper">
        <div className="receipt-box">
          <div className="actual-receipt" style={{ height: "1050px" }}>
            <div
              className="home-image m-4"
              style={{
                height: "100%",
                backgroundImage: `linear-gradient(rgba(255, 255, 255,0.930),rgb(255, 255, 255,0.930)),url(${logo})`,
              }}
            >
              <div className="container position-relative">
                <div className="row pt-4 pb-2  border-3 border-bottom border-black">
                  <div className=" p-0 col-6 text-start">
                    <img src={logo} style={{ width: "112px" }} />
                  </div>
                  <div className="col-6 text-end">
                    {" "}
                    <p className="text-black position-absolute bottom-0 end-0 mb-1">
                      Date: {getData?.startDate}
                    </p>
                  </div>
                </div>
              </div>

              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Client information
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {EventDetail?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Food and drinks
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {FoodAndDrinkData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Sound and light
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {SoundAndLightData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {(getData?.e_notes !== "" || getData?.e_notes !== null) && (
                <div className="container p-2">
                  <table
                    className="tables mt-5"
                    style={{ padding: "25px", width: "90%", margin: "auto" }}
                  >
                    <tbody>
                      <tr>
                        <td className="th">
                          <h6 className="text-black fw-bold mb-1 ps-1">
                            General notes:
                          </h6>
                        </td>
                        <td className="td">
                          <p className="p-td text-muted">
                            {getData?.e_notes === "" ? "-" : getData?.e_notes}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetEventContractPDF;
