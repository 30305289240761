import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import {
  AdminReportsPdfDownload,
  FinalAccountPayPdf,
  getToken,
} from "../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Pusher from "pusher-js";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";

const AccountStatement = ({ dispatch, res_token, res_pdf }) => {
  const api_token = sessionStorage.getItem("api_token");
  const [length, setLength] = useState(0);
  const [finalDate, setFinalDate] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [socket, setSocket] = useState();
  const [filterData, setFilterData] = useState({
    startDate: "",
  });

  useEffect(() => {
    res_pdf.loading = true;
    res_pdf.data = {};
    dispatch(getToken());
  }, []);
  useEffect(() => {
    TableDatatablesManaged.init();
  }, [filterData]);

  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    // console.log(channel, "channel");
    setSocket(channel);
  }, []);
  const handleDateChange = (e) => {
    const dateString = e;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setSelectedDate(date);
    setFilterData({ ...filterData, startDate: `${year}-${month}-${day}` });
    setFinalDate(`${day}-${month}-${year}`);
  };
  useEffect(() => {
    if (socket) {
      // console.log(socket, "socket");
      socket.bind("tax_mode_on", async (data) => {
        window.location.reload();
      });
    }
  }, [socket]);
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showReporting");
      var table1 = $("#showReporting").DataTable({
        columnDefs: [{ orderable: false, targets: 6 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: `/api/acc_bill_stat`,
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          data: {
            startDate: filterData?.startDate,
          },
          dataSrc: "user_data",
        },

        columns: [
          {
            data: "eventID",
            width: "10%",
          },
          {
            data: "clientName",
            width: "25%",
          },
          {
            data: "startDate",
            width: "20%",
          },
          {
            data: null,
            width: "29%",
            render: function (data) {
              if (data.paid === null) {
                return `-`;
              } else {
                return data.paid;
              }
            },
          },
          {
            data: null,
            width: "29%",
            render: function (data) {
              if (data.total_grand_total === null) {
                return `-`;
              } else {
                return data.total_grand_total;
              }
            },
          },
          {
            data: null,
            width: "29%",
            render: function (data) {
              if (data.unpaid === null) {
                return `-`;
              } else {
                return data.unpaid;
              }
            },
          },
          {
            data: null,
            width: "10%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-eye fs-4 ps-2 me-0 fw-bold  text-success cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
          // console.log("Table length:", tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showReporting tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        //console.log(rowData, "rowData");
        if (rowData && rowData.eventID) {
          dispatch(FinalAccountPayPdf(rowData.eventID));
        }
      });
      $("#showReporting_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  useEffect(() => {
    if (res_pdf?.data?.status === true) {
      const pdfUrl = res_pdf?.data?.url;
      window.open(pdfUrl);
    } else if (res_pdf?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
      }, 1500);
    }
  }, [res_pdf]);
  return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ? `padding-left:9px!important;` : ``}
  }
  .table td:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(3) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
  }

  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }
      .table td:nth-child(6) {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #cccccc;
        }
      .table td:nth-child(7) {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #cccccc;
        }
        
td:nth-of-type(1):before {
  ${length !== 0 ? `content: "#";` : ``}
 
}
td:nth-of-type(2):before {
  content: "Name";
}
td:nth-of-type(3):before {
  content: "Date";
}
td:nth-of-type(4):before {
  content: "Payment";
}
td:nth-of-type(5):before {
  content: "Total Amount";
}
td:nth-of-type(6):before {
  content: "Rest Amount";
}
td:nth-of-type(7):before {
  content: "Action";
}
}
`}
      </style>
      <Toaster />
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 position-relative">
              <div className="container">
                <div className="row d-flex row-7 justify-content-center justify-content-lg-end mt-4 mt-lg-auto">
                  <div className="col-12 col-sm-6 col-lg-6 col-xl-5  mb-xl-4">
                    <div className="row d-flex ">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label d-none d-lg-block"
                        >
                          EVENT DATE
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div
                          className="input-group mb-3 jod_width price price-768 cal_width w-auto kitchen-calender"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            type=""
                            className="form-control inpt-border-trans "
                            value={finalDate}
                            placeholder="EVENT DATE"
                            readOnly
                          />
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            className="form-control inpt-border-trans "
                            showPopperArrow={false}
                            dateFormat="yyyy-MM-dd"
                            //   value={finalDate ? finalDate : "aaa"}
                            customInput={
                              <span className="cursor-pointer">
                                <FaCalendarDays />
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="role-table">
                <table
                  id="showReporting"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Name
                      </th>
                      <th className="thead-color" scope="col">
                        Date
                      </th>
                      <th className="thead-color" scope="col">
                        Payment
                      </th>
                      <th className="thead-color" scope="col">
                        Total Amount
                      </th>
                      <th className="thead-color" scope="col">
                        Rest Amount
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf: state?.Final_account_pay_PDF_data_reducer,
});

export default connect(mapStateToProps)(AccountStatement);
