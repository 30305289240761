import React, { useEffect, useRef, useState } from "react";
import { GrNext } from "react-icons/gr";
import { MdArrowBackIos } from "react-icons/md";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FirstEvent from "./EventType/FirstEvent";
import InformationEvent from "./EventType/InformationEvent";
import FoodAndDrinkEvent from "./EventType/FoodAndDrinkEvent";
import SupplierEvent from "./EventType/SupplierEvent";
import ExtrasEvent from "./EventType/ExtrasEvent";
import { connect } from "react-redux";
import {
  createEventCalander,
  createEventType,
  getCalendarEvent,
  getEditEventDetailsOnId,
  getEventDetailsOnId,
  getToken,
} from "../../../Store/Action/useFetchData";
import pink_img from "../../../../images/pink-triangle.png";
import green_img from "../../../../images/greentriangle.png";
import toast, { Toaster } from "react-hot-toast";
import EditFirstEvent from "../Edit/EventType/EditFirstEvent";
import EditInformationEvent from "../Edit/EventType/EditInformationEvent";
import EditFoodAndDtinkEvent from "../Edit/EventType/EditFoodAndDtinkEvent";
import EditSupplierEvent from "../Edit/EventType/EditSupplierEvent";
import EditExtrasEvent from "../Edit/EventType/EditExtrasEvent";
import { useNavigate } from "react-router-dom";
import BillingEvent from "./EventType/BillingEvent";
import SubContractPdf from "./EventType/SubContractPdf";

const localizer = momentLocalizer(moment);
const CreateEventType = ({
  dispatch,
  res_get_events,
  res_event_on_id,
  res_edit_event_on_id,
  res_create_event,
}) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [selectedMenu, setselectedMenu] = useState(5);
  const [secondEvent, setSecondEvent] = useState(null);
  const [currentEventId, setCurrentEventId] = useState();

  const [defaultDate, setDefaultDate] = useState(new Date());
  const [hideAndShow, setHideAndShow] = useState(false);
  const [clientName, setClientName] = useState("");

  const [billId, setBillId] = useState();

  const [getData, setGetData] = useState({
    eventID: "",
    eventType: "",
    clientName: "",
    clientPhone: "",
    groomName: "",
    brideName: "",
    noOfGuests: 0,
    compneyName: "",
    compneyId: "",
    // singersNames1: "",
    // singersNames2: "",
    // singersNames3: "",
    // eventCategory: "",
    phoneNumber1: "",
    phoneNumber2: "",
    timeToStart: "",
    timeOfArrival: "",
    firstGateEntryTime: "",
    zaffaTime: "",
    zaffaArtistName: "",
    zaffaArtistPhoneNumber: "",
    cakeShowTime: "",
    buffetTime: "",
    secondGateEntryTime: "",


    weddingPlannerID: "",
    photographerID: "",
    flowersPlannerID: "",
    furnitureID: "",
    extrasNotes: "",
    waiters: "",
    napkins: "",
    contentOfScreens: "",
    liveStream: "",
    band: "",
    singer: "",
    ushers: "",
    generalNotes: "",

    cakeSource: "",
    suppliersNotes: "",
  });



  const [state, setState] = useState(false);
  useEffect(() => {
    dispatch(getToken());
    dispatch(createEventCalander());
  }, []);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {

    if (res_get_events?.data?.status === true) {
      var tax_mode_final = res_get_events?.data?.tax?.tax_mode
      const events_arr = res_get_events.data.data;
      let transformedEvents = [];

      if (tax_mode_final === "0") {
        transformedEvents = events_arr.map((event, index, array) => {
          const {
            eventID,
            eventType,
            startDate,
            endDate,
            reservationStatus,
            tax_mode,
          } = event;
          if (tax_mode === "0") {
            const isSecond = array
              .slice(0, index)
              .some((prevEvent) => prevEvent.startDate === startDate);
            // Adjusting the time to 00:00:00
            const startDateTime = new Date(startDate);
            startDateTime.setHours(0, 0, 0, 0);

            const endDateTime = new Date(endDate);
            endDateTime.setHours(0, 0, 0, 0);

            // Check if eventID already exists in transformedEvents
            const isDuplicate = transformedEvents.some(
              (prevTransformedEvent) => prevTransformedEvent.id === eventID
            );

            // Return null if it's a duplicate eventID
            if (isDuplicate) {
              return null;
            }

            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: true,
            };
          } else {
            return null;
          }
        });
        if (transformedEvents) {
          setEvents([...transformedEvents.filter((event) => event !== null && event !== undefined)]);
      }
      } else if (tax_mode_final === "1") {
        transformedEvents = events_arr.map((event, index, array) => {
          const {
            eventID,
            eventType,
            startDate,
            endDate,
            reservationStatus,
            tax_mode,
          } = event;
          const isSecond = array
            .slice(0, index)
            .some((prevEvent) => prevEvent.startDate === startDate);

          // Adjusting the time to 00:00:00
          const startDateTime = new Date(startDate);
          startDateTime.setHours(0, 0, 0, 0);

          const endDateTime = new Date(endDate);
          endDateTime.setHours(0, 0, 0, 0);

          // Check if eventID already exists in transformedEvents
          const isDuplicate = transformedEvents.some(
            (prevTransformedEvent) => prevTransformedEvent.id === eventID
          );

          // Return null if it's a duplicate eventID
          if (isDuplicate) {
            return null;
          }
          if (tax_mode === "0") {
            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: true,
            };
          } else if (tax_mode === "1") {
            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: false,
            };
          }
        });
        if (transformedEvents) {
          setEvents([...transformedEvents.filter((event) => event !== null && event !== undefined)]);
      }
      }

    } else if (res_get_events?.data.status === false) {

    }
  }, [res_get_events]);
  const handleEventClick = (event) => {
    setCurrentEventId(event.id)
    setSecondEvent(event);
    dispatch(getEditEventDetailsOnId(event.id));
    dispatch(getEventDetailsOnId(event.id));
    setGetData({
      eventID: "",
      eventType: "",
      clientName: "",
      clientPhone: "",
      groomName: "",
      brideName: "",
      noOfGuests: 0,
      compneyName: "",
      compneyId: "",
      phoneNumber1: "",
      phoneNumber2: "",
      timeToStart: "",
      timeOfArrival: "",
      firstGateEntryTime: "",
      zaffaTime: "",
      zaffaArtistName: "",
      zaffaArtistPhoneNumber: "",
      cakeShowTime: "",
      buffetTime: "",
      secondGateEntryTime: "",
      weddingPlannerID: "",
      photographerID: "",
      flowersPlannerID: "",
      furnitureID: "",
      extrasNotes: "",
      waiters: "",
      napkins: "",
      contentOfScreens: "",
      liveStream: "",
      band: "",
      singer: "",
      ushers: "",
      generalNotes: "",
      cakeSource: "",
      suppliersNotes: "",
    });
  };


  useEffect(() => {
    if (res_event_on_id?.data?.status === true) {
      const events_arr = res_event_on_id.data.data;

      setGetData({
        ...getData,
        eventID: events_arr.eventID,
        eventType: events_arr.eventType,
        clientName: events_arr.clientName,
        clientPhone: events_arr.clientPhone,
        groomName: events_arr.groomName,
        brideName: events_arr.brideName,
        noOfGuests: events_arr.noOfGuests,
        phoneNumber1: events_arr.phoneNumber1,
        phoneNumber2: events_arr.phoneNumber2,


        timeToStart: events_arr?.timeToStart,
        timeOfArrival: events_arr.timeOfArrival,
        firstGateEntryTime: events_arr.firstGateEntryTime,
        zaffaTime: events_arr.zaffaTime,
        zaffaArtistName: events_arr.zaffaArtistName,
        zaffaArtistPhoneNumber: events_arr.zaffaArtistPhoneNumber,
        cakeShowTime: events_arr.cakeShowTime,
        buffetTime: events_arr.buffetTime,
        secondGateEntryTime: events_arr.secondGateEntryTime,

        weddingPlannerID: events_arr.weddingPlannerID,

        photographerID: events_arr.photographerID,
        flowersPlannerID: events_arr.flowersPlannerID,
        furnitureID: events_arr.furnitureID,
        extrasNotes: events_arr.extrasNotes,
        waiters: events_arr.waiters,
        napkins: events_arr.napkins,
        contentOfScreens: events_arr.contentOfScreens,
        liveStream: events_arr.liveStream,
        band: events_arr.band,
        singer: events_arr.singer,
        ushers: events_arr.ushers,
        generalNotes: events_arr.generalNotes,


        cakeSource: events_arr.cakeSource,
        suppliersNotes: events_arr.suppliersNotes,

      });

      setShowForm(true);
      setState(false);
    } else if (res_event_on_id?.data.status === false) {
      setState(true);

    }
  }, [res_event_on_id]);
  const rbc_dates = document.querySelectorAll(".rbc-date-cell");

  useEffect(() => {
    if (rbc_dates.length > 0) {
      rbc_dates.forEach((date) => {
        date.style.setProperty("margin-top", "26px");
        // date.style.marginTop = '26px';
      });
    }
  }, [rbc_dates]);


  useEffect(() => {
    setClientName(res_edit_event_on_id?.data?.data?.clientName)

    setBillId(res_edit_event_on_id?.data?.bill_id)
    if (res_edit_event_on_id?.data?.status === true && state === true) {

      // setState(false);
      const events_arr = res_edit_event_on_id.data.data;
      if (events_arr.eventType === "Wedding")
        setGetData({
          ...getData,
          eventID: events_arr.eventID,
          eventType: events_arr.eventType,
          clientName: events_arr.clientName,
          clientPhone: events_arr.clientPhone,
          groomName: events_arr.groomName,
          brideName: events_arr.brideName,
          noOfGuests: events_arr.noOfGuests,
          phoneNumber1: events_arr.phoneNumber1,
          phoneNumber2: events_arr.phoneNumber2,
        });

      if (events_arr.eventType === "Corporate")
        setGetData({
          ...getData,
          eventID: events_arr.eventID,
          eventType: events_arr.eventType,
          clientName: events_arr.clientName,
          clientPhone: events_arr.clientPhone,
          compneyName: events_arr.companyName,
          compneyId: events_arr.companyID,
          noOfGuests: events_arr.noOfGuests,
        });

      if (events_arr.eventType === "Concert")
        setGetData({
          ...getData,
          eventID: events_arr.eventID,
          eventType: events_arr.eventType,
          clientName: events_arr.clientName,
          clientPhone: events_arr.clientPhone,
          compneyName: events_arr.companyName,
          compneyId: events_arr.companyID,
          noOfGuests: events_arr.noOfGuests,
          singersNames1: events_arr.singersNames1,
          singersNames2: events_arr.singersNames2,
          singersNames3: events_arr.singersNames3,
        });

      if (events_arr.eventType === "Private")
        setGetData({
          ...getData,
          eventID: events_arr.eventID,
          eventType: events_arr.eventType,
          clientName: events_arr.clientName,
          clientPhone: events_arr.clientPhone,
          eventCategory: events_arr.eventCategory,
          noOfGuests: events_arr.noOfGuests,
        });

      setShowForm(true);
    } else if (res_edit_event_on_id?.data.status === false && state === true) {
      const notify = () =>
        toast.error(res_edit_event_on_id?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  }, [res_edit_event_on_id]);

  useEffect(() => {
    if (res_create_event?.data?.status === true) {
      setTimeout(() => {
        res_create_event.loading = true;
        res_create_event.data = {};
        res_event_on_id.loading = true;
        res_event_on_id.data = {};
        res_edit_event_on_id.loading = true;
        res_edit_event_on_id.data = {};
        // setselectedMenu(6);
        setShowForm(false);
      }, 1500);
    } else if (res_create_event?.data?.status === false) {
      const notify = () =>
        toast.error(res_create_event?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        setselectedMenu(5);

        res_create_event.loading = true;
        res_create_event.data = {};
      }, 1500);
    }
  }, [res_create_event]);


  useEffect(() => {
    setSecondEvent(null);
  }, [events]);

  const handleSelectSlot = ({ slots }) => {
    setShowForm(true);
    setSelectedDates(slots);
  };
  const handleDeleteEvent = (eventId) => {
    const updatedEvents = events.filter((event) => event.id !== eventId);
    setEvents(updatedEvents);
  };
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const CustomToolbar = ({ label, onNavigate }) => {
    const labelRef = useRef(label);
    useEffect(() => {
      const selectedDate = new Date(labelRef.current);
      const selectedMonthStart = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );

      if (
        selectedMonth === null ||
        selectedMonth.getTime() !== selectedMonthStart.getTime()
      ) {
        setSelectedMonth(selectedMonthStart);
      }
    }, [labelRef, selectedMonth]);

    return (
      <>
        <div
          style={{ backgroundColor: "black", padding: "10px" }}
          className="rbc-toolbar "
        >
          <div>
            <MdArrowBackIos
              className="cursor-pointer"
              onClick={() => onNavigate("PREV")}
            />
          </div>
          <div>
            <div className="rbc-toolbar-label ">{label}</div>
          </div>
          <div>
            <GrNext
              className="cursor-pointer"
              onClick={() => onNavigate("NEXT")}
            />
          </div>
        </div>
        <div className="note-above-calendar-header d-sm-flex my-3 justify-content-around d-block mx-sm-0 mx-xs-5 mx-4">
          <div>
            {" "}
            <span>NOTE:</span>
            <img src={pink_img} alt="123" className="ms-4 me-2 mb-2" />
            <span>Final Reservation</span>
          </div>
          <div className="ms-sm-0 ms-5 ps-sm-0 ps-4 ">
            {" "}
            <img src={green_img} alt="123" className="me-2 mb-2" />
            <span>Tentative (one only)</span>
          </div>
          <div className="ms-sm-0 ms-5 ps-sm-0 ps-4 ">
            {" "}
            <img src={green_img} alt="123" className="me-1 mb-2" />
            <img src={green_img} alt="123" className="me-2 mb-2" />
            <span>Tentative (two only)</span>
          </div>
        </div>
      </>
    );
  };

  const handleFormSubmit = () => {
    dispatch(createEventType(getData, setLoading));
  };

  const currentMonthStart = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth(),
    1
  );

  const isFirstDayFridayOrSaturday =
    currentMonthStart.getDay() === 5 || currentMonthStart.getDay() === 6;

  const isSaturday = currentMonthStart.getDay() === 6;

  const lastDayOfCurrentMonth = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth() + 1,
    0
  );

  const has31Days = lastDayOfCurrentMonth.getDate() === 31;

  const has30Days = lastDayOfCurrentMonth.getDate() === 30;

  let rowSix = false; // Initialize rowSix to false

  if (isFirstDayFridayOrSaturday && has31Days) {
    rowSix = true;
  } else if (isSaturday && has30Days) {
    rowSix = true;
  }


  const eventStyleGetter = (event, start, end, isSelected) => {
    const differenceInMilliseconds = end.getTime() - start.getTime();

    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24); 

    const isFirstDay = moment(event.start).isSame(start, "day");

    // Check if the start day of the event is Saturday
    const isFirstSaturday = moment(event.start).format("dddd") === "Saturday";
    const isFirstFriday = moment(event.start).format("dddd") === "Friday";
    const isFirstThursday = moment(event.start).format("dddd") === "Thursday";

    //------------------------------------------------------- 6 row -----------------------------------------------------------

    const monthStart = new Date(start.getFullYear(), start.getMonth(), 1);

    const isFirstDayFridayOrSaturday =
      monthStart.getDay() === 5 || monthStart.getDay() === 6;
    const isSaturday = monthStart.getDay() === 6;

    const lastDayOfMonth = new Date(
      start.getFullYear(),
      start.getMonth() + 1,
      0
    );
    const has31Days = lastDayOfMonth.getDate() === 31;

    const has30Days = lastDayOfMonth.getDate() === 30;

    if (isFirstDayFridayOrSaturday && has31Days) {
      rowSix = true;
    } else if (isSaturday && has30Days) {
      rowSix = true;
    }

    //-----------------------------------------------------------------------------------------------------------------------------------

    let style = {
      position:
        hoveredEvent === event.id && isFirstSaturday ? "relative" : "relative",
      top:
        isFirstSaturday && hoveredEvent === event.id
          ? "-50px"
          : hoveredEvent === event.id
            ? "-50px"
            : "-50px",
      backgroundColor: event.type === "FinalReserve" ? "#eecde0" : "#ceede7",
      borderRadius: "0px",
      opacity: hoveredEvent === event.id ? 1 : 0.8,
      color: "white",

      display: "block",
      height: rowSix
        ? hoveredEvent === event.id
          ? "88px"
          : "88px"
        : hoveredEvent === event.id && isFirstSaturday
          ? "88px"
          : hoveredEvent === event.id
            ? "88px"
            : "88px",
      // border:
      //   hoveredEvent === event.id ? " 1px solid #000000" : "1px solid #000000",

      zIndex: hoveredEvent === event.id ? 8 : 5,
      width:
        isFirstSaturday && hoveredEvent === event.id
          ? "100%"
          : hoveredEvent === event.id && differenceInDays === 1
            ? "100%"
            : "100%",
      transition: "width 0.1s ease ",
      boxShadow: hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none",
      right: isFirstSaturday && hoveredEvent === event.id ? "0%" : "",
    };

    if (window.innerWidth <= 1440) {
      style.position =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
          ? "relative"
          : "relative";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
          ? "100%"
          : hoveredEvent === event.id && differenceInDays === 1
            ? event.type === "FinalReserve"
              ? "100%"
              : "100%"
            : "100%";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
          ? "-50px"
          : "-50px";

      // style.right =
      //   (isFirstFriday && hoveredEvent === event.id) ||
      //     (isFirstSaturday && hoveredEvent === event.id)
      //     ? "0%"
      //     : "";
    }
    if (window.innerWidth <= 768) {
      // style.width =
      //   hoveredEvent === event.id && differenceInDays === 1 ? "170%" : "auto";
      style.height = rowSix ? "95px" : "95px";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
          ? "100%"
          : hoveredEvent === event.id && differenceInDays <= 2
            ? event.type === "FinalReserve"
              ? "150%"
              : "150%"
            : "auto";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
          ? "-58px"
          : hoveredEvent === event.id
            ? "-58px"
            : "-58px";
    }
    if (window.innerWidth <= 576) {
      style.position =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "relative"
          : "relative";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "150%"
          : hoveredEvent === event.id && differenceInDays <= 3
            ? event.type === "FinalReserve"
              ? "150%"
              : "150%"
            : hoveredEvent === event.id
              ? "150%"
              : "auto";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "84px"
          : "84px"
        : "84px";

      // style.right =
      //   (isFirstFriday && hoveredEvent === event.id) ||
      //     (isFirstSaturday && hoveredEvent === event.id) ||
      //     (isFirstThursday && hoveredEvent === event.id)
      //     ? "0%"
      //     : "";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "-58px"
          : "-58px";
      style.right = isFirstSaturday && hoveredEvent === event.id ? "50%" : "";
    }
    if (window.innerWidth <= 375) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "150%"
          : hoveredEvent === event.id && differenceInDays <= 3
            ? event.type === "FinalReserve"
              ? "200%"
              : "200%"
            : "100%";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "84px"
          : "84px"
        : "84px";

      style.right = isFirstSaturday && hoveredEvent === event.id ? "50%" : "";
    }
    if (window.innerWidth <= 320) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "70%"
          : hoveredEvent === event.id && differenceInDays <= 3
            ? event.type === "FinalReserve"
              ? "500%"
              : "420%"
            : "auto";
      style.height = rowSix ? "52px" : "52px";
    }

    const otherEventsOnSameDay = events.filter(
      (e) =>
        e.id !== event.id &&
        moment(e.start).isSame(event.start, "day") &&
        moment(e.end).isSame(event.end, "day")
    );

    if (otherEventsOnSameDay.length > 0) {
      style.position =
        event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "relative"
            : "relative"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "relative"
            : "relative";
      style.top = rowSix
        ? event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "-50px"
            : "-50px"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "-139px"
            : "-139px"
        : event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "-50px"
            : "-50px"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "-139px"
            : "-139px";
      style.backgroundColor =
        event.type === "FinalReserve" ? "#eecde0" : "#ceede7";
      style.borderRadius = "0px";
      style.opacity = hoveredEvent === event.id ? 1 : 0.8;
      style.color = "white";
      style.display = "block";
      style.height =
        event.isSecond === 0
          ? rowSix
            ? hoveredEvent === event.id
              ? "88px"
              : "88px"
            : hoveredEvent === event.id
              ? "88px"
              : "88px"
          : rowSix
            ? hoveredEvent === event.id
              ? "88px"
              : "88px"
            : hoveredEvent === event.id
              ? "89px"
              : "88px";

      style.zIndex = hoveredEvent === event.id ? 8 : 5;
      style.transition = "width 0.1s ease";
      style.boxShadow =
        hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none";

      style.left =
        event.isSecond === 0
          ? ""
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? ""
            : "55%";

      style.width =
        event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "100%"
            : hoveredEvent === event.id
              ? differenceInDays < 3
                ? "140%"
                : "100%"
              : "50%"
          : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "140%"
              : "100%"
            : "45%";

      if (window.innerWidth <= 1440) {

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "100%"
              : hoveredEvent === event.id
                ? differenceInDays < 3
                  ? "140%"
                  : "100%"
                : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "100%"
              : hoveredEvent === event.id
                ? differenceInDays < 3
                  ? "140%"
                  : "100%"
                : "45%";
        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-50%"
              : hoveredEvent === event.id && isFirstFriday
                ? ""
                : ""
            : hoveredEvent === event.id && isFirstSaturday
              ? "-0%"
              : hoveredEvent === event.id && isFirstFriday
                ? ""
                : hoveredEvent === event.id && isFirstThursday
                  ? "0px"
                  : "55%";
        style.right = "0%";

      }
      if (window.innerWidth <= 768) {
        style.height = rowSix
          ? event.isSecond === 0
            ? "95px"
            : "95px"
          : event.isSecond === 0
            ? "95px"
            : "95px";
        style.top =
          (isFirstFriday && hoveredEvent === event.id) ||
            (isFirstSaturday && hoveredEvent === event.id)
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : rowSix
              ? event.isSecond === 0
                ? "-58px"
                : "-154px"
              : event.isSecond === 0
                ? "-58px"
                : "-154px";

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "100%"
              : hoveredEvent === event.id
                ? differenceInDays < 3
                  ? "220%"
                  : "100%"
                : "50%"
            : hoveredEvent === event.id
              ? differenceInDays < 3
                ? "100%"
                : "100%"
              : "45%";
        // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
      }
      if (window.innerWidth <= 576) {
        // style.height = event.isSecond === 0
        // ? rowSix
        //   ? "78px"
        //   : hoveredEvent === event.id
        //   ? "55px"
        //   : "55px"
        // :rowSix
        //   ?"78px"
        //   : hoveredEvent === event.id
        //     ? "55px"
        //     : "55px";

        style.top =
          (isFirstFriday && hoveredEvent === event.id) ||
            (isFirstSaturday && hoveredEvent === event.id)
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : rowSix
              ? event.isSecond === 0
                ? "-58px"
                : "-154px"
              : event.isSecond === 0
                ? "-58px"
                : "-154px";

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "140%"
              : hoveredEvent === event.id
                ? differenceInDays < 3
                  ? "350%"
                  : "100%"
                : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "140%"
              : hoveredEvent === event.id
                ? differenceInDays < 3
                  ? "350%"
                  : "100%"
                : "45%";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-50%"
              : hoveredEvent === event.id && isFirstFriday
                ? "-50%"
                : ""
            : hoveredEvent === event.id && isFirstSaturday
              ? "-0%"
              : hoveredEvent === event.id && isFirstFriday
                ? "-0%"
                : hoveredEvent === event.id && isFirstThursday
                  ? "0px"
                  : "55%";
        style.right = "0%";
      }

      if (window.innerWidth <= 375) {
        // style.height = event.isSecond === 0
        // ? rowSix
        //   ? "78px"
        //   : hoveredEvent === event.id
        //   ? "55px"
        //   : "55px"
        // :rowSix
        //   ?"78px"
        //   : hoveredEvent === event.id
        //     ? "55px"
        //     : "55px";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-50%"
              : hoveredEvent === event.id && isFirstFriday
                ? "-50%"
                : ""
            : hoveredEvent === event.id && isFirstSaturday
              ? "-10%"
              : hoveredEvent === event.id && isFirstFriday
                ? "-10%"
                : hoveredEvent === event.id && isFirstThursday
                  ? "0px"
                  : "55%";
        style.right = "0%";
      }

    }

    return {
      style: style,
    };
  };

  return (
    <div className="container-fluid">
      {/* <Header name="Check List Event" /> */}
      <Toaster />
      {/* Wedding Info */}
      {selectedMenu === 5 && (
        <div className={`form-margin`}>
          <div
            className={` form-margin-768  padding-left-21  ${hideAndShow ? "true-event-type-clnder" : "false-event-type-clnder"
              }`}
          >
            <div className="row d-flex">
              <div className="col-12 col-xl-8 ">
                <div
                  style={{
                    height: `${rowSix ? "738px" : "650px"}`,
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    // selectable
                    onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleEventClick}
                    style={{ margin: "" }}
                    eventPropGetter={eventStyleGetter}
                    defaultDate={defaultDate}
                    onNavigate={(date) => {
                      setDefaultDate(date);
                    }}
                    components={{
                      event: ({ event: calendarEvent, start, end }) => (
                        <>
                          <div
                            onMouseOver={() => {
                              setHoveredEvent(calendarEvent.id);
                            }}
                            onMouseOut={() => setHoveredEvent(null)}
                            onClick={() => {
                              if (calendarEvent.type === "Tentative") {
                              }
                            }}
                            style={{
                              height: "51px",
                              width: "100%",
                              zIndex: 100,
                            }}
                          >
                            {calendarEvent && (
                              <div className="" key={calendarEvent.id}>
                                {hoveredEvent === calendarEvent.id && (
                                  <div className="">
                                    {hoveredEvent === calendarEvent.id && (
                                      <>
                                        <div
                                          className={`d-flex justify-content-center  mt-3
                                              }`}
                                        >
                                          <span className="   date-reservation me-sm-3 me-2 ">
                                            {calendarEvent.start.getDate() < 10
                                              ? "0" +
                                              calendarEvent.start.getDate()
                                              : calendarEvent.start.getDate()}
                                          </span>
                                          {/* <div
                                              className={`${calendarEvent.type === "Tentative"
                                                ? "mt-2 "
                                                : ""
                                                } btn-group d-block`}
                                            >
                                              <div
                                                className={`${calendarEvent.type === "Tentative"
                                                  ? "d-none"
                                                  : "d-block"
                                                  }`}
                                              >
                                                <button className="btn text-center btn-calender  ">
                                                  Reserve |
                                                </button>
                                                <button className="btn text-center btn-calender ">
                                                  Transfer
                                                </button>
                                              </div>
                                              <button className="btn text-center btn-calender pb-1 " 
                                              // onClick={()=>handleEdit(calendarEvent.id)}
                                              
                                              >
                                                Edit |
                                              </button>
                                              <button
                                                onClick={() => {
                                                  handleDeleteEvent(
                                                    calendarEvent.id
                                                  );
                                                
                                                }}
                                                className="btn text-center btn-calender pb-1 ms-1"
                                              >
                                                Delete
                                              </button>
                                            </div> */}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ),
                      // month: {
                      //   dateCellWrapper: (props) => {
                      //     const { date, range } = props;

                      //     // Check if the current date is the first day of the month
                      //     const isFirstDayOfMonth = moment(date).isSame(range.start, 'month') && moment(date).date() === 1;

                      //     return isFirstDayOfMonth ? props.children : null;
                      //   },
                      // },

                      toolbar: CustomToolbar,
                    }}

                  // views={{}}
                  />
                </div>
              </div>
              <div className="col-12 col-xl-4 d-none d-xl-block"></div>
            </div>
          </div>

          {/* form */}
          <div className=" ">
            <div className=" padding-left-17  pt-5  pe-0   ">
              {showForm && state ? (
                <FirstEvent
                  setHideAndShow={setHideAndShow}
                  hideAndShow={hideAndShow}
                  setselectedMenu={setselectedMenu}
                  getData={getData}
                  setGetData={setGetData}
                />
              ) : showForm && !state ? (
                <EditFirstEvent
                  setHideAndShow={setHideAndShow}
                  hideAndShow={hideAndShow}
                  setselectedMenu={setselectedMenu}
                  getData={getData}
                  setGetData={setGetData}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      {/* -----------------------Information of the event------------------- */}
      {selectedMenu === 1 && state ? (
        <InformationEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
        />
      ) : selectedMenu === 1 && !state ? (
        <EditInformationEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
        />
      ) : (
        ""
      )}

      {/* ----------------------------Food and Drinks---------------------------- */}
      {selectedMenu === 2 && state ? (
        <FoodAndDrinkEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
        />
      ) : selectedMenu === 2 && !state ? (
        <EditFoodAndDtinkEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
        />
      ) : (
        ""
      )}
      {/* ----------------------------Suppliers---------------------------- */}
      {selectedMenu === 3 && state ? (
        <SupplierEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
        />
      ) : selectedMenu === 3 && !state ? (
        <EditSupplierEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
        />
      ) : (
        ""
      )}

      {/* ----------------------------Extras--------------------------------- */}
      {selectedMenu === 4 && state ? (
        <ExtrasEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
          handleFormSubmit={handleFormSubmit}
          loading={loading}
        />
      ) : selectedMenu === 4 && !state ? (
        <EditExtrasEvent
          setselectedMenu={setselectedMenu}
          setGetData={setGetData}
          getData={getData}
          handleFormSubmit={handleFormSubmit}
          loading={loading}
        />
      ) : (
        ""
      )}

      {selectedMenu === 6 ? <BillingEvent setselectedMenu={setselectedMenu} billID={currentEventId} clientName={clientName}  /> : ""}
      {selectedMenu === 7 ? <SubContractPdf submitEventID={currentEventId} clientName={clientName} /> : ""}
    </div>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_events: state?.create_event_calendar_reducer,
  res_event_on_id: state?.Edit_Calendar_Event_Details_reducer,
  res_edit_event_on_id: state?.Event_Details__on_id_reducer,
  res_create_event: state?.create_event_type, //
});
export default connect(mapStateToProps)(CreateEventType);