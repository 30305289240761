import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  AddBillData,
  GetBillingData,
  getToken,
} from "../../../../Store/Action/useFetchData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLocaleText } from "@mui/x-date-pickers/internals";
export const getRandomFourDigitNumber = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

const AddPayment = ({ dispatch, res_get_data, res_add_data }) => {
  const navigate = useNavigate();
  const id = useParams();
  const location = useLocation();
  const [paymentData, setPaymentData] = useState({
    client_name: "",
    amount: "",
    payment_method: "",
  });
  const [loading, setLoading] = useState(false);
  const [WHTotal, setWHTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [billingData, setBillingData] = useState();
  const [eventCheck, setEventCheck] = useState(false);
  const [taxMode, setTaxMode] = useState(false);
  const [charityTax, setCharityTax] = useState(0);
  const [charityTaxSL, setCharityTaxSL] = useState(0);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    const header = document.querySelector(".header-chnage");
    header.textContent = "Billing";
    res_get_data.data = {};
    res_get_data.loading = true;
    dispatch(getToken());
    dispatch(GetBillingData(id.id));
    // dispatch(getCalendarEvent());
  }, [id.id, dispatch]);
  const role = sessionStorage.getItem("role");
  const userId = sessionStorage.getItem("member_id");
  useEffect(() => {
    if (id.id && res_get_data?.data?.status === true) {
      const tax = res_get_data?.data?.data?.getEventBill[0]?.tax_mode;
      const event_type = res_get_data?.data?.data?.getEventBill[0]?.eventType;
      const menuPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.m_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.m_price
      );
      const stationPrice = Number(
        res_get_data?.data?.data?.stations === null
          ? 0
          : res_get_data?.data?.data?.stations
      );
      const SoundLightPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.package_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.package_price
      );

      const GuestNumber = Number(
        res_get_data?.data?.data?.getEventBill[0]?.noOfGuests
      );
      if (event_type === "Charity") {
        setCharityTax(Number(stationPrice * 0.16) + Number(menuPrice * 0.16));
        setCharityTaxSL(SoundLightPrice * 0.16);
      }
      setBillingData({
        event_id: id.id,
        // special_dis:"",
        eventType: res_get_data?.data?.data?.getEventBill[0]?.eventType,
        menu_price:
          (Number(tax === "0" ? stationPrice / 1.16 : stationPrice) +
            (tax === "0" ? menuPrice / 1.16 : menuPrice)) *
          Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests),
        m_price: tax === "0" ? Number(menuPrice / 1.16) : menuPrice,
        s_price: tax === "0" ? Number(stationPrice / 1.16) : stationPrice,
        discount_per_user: "",
        food_extra_cost: "",
        food_notes: "",
        service_charge: "",
        sound_light_payment:
        tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice,
        sound_extra_cost: "",
        sound_notes: "",
        noOfGuests: res_get_data?.data?.data?.getEventBill[0]?.noOfGuests,
        tax_mode: res_get_data?.data?.data?.getEventBill[0]?.tax_mode,
        event_hide: res_get_data?.data?.data?.getEventBill[0]?.event_hide,
        eventType: res_get_data?.data?.data?.getEventBill[0]?.eventType,
        reservationStatus:
          res_get_data?.data?.data?.getEventBill[0]?.reservationStatus,
        notes: "",
        package_price:
        tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice,
        whitehall_payment:
        Number(
          (tax === "0"
            ? (stationPrice / 1.16) * GuestNumber
            : stationPrice * GuestNumber) +
            (tax === "0"
              ? (menuPrice / 1.16) * GuestNumber
              : menuPrice * GuestNumber)
        ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice),
        payment_type: "",
      });
      setWHTotal(
        Number(
          Number(
            (tax === "0" ? Number(stationPrice / 1.16) : stationPrice) +
              Number(tax === "0" ? Number(menuPrice / 1.16) : menuPrice)
          ) * Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
        ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice)
      );
     
      setTotal(
        Number(
          (tax === "0" ? Number(stationPrice / 1.16) : stationPrice) +
            (tax === "0" ? Number(menuPrice / 1.16) : menuPrice) *
              Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
        ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice)
      );
    }
  }, [id.id, res_get_data]);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPaymentData({ ...paymentData, [name]: value });
  };
  const handleSubmitData = async () => {
    const now = new Date();
    const formatdate = now.toISOString();
    const date = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`;
    const rowData = [
      {
        f_v_name: "FIRST",
        price: paymentData?.amount,
        formatdate: formatdate,
        date: date,
        paymentIndex: "0",
        paymentType: paymentData?.payment_type,
        i_payment_id: Number(
          `${id.id}${getRandomFourDigitNumber().toString()}`
        ),
      },
    ];
    const jsonString = JSON.stringify(rowData);

    const formData = new FormData();
    formData.append("event_id", id.id);
    formData.append("menu_price", billingData?.menu_price);
    formData.append("client_name", paymentData?.client_name);
    formData.append(
      "discount_per_user",
      billingData?.discount_per_user == (undefined || "")
        ? 0
        : billingData?.discount_per_user
    );
    formData.append(
      "food_extra_cost",
      billingData?.food_extra_cost === (undefined || "")
        ? 0
        : billingData?.food_extra_cost
    );
    formData.append(
      "sound_extra_cost",
      billingData?.sound_extra_cost === (undefined || "")
        ? 0
        : billingData?.sound_extra_cost
    );
    formData.append("food_notes", billingData?.food_notes);
    formData.append("billreservationStatus", "FinalReserve");
    formData.append("sound_notes", billingData?.sound_notes);
    formData.append("noOfGuests", billingData?.noOfGuests);
    formData.append(
      "service_charge",
      billingData?.service_charge == "" ? 0 : billingData?.service_charge
    );
    formData.append("installment_payments", jsonString);
    formData.append("white_hall_payment", WHTotal);
    formData.append("sound_light_payment", billingData?.sound_light_payment);
    formData.append("grand_total", WHTotal);
    formData.append("unpaid", WHTotal - Number(paymentData?.amount));
    // formData.append("grand_total", WHTotal + (taxMode ? WHTotal * 0.16 : 0));
    // formData.append(
    //   "unpaid",
    //   WHTotal + (taxMode ? WHTotal * 0.16 : 0) - Number(paymentData?.amount)
    // );
    formData.append("charity_tax", charityTax);
    formData.append("charity_SL", charityTaxSL);
    formData.append("payment_type", paymentData?.payment_type);
    formData.append("paid", paymentData?.amount);
    formData.append("discount_per_user_total", 0);
    formData.append("white_total_dis", billingData?.whitehall_payment);
    formData.append("m_price", billingData?.m_price);
    formData.append("s_price", billingData?.s_price);
    formData.append("white_total", billingData?.whitehall_payment);
    formData.append("discount", 0);
    formData.append("create_bill_user_id", userId);
    formData.append("sub_grand_total", total);
    formData.append("total_gst", 0);
    // formData.append("gst_mode", taxMode ? "0" : "1");
    formData.append("tax_mode", "1");
    formData.append("event_hide", "1");
    formData.append("bill_type", "new");
    formData.append(
      "user_type",
      role == "0"
        ? "superadmin"
        : role == "1"
        ? "kitchen user"
        : role == "2"
        ? "sales user"
        : role == "3"
        ? "accounting user"
        : ""
    );
    formData.append(
      "spacial_dis",
      billingData?.special_dis === undefined || billingData?.special_dis === ""
        ? 0
        : billingData?.special_dis
    );

    formData.append("files[]", "");
    if (
      !paymentData?.amount ||
      !paymentData?.client_name ||
      !paymentData?.payment_type
    ) {
      const notify = () =>
        toast.error("All fields are required.", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else {
    
      dispatch(AddBillData(formData, setLoading));
    }
  };
  useEffect(() => {
    if (res_add_data?.data?.status === true) {
      const notify = () =>
        toast.success(res_add_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        navigate("/sales_main");
        res_add_data.loading = true;
        res_add_data.data = {};
        res_get_data.loading = true;
        res_get_data.data = {};
        // window.location.reload();
        // if (location.state.type === "billing") {
        //   navigate("/billing");
        // } else if (location.state.type === "account") {
        //   navigate("/accounting_main");
        // }
      }, 1500);
    } else if (res_add_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_add_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_add_data.loading = true;
        res_add_data.data = {};
      }, 1500);
    }
  }, [res_add_data, navigate]);
  const handleReset = () => {
    setPaymentData({
      client_name: "",
      amount: "",
      payment_method: "",
    });
  };
  useEffect(() => {
    if (WHTotal) {
      if (Number(paymentData?.amount) >= WHTotal + 1) {
        const notify = () =>
          toast.error("The amount cannot exceed the total.", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      }
    }
  }, [paymentData]);
  return (
    <>
      <Toaster />
      <div className="">
        <div className="container-fluid ">
          <div className=" form-margin-768 padding-left-21 form-margin">
            <div className="   form-in-768">
              <div className="row mb-3 mb-sm-2 mt-4 mt-sm-5">
                <div className="col-lg-4 col-12">
                  <label
                    for="basic-url"
                    className="form-label me-xxl-5 me-0   label"
                  >
                    NAME
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="text"
                    name="client_name"
                    autoComplete="off"
                    value={paymentData?.client_name}
                    onChange={handleChange}
                    className="form-control inpt inpt-768 "
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    AMOUNT
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="input-group price-jod price-jod-768">
                    <input
                      type="number"
                      className="form-control inpt-border-trans"
                      name="amount"
                      value={paymentData?.amount}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                      JOD
                    </span>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center  mb-3">
                <div className="col-12 col-lg-4">
                  <label
                    for="basic-url"
                    className="form-label me-xxl-5 me-0   label"
                  >
                    PAYMENT TYPE
                  </label>
                </div>
                <div className="col-12 col-lg-8 ps-0">
                  <div className="row d-flex ms-sm-auto">
                    <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ps-3 ps-md-0">
                      <input
                        className="form-check-input cursor-pointer mb-2"
                        type="radio"
                        name="payment_type"
                        value="1"
                        checked={paymentData?.payment_type === "1"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label check-label"
                        for="inlineRadio1"
                      >
                        Cash
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ps-3 ps-md-0 ">
                      <input
                        className="form-check-input cursor-pointer mb-2"
                        type="radio"
                        name="payment_type"
                        value="2"
                        onChange={handleChange}
                        checked={paymentData?.payment_type === "2"}
                      />
                      <label
                        className="form-check-label check-label"
                        for="inlineRadio2"
                      >
                        Cheque
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ps-3 ps-md-0 ">
                      <input
                        className="form-check-input cursor-pointer mb-2"
                        type="radio"
                        name="payment_type"
                        value="3"
                        onChange={handleChange}
                        checked={paymentData?.payment_type === "3"}
                      />
                      <label
                        className="form-check-label check-label"
                        for="inlineRadio3"
                      >
                        Bank Transfer
                      </label>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ps-3 ps-md-0 ">
                      <input
                        className="form-check-input cursor-pointer mb-2"
                        type="radio"
                        name="payment_type"
                        value="4"
                        onChange={handleChange}
                        checked={paymentData?.payment_type == "4"}
                      />
                      <label
                        className="form-check-label check-label"
                        for="inlineRadio3"
                      >
                        E-Payment
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768 ">
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  //   onClick={() => {
                  //     res_profile.loading = true;
                  //     res_profile.data = {};
                  //     navigate("/calender");
                  //   }}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                {loading ? (
                  <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 btn-768 mb-4"
                    onClick={handleSubmitData}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section  display-for-768">
                {loading ? (
                  <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 btn-768 mb-4"
                    onClick={handleSubmitData}
                  >
                    SUBMIT
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  //   onClick={() => {
                  //     res_profile.loading = true;
                  //     res_profile.data = {};
                  //     navigate("/calender");
                  //   }}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_data: state?.get_Billing_reducer,
  res_add_data: state?.Add_Billing_reducer,
  res_calender_data: state?.Calendar_get_Event_reducer,
});
export default connect(mapStateToProps)(AddPayment);
