import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdCloseCircle } from "react-icons/io";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { FiPaperclip } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import pdf from "../../../../../images/PDF.png";
import {
  AddBillData,
  GetBillingData,
  getCalendarEvent,
  getToken,
  sendShowDetails,
} from "../../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../../Loader";
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #000000",
  marginBottom: 8,
  marginRight: 8,
  width: "100px",
  height: "100px",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  position: "relative",
  overflow: "hidden",
  width: "100%",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};
const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const getRandomFourDigitNumber = () => {
  return Math.floor(1000 + Math.random() * 9000);
};
const AddBilling = ({
  dispatch,
  res_get_data,
  res_add_data,
  submitEventID,
  setShowData,
  billType,
  res_calender_data,
}) => {
  const location = useLocation();
  const role = sessionStorage.getItem("role");
  const userId = sessionStorage.getItem("member_id");
  const [calenderData, setCalenderData] = useState([]);
  const navigate = useNavigate();
  const [thumbs, setThumbs] = useState([]);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [billingData, setBillingData] = useState();
  const [WHTotal, setWHTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [settingPer, setSettingPer] = useState(0);
  const [disPer, setDisPer] = useState(0);
  const [eventCheck, setEventCheck] = useState(false);
  // const [settingPerUser, setSettingPerUser] = useState(0);
  const [disPerUser, setDisPerUser] = useState(0);
  const [showError, setShowError] = useState("0");
  const [rowsData, setRowsData] = useState([
    {
      f_v_name: "FIRST",
      price: "",
      formatdate: "",
      date: "",
      paymentIndex: "0",
      paymentType: "",
      i_payment_id: Number(
        `${submitEventID}${getRandomFourDigitNumber().toString()}`
      ),
    },
  ]);
  const [updatedWH, setUpdatedWH] = useState(null);
  const [totalMap, setTotalMap] = useState(0);
  const [unPaidData, setUnPaidData] = useState(0);
  const [paidData, setPaidData] = useState(0);
  const [taxMode, setTaxMode] = useState(false);
  const [totalGST, setTotalGST] = useState(0);
  const [charityTax, setCharityTax] = useState(0);
  const [charityTaxSL, setCharityTaxSL] = useState(0);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    const header = document.querySelector(".header-chnage");
    header.textContent = "Billing";
    res_get_data.data = {};
    res_get_data.loading = true;
    dispatch(getToken());
    dispatch(GetBillingData(submitEventID));
    dispatch(getCalendarEvent());
  }, [submitEventID, dispatch]);

  useEffect(() => {
    if (submitEventID && res_calender_data?.data?.status === true) {
      const startDateMap = {};
      res_calender_data?.data?.data.forEach((event) => {
        if (startDateMap[event.startDate]) {
          startDateMap[event.startDate].push(event.eventID);
        } else {
          startDateMap[event.startDate] = [event.eventID];
        }
      });

      const sameStartDateEventIDs = Object.values(startDateMap)
        .filter((ids) => ids.length > 1)
        .flat();

      setCalenderData(sameStartDateEventIDs);
    }
  }, [res_calender_data]);
  useEffect(() => {
    if (submitEventID && res_get_data?.data?.status === true) {
      const tax = res_get_data?.data?.data?.getEventBill[0]?.tax_mode;
      const event_type = res_get_data?.data?.data?.getEventBill[0]?.eventType;
      const menuPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.m_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.m_price
      );
      const stationPrice = Number(
        res_get_data?.data?.data?.stations === null
          ? 0
          : res_get_data?.data?.data?.stations
      );
      const SoundLightPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.package_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.package_price
      );

      const GuestNumber = Number(
        res_get_data?.data?.data?.getEventBill[0]?.noOfGuests
      );
      if (event_type === "Charity") {
        setCharityTax(Number(stationPrice * 0.16) + Number(menuPrice * 0.16));
        setCharityTaxSL(SoundLightPrice * 0.16);
      }
      setBillingData({
        event_id: submitEventID,
        // special_dis:"",
        eventType: res_get_data?.data?.data?.getEventBill[0]?.eventType,
        menu_price:
          (Number(tax === "0" ? stationPrice / 1.16 : stationPrice) +
            (tax === "0" ? menuPrice / 1.16 : menuPrice)) *
          Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests),
        m_price: tax === "0" ? Number(menuPrice / 1.16) : menuPrice,
        s_price: tax === "0" ? Number(stationPrice / 1.16) : stationPrice,
        discount_per_user: "",
        food_extra_cost: "",
        food_notes: "",
        service_charge: "",
        sound_light_payment:
          tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice,
        sound_extra_cost: "",
        sound_notes: "",
        noOfGuests: res_get_data?.data?.data?.getEventBill[0]?.noOfGuests,
        tax_mode: res_get_data?.data?.data?.getEventBill[0]?.tax_mode,
        eventType: res_get_data?.data?.data?.getEventBill[0]?.eventType,
        reservationStatus:
          res_get_data?.data?.data?.getEventBill[0]?.reservationStatus,
        notes: "",
        package_price:
          tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice,
        whitehall_payment:
          Number(
            (tax === "0"
              ? (stationPrice / 1.16) * GuestNumber
              : stationPrice * GuestNumber) +
              (tax === "0"
                ? (menuPrice / 1.16) * GuestNumber
                : menuPrice * GuestNumber)
          ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice),
        payment_type: "",
      });
      setWHTotal(
        Number(
          Number(
            (tax === "0" ? Number(stationPrice / 1.16) : stationPrice) +
              Number(tax === "0" ? Number(menuPrice / 1.16) : menuPrice)
          ) * Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
        ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice)
      );
      setTotalMap(
        Number(
          (tax === "0" ? Number(stationPrice / 1.16) : stationPrice) +
            (tax === "0" ? Number(menuPrice / 1.16) : menuPrice) *
              Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
        ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice)
      );
      setTotal(
        Number(
          (tax === "0" ? Number(stationPrice / 1.16) : stationPrice) +
            (tax === "0" ? Number(menuPrice / 1.16) : menuPrice) *
              Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
        ) + (tax === "0" ? Number(SoundLightPrice / 1.16) : SoundLightPrice)
      );
      setSettingPer({
        tax_dis: Number(res_get_data?.data?.data?.getsetting[0]?.tax_dis),
        tax_mode: res_get_data?.data?.data?.getsetting[0]?.tax_mode,
      });
      if (res_get_data?.data?.data?.getEventBill[0]?.tax_mode == "0") {
        setTaxMode(true);
      } else {
        setTaxMode(false);
      }

      if (res_get_data?.data?.data?.getEventBill[0]?.event_hide === "0") {
        setEventCheck(true);
      } else if (
        res_get_data?.data?.data?.getEventBill[0]?.event_hide === "1"
      ) {
        setEventCheck(false);
      }
    }
  }, [submitEventID, res_get_data]);
  useEffect(() => {
    if (submitEventID && res_get_data?.data?.status === true) {
      const menuPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.m_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.m_price
      );
      const stationPrice = Number(
        res_get_data?.data?.data?.stations === null
          ? 0
          : res_get_data?.data?.data?.stations
      );
      const SoundLightPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.package_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.package_price
      );

      const GuestNumber =
        Number(billingData?.noOfGuests) === 0
          ? 1
          : Number(billingData?.noOfGuests);

      const event_type = res_get_data?.data?.data?.getEventBill[0]?.eventType;
      if (taxMode === true) {
        if (event_type === "Charity") {
          setBillingData((prevBillingData) => ({
            ...prevBillingData,
            menu_price:
              Number(Number(menuPrice) + Number(stationPrice)) * GuestNumber,
            sound_light_payment: Number(SoundLightPrice),
            m_price: Number(menuPrice),
            s_price: Number(stationPrice),
            whitehall_payment:
              Number(
                Number(Number(stationPrice) * GuestNumber) +
                  Number(menuPrice) * GuestNumber
              ) + Number(Number(SoundLightPrice)),
          }));
        } else {
          setBillingData((prevBillingData) => ({
            ...prevBillingData,
            menu_price:
              Number(Number(menuPrice / 1.16) + Number(stationPrice / 1.16)) *
              GuestNumber,
            m_price: Number(menuPrice / 1.16),
            s_price: Number(stationPrice / 1.16),
            sound_light_payment: Number(SoundLightPrice / 1.16),
            whitehall_payment:
              Number(
                Number(Number(stationPrice / 1.16) * GuestNumber) +
                  Number(menuPrice / 1.16) * GuestNumber
              ) + Number(Number(SoundLightPrice / 1.16)),
            // whitehall_payment: taxMode
            //   ? Number(menu_price) + Number(sound_light_payment)
            //   : Number(Number(menu_price + charityTax)) +
            //     Number(Number(sound_light_payment + charityTaxSL)),
          }));
        }
      } else if (taxMode === false) {
        setBillingData((prevBillingData) => ({
          ...prevBillingData,
          menu_price:
            Number(Number(menuPrice) + Number(stationPrice)) * GuestNumber,
          sound_light_payment: Number(SoundLightPrice),
          m_price: Number(menuPrice),
          s_price: Number(stationPrice),
          whitehall_payment:
            Number(
              Number(Number(stationPrice) * GuestNumber) +
                Number(menuPrice) * GuestNumber
            ) + Number(Number(SoundLightPrice)),
        }));
      }
    }
  }, [taxMode]);
  useEffect(() => {
    if (submitEventID && res_get_data?.data?.status === true) {
      const menuPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.m_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.m_price
      );
      const stationPrice = Number(
        res_get_data?.data?.data?.stations === null
          ? 0
          : res_get_data?.data?.data?.stations
      );
      const SoundLightPrice = Number(
        res_get_data?.data?.data?.getEventBill[0]?.package_price === null
          ? 0
          : res_get_data?.data?.data?.getEventBill[0]?.package_price
      );

      const GuestNumber =
        Number(billingData?.noOfGuests) === 0
          ? 1
          : Number(billingData?.noOfGuests);

      const event_type = res_get_data?.data?.data?.getEventBill[0]?.eventType;

      if (taxMode === true) {
        if (event_type === "Charity") {
          setWHTotal(
            updatedWH === null
              ? Number(
                  Number(
                    Number(
                      Number(
                        Number(Number(menuPrice) + Number(stationPrice)) *
                          GuestNumber
                      ) + Number(SoundLightPrice)
                    ) +
                      Number(billingData?.service_charge) +
                      Number(billingData?.food_extra_cost) +
                      Number(billingData?.sound_extra_cost)
                  )
                )
              : Number(
                  Number(
                    Number(
                      Number(
                        Number(Number(menuPrice) + Number(stationPrice)) *
                          GuestNumber
                      ) + Number(SoundLightPrice)
                    ) +
                      Number(billingData?.service_charge) +
                      Number(billingData?.food_extra_cost) +
                      Number(billingData?.sound_extra_cost)
                  )
                ) - Number(Number(disPer * GuestNumber))
          );
          setTotalMap(
            Number(
              Number(
                Number(
                  updatedWH === null
                    ? Number(
                        Number(
                          Number(
                            Number(
                              Number(Number(menuPrice) + Number(stationPrice)) *
                                GuestNumber
                            ) + Number(SoundLightPrice)
                          ) +
                            Number(billingData?.service_charge) +
                            Number(billingData?.food_extra_cost) +
                            Number(billingData?.sound_extra_cost)
                        )
                      )
                    : Number(
                        Number(
                          Number(
                            Number(
                              Number(Number(menuPrice) + Number(stationPrice)) *
                                GuestNumber
                            ) + Number(SoundLightPrice)
                          ) +
                            Number(billingData?.service_charge) +
                            Number(billingData?.food_extra_cost) +
                            Number(billingData?.sound_extra_cost)
                        )
                      ) - Number(Number(disPer * GuestNumber))
                ) -
                  Number(
                    billingData?.special_dis === undefined ||
                      billingData?.special_dis === ""
                      ? 0
                      : billingData?.special_dis
                  )
              )
            ) - paidData
          );
          setTotalGST(0);
          setTotal(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(Number(menuPrice) + Number(stationPrice)) *
                              GuestNumber
                          ) + Number(SoundLightPrice)
                        ) +
                          Number(billingData?.service_charge) +
                          Number(billingData?.food_extra_cost) +
                          Number(billingData?.sound_extra_cost)
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(Number(menuPrice) + Number(stationPrice)) *
                              GuestNumber
                          ) + Number(SoundLightPrice)
                        ) +
                          Number(billingData?.service_charge) +
                          Number(billingData?.food_extra_cost) +
                          Number(billingData?.sound_extra_cost)
                      )
                    ) - Number(Number(disPer * GuestNumber))
              ) -
                Number(
                  billingData?.special_dis === undefined ||
                    billingData?.special_dis === ""
                    ? 0
                    : billingData?.special_dis
                )
            )
          );
        } else {
          setWHTotal(
            updatedWH === null
              ? Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(menuPrice / 1.16) + Number(stationPrice / 1.16)
                        ) * GuestNumber
                      ) + Number(SoundLightPrice / 1.16)
                    ) +
                      Number(Number(billingData?.service_charge) / 1.16) +
                      Number(Number(billingData?.food_extra_cost) / 1.16) +
                      Number(Number(billingData?.sound_extra_cost) / 1.16)
                  )
                )
              : Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(menuPrice / 1.16) + Number(stationPrice / 1.16)
                        ) * GuestNumber
                      ) + Number(SoundLightPrice / 1.16)
                    ) +
                      Number(Number(billingData?.service_charge) / 1.16) +
                      Number(Number(billingData?.food_extra_cost) / 1.16) +
                      Number(Number(billingData?.sound_extra_cost) / 1.16)
                  )
                ) - Number(Number(disPer * GuestNumber) / 1.16)
          );
          setTotalMap(
            Number(
              Number(
                Number(
                  updatedWH === null
                    ? Number(
                        Number(
                          Number(
                            Number(
                              Number(
                                Number(menuPrice / 1.16) +
                                  Number(stationPrice / 1.16)
                              ) * GuestNumber
                            ) + Number(SoundLightPrice / 1.16)
                          ) +
                            Number(Number(billingData?.service_charge) / 1.16) +
                            Number(
                              Number(billingData?.food_extra_cost) / 1.16
                            ) +
                            Number(Number(billingData?.sound_extra_cost) / 1.16)
                        )
                      )
                    : Number(
                        Number(
                          Number(
                            Number(
                              Number(
                                Number(menuPrice / 1.16) +
                                  Number(stationPrice / 1.16)
                              ) * GuestNumber
                            ) + Number(SoundLightPrice / 1.16)
                          ) +
                            Number(Number(billingData?.service_charge) / 1.16) +
                            Number(
                              Number(billingData?.food_extra_cost) / 1.16
                            ) +
                            Number(Number(billingData?.sound_extra_cost) / 1.16)
                        )
                      ) - Number(Number(disPer * GuestNumber) / 1.16)
                ) -
                  -Number(
                    Number(
                      billingData?.special_dis === undefined ||
                        billingData?.special_dis === ""
                        ? 0
                        : billingData?.special_dis
                    ) / 1.16
                  )
              ) +
                Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(menuPrice / 1.16) + Number(stationPrice / 1.16)
                        ) * GuestNumber
                      ) +
                        Number(SoundLightPrice / 1.16) +
                        Number(Number(billingData?.service_charge) / 1.16) +
                        Number(Number(billingData?.food_extra_cost) / 1.16) +
                        Number(Number(billingData?.sound_extra_cost) / 1.16)
                    ) -
                      Number(Number(disPer * GuestNumber) / 1.16) -
                      Number(
                        Number(
                          billingData?.special_dis === undefined ||
                            billingData?.special_dis === ""
                            ? 0
                            : billingData?.special_dis
                        ) / 1.16
                      )
                  ) * 0.16
                )
            ) - paidData
          );
          setTotalGST(
            Number(
              Number(
                Number(
                  Number(
                    Number(
                      Number(menuPrice / 1.16) + Number(stationPrice / 1.16)
                    ) * GuestNumber
                  ) +
                    Number(SoundLightPrice / 1.16) +
                    Number(Number(billingData?.service_charge) / 1.16) +
                    Number(Number(billingData?.food_extra_cost) / 1.16) +
                    Number(Number(billingData?.sound_extra_cost) / 1.16)
                ) -
                  Number(Number(disPer * GuestNumber) / 1.16) -
                  Number(
                    Number(
                      billingData?.special_dis === undefined ||
                        billingData?.special_dis === ""
                        ? 0
                        : billingData?.special_dis
                    ) / 1.16
                  )
              ) * 0.16
            )
          );
          setTotal(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              Number(menuPrice / 1.16) +
                                Number(stationPrice / 1.16)
                            ) * GuestNumber
                          ) + Number(SoundLightPrice / 1.16)
                        ) +
                          Number(Number(billingData?.service_charge) / 1.16) +
                          Number(Number(billingData?.food_extra_cost) / 1.16) +
                          Number(Number(billingData?.sound_extra_cost) / 1.16)
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              Number(menuPrice / 1.16) +
                                Number(stationPrice / 1.16)
                            ) * GuestNumber
                          ) + Number(SoundLightPrice / 1.16)
                        ) +
                          Number(Number(billingData?.service_charge) / 1.16) +
                          Number(Number(billingData?.food_extra_cost) / 1.16) +
                          Number(Number(billingData?.sound_extra_cost) / 1.16)
                      )
                    )
              ) -
                Number(
                  Number(
                    billingData?.special_dis === undefined ||
                      billingData?.special_dis === ""
                      ? 0
                      : billingData?.special_dis
                  ) / 1.16
                )
            ) +
              Number(
                Number(
                  Number(
                    Number(
                      Number(
                        Number(menuPrice / 1.16) + Number(stationPrice / 1.16)
                      ) * GuestNumber
                    ) +
                      Number(SoundLightPrice / 1.16) +
                      Number(Number(billingData?.service_charge) / 1.16) +
                      Number(Number(billingData?.food_extra_cost) / 1.16) +
                      Number(Number(billingData?.sound_extra_cost) / 1.16)
                  ) -
                    Number(Number(disPer * GuestNumber) / 1.16) -
                    Number(
                      Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      ) / 1.16
                    )
                ) * 0.16
              )
          );
        }
      } else if (taxMode === false) {
        setWHTotal(
          updatedWH === null
            ? Number(
                Number(
                  Number(
                    Number(
                      Number(Number(menuPrice) + Number(stationPrice)) *
                        GuestNumber
                    ) + Number(SoundLightPrice)
                  ) +
                    Number(billingData?.service_charge) +
                    Number(billingData?.food_extra_cost) +
                    Number(billingData?.sound_extra_cost)
                )
              )
            : Number(
                Number(
                  Number(
                    Number(
                      Number(Number(menuPrice) + Number(stationPrice)) *
                        GuestNumber
                    ) + Number(SoundLightPrice)
                  ) +
                    Number(billingData?.service_charge) +
                    Number(billingData?.food_extra_cost) +
                    Number(billingData?.sound_extra_cost)
                )
              ) - Number(Number(disPer * GuestNumber))
        );
        setTotalMap(
          Number(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(Number(menuPrice) + Number(stationPrice)) *
                              GuestNumber
                          ) + Number(SoundLightPrice)
                        ) +
                          Number(billingData?.service_charge) +
                          Number(billingData?.food_extra_cost) +
                          Number(billingData?.sound_extra_cost)
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(Number(menuPrice) + Number(stationPrice)) *
                              GuestNumber
                          ) + Number(SoundLightPrice)
                        ) +
                          Number(billingData?.service_charge) +
                          Number(billingData?.food_extra_cost) +
                          Number(billingData?.sound_extra_cost)
                      )
                    ) - Number(Number(disPer * GuestNumber))
              ) -
                Number(
                  billingData?.special_dis === undefined ||
                    billingData?.special_dis === ""
                    ? 0
                    : billingData?.special_dis
                )
            )
          ) - paidData
        );
        setTotalGST(0);
        setTotal(
          Number(
            Number(
              updatedWH === null
                ? Number(
                    Number(
                      Number(
                        Number(
                          Number(Number(menuPrice) + Number(stationPrice)) *
                            GuestNumber
                        ) + Number(SoundLightPrice)
                      ) +
                        Number(billingData?.service_charge) +
                        Number(billingData?.food_extra_cost) +
                        Number(billingData?.sound_extra_cost)
                    )
                  )
                : Number(
                    Number(
                      Number(
                        Number(
                          Number(Number(menuPrice) + Number(stationPrice)) *
                            GuestNumber
                        ) + Number(SoundLightPrice)
                      ) +
                        Number(billingData?.service_charge) +
                        Number(billingData?.food_extra_cost) +
                        Number(billingData?.sound_extra_cost)
                    )
                  ) - Number(Number(disPer * GuestNumber))
            ) -
              Number(
                billingData?.special_dis === undefined ||
                  billingData?.special_dis === ""
                  ? 0
                  : billingData?.special_dis
              )
          )
        );
      }
    }
  }, [total, billingData, WHTotal, paidData, taxMode]);

  useEffect(() => {
    if (Number(disPer) !== 0) {
      // if (role === "0") {
      //   if (Number(billingData?.noOfGuests * Number(disPer)) === 0) {
      //     // console.log("first1");
      //     setShowError("0");
      //     setDisPerUser(0);
      //     setWHTotal(
      //       Number(billingData?.whitehall_payment) +
      //         Number(billingData?.service_charge) +
      //         Number(billingData?.food_extra_cost) +
      //         Number(billingData?.sound_extra_cost)
      //     );
      //     // console.log("Number(billingData?.noOfGuests * Number(disPer)) === 0");
      //     setUpdatedWH(null);
      //   } else if (Number(billingData?.noOfGuests * Number(disPer)) !== 0) {
      //     // console.log("first2");
      //     setShowError("0");
      //     setDisPerUser(Number(billingData?.noOfGuests * Number(disPer)));
      //     setWHTotal(
      //       Number(
      //         Number(
      //           Number(billingData?.m_price) <= 0
      //             ? 0
      //             : (Number(billingData?.m_price) - Number(disPer)) *
      //                 Number(billingData?.noOfGuests)
      //         ) +
      //           Number(
      //             Number(billingData?.s_price) <= 0
      //               ? 0
      //               : (Number(billingData?.s_price) - Number(disPer)) *
      //                   Number(billingData?.noOfGuests)
      //           ) +
      //           Number(billingData?.food_extra_cost) +
      //           Number(billingData?.service_charge) +
      //           Number(billingData?.package_price) +
      //           Number(billingData?.sound_extra_cost)
      //       )
      //     );
      //     setUpdatedWH(
      //       Number(
      //         Number(
      //           Number(billingData?.m_price) <= 0
      //             ? 0
      //             : (Number(billingData?.m_price) - Number(disPer)) *
      //                 Number(billingData?.noOfGuests)
      //         ) +
      //           Number(
      //             Number(billingData?.s_price) <= 0
      //               ? 0
      //               : (Number(billingData?.s_price) - Number(disPer)) *
      //                   Number(billingData?.noOfGuests)
      //           ) +
      //           Number(billingData?.food_extra_cost) +
      //           Number(billingData?.service_charge) +
      //           Number(billingData?.package_price) +
      //           Number(billingData?.sound_extra_cost)
      //       )
      //     );
      //     // console.log("value added");
      //   }
      // } else if (role !== "0") {
      if (settingPer?.tax_dis + 1 <= Number(disPer)) {
        setUpdatedWH(null);
        setDisPerUser(0);
        setShowError("1");
        setWHTotal(
          Number(billingData?.whitehall_payment) +
            Number(billingData?.service_charge) +
            Number(billingData?.food_extra_cost) +
            Number(billingData?.sound_extra_cost)
        );
        const notify = () =>
          toast.error(
            "Maximum discount exceeded. Please review your discount settings and try again!",
            {
              style: {
                padding: "10px",
                borderRadius: "30px",
              },
            }
          );
        notify();
      } else if (Number(billingData?.noOfGuests * Number(disPer)) === 0) {
        setShowError("0");
        setDisPerUser(0);
        setWHTotal(
          Number(billingData?.whitehall_payment) +
            Number(billingData?.service_charge) +
            Number(billingData?.food_extra_cost) +
            Number(billingData?.sound_extra_cost)
        );
        setUpdatedWH(null);
      } else if (Number(billingData?.noOfGuests * Number(disPer)) !== 0) {
        setShowError("0");
        setDisPerUser(Number(billingData?.noOfGuests * Number(disPer)));
        setWHTotal(
          Number(
            Number(
              Number(billingData?.m_price) <= 0
                ? 0
                : (Number(billingData?.m_price) - Number(disPer)) *
                    Number(billingData?.noOfGuests)
            ) +
              Number(
                Number(billingData?.s_price) <= 0
                  ? 0
                  : (Number(billingData?.s_price) - Number(disPer)) *
                      Number(billingData?.noOfGuests)
              ) +
              Number(billingData?.food_extra_cost) +
              Number(billingData?.service_charge) +
              Number(billingData?.package_price) +
              Number(billingData?.sound_extra_cost)
          )
        );
        setUpdatedWH(
          Number(
            Number(
              Number(billingData?.m_price) <= 0
                ? 0
                : (Number(billingData?.m_price) - Number(disPer)) *
                    Number(billingData?.noOfGuests)
            ) +
              Number(
                Number(billingData?.s_price) <= 0
                  ? 0
                  : (Number(billingData?.s_price) - Number(disPer)) *
                      Number(billingData?.noOfGuests)
              ) +
              Number(billingData?.food_extra_cost) +
              Number(billingData?.service_charge) +
              Number(billingData?.package_price) +
              Number(billingData?.sound_extra_cost)
          )
        );
      }
      // }
    } else if (Number(disPer) === 0) {
      setShowError("0");
      setDisPerUser(0);
      setWHTotal(
        Number(billingData?.whitehall_payment) +
          Number(billingData?.service_charge) +
          Number(billingData?.food_extra_cost) +
          Number(billingData?.sound_extra_cost)
      );

      setUpdatedWH(null);
    }
  }, [billingData, disPer]);
  useEffect(() => {
    if (WHTotal < 0) {
      const notify = () =>
        toast.error("White Hall Payment should not be Minus", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  }, [WHTotal]);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "discount_per_user") {
      setDisPer(Number(value));
    }
    setBillingData({ ...billingData, [name]: value });
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: [".jpg", ".jpeg", ".png", ".pdf"],
    onDrop: (acceptedFiles, rejectedFiles) => {
      const largeFiles = [];
      const newThumbs = [];
      acceptedFiles.forEach((file) => {
        if (file.size > MAX_FILE_SIZE) {
          largeFiles.push(file.name);
        } else {
          newThumbs.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
        }
      });
      const mergedThumbs = [...thumbs, ...newThumbs];
      setThumbs(mergedThumbs);
      setFile(mergedThumbs);
    },
  });
  useEffect(() => {
    return () => thumbs.forEach((thumb) => URL.revokeObjectURL(thumb.preview));
  }, [thumbs]);
  const installmentNames = [
    "FIRST",
    "SECOND",
    "THIRD",
    "FOURTH",
    "FIFTH",
    "SIXTH",
    "SEVENTH",
    "EIGHTH",
    "NINTH",
    "TENTH",
    "ELEVENTH",
    "TWELFTH",
    "THIRTEENTH",
    "FOURTEENTH",
    "FIFTEENTH",
    "SIXTEENTH",
    "SEVENTEENTH",
    "EIGHTEENTH",
    "NINETEENTH",
    "TWENTIETH",
    "TWENTY-FIRST",
    "TWENTY-SECOND",
    "TWENTY-THIRD",
    "TWENTY-FOURTH",
    "TWENTY-FIFTH",
    "TWENTY-SIXTH",
    "TWENTY-SEVENTH",
    "TWENTY-EIGHTH",
    "TWENTY-NINTH",
    "THIRTIETH",
    "THIRTY-FIRST",
    "THIRTY-SECOND",
    "THIRTY-THIRD",
    "THIRTY-FOURTH",
    "THIRTY-FIFTH",
    "THIRTY-SIXTH",
    "THIRTY-SEVENTH",
    "THIRTY-EIGHTH",
    "THIRTY-NINTH",
    "FORTIETH",
    "FORTY-FIRST",
    "FORTY-SECOND",
    "FORTY-THIRD",
    "FORTY-FOURTH",
    "FORTY-FIFTH",
    "FORTY-SIXTH",
    "FORTY-SEVENTH",
    "FORTY-EIGHTH",
    "FORTY-NINTH",
    "FIFTIETH",
    "FIFTY-FIRST",
    "FIFTY-SECOND",
    "FIFTY-THIRD",
    "FIFTY-FOURTH",
    "FIFTY-FIFTH",
    "FIFTY-SIXTH",
    "FIFTY-SEVENTH",
    "FIFTY-EIGHTH",
    "FIFTY-NINTH",
    "SIXTIETH",
    "SIXTY-FIRST",
    "SIXTY-SECOND",
    "SIXTY-THIRD",
    "SIXTY-FOURTH",
    "SIXTY-FIFTH",
    "SIXTY-SIXTH",
    "SIXTY-SEVENTH",
    "SIXTY-EIGHTH",
    "SIXTY-NINTH",
    "SEVENTIETH",
    "SEVENTY-FIRST",
    "SEVENTY-SECOND",
    "SEVENTY-THIRD",
    "SEVENTY-FOURTH",
    "SEVENTY-FIFTH",
    "SEVENTY-SIXTH",
    "SEVENTY-SEVENTH",
    "SEVENTY-EIGHTH",
    "SEVENTY-NINTH",
    "EIGHTIETH",
    "EIGHTY-FIRST",
    "EIGHTY-SECOND",
    "EIGHTY-THIRD",
    "EIGHTY-FOURTH",
    "EIGHTY-FIFTH",
    "EIGHTY-SIXTH",
    "EIGHTY-SEVENTH",
    "EIGHTY-EIGHTH",
    "EIGHTY-NINTH",
    "NINETIETH",
    "NINETY-FIRST",
    "NINETY-SECOND",
    "NINETY-THIRD",
    "NINETY-FOURTH",
    "NINETY-FIFTH",
    "NINETY-SIXTH",
    "NINETY-SEVENTH",
    "NINETY-EIGHTH",
    "NINETY-NINTH",
    "HUNDREDTH",
  ];
  const addTableRows = () => {
    const rowsInput = {
      f_v_name: `${installmentNames[rowsData?.length]}`,
      paymentIndex: "1",
      price: "",
      formatdate: "",
      date: "",
      paymentType: "",
      i_payment_id: Number(
        `${submitEventID}${getRandomFourDigitNumber().toString()}`
      ),
    };

    setRowsData((prevRowsData) => [...prevRowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const paymentHandleChange = (e, index) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);

    const updatedProductMap = [...rowsData];
    if ((name === "price" || name === "formatdate") && !isNaN(value)) {
      updatedProductMap[index] = {
        ...updatedProductMap[index],
        [name]: value,
      };
    }

    if (name === "formatdate" && !isNaN(value)) {
      const updatedItem = updatedProductMap[index];
      const updatedDate = updatedItem?.formatdate;
      const date = new Date(updatedDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formatDate = `${day}-${month}-${year}`;
      updatedProductMap[index] = {
        ...updatedItem,
        date: formatDate,
      };
    }

    const updatedProductArray = Object.keys(updatedProductMap).map(
      (key) => updatedProductMap[key]
    );
    setRowsData(updatedProductArray);
  };

  const calculateTotalAmount = () => {
    const totalSum = Object.values(rowsData)
      .map((row) => Number(row?.price))
      .filter((price) => !isNaN(price))
      .reduce((acc, price) => acc + price, 0);
    if (totalSum !== 0) {
      if (totalSum >= total) {
        if (total - totalSum + 1 <= 0) {
          setShowError("2");
          const notify = () =>
            toast.error(
              "Total installment amount cannot exceed the total amount !",
              {
                style: {
                  padding: "10px",
                  borderRadius: "30px",
                },
              }
            );
          notify();
        } else {
          setUnPaidData(0);
          setPaidData(totalSum);
        }
      } else if (totalSum <= total + 1 || totalSum === 0) {
        setShowError("0");
        const unpaid = total - totalSum;
        const Paid = totalSum;
        setUnPaidData(unpaid);
        setPaidData(Paid);
      }
    } else if (totalSum === 0) {
      setShowError("0");
      setUnPaidData(total);
      setPaidData(0);
    }
  };
  useEffect(() => {
    calculateTotalAmount();
  }, [rowsData, total, totalMap, billingData]);
  const filterData = calenderData.filter((e) => e == submitEventID);

  const daaattaa = rowsData.map((e) => e.price);
  const length = daaattaa.filter((value) => value !== "").length;

  const handleSubmit = () => {
    const hasMissingFields =
      billingData?.reservationStatus === "Tentative"
        ? rowsData.some((row) => {
            return (row.price && !row.formatdate && !row.date) ||
              (!row.price && row.formatdate && !row.date) ||
              (!row.price && !row.formatdate && row.date)
              ? true
              : false;
          })
        : billingData?.reservationStatus === "FinalReserve"
        ? rowsData.some((row) => {
            return !row.price || !row.formatdate || !row.date;
          })
        : "";
    const updatedRowsData = rowsData.map((row) => {
      if (row?.paymentType === "") {
        return { ...row, paymentType: billingData?.payment_type };
      }
      return row;
    });
    const jsonString = JSON.stringify(updatedRowsData);
    const formData = new FormData();
    formData.append("event_id", submitEventID);
    formData.append("menu_price", billingData?.menu_price);
    formData.append(
      "discount_per_user",
      billingData?.discount_per_user == (undefined || "")
        ? 0
        : billingData?.discount_per_user
    );
    formData.append(
      "food_extra_cost",
      billingData?.food_extra_cost === (undefined || "")
        ? 0
        : billingData?.food_extra_cost
    );
    formData.append(
      "sound_extra_cost",
      billingData?.sound_extra_cost === (undefined || "")
        ? 0
        : billingData?.sound_extra_cost
    );
    formData.append("food_notes", billingData?.food_notes);
    formData.append(
      "billreservationStatus",
      length < 1 ? billingData?.reservationStatus : "FinalReserve"
    );
    formData.append("sound_notes", billingData?.sound_notes);
    formData.append("noOfGuests", billingData?.noOfGuests);
    formData.append(
      "service_charge",
      billingData?.service_charge == "" ? 0 : billingData?.service_charge
    );
    formData.append("installment_payments", jsonString);
    formData.append("white_hall_payment", WHTotal);
    formData.append("sound_light_payment", billingData?.sound_light_payment);
    // formData.append("extra_payment", billingData?.extra_payment );
    formData.append("grand_total", totalMap);
    formData.append("payment_type", billingData?.payment_type);
    formData.append("unpaid", unPaidData);
    formData.append("paid", paidData);
    formData.append("discount_per_user_total", disPerUser);
    formData.append(
      "white_total_dis",
      billingData?.whitehall_payment - disPerUser
    );
    formData.append("charity_tax", charityTax);
    formData.append("charity_SL", charityTaxSL);
    formData.append("m_price", billingData?.m_price);
    formData.append("s_price", billingData?.s_price);
    formData.append("white_total", billingData?.whitehall_payment);
    formData.append("discount", disPerUser);
    formData.append("create_bill_user_id", userId);
    formData.append("sub_grand_total", total);
    formData.append("total_gst", totalGST);
    // formData.append("gst_mode", taxMode ? "0" : "1");
    formData.append("tax_mode", taxMode ? "0" : "1");
    formData.append("event_hide", eventCheck ? "0" : "1");
    formData.append("bill_type", billType);
    formData.append(
      "user_type",
      role == "0"
        ? "superadmin"
        : role == "1"
        ? "kitchen user"
        : role == "2"
        ? "sales user"
        : role == "3"
        ? "accounting user"
        : ""
    );
    formData.append(
      "spacial_dis",
      billingData?.special_dis === undefined || billingData?.special_dis === ""
        ? 0
        : billingData?.special_dis
    );
    if (file) {
      file.forEach((single) => formData.append("files[]", single));
    } else {
      formData.append("files[]", "");
    }
    if (showError === "1") {
      const notify = () =>
        toast.error(
          "Maximum discount exceeded. Please review your discount settings and try again!",
          {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          }
        );
      notify();
    } else if (WHTotal < 0) {
      const notify = () =>
        toast.error("White Hall Payment should not be Minus", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (showError === "2") {
      const notify = () =>
        toast.error(
          "Total installment amount cannot exceed the total amount !",
          {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          }
        );
      notify();
    } else if (hasMissingFields) {
      const notify = () =>
        toast.error("All Installment Payment fields are required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      billingData?.reservationStatus === "FinalReserve" &&
      billingData?.payment_type === ""
    ) {
      const notify = () =>
        toast.error("Payment type is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      billingData?.reservationStatus === "Tentative" &&
      length < 1 === false &&
      billingData?.payment_type === ""
    ) {
      const notify = () =>
        toast.error("Payment type is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (showError === "0") {
      const submitData = {
        tax_mode: eventCheck ? "1" : "0",
      };
      dispatch(AddBillData(formData, setLoading));
      // dispatch(sendShowDetails(submitEventID, submitData, setLoading));
      for (let pair of formData.entries()) {
        // console.log(pair[0] + ": " + pair[1]);
      }
    }
  };
  useEffect(() => {
    if (res_add_data?.data?.status === true) {
      const notify = () =>
        toast.success(res_add_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        setShowData(5);
        res_add_data.loading = true;
        res_add_data.data = {};
        res_get_data.loading = true;
        res_get_data.data = {};
        // window.location.reload();
        // if (location.state.type === "billing") {
        //   navigate("/billing");
        // } else if (location.state.type === "account") {
        //   navigate("/accounting_main");
        // }
      }, 1500);
    } else if (res_add_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_add_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_add_data.loading = true;
        res_add_data.data = {};
      }, 1500);
    }
  }, [res_add_data, navigate]);
  const backData = () => {
    res_add_data.loading = true;
    res_add_data.data = {};
    res_get_data.loading = true;
    res_get_data.data = {};
  };

  const resetData = () => {
    const tax = res_get_data?.data?.data?.getEventBill[0]?.tax_mode;
    const event_type = res_get_data?.data?.data?.getEventBill[0]?.eventType;
    const menuPrice = Number(
      res_get_data?.data?.data?.getEventBill[0]?.m_price === null
        ? 0
        : res_get_data?.data?.data?.getEventBill[0]?.m_price
    );
    const stationPrice = Number(
      res_get_data?.data?.data?.stations === null
        ? 0
        : res_get_data?.data?.data?.stations
    );
    const SoundLightPrice = Number(
      res_get_data?.data?.data?.getEventBill[0]?.package_price === null
        ? 0
        : res_get_data?.data?.data?.getEventBill[0]?.package_price
    );

    const GuestNumber = Number(
      res_get_data?.data?.data?.getEventBill[0]?.noOfGuests
    );
    setBillingData({
      event_id: submitEventID,
      // special_dis:"",
      eventType: res_get_data?.data?.data?.getEventBill[0]?.eventType,
      menu_price:
        Number(
          (event_type === "Charity" && tax === "0"
            ? stationPrice - stationPrice * 0.16
            : stationPrice) +
            (event_type === "Charity" && tax === "0"
              ? menuPrice - menuPrice * 0.16
              : menuPrice)
        ) * Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests),
      m_price:
        event_type === "Charity" && tax === "0"
          ? Number(menuPrice - menuPrice * 0.16)
          : menuPrice,
      s_price:
        event_type === "Charity" && tax === "0"
          ? Number(stationPrice - stationPrice * 0.16)
          : stationPrice,
      discount_per_user: "",
      food_extra_cost: "",
      food_notes: "",
      service_charge: "",
      sound_light_payment:
        event_type === "Charity" && tax === "0"
          ? Number(SoundLightPrice - SoundLightPrice * 0.16)
          : SoundLightPrice,
      sound_extra_cost: "",
      sound_notes: "",
      noOfGuests: res_get_data?.data?.data?.getEventBill[0]?.noOfGuests,
      tax_mode: res_get_data?.data?.data?.getEventBill[0]?.tax_mode,
      eventType: res_get_data?.data?.data?.getEventBill[0]?.eventType,
      reservationStatus:
        res_get_data?.data?.data?.getEventBill[0]?.reservationStatus,
      notes: "",
      package_price:
        event_type === "Charity" && tax === "0"
          ? Number(SoundLightPrice - SoundLightPrice * 0.16)
          : SoundLightPrice,
      whitehall_payment:
        Number(
          (event_type === "Charity" && tax === "0"
            ? (stationPrice - stationPrice * 0.16) * GuestNumber
            : stationPrice * GuestNumber) +
            (event_type === "Charity" && tax === "0"
              ? (menuPrice - menuPrice * 0.16) * GuestNumber
              : menuPrice * GuestNumber)
        ) +
        (event_type === "Charity" && tax === "0"
          ? Number(SoundLightPrice - SoundLightPrice * 0.16)
          : SoundLightPrice),
      payment_type: "",
    });
    setWHTotal(
      Number(
        (event_type === "Charity" && tax === "0"
          ? Number(stationPrice - stationPrice * 0.16)
          : stationPrice) +
          (event_type === "Charity" && tax === "0"
            ? Number(menuPrice - menuPrice * 0.16)
            : menuPrice) *
            Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
      ) +
        (event_type === "Charity" && tax === "0"
          ? Number(SoundLightPrice - SoundLightPrice * 0.16)
          : SoundLightPrice)
    );
    setTotalMap(
      Number(
        (event_type === "Charity" && tax === "0"
          ? Number(stationPrice - stationPrice * 0.16)
          : stationPrice) +
          (event_type === "Charity" && tax === "0"
            ? Number(menuPrice - menuPrice * 0.16)
            : menuPrice) *
            Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
      ) +
        (event_type === "Charity" && tax === "0"
          ? Number(SoundLightPrice - SoundLightPrice * 0.16)
          : SoundLightPrice)
    );
    setTotal(
      Number(
        (event_type === "Charity" && tax === "0"
          ? Number(stationPrice - stationPrice * 0.16)
          : stationPrice) +
          (event_type === "Charity" && tax === "0"
            ? Number(menuPrice - menuPrice * 0.16)
            : menuPrice) *
            Number(res_get_data?.data?.data?.getEventBill[0]?.noOfGuests)
      ) +
        (event_type === "Charity" && tax === "0"
          ? Number(SoundLightPrice - SoundLightPrice * 0.16)
          : SoundLightPrice)
    );
    setSettingPer({
      tax_dis: res_get_data?.data?.data?.getsetting[0]?.tax_dis,
      tax_mode: res_get_data?.data?.data?.getsetting[0]?.tax_mode,
    });
    if (res_get_data?.data?.data?.getEventBill[0]?.tax_mode == "0") {
      setTaxMode(true);
    } else {
      setTaxMode(false);
    }

    setRowsData([
      {
        f_v_name: "FIRST", // Add your default values here
        price: "",
        formatdate: null,
        date: null,
        paymentIndex: "0",
        paymentType: "",
      },
    ]);
    if (res_get_data?.data?.data?.getEventBill[0]?.event_hide === "0") {
      setEventCheck(false);
    } else if (res_get_data?.data?.data?.getEventBill[0]?.event_hide === "1") {
      setEventCheck(true);
    }
  };

  return (
    <>
      <Toaster />
      {res_get_data.loading ? (
        <Loader />
      ) : (
        <div className="">
          {/* <Header name="Billing" /> */}
          <div className=" container-fluid">
            <div className=" form-margin-768 padding-left-21">
              <div className=" text-start mt-4 mt-sm-5 form-in-768">
                <div className=" ms-md-0">
                  <div className="disply-manu-768 ">
                    <div className="row mb-3 width-100-768 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label d-block text-start label"
                        >
                          FOOD AND DRINKS
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            min={0}
                            className="form-control inpt-border-trans"
                            value={
                              role === "3"
                                ? Number(
                                    Number(billingData?.m_price) +
                                      Number(billingData?.s_price)
                                  )?.toFixed(5)
                                : billingData?.menu_price?.toFixed(5)
                            }
                            readOnly
                            autoComplete="off"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable justify-content-center ">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 width-second-768 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label d-block text-start label "
                        >
                          DISCOUNT PER PERSON
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            min={0}
                            className="form-control inpt-border-trans"
                            name="discount_per_user"
                            value={billingData?.discount_per_user}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="disply-manu-768 ">
                    <div className="row mb-3 width-100-768 ">
                      <div className="col-lg-4 col-12">
                        <label
                          for="basic-url"
                          className="form-label label text-start d-block "
                        >
                          SERVICE CHARGE
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group  price-jod price-jod-768">
                          <input
                            type="number"
                            min={0}
                            className="form-control inpt-border-trans"
                            name="service_charge"
                            value={billingData?.service_charge}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 width-second-768">
                      <div className="col-lg-4 col-12">
                        <label
                          for="basic-url"
                          className="form-label label text-start d-block "
                        >
                          EXTRA COST
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group  price-jod price-jod-768">
                          <input
                            type="number"
                            min={0}
                            className="form-control inpt-border-trans"
                            name="food_extra_cost"
                            value={billingData?.food_extra_cost}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="disply-manu-768 ">
                    {role === "3" && (
                      <div className="row mb-3 width-100-768 ">
                        <div className="col-lg-4 col-12">
                          <label
                            for="basic-url"
                            className="form-label label text-start d-block "
                          >
                            NUMBER OF GUESTS
                          </label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <input
                            type="number"
                            className="form-control inpt inpt-number inpt-768"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            name="noOfGuests"
                            value={billingData?.noOfGuests}
                            // readOnly
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          {/* <div className="input-group  price-jod price-jod-768">
                            <input
                              type="number"
                              min={0}
                              className="form-control inpt-border-trans"
                              name="noOfGuests"
                              value={billingData?.noOfGuests}
                              //readOnly
                              onChange={handleChange}
                              autoComplete="off"
                            />
                            <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                              JOD
                            </span>
                          </div> */}
                        </div>
                      </div>
                    )}
                    {/* <div className="row mb-3 width-second-768">
                          <div className="col-lg-4 col-12">
                            <label
                              for="basic-url"
                              className="form-label label text-start d-block "
                            >
                              EXTRA COST
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group  price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                name="food_extra_cost"
                                value={billingData?.food_extra_cost}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div> */}
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4 col-12">
                      <label
                        for="basic-url"
                        className="form-label  label text-black text-lg-white"
                      >
                        NOTES
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group  price-textarea">
                        <textarea
                          className=" form-control inpt-border-trans"
                          rows="6"
                          name="food_notes"
                          autoComplete="off"
                          placeholder="Why..."
                          onChange={handleChange}
                          value={billingData?.food_notes}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="disply-manu-768 ">
                    <div className="row mb-3 width-100-768 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label d-block text-start label"
                        >
                          SOUND AND LIGHT COST
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            min={0}
                            className="form-control inpt-border-trans"
                            value={billingData?.sound_light_payment?.toFixed(5)}
                            readOnly
                            autoComplete="off"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable justify-content-center ">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 width-second-768 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label d-block text-start label "
                        >
                          EXTRA COST
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            min={0}
                            className="form-control inpt-border-trans"
                            name="sound_extra_cost"
                            value={billingData?.sound_extra_cost}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-4 col-12">
                      <label for="basic-url" className="form-label  label">
                        NOTES
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group  price-textarea">
                        <textarea
                          className=" form-control inpt-border-trans"
                          rows="6"
                          name="sound_notes"
                          autoComplete="off"
                          placeholder="Why..."
                          onChange={handleChange}
                          value={billingData?.sound_notes}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {role === "3" && (
                <div className="form-in-768 mb-3">
                  <div className="row mb-2 mt-3">
                    <div className=" col-auto col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        ADD TAX
                      </label>
                    </div>
                    <div className=" col-auto col-lg-8">
                      <div className="form-check ps-0">
                        <input
                          className="form-check-input cursor-pointer bg-transparant"
                          type="checkbox"
                          // role="switch"
                          // disabled
                          id="flexSwitchCheckDefault"
                          name="tax_mode"
                          checked={taxMode}
                          onChange={() => setTaxMode(!taxMode)}
                        />
                        {/* <input
                          className="form-check-input on-off cursor-pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          name="tax_mode"
                          checked={taxMode}
                          onChange={() => setTaxMode(!taxMode)}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="">
                <h3 style={{ fontWeight: 600 }} className="mb-4">
                  Payment
                </h3>
                <div className="form-in-768">
                  <h6>INSTALLMENT PAYMENT</h6>

                  {rowsData.map((data, index) => {
                    return (
                      <div key={index}>
                        <hr className="d-block d-sm-none" />
                        <div className="d-flex align-items-center">
                          <div className="col-10 col-lg-8 col-xl-6">
                            <div className="d-block d-sm-flex ">
                              <div className="col-12 col-sm-6 pe-sm-2 pe-0">
                                <label
                                  for="basic-url"
                                  className="form-label pe-5 payment-lable"
                                >
                                  {rowsData[index]?.f_v_name} PAYMENT
                                </label>
                                <div className="input-group mb-3 price-payment price-payment-768 ">
                                  <input
                                    type="number"
                                    min={0}
                                    className="form-control inpt-border-trans "
                                    name="price"
                                    autoComplete="off"
                                    value={rowsData[index]?.price}
                                    onChange={(e) =>
                                      paymentHandleChange(e, index)
                                    }
                                  />
                                  <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                    JOD
                                  </span>
                                </div>
                              </div>

                              <div className=" col-12 col-sm-6 pe-sm-2 pe-0">
                                <label
                                  for="basic-url"
                                  className="form-label pe-5  payment-lable"
                                >
                                  DATE
                                </label>
                                <div className="input-group mb-3 jod_width price-payment price-payment-768 ">
                                  <input
                                    type=""
                                    className="form-control inpt-border-trans "
                                    readOnly
                                    value={
                                      rowsData[index]?.date === null
                                        ? ""
                                        : rowsData[index]?.date
                                    }
                                  />
                                  <DatePicker
                                    selected={
                                      !rowsData[index]?.formatdate === null
                                        ? rowsData[index].formatdate
                                        : null
                                    }
                                    onChange={(date) =>
                                      paymentHandleChange(
                                        {
                                          target: {
                                            name: "formatdate",
                                            value: date,
                                          },
                                        },
                                        index
                                      )
                                    }
                                    className="form-control inpt-border-trans"
                                    showPopperArrow={false}
                                    name="formatdate"
                                    dateFormat="dd-MM-yyyy"
                                    customInput={
                                      <span>
                                        <FaCalendarDays className="cursor-pointer" />
                                      </span>
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {rowsData[index]?.paymentIndex === "0" ? (
                            <div className="col-2 col-sm-1 col-xl-6">
                              <div className=" plus-icon ">
                                <FaCirclePlus
                                  onClick={addTableRows}
                                  className="cursor-pointer"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="col-2 col-sm-4 col-xl-6">
                              <div className="fs-1 ">
                                <IoMdCloseCircle
                                  className="cursor-pointer"
                                  onClick={() => deleteTableRows(index)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {role === "3" && (
                <div className="form-in-768 mb-3">
                  <div className="row mb-2 mt-3">
                    <div className=" col-auto col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        MARK THIS EVENT TO BE HIDEN
                      </label>
                    </div>
                    <div className=" col-auto col-lg-8">
                      <div className="form-check form-switch ps-0">
                        <input
                          className="form-check-input on-off cursor-pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          name="tax_mode"
                          checked={eventCheck}
                          onChange={() => setEventCheck(!eventCheck)}
                        />
                        {/* <input
                        className="form-check-input cursor-pointer bg-transparant"
                        type="checkbox"
                        // role="switch"
                        id="flexSwitchCheckDefault"
                        name="tax_mode"
                        checked={taxMode} // Set checked state based on tex_mode value
                        onChange={() => setTaxMode(!taxMode)}
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="">
                <div className=" mt-5 p-3 payment-box ">
                  <div className="form-in-768">
                    <div className="disply-manu-768">
                      <div className="row mb-3 width-100-768">
                        <div className="col-12 col-lg-5 ">
                          <label
                            for="basic-url"
                            className="form-label d-block text-start label "
                          >
                            WHITE HALL PAYMENT
                          </label>
                        </div>
                        <div className="col-12 col-lg-7 mt-auto mt-sm-1 mt-md-auto ">
                          <div className="input-group price-jod price-jod-768">
                            <input
                              type="number"
                              min={0}
                              className="form-control inpt-border-trans"
                              autoComplete="off"
                              value={WHTotal?.toFixed(5)}
                              readOnly
                            />
                            <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                              JOD
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3 width-second-768">
                        <div className="col-lg-5 col-12 ">
                          <label
                            for="basic-url"
                            className="form-label d-block text-start label "
                          >
                            SPECIAL DISCOUNT
                          </label>
                        </div>
                        <div className="col-lg-7 col-12">
                          <div className="input-group price-jod price-jod-768">
                            <input
                              type="number"
                              min={0}
                              className="form-control inpt-border-trans"
                              name="special_dis"
                              onChange={handleChange}
                              disabled={taxMode === true}
                              // min={0}
                              value={
                                taxMode
                                  ? Number(
                                      Number(
                                        billingData?.special_dis ===
                                          undefined ||
                                          billingData?.special_dis === ""
                                          ? 0
                                          : billingData?.special_dis
                                      ) / 1.16
                                    ).toFixed(5)
                                  : Number(billingData?.special_dis)
                              }
                              // min={0}
                              // value={Number(billingData?.special_dis)}
                              // readOnly
                            />
                            <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                              JOD
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="disply-manu-768">
                      {role === "3" && (
                        <>
                          <div className="row mb-3 width-100-768">
                            <div className="col-lg-5 col-12">
                              <label
                                for="basic-url"
                                className="form-label label text-start d-block"
                              >
                                TAX
                              </label>
                            </div>
                            <div className="col-12 col-lg-7">
                              <div className="input-group price-jod price-jod-768">
                                <input
                                  type="number"
                                  className="form-control inpt-border-trans"
                                  value={totalGST.toFixed(5)}
                                  readOnly
                                />
                                <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                  JOD
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div
                        className={`row mb-3 ${
                          role === "3" ? "width-second-768" : "width-100-768"
                        }`}
                      >
                        <div className="col-lg-5 col-12 ">
                          <label
                            for="basic-url"
                            className="form-label d-block text-start label "
                          >
                            GRAND TOTAL
                          </label>
                        </div>
                        <div className="col-lg-7 col-12">
                          <div className="input-group price-jod price-jod-768">
                            <input
                              type="number"
                              min={0}
                              className="form-control inpt-border-trans"
                              value={totalMap?.toFixed(5)}
                              readOnly
                            />
                            <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                              JOD
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-in-768">
                {/* <div className="row mb-2 mt-3">
                  <div className=" col-auto col-lg-3">
                    <label for="basic-url" className="form-label  label">
                      TAX MODE
                    </label>
                  </div>
                  <div className=" col-auto col-lg-9">
                    <div className="form-check ps-0">
                      <input
                        className="form-check-input cursor-pointer bg-transparant"
                        type="checkbox"
                        // role="switch"
                        id="flexSwitchCheckDefault"
                        name="tax_mode"
                        checked={taxMode} // Set checked state based on tex_mode value
                        onChange={() => setTaxMode(!taxMode)}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="row d-flex  mt-2 align-items-center  mb-3">
                  <div className="col-12 col-lg-3">
                    <label
                      for="basic-url"
                      className="form-label me-xxl-5 me-0   label"
                    >
                      PAYMENT TYPE
                    </label>
                  </div>
                  <div className="col-12 col-lg-9">
                    <div className="mt-2 row d-flex ms-2 ms-sm-auto">
                      <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="1"
                          checked={billingData?.payment_type === "1"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio1"
                        >
                          Cash
                        </label>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="2"
                          onChange={handleChange}
                          checked={billingData?.payment_type === "2"}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio2"
                        >
                          Cheque
                        </label>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="3"
                          onChange={handleChange}
                          checked={billingData?.payment_type === "3"}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio3"
                        >
                          Bank Transfer
                        </label>
                      </div>
                      <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ">
                        <input
                          className="form-check-input cursor-pointer mb-2"
                          type="radio"
                          name="payment_type"
                          value="4"
                          onChange={handleChange}
                          checked={billingData?.payment_type == "4"}
                        />
                        <label
                          className="form-check-label check-label"
                          for="inlineRadio3"
                        >
                          E-Payment
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex  mt-2 align-items-center  ">
                  <div className="col-12 col-lg-3">
                    <label
                      for="basic-url"
                      className="form-label me-xxl-5 me-0   label"
                    >
                      UPLOAD
                    </label>
                  </div>
                  <div className="col-12 col-lg-9">
                    <div className="row d-flex">
                      <div className="col-auto">
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="input-group jod_width_cal price cal_width price-upload-768"
                            id="upload"
                          >
                            <input type="file" className="d-none" id="upload" />
                            <input {...getInputProps()} />
                            <input
                              className="form-control inpt-border-trans text-info text-decoration-underline"
                              value={"Attach File"}
                              style={{ cursor: "pointer" }}
                              readOnly
                              // disabled
                              onChange={(e) =>
                                setFile(parseInt(e.target.value) || 0)
                              }
                            />
                            <span className="input-group-text inpt-border-trans inpt-lable justify-content-center">
                              <FiPaperclip
                                className="fs-5"
                                // onClick={incNum}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex  mt-2 align-items-center  mb-3">
                  <div className="col-12 col-lg-3"></div>
                  <div className="col-12 col-lg-9">
                    <div className="row d-flex justify-content-center justify-content-lg-start">
                      {thumbs.map((file, index) => (
                        <div style={thumb} key={file.name}>
                          <div style={thumbInner}>
                            {file.name.endsWith(".pdf") ? (
                              <img
                                src={pdf}
                                style={img}
                                onLoad={() => {
                                  URL.revokeObjectURL(file.preview);
                                }}
                              />
                            ) : (
                              <img
                                src={file.preview}
                                style={img}
                                onLoad={() => {
                                  URL.revokeObjectURL(file.preview);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-in-768 ">
                <div className="mt-xxl-3 mt-5 pt-xxl-4 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section position-relative display-above-768">
                  {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={() => setShowData(3)}
                  // onClick={backData}
                >
                  BACK
                </button> */}
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={resetData}
                  >
                    RESET
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SUBMIT
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-4 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section position-relative display-for-768">
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SUBMIT
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={resetData}
                  >
                    RESET
                  </button>
                  {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={() => setShowData(3)}
                >
                  BACK
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_data: state?.get_Billing_reducer,
  res_add_data: state?.Add_Billing_reducer,
  res_calender_data: state?.Calendar_get_Event_reducer,
});
export default connect(mapStateToProps)(AddBilling);
