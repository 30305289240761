import React, { useEffect } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {  DeleteSoundAndLightData, getToken } from "../../../Store/Action/useFetchData";

const DeleteSoundAndLight = ({deleteSoundLight,setDeleteSoundLight,deleteType,dispatch,res_extra}) => {
    useEffect(() => {
        dispatch(getToken());
      }, [dispatch]);
      useEffect(()=>{
         toast.dismiss();
      },[])
      const handleDeleteData = () => {
        dispatch(DeleteSoundAndLightData(deleteType));
      };
      const handleClose = () => {
        setDeleteSoundLight(false);
        res_extra.loading = true;
        res_extra.data = {};
      };
      useEffect(() => {
        if (res_extra?.data?.status === true) {
          const notify = () =>
            toast.success(res_extra?.data?.message, {
              style: {
                padding: "10px",
                borderRadius: "30px",
              },
            });
          notify();
          setTimeout(() => {
            setDeleteSoundLight(false);
            res_extra.loading = true;
            res_extra.data = {};
          }, 1500);
        } else if (res_extra?.data?.status === false) {
          const notify = () =>
            toast.error(res_extra?.data?.message, {
              style: {
                padding: "10px",
                borderRadius: "30px",
              },
            });
          notify();
          setTimeout(() => {
            res_extra.loading = true;
            res_extra.data = {};
          }, 1500);
        }
      }, [res_extra,setDeleteSoundLight]);
  return (
    <>
       <Toaster />
      <div
        className={`modal${deleteSoundLight ? " fade show d-block " : " d-none"
          }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-xl-role">
          <div className="modal-content">
            <div className="modal-header border-0 justify-content-end">
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body modal-padd p-1 px-4">
              <h5 className="fw-bold">
                {" "}
                Are you sure you want to delete the selected Menu Type?
              </h5>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                NO
              </button>
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleDeleteData}
              >
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
    res_token: state?.CrcToken_reducer,
    res_extra: state?.Delete_sound_and_light_data_reducer,
  });
export default connect(mapStateToProps)(DeleteSoundAndLight);
