import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SendSetting,
  getSetting,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader";

const Settings = ({ dispatch, res_setting, res_get_setting }) => {
  // on-0
  // off-1
  const navigate = useNavigate();
  const [settingData, setSettingData] = useState({
    tax_dis: "",
    // tax_mode: "1", // Default value for tex_mode
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getSetting());
  }, []);
  useEffect(()=>{
     toast.dismiss();
  },[])
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    if (res_get_setting?.data?.status === true) {
      setSettingData({
        tax_dis: res_get_setting?.data?.data?.tax_dis,
        // tax_mode: res_get_setting?.data?.data?.tax_mode,
      });
      // sessionStorage.setItem("tax_mode", res_get_setting?.data?.data?.tax_mode);

      // setTimeout(() => {
      //   res_get_setting.loading = true;
      //   res_get_setting.data = {};
      // }, 1500);
    } else if (res_get_setting?.data?.status === false) {
      const notify = () =>
        toast.error(res_get_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      // setTimeout(() => {
      //   res_get_setting.loading = true;
      //   res_get_setting.data = {};
      // }, 1500);
    }
  }, [res_get_setting]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update tex_mode based on checkbox
    // if (type === "checkbox") {
    //   const newValue = checked ? "0" : "1";
    //   setSettingData({ ...settingData, [name]: newValue });
    // } else {
      setSettingData({ ...settingData, [name]: value });
    // }
  };
  const handleSubmit = () => {
    // sessionStorage.setItem("tax_mode", settingData.tax_mode);
    dispatch(SendSetting(settingData, setLoading));
  };
  useEffect(() => {
    if (res_setting?.data?.status === true) {
      const notify = () =>
        toast.success(res_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_setting.loading = true;
        res_setting.data = {};
        res_get_setting.loading = true;
        res_get_setting.data = {};
        window.location.reload();
      }, 1500);
    } else if (res_setting?.data?.status === false) {
      const notify = () =>
        toast.error(res_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_setting.loading = true;
        res_setting.data = {};
      }, 1500);
    }
  }, [res_setting]);

  const resetData = () => {
    setSettingData({
      tax_dis: res_get_setting?.data?.data?.tax_dis,
      tax_mode: res_get_setting?.data?.data?.tax_mode,
    });
  };
  return (
    <>
      <Toaster />
      {res_get_setting.loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="container-fluid ">
            <div className="form-margin-setting-768  form-margin padding-left-21">
              <div className="   form-in-768">
                {role === "0" ? (
                  <div className="row mb-3 mt-5">
                    <div className="col-12 col-lg-4">
                      <label htmlFor="basic-url" className="form-label  label">
                        SET MAX DISCOUNT PER PERSON
                      </label>
                    </div>
                    <div className=" col-12 col-sm-6 col-lg-8">
                      <div className="input-group mb-3 price price-768">
                        <input
                          type="number"
                          className="form-control inpt-border-trans"
                          name="tax_dis"
                          autoComplete="off"
                          value={settingData.tax_dis}
                          onChange={handleChange}
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-setting inpt-lable ps-4 fs-6 fs-sm-5  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-5">
                    <p className="text-black mb-5">
                      NOTE: Any event you made hidden will not appear when Tax
                      Mode is activated!
                    </p>
                  </div>
                )}

                {/* <div className="row mb-3 ">
                  <div className="col-6 col-sm-4">
                    <label htmlFor="basic-url" className="form-label  label">
                      TAX MODE
                    </label>
                  </div>
                  <div className="col-6 col-sm-8">
                    <div className="form-check form-switch ps-0">
                      <input
                        className="form-check-input on-off cursor-pointer"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        name="tax_mode"
                        checked={settingData.tax_mode === "0"} // Set checked state based on tex_mode value
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768 ">
                  <button
                    type="button"
                    className="btn btn-g me-2 btn-768 mb-4"
                    onClick={() => {
                      res_setting.loading = true;
                      res_setting.data = {};
                      res_get_setting.loading = true;
                      res_get_setting.data = {};
                      if (role === "0") {
                        navigate("/calender");
                      } else if (role === "1") {
                        navigate("/kitchen_main");
                      } else if (role === "2") {
                        navigate("/sales_main");
                      } else if (role === "3") {
                        navigate("/accounting_main");
                      }
                    }}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="btn btn-g me-2 btn-768 mb-4 "
                    onClick={resetData}
                  >
                    RESET
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 btn-768 mb-4"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SUBMIT
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 btn-768 mb-4"
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768 ">
                  <button
                    type="button"
                    className="btn btn-g me-2 btn-768 mb-4"
                    onClick={() => {
                      res_setting.loading = true;
                      res_setting.data = {};
                      res_get_setting.loading = true;
                      res_get_setting.data = {};
                      if (role === "0") {
                        navigate("/calender");
                      } else if (role === "3") {
                        navigate("/accounting_main");
                      }
                    }}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="btn btn-g me-2 btn-768 mb-4 "
                    onClick={resetData}
                  >
                    RESET
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 btn-768 mb-4"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SUBMIT
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 btn-768 mb-4"
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_setting: state?.send_setting_reducer,
  res_get_setting: state?.get_setting_reducer,
});

export default connect(mapStateToProps)(Settings);
