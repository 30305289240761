import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import {
  MainKitchenPdfDownload,
  getAccountCalendarDetails,
  getEventDetailsOnId,
  getKitchenEvent,
  getToken,
  sendShowDetails,
  transferDateEvent,
} from "../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import { saveAs } from "file-saver";
import DownloadLink from "react-download-link";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import pink_img from "../../../images/pink-triangle.png";
import green_img from "../../../images/greentriangle.png";
import { MdArrowBackIos } from "react-icons/md";
import { GrNext } from "react-icons/gr";
import ViewBillDetails from "../Forms/View/ViewBillDetails";
import Loader from "../../Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarDays } from "react-icons/fa6";
import { RiArrowRightSLine } from "react-icons/ri";
import Pusher from "pusher-js";

const localizer = momentLocalizer(moment);
const AccountantDetailTable = ({
  dispatch,
  res_token,
  res_calendar_event,
  res_show_details,
  res_transfer_date,
  res_event_on_id,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const [events, setEvents] = useState([]);
  const [eventsTemp, setEventsTemp] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [viewBillTable, setViewBillTable] = useState(false);
  const [viewBillTableID, setViewBillTableID] = useState();
  const [secondEvent, setSecondEvent] = useState(null);
  const [eventType, setEventType] = useState("Wedding");
  const [eventTypePhone, setEventTypePhone] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [deleteEventId, setDeleteEventID] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditEvent, setShowEditEvent] = useState(false);
  const [editEventData, setEditEventData] = useState(false);
  const [editEventType, setEditEventType] = useState("");
  const [editFile, setEditFile] = useState([]);
  const [showTransferForm, setShowTransferForm] = useState(false);
  const [eventIdTransfer, setEventIdTransfer] = useState();
  const [selectedMonth, setSelectedMonth] = useState(new Date()); // Assuming selectedMonth is stored in state
  const [showTable, setShowTable] = useState(true);
  const [length, setLength] = useState(0);
  const [tableInitialized, setTableInitialized] = useState(false);
  const [transferDate, setTransferDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [socket, setSocket] = useState();
  // const [billId, setBillId] = useState();

  const backData = () => {
    // TableDatatablesManaged.init();
    setShowTransferForm(false);
    setEventTypePhone(false);
    setTableInitialized(true);
    setShowTable(true);
    window.location.reload();
  };
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");

    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log(socket,"socket");
      socket.bind("tax_mode_on", async (data) => {
        window.location.reload();
      });
    }
  }, [socket]);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getToken());
    dispatch(getAccountCalendarDetails());
  }, []);
  useEffect(() => {
    // if (!tableInitialized) {
    TableDatatablesManaged.init();
    setTableInitialized(true);
    // }
  }, []);
  // console.log(tableInitialized, "tableiii");
  // useEffect(() => {
  //   dispatch(getKitchenEvent())

  // }, [res_calendar_event]);

  const rbc_dates = document.querySelectorAll(".rbc-date-cell");
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    if (rbc_dates.length > 0) {
      rbc_dates.forEach((date) => {
        date.style.setProperty("margin-top", "26px");
        // date.style.marginTop = '26px';
      });
    }
  }, [rbc_dates]);
  // console.log(rbc_dates, "rbc");

  useEffect(() => {
    // if (res_get_events?.data?.status === true) {
    //   const events_arr = res_get_events.data.data;
    //   const transformedEvents = events_arr.map((event, index, array) => {
    //     const { eventID, eventType, startDate, endDate, reservationStatus } = event;
    //     const isSecond = array.slice(0, index).some(prevEvent => prevEvent.startDate === startDate
    //       );

    //     // Adjusting the time to 00:00:00
    //     const startDateTime = new Date(startDate);
    //     startDateTime.setHours(0, 0, 0, 0);

    //     const endDateTime = new Date(endDate);
    //     endDateTime.setHours(0, 0, 0, 0);

    //     return {
    //       end: endDateTime,
    //       id: eventID,
    //       isSecond: isSecond ? 1 : 0,
    //       start: startDateTime,
    //       type: reservationStatus,
    //     };
    //   });

    //   setEvents([...events, ...transformedEvents] )
    //   //   const notify = () =>
    //   //   toast.success(res_get_events?.data?.message, {
    //   //     style: {
    //   //       padding: "10px",
    //   //       borderRadius: "30px",
    //   //     },
    //   //   });
    //   // notify();

    // }

    if (res_calendar_event?.data?.status === true) {
      var tax_mode_final = res_calendar_event?.data?.tax?.tax_mode;
      const events_arr = res_calendar_event.data.data;
      let transformedEvents = [];

      if (tax_mode_final === "0") {
        transformedEvents = events_arr.map((event, index, array) => {
          const {
            eventID,
            eventType,
            startDate,
            endDate,
            reservationStatus,
            tax_mode,
            userRole,
            bill_id,
          } = event;
          if (tax_mode === "0") {
            const isSecond = array
              .slice(0, index)
              .some((prevEvent) => prevEvent.startDate === startDate);
            // Adjusting the time to 00:00:00
            const startDateTime = new Date(startDate);
            startDateTime.setHours(0, 0, 0, 0);

            const endDateTime = new Date(endDate);
            endDateTime.setHours(0, 0, 0, 0);

            // Check if eventID already exists in transformedEvents
            const isDuplicate = transformedEvents.some(
              (prevTransformedEvent) => prevTransformedEvent.id === eventID
            );

            // Return null if it's a duplicate eventID
            if (isDuplicate) {
              return null;
            }

            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: true,
              bill_id: bill_id,
              userRole: userRole,
            };
          } else {
            return null;
          }
        });
        // const uniqueTransformedEvents = transformedEvents.filter(
        //   (event) =>
        //     event !== null &&
        //     !events.some((existingEvent) => existingEvent.id === event.id)
        // );

        // setEvents([...events, ...uniqueTransformedEvents]);
        if (transformedEvents) {
          setEvents([
            ...transformedEvents.filter(
              (event) => event !== null && event !== undefined
            ),
          ]);
        }
      } else if (tax_mode_final === "1") {
        transformedEvents = events_arr.map((event, index, array) => {
          const {
            eventID,
            eventType,
            startDate,
            endDate,
            reservationStatus,
            tax_mode,
            bill_id,
            userRole,
          } = event;
          const isSecond = array
            .slice(0, index)
            .some((prevEvent) => prevEvent.startDate === startDate);

          // Adjusting the time to 00:00:00
          const startDateTime = new Date(startDate);
          startDateTime.setHours(0, 0, 0, 0);

          const endDateTime = new Date(endDate);
          endDateTime.setHours(0, 0, 0, 0);

          // Check if eventID already exists in transformedEvents
          const isDuplicate = transformedEvents.some(
            (prevTransformedEvent) => prevTransformedEvent.id === eventID
          );

          // Return null if it's a duplicate eventID
          if (isDuplicate) {
            return null;
          }
          if (tax_mode === "0") {
            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: true,
              bill_id: bill_id,
              userRole: userRole,
            };
          } else if (tax_mode === "1") {
            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: false,
              bill_id: bill_id,
              userRole: userRole,
            };
          }
        });
        // const uniqueTransformedEvents = transformedEvents.filter(
        //   (event) =>
        //     event !== null &&
        //     !events.some((existingEvent) => existingEvent.id === event.id)
        // );

        // setEvents([...events, ...uniqueTransformedEvents]);
        if (transformedEvents) {
          setEvents([
            ...transformedEvents.filter(
              (event) => event !== null && event !== undefined
            ),
          ]);
        }
      }

      // Filter out null values (duplicate eventIDs) and existing duplicates in events

      //   const notify = () =>
      //   toast.success(res_calendar_event?.data?.message, {
      //     style: {
      //       padding: "10px",
      //       borderRadius: "30px",
      //     },
      //   });
      // notify();
    } else if (res_calendar_event?.data.status === false) {
      //     const notify = () =>
      //   toast.error(res_calendar_event?.data?.message, {
      //     style: {
      //       padding: "10px",
      //       borderRadius: "30px",
      //     },
      //   });
      // notify();
    }
  }, [res_calendar_event]);

  // console.log(events,'update');
  // console.log(res_token, "res_token");
  const api_token = sessionStorage.getItem("api_token");
  const updateEventsWithBillingID = (data) => {
    if (tableInitialized && events) {
      const updatedEvents = events.map((event) => {
        if (event.id === data.eventID) {
          return { ...event, billing_id: data.bill_id };
        } else {
          return event;
        }
      });
      // console.log(updatedEvents,'update');
      setEvents(updatedEvents);
      setEventsTemp(updatedEvents);
      setTableInitialized(false);
    }
  };

  // console.log(eventsTemp,'temp');

  useEffect(() => {
    if (res_event_on_id?.data?.status === true) {
      if (showTransferForm) {
        const start_date = new Date(res_event_on_id?.data?.data?.startDate);
        const end_date = new Date(res_event_on_id?.data?.data?.endDate);

        const s_day = start_date.getDate();
        const s_month = start_date.getMonth() + 1;
        const s_year = start_date.getFullYear();

        const e_day = end_date.getDate();
        const e_month = end_date.getMonth() + 1;
        const e_year = end_date.getFullYear();
        // console.log("Month:", month);
        // console.log(`${day}-${month}-${year}`);
        // console.log("Year:", year);
        // console.log(transferDate, 'enddate');

        setTransferDate({
          startDate: `${s_year}-${s_month}-${s_day}`,
          endDate: `${e_year}-${e_month}-${e_day}`,
        });

        // handleDateChange(start_date)
        // handleDateChange2(end_date)
        // console.log(start_date, end_date, 'start date');
        // setTransferDate({startDate:res_event_on_id?.data?.startDate,endDate:res_event_on_id?.data?.endDate})
        return;
      }
      const events_arr = res_event_on_id.data.data;
      setShowEditEvent(res_event_on_id?.data?.status);
      setEditEventData(res_event_on_id.data.data);
      setEditEventType(res_event_on_id.data.data.eventType);
      setEditFile(res_event_on_id.data.data.files);
    } else if (res_event_on_id?.data.status === false) {
      setShowEditEvent(res_event_on_id?.data?.status);
      const notify = () =>
        toast.error(res_event_on_id?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  }, [res_event_on_id]);

  var TableDatatablesManaged = (function () {
    // setEventsTemp(events)
    // console.log("tableeee",'show');
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showBill");
      var table1 = $("#showBill").DataTable({
        columnDefs: [{ orderable: false, targets: 3 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold  n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_event_account_data",
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          // data:
          //   searchData === ""
          //     ? { submit: "" }
          //     : { submit: "custom", status: searchData },
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "eventID",
            width: "6%",
          },
          {
            data: "clientName",
            width: "60%",
          },
          {
            data: "startDate",
            width: "30%",
          },

          {
            data: null,
            width: "30%",
            render: function (data) {
              if (data.bill_id === null) {
                return (
                  '<div class="d-flex line-height"><span  id="createBill" value="' +
                  data.id +
                  '"><i class="bi bi-receipt fs-4 ps-2 me-0 text-primary cursor-pointer"></i></span></div>'
                );
              } else {
                return (
                  '<div class="d-flex line-height"><span  id="viewMenu" value="' +
                  data.id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editMenu" value="' +
                  data.id +
                  '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
                );
              }
            },
            sorting: false,
          },

          // {
          //   data: null,
          //   width: "18%",
          //   render: function (data) {
          //     //   console.log(data, "data");

          //     return (
          //       '<div class="d-flex"><span  id="viewMenu" value="' +
          //       data.id +
          //       '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editMenu" value="' +
          //       data.id +
          //       '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteMenu" value="' +
          //       data.id +
          //       '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
          //     );
          //   },
          // },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          // console.log("Table length:", tableLength);
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showBill tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        // setViewRoleModel(true)
        // console.log(rowData,'rowdata');
        if (rowData && rowData.eventID) {
          // handleDownload(rowData.eventID);
          // dispatch(MainKitchenPdfDownload(rowData.eventID));
          rowData.eventID &&
            navigate(`/create_billing/${rowData.eventID}`, {
              state: {
                value: "account",
                clientName: rowData.clientName,
                billType: "new",
              },
            });
        }
      });
      $("#showBill tbody").on("click", "#editMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        // setViewRoleModel(true)
        // console.log(rowData,'rowdata');
        if (rowData && rowData.eventID) {
          // handleDownload(rowData.eventID);
          // dispatch(MainKitchenPdfDownload(rowData.eventID));
          if (rowData && rowData?.userRole === "2") {
            rowData.eventID &&
              navigate(`/sale_edit_billing/${rowData.eventID}`, {
                state: { type: "account" },
              });
          } else {
            rowData.eventID &&
              navigate(`/edit_billing/${rowData.eventID}`, {
                state: { type: "account" },
              });
          }
        }
      });
      $("#showBill tbody").on("click", "#viewMenu", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        // setViewRoleModel(true)
        // console.log(rowData,'rowdata');
        if (rowData && rowData.eventID) {
          setViewBillTable(true);
          setViewBillTableID(rowData.eventID);
          // handleDownload(rowData.eventID);
          // dispatch(MainKitchenPdfDownload(rowData.eventID));
          // rowData.eventID && navigate(`/edit_billing/${rowData.eventID}`);
        }
      });
      $("#showBill_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // setRemove(!remove)
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  // const handleDownload = async (id) => {
  //   try {
  //     const res = await fetch(`/api/getEventPDF/${id}`, {
  //       method: "GET",
  //       headers: {
  //         "X-CSRF-TOKEN": res_token?.data,
  //         Authorization: `Bearer ${api_token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (!res.ok) {
  //       throw new Error(`HTTP error! Status: ${res.status}`);
  //     }
  //     const data = await res.json();
  //     console.log(data, "data");

  //     if (data?.status === true) {
  //       const pdfUrl = data?.url;
  //       const link = document.createElement("a");
  //       link.href = pdfUrl;
  //       link.target = "_blank";
  //       // link.download = "document.pdf"; // specify the filename
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else if (data?.status === false) {
  //       const notify = () =>
  //         toast.error(data?.message, {
  //           style: {
  //             padding: "10px",
  //             borderRadius: "30px",
  //           },
  //         });
  //       notify();
  //     }

  //   } catch (error) {
  //     const notify = () =>
  //       toast.error("Something went wrong", {
  //         style: {
  //           padding: "10px",
  //           borderRadius: "30px",
  //         },
  //       });
  //     notify();
  //   }
  // };
  const handleSelectSlot = ({ slots }) => {
    setShowForm(true);
    setSelectedDates(slots);
  };

  const handleEventClick = (event) => {
    setEventIdTransfer(event.id);
    setShowForm(true);
    setSecondEvent(event);
  };

  // -------------------------------------------------------------------transfer-------------------------

  // const handleTransferClick = () => {
  //   setShowTransferForm(true);
  // };

  useEffect(() => {
    setShowForm(false);
  }, [showTransferForm]);

  // ---------------------------------------------edit Event -------------------------------

  const handleAdd = (eventID) => {
    navigate(`/create_billing/${eventID}`, {
      state: { type: "account" },
    });
  };

  const handleEdit = (eventID, user_role) => {
   // console.log(user_role, "user_role");

    if (user_role === "2") {
      navigate(`/sale_edit_billing/${eventID}`, {
        state: { type: "account" },
      });
    } else {
      navigate(`/edit_billing/${eventID}`, {
        state: { type: "account" },
      });
    }
  };

  // ----------------------------------------------------delete_Event---------------------------------

  const handleDeleteEvent = (eventId) => {
    setShowForm(false);
    setDeleteEventID(eventId);

    setShowDeleteModal(true);
    // dispatch(deleteCalendarEvent(eventId))
    setSelectedDates([]);
  };

  const CustomToolbar = ({ label, onNavigate }) => {
    const labelRef = useRef(label); // Store label without causing re-renders

    useEffect(() => {
      const selectedDate = new Date(labelRef.current);
      const selectedMonthStart = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );

      // Only update state if selectedMonth is different to prevent infinite loops
      if (
        selectedMonth === null ||
        selectedMonth.getTime() !== selectedMonthStart.getTime()
      ) {
        setSelectedMonth(selectedMonthStart);
      }
    }, [labelRef, selectedMonth]);

    return (
      <>
        <div style={{ padding: "10px" }} className="rbc-toolbar ">
          <div>
            <MdArrowBackIos
              className="cursor-pointer"
              onClick={() => onNavigate("PREV")}
            />
          </div>
          <div>
            <div className="rbc-toolbar-label ">{label}</div>
          </div>
          <div>
            <GrNext
              className="cursor-pointer"
              onClick={() => onNavigate("NEXT")}
            />
          </div>
        </div>
        <div className="note-above-calendar-header d-sm-flex my-3 justify-content-around d-block mx-sm-0 mx-xs-5 mx-4">
          <div>
            {" "}
            <span>NOTE:</span>
            <img src={pink_img} alt="123" className="ms-4 me-2 mb-2" />
            <span>Final Reservation</span>
          </div>
          <div className="ms-sm-0 ms-5 ps-sm-0 ps-4 ">
            {" "}
            <img src={green_img} alt="123" className="me-2 mb-2" />
            <span>Tentative (one only)</span>
          </div>
          <div className="ms-sm-0 ms-5 ps-sm-0 ps-4 ">
            {" "}
            <img src={green_img} alt="123" className="me-1 mb-2" />
            <img src={green_img} alt="123" className="me-2 mb-2" />
            <span>Tentative (two only)</span>
          </div>
        </div>
      </>
    );
  };

  const currentMonthStart = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth(),
    1
  );

  const isFirstDayFridayOrSaturday =
    currentMonthStart.getDay() === 5 || currentMonthStart.getDay() === 6;

  const isSaturday = currentMonthStart.getDay() === 6;

  const lastDayOfCurrentMonth = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth() + 1,
    0
  );

  const has31Days = lastDayOfCurrentMonth.getDate() === 31;

  const has30Days = lastDayOfCurrentMonth.getDate() === 30;

  let rowSix = false; // Initialize rowSix to false

  if (isFirstDayFridayOrSaturday && has31Days) {
    rowSix = true;
  } else if (isSaturday && has30Days) {
    rowSix = true;
  }
  // console.log(rowSix, 'row');

  // const eventStyleGetter = (event, start, end, isSelected) => {
  //   const differenceInMilliseconds = end.getTime() - start.getTime();

  //   const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24); // 1000ms  60s  60min * 24h
  //   // console.log(differenceInDays,'ddddsssss');

  //   const isFirstDay = moment(event.start).isSame(start, "day");

  //   // Check if the start day of the event is Saturday
  //   const isFirstSaturday = moment(event.start).format("dddd") === "Saturday";
  //   const isFirstFriday = moment(event.start).format("dddd") === "Friday";
  //   const isFirstThursday = moment(event.start).format("dddd") === "Thursday";
  //   // console.log(isFirstSaturday,'isssss');

  //   //------------------------------------------------------- 6 row -----------------------------------------------------------

  //   const monthStart = new Date(start.getFullYear(), start.getMonth(), 1);

  //   const isFirstDayFridayOrSaturday =
  //     monthStart.getDay() === 5 || monthStart.getDay() === 6;
  //   const isSaturday = monthStart.getDay() === 6;

  //   const lastDayOfMonth = new Date(
  //     start.getFullYear(),
  //     start.getMonth() + 1,
  //     0
  //   );
  //   const has31Days = lastDayOfMonth.getDate() === 31;

  //   const has30Days = lastDayOfMonth.getDate() === 30;

  //   if (isFirstDayFridayOrSaturday && has31Days) {
  //     rowSix = true;
  //   } else if (isSaturday && has30Days) {
  //     rowSix = true;
  //   }

  //   console.log(events, 'events123');

  //   //-----------------------------------------------------------------------------------------------------------------------------------

  //   let style = {
  //     position:
  //       hoveredEvent === event.id && isFirstSaturday ? "absolute" : "relative",
  //     top:
  //       isFirstSaturday && hoveredEvent === event.id
  //         ? "0px"
  //         : hoveredEvent === event.id
  //           ? "-50px"
  //           : "-50px",
  //     backgroundColor: event.type === "FinalReserve" ? "#eecde0" : "#ceede7",
  //     borderRadius: "0px",
  //     opacity: hoveredEvent === event.id ? 1 : 0.8,
  //     color: "white",

  //     display: "block",
  //     height: rowSix
  //       ? hoveredEvent === event.id
  //         ? "88px"
  //         : "88px"
  //       : hoveredEvent === event.id && isFirstSaturday
  //         ? "88px"
  //         : hoveredEvent === event.id
  //           ? "88px"
  //           : "88px",
  //     // border:
  //     //   hoveredEvent === event.id ? " 1px solid #000000" : "1px solid #000000",

  //     zIndex: hoveredEvent === event.id ? 8 : 5,
  //     width:
  //       isFirstSaturday && hoveredEvent === event.id
  //         ? "auto"
  //         : hoveredEvent === event.id && differenceInDays === 1
  //           ? "170%"
  //           : "auto",
  //     transition: "width 0.1s ease ",
  //     boxShadow: hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none",
  //     right: isFirstSaturday && hoveredEvent === event.id ? "0%" : "",
  //   };

  //   if (window.innerWidth <= 1440) {
  //     style.position =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id)
  //         ? "absolute"
  //         : "relative";
  //     style.width =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id)
  //         ? "50%"
  //         : hoveredEvent === event.id && differenceInDays === 1
  //           ? event.type === "FinalReserve"
  //             ? "270%"
  //             : "210%"
  //           : "auto";
  //     style.top =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id)
  //         ? "0px"
  //         : "-50px";

  //     style.right =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id)
  //         ? "0%"
  //         : "";
  //   }
  //   if (window.innerWidth <= 768) {
  //     // style.width =
  //     //   hoveredEvent === event.id && differenceInDays === 1 ? "170%" : "auto";
  //     style.height = rowSix ? "95px" : "95px";
  //     style.width =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id)
  //         ? "40%"
  //         : hoveredEvent === event.id && differenceInDays <= 2
  //           ? event.type === "FinalReserve"
  //             ? "400%"
  //             : "400%"
  //           : "auto";
  //     style.top =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id)
  //         ? "-0px"
  //         : hoveredEvent === event.id
  //           ? "-58px"
  //           : "-58px";
  //   }
  //   if (window.innerWidth <= 576) {
  //     style.position =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "absolute"
  //         : "relative";
  //     style.width =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "70%"
  //         : hoveredEvent === event.id && differenceInDays <= 3
  //           ? event.type === "FinalReserve"
  //             ? "400%"
  //             : "400%"
  //           : "auto";
  //     style.height = rowSix
  //       ? (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "84px"
  //         : "84px"
  //       : "84px";

  //     style.right =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "0%"
  //         : "";
  //     style.top =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "-0px"
  //         : "-58px";
  //   }
  //   if (window.innerWidth <= 375) {
  //     style.width =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "70%"
  //         : hoveredEvent === event.id && differenceInDays <= 3
  //           ? event.type === "FinalReserve"
  //             ? "430%"
  //             : "430%"
  //           : "auto";
  //     style.height = rowSix
  //       ? (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "84px"
  //         : "84px"
  //       : "84px";
  //   }
  //   if (window.innerWidth <= 320) {
  //     style.width =
  //       (isFirstFriday && hoveredEvent === event.id) ||
  //         (isFirstSaturday && hoveredEvent === event.id) ||
  //         (isFirstThursday && hoveredEvent === event.id)
  //         ? "70%"
  //         : hoveredEvent === event.id && differenceInDays <= 3
  //           ? event.type === "FinalReserve"
  //             ? "500%"
  //             : "420%"
  //           : "auto";
  //     style.height = rowSix ? "52px" : "52px";
  //   }

  //   const otherEventsOnSameDay = events.filter(
  //     (e) =>
  //       e.id !== event.id &&
  //       moment(e.start).isSame(event.start, "day") &&
  //       moment(e.end).isSame(event.end, "day")
  //   );

  //   if (otherEventsOnSameDay.length > 0) {
  //     style.position =
  //       event.isSecond === 0
  //         ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "absolute"
  //           : "relative"
  //         : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "absolute"
  //           : "relative";
  //     style.top = rowSix
  //       ? event.isSecond === 0
  //         ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "0px"
  //           : "-50px"
  //         : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "-2px"
  //           : "-139px"
  //       : event.isSecond === 0
  //         ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "0px"
  //           : "-50px"
  //         : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "-2px"
  //           : "-139px";
  //     style.backgroundColor =
  //       event.type === "FinalReserve" ? "#eecde0" : "#ceede7";
  //     style.borderRadius = "0px";
  //     style.opacity = hoveredEvent === event.id ? 1 : 0.8;
  //     style.color = "white";
  //     style.display = "block";
  //     style.height =
  //       event.isSecond === 0
  //         ? rowSix
  //           ? hoveredEvent === event.id
  //             ? "88px"
  //             : "88px"
  //           : hoveredEvent === event.id
  //             ? "88px"
  //             : "88px"
  //         : rowSix
  //           ? hoveredEvent === event.id
  //             ? "88px"
  //             : "88px"
  //           : hoveredEvent === event.id
  //             ? "89px"
  //             : "88px";

  //     style.zIndex = hoveredEvent === event.id ? 8 : 5;
  //     style.transition = "width 0.1s ease";
  //     style.boxShadow =
  //       hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none";

  //     style.left = event.isSecond === 0 ? "" : "55%";

  //     style.width =
  //       event.isSecond === 0
  //         ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //           ? "30%"
  //           : hoveredEvent === event.id
  //             ? differenceInDays < 3
  //               ? "140%"
  //               : "100%"
  //             : "50%"
  //         : hoveredEvent === event.id
  //           ? differenceInDays < 3
  //             ? "140%"
  //             : "100%"
  //           : "45%";

  //     if (window.innerWidth <= 1440) {
  //       style.width =
  //         event.isSecond === 0
  //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //             ? "30%"
  //             : hoveredEvent === event.id
  //               ? differenceInDays < 3
  //                 ? "200%"
  //                 : "100%"
  //               : "50%"
  //           : hoveredEvent === event.id
  //             ? differenceInDays < 3
  //               ? "200%"
  //               : "100%"
  //             : "45%";

  //       // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
  //     }

  //     if (window.innerWidth <= 768) {
  //       style.height = rowSix
  //         ? event.isSecond === 0
  //           ? "68px"
  //           : "70px"
  //         : event.isSecond === 0
  //           ? "65px"
  //           : "63px";
  //       style.top =
  //         (isFirstFriday && hoveredEvent === event.id) ||
  //           (isFirstSaturday && hoveredEvent === event.id)
  //           ? "0px"
  //           : rowSix
  //             ? event.isSecond === 0
  //               ? "-57px"
  //               : "-128px"
  //             : event.isSecond === 0
  //               ? "-52px"
  //               : "-116px";

  //       style.width =
  //         event.isSecond === 0
  //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //             ? "30%"
  //             : hoveredEvent === event.id
  //               ? differenceInDays < 3
  //                 ? "220%"
  //                 : "100%"
  //               : "50%"
  //           : hoveredEvent === event.id
  //             ? differenceInDays < 3
  //               ? "220%"
  //               : "100%"
  //             : "45%";
  //       // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
  //     }
  //     if (window.innerWidth <= 425) {
  //       style.height = rowSix
  //         ? event.isSecond === 0
  //           ? "59px"
  //           : "59px"
  //         : event.isSecond === 0
  //           ? "55px"
  //           : "55px";
  //       // style.height = event.isSecond === 0
  //       // ? rowSix
  //       //   ? "78px"
  //       //   : hoveredEvent === event.id
  //       //   ? "55px"
  //       //   : "55px"
  //       // :rowSix
  //       //   ?"78px"
  //       //   : hoveredEvent === event.id
  //       //     ? "55px"
  //       //     : "55px";

  //       style.top =
  //         (isFirstFriday && hoveredEvent === event.id) ||
  //           (isFirstSaturday && hoveredEvent === event.id)
  //           ? "0px"
  //           : rowSix
  //             ? event.isSecond === 0
  //               ? "-58px"
  //               : "-118px"
  //             : event.isSecond === 0
  //               ? "-52px"
  //               : "-108px";

  //       style.width =
  //         event.isSecond === 0
  //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
  //             ? "60%"
  //             : hoveredEvent === event.id
  //               ? differenceInDays < 3
  //                 ? "350%"
  //                 : "100%"
  //               : "50%"
  //           : hoveredEvent === event.id
  //             ? differenceInDays < 3
  //               ? "350%"
  //               : "100%"
  //             : "45%";
  //     }
  //     if (hoveredEvent === event.id) {
  //       if (event.isSecond === 0) {
  //         //   const rbc_dates = document.querySelector('.rbc-event-continues-prior');
  //         //   if (rbc_dates) {
  //         //     rbc_dates.style.setProperty('width', '50%', 'important');
  //         //     rbc_dates.style.setProperty('top', '-50px', 'important');
  //         //     console.log(rbc_dates,'rbcccc');
  //         // }
  //         // rbc_dates.forEach((date, index) => {
  //         //     if (index % 2 === 0) { // Even index
  //         //         date.style.setProperty('width', '50%');
  //         //         date.style.setProperty('top', '-50px');
  //         //     } else { // Odd index
  //         //         date.style.setProperty('width', '45%');
  //         //         date.style.setProperty('top', '-139px');
  //         //     }
  //         // });
  //       }
  //       if (event.isSecond === 1) {
  //         // const rbc_dates = document.querySelector('.rbc-event-continues-prior');
  //         // rbc_dates.style.setProperty('width', '45%','!important');
  //         // rbc_dates.style.setProperty('top', '-139px','!important');
  //         // console.log(rbc_dates,'rbc___1');
  //         // rbc_dates.forEach((date, index) => {
  //         //     if (index % 2 === 0) { // Even index
  //         //         date.style.setProperty('width', '50%');
  //         //         date.style.setProperty('top', '-50px');
  //         //     } else { // Odd index
  //         //         date.style.setProperty('width', '45%');
  //         //         date.style.setProperty('top', '-139px');
  //         //     }
  //         // });
  //       }
  //       // if(event.isSecond === 1){
  //       //   const rbc_dates = document.querySelectorAll('.rbc-event-continues-prior');

  //       //   rbc_dates.forEach((date, index) => {
  //       //       if (index % 2 === 0) { // Even index
  //       //           date.style.setProperty('width', '50%');
  //       //           date.style.setProperty('top', '-80px');
  //       //       } else { // Odd index
  //       //           date.style.setProperty('width', '50%');
  //       //           date.style.setProperty('top', '-50px');
  //       //       }
  //       //   });

  //       // }
  //     }
  //   }

  //   return {
  //     style: style,
  //   };
  // };
  const eventStyleGetter = (event, start, end, isSelected) => {
    const differenceInMilliseconds = end.getTime() - start.getTime();

    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24); // 1000ms  60s  60min * 24h
    // console.log(differenceInDays,'ddddsssss');

    const isFirstDay = moment(event.start).isSame(start, "day");

    // Check if the start day of the event is Saturday
    const isFirstSaturday = moment(event.start).format("dddd") === "Saturday";
    const isFirstFriday = moment(event.start).format("dddd") === "Friday";
    const isFirstThursday = moment(event.start).format("dddd") === "Thursday";
    // console.log(isFirstSaturday,'isssss');

    //------------------------------------------------------- 6 row -----------------------------------------------------------

    const monthStart = new Date(start.getFullYear(), start.getMonth(), 1);

    const isFirstDayFridayOrSaturday =
      monthStart.getDay() === 5 || monthStart.getDay() === 6;
    const isSaturday = monthStart.getDay() === 6;

    const lastDayOfMonth = new Date(
      start.getFullYear(),
      start.getMonth() + 1,
      0
    );
    const has31Days = lastDayOfMonth.getDate() === 31;

    const has30Days = lastDayOfMonth.getDate() === 30;

    if (isFirstDayFridayOrSaturday && has31Days) {
      rowSix = true;
    } else if (isSaturday && has30Days) {
      rowSix = true;
    }

    //-----------------------------------------------------------------------------------------------------------------------------------

    let style = {
      position:
        hoveredEvent === event.id && isFirstSaturday ? "relative" : "relative",
      top:
        isFirstSaturday && hoveredEvent === event.id
          ? "-50px"
          : hoveredEvent === event.id
          ? "-50px"
          : "-50px",
      backgroundColor:
        event.type === "selected"
          ? "rgb(219 168 168)"
          : event.type === "FinalReserve"
          ? "#eecde0"
          : "#ceede7",
      borderRadius: "0px",
      opacity: hoveredEvent === event.id ? 1 : 0.8,
      color: "white",

      display: "block",
      height: rowSix
        ? hoveredEvent === event.id
          ? "88px"
          : "88px"
        : hoveredEvent === event.id && isFirstSaturday
        ? "88px"
        : hoveredEvent === event.id
        ? "88px"
        : "88px",
      // border:
      //   hoveredEvent === event.id ? " 1px solid #000000" : "1px solid #000000",

      zIndex: hoveredEvent === event.id ? 8 : 5,
      width:
        isFirstSaturday && hoveredEvent === event.id
          ? "200%"
          : hoveredEvent === event.id && differenceInDays === 1
          ? "190%"
          : "auto",
      transition: "width 0.1s ease ",
      boxShadow: hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none",
      right: isFirstSaturday && hoveredEvent === event.id ? "80%" : "",
      // left: (isFirstSaturday && hoveredEvent === event.id) ?"55%" :"",
    };
    if (window.innerWidth <= 1640) {
      style.width =
        isFirstSaturday && hoveredEvent === event.id
          ? "240%"
          : hoveredEvent === event.id && differenceInDays === 1
          ? "230%"
          : hoveredEvent === event.id && differenceInDays === 2
          ? "120%"
          : "auto";
      style.right =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "120%"
          : "";
    }

    if (window.innerWidth <= 1440) {
      style.position =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "relative"
          : "relative";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "270%"
          : hoveredEvent === event.id && differenceInDays === 1
          ? event.type === "FinalReserve"
            ? "270%"
            : "210%"
          : hoveredEvent === event.id
          ? "125%"
          : "auto";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "-50px"
          : "-50px";

      style.right =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "160%"
          : "";
    }
    if (window.innerWidth <= 1300) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "320%"
          : hoveredEvent === event.id && differenceInDays === 1
          ? event.type === "FinalReserve"
            ? "330%"
            : "210%"
          : hoveredEvent === event.id && differenceInDays === 2
          ? event.type === "FinalReserve"
            ? "160%"
            : "110%"
          : hoveredEvent === event.id
          ? "125%"
          : "auto";

      style.right =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "200%"
          : "";
    }
    if (window.innerWidth <= 768) {
      // style.width =
      //   hoveredEvent === event.id && differenceInDays === 1 ? "170%" : "auto";
      style.height = rowSix ? "95px" : "95px";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "400%"
          : hoveredEvent === event.id && differenceInDays == 1
          ? event.type === "FinalReserve"
            ? "300%"
            : "200%"
          : hoveredEvent === event.id && differenceInDays == 2
          ? event.type === "FinalReserve"
            ? "200%"
            : "200%"
          : "auto";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "-58px"
          : hoveredEvent === event.id
          ? "-58px"
          : "-58px";
    }
    if (window.innerWidth <= 576) {
      style.position =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "relative"
          : "relative";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "400%"
          : hoveredEvent === event.id && differenceInDays <= 3
          ? event.type === "FinalReserve"
            ? "400%"
            : "400%"
          : hoveredEvent === event.id
          ? "400%"
          : "auto";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "85px"
          : "85px"
        : "85px";

      style.right =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "280%"
          : "";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "-58px"
          : "-58px";
    }
    if (window.innerWidth <= 375) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "450%"
          : hoveredEvent === event.id && differenceInDays <= 3
          ? event.type === "FinalReserve"
            ? "430%"
            : "430%"
          : "auto";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "85px"
          : "85px"
        : "85px";
      style.right =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "340%"
          : "";
    }
    if (window.innerWidth <= 320) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "430%"
          : hoveredEvent === event.id && differenceInDays <= 3
          ? event.type === "FinalReserve"
            ? "430%"
            : "430%"
          : "auto";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "85px"
          : "85px"
        : "85px";
      style.right =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "340%"
          : "";
    }

    const otherEventsOnSameDay = events.filter(
      (e) =>
        e.id !== event.id &&
        moment(e.start).isSame(event.start, "day") &&
        moment(e.end).isSame(event.end, "day")
    );

    if (otherEventsOnSameDay.length > 0) {
      style.position =
        event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "relative"
            : "relative"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "relative"
          : "relative";
      style.top = rowSix
        ? event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "-50px"
            : "-50px"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "-139px"
          : "-139px"
        : event.isSecond === 0
        ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "-50px"
          : "-50px"
        : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
        ? "-139px"
        : "-139px";
      style.backgroundColor =
        event.type === "FinalReserve" ? "#eecde0" : "#ceede7";
      style.borderRadius = "0px";
      style.opacity = hoveredEvent === event.id ? 1 : 0.8;
      style.color = "white";
      style.display = "block";
      style.height =
        event.isSecond === 0
          ? rowSix
            ? hoveredEvent === event.id
              ? "88px"
              : "88px"
            : hoveredEvent === event.id
            ? "88px"
            : "88px"
          : rowSix
          ? hoveredEvent === event.id
            ? "88px"
            : "88px"
          : hoveredEvent === event.id
          ? "89px"
          : "88px";

      style.zIndex = hoveredEvent === event.id ? 8 : 5;
      style.transition = "width 0.1s ease";
      style.boxShadow =
        hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none";

      style.left =
        event.isSecond === 0
          ? hoveredEvent === event.id && isFirstSaturday
            ? "-40%"
            : hoveredEvent === event.id && isFirstFriday
            ? ""
            : ""
          : hoveredEvent === event.id && isFirstSaturday
          ? "-60%"
          : hoveredEvent === event.id && isFirstFriday
          ? ""
          : "55%";

      style.width =
        event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "140%"
            : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "160%"
              : "100%"
            : "50%"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "160%"
          : hoveredEvent === event.id
          ? differenceInDays < 3
            ? "160%"
            : "100%"
          : "45%";

      if (window.innerWidth <= 1640) {
        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "190%"
              : hoveredEvent === event.id
              ? differenceInDays === 1
                ? "180%"
                : differenceInDays === 3 || differenceInDays === 2
                ? "140%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "190%"
            : hoveredEvent === event.id
            ? differenceInDays === 1
              ? "180%"
              : differenceInDays === 3 || differenceInDays === 2
              ? "140%"
              : "100%"
            : "45%";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-90%"
              : hoveredEvent === event.id && isFirstFriday
              ? ""
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-90%"
            : hoveredEvent === event.id && isFirstFriday
            ? ""
            : "55%";
        style.right = "";
      }

      if (window.innerWidth <= 1440) {
        // style.width =
        //   event.isSecond === 0
        //     ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
        //       ? "30%"
        //       : hoveredEvent === event.id
        //         ? differenceInDays < 3
        //           ? "200%"
        //           : "100%"
        //         : "140%"
        //     : hoveredEvent === event.id
        //       ? differenceInDays < 3
        //         ? "200%"
        //         : "100%"
        //       : "45%";
        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "280%"
              : hoveredEvent === event.id
              ? differenceInDays === 1
                ? "250%"
                : differenceInDays === 3 || differenceInDays === 2
                ? "140%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "280%"
            : hoveredEvent === event.id
            ? differenceInDays === 1
              ? "250%"
              : differenceInDays === 3 || differenceInDays === 2
              ? "140%"
              : "100%"
            : "45%";
        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-180%"
              : hoveredEvent === event.id && isFirstFriday
              ? ""
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-180%"
            : hoveredEvent === event.id && isFirstFriday
            ? ""
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";

        // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
      }
      if (window.innerWidth <= 1300) {
        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "200%"
              : hoveredEvent === event.id
              ? differenceInDays === 1
                ? "250%"
                : differenceInDays === 3 || differenceInDays === 2
                ? "140%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "250%"
            : hoveredEvent === event.id
            ? differenceInDays === 1
              ? "250%"
              : differenceInDays === 3 || differenceInDays === 2
              ? "140%"
              : "100%"
            : "45%";
        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-100%"
              : hoveredEvent === event.id && isFirstFriday
              ? ""
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-150%"
            : hoveredEvent === event.id && isFirstFriday
            ? ""
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";
      }

      if (window.innerWidth <= 768) {
        style.height = rowSix
          ? event.isSecond === 0
            ? "95px"
            : "95px"
          : event.isSecond === 0
          ? "95px"
          : "95px";
        style.top =
          (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : rowSix
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : event.isSecond === 0
            ? "-58px"
            : "-154px";

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "290%"
              : hoveredEvent === event.id
              ? differenceInDays === 1
                ? "280%"
                : differenceInDays === 3 || differenceInDays === 2
                ? "220%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "290%"
            : hoveredEvent === event.id
            ? differenceInDays === 1
              ? "280%"
              : differenceInDays === 3 || differenceInDays === 2
              ? "220%"
              : "100%"
            : "45%";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-250%"
              : hoveredEvent === event.id && isFirstFriday
              ? "-150%"
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-190%"
            : hoveredEvent === event.id && isFirstFriday
            ? "-150%"
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";
        // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
      }
      if (window.innerWidth <= 425) {
        // style.height = event.isSecond === 0
        // ? rowSix
        //   ? "78px"
        //   : hoveredEvent === event.id
        //   ? "55px"
        //   : "55px"
        // :rowSix
        //   ?"78px"
        //   : hoveredEvent === event.id
        //     ? "55px"
        //     : "55px";

        style.top =
          (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : rowSix
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : event.isSecond === 0
            ? "-58px"
            : "-154px";

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "350%"
              : hoveredEvent === event.id
              ? differenceInDays < 3
                ? "350%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "350%"
            : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "350%"
              : "100%"
            : "45%";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-250%"
              : hoveredEvent === event.id && isFirstFriday
              ? "-150%"
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-200%"
            : hoveredEvent === event.id && isFirstFriday
            ? "-150%"
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";
      }

      if (window.innerWidth <= 375) {
        // style.height = event.isSecond === 0
        // ? rowSix
        //   ? "78px"
        //   : hoveredEvent === event.id
        //   ? "55px"
        //   : "55px"
        // :rowSix
        //   ?"78px"
        //   : hoveredEvent === event.id
        //     ? "55px"
        //     : "55px";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-250%"
              : hoveredEvent === event.id && isFirstFriday
              ? "-150%"
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-250%"
            : hoveredEvent === event.id && isFirstFriday
            ? "-150%"
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";
      }

      //       useEffect(() => {
      //   if (hoveredEvent === event.id) {
      //     if (event.isSecond === 0) {
      //       if (rbc_dates) {
      //         console.log(rbc_dates, 'rbcccc');
      //       }
      //       rbc_dates.forEach((date, index) => {
      //         if (index % 2 === 0) {
      //           date.style.setProperty('width', '50%');
      //           date.style.setProperty('top', '-50px');
      //         } else {
      //           date.style.setProperty('width', '45%');
      //           date.style.setProperty('top', '-139px');
      //         }
      //       });
      //     }
      //     if (event.isSecond === 1) {
      //       console.log(rbc_dates, 'rbc___1');
      //       rbc_dates.forEach((date, index) => {
      //         if (index % 2 === 0) {
      //           date.style.setProperty('width', '50%');
      //           date.style.setProperty('top', '-50px');
      //         } else {
      //           date.style.setProperty('width', '45%');
      //           date.style.setProperty('top', '-139px');
      //         }
      //       });
      //     }
      //   }
      // }, [rbc_dates]);
    }

    return {
      style: style,
    };
  };

  // useEffect(() => {
  //   const rbc_style = document.querySelectorAll(".rbc-event-continues-prior");
  //   const screenWidth = window.innerWidth;
  //   if (rbc_style.length > 0) {
  //     rbc_style.forEach((date) => {
  //       date.style.setProperty("position", "relative", "important");

  //       if (screenWidth <= 768) {
  //         date.style.setProperty("top", "-58px", "important");
  //       } else {
  //         date.style.setProperty("top", "-50px", "important");
  //       }
  //       date.style.setProperty("width", "100%", "important");
  //     });
  //   }
  // }, [rbc_dates]);

  const handleCheckboxChange = (eventId, status) => {
    setShowForm(false);
    setIsChecked(!status);
    // console.log(status, "status");
    // Use the updated value of isChecked
    if (!status && eventId) {
      let show_details;
      show_details = {
        tax_mode: "0",
      };
      dispatch(sendShowDetails(eventId, show_details, setLoading));
    } else if (eventId) {
      let show_details;
      show_details = {
        tax_mode: "1",
      };
      dispatch(sendShowDetails(eventId, show_details, setLoading));
    }

    const updatedEvents = events.map((event) => {
      // console.log(event, "event");
      if (event.id === eventId) {
        return { ...event, show_details: !status };
      } else {
        return event; // If the condition is not true, return the event as it is
      }
    });

    setEvents(updatedEvents);

    // setEvents(updatedEvents)

    // setTimeout(() => {
    window.location.reload();
    // }, 500);
    //   setTimeout(() => {
    //   dispatch(getCalendarEvent())
    // }, 5000);
  };

  useEffect(() => {
    if (res_show_details?.data?.status === true) {
      const notify = () =>
        toast.success(res_show_details?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_show_details.loading = true;
        res_show_details.data = {};
      }, 1500);
    } else if (res_show_details?.data?.status === false) {
      const notify = () =>
        toast.error(res_show_details?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_show_details.loading = true;
        res_show_details.data = {};
      }, 1500);
    }
  }, [res_show_details]);

  // ------------------------------------------------------------------------------------------------------------------
  const handleDateChange = (e) => {
    const dateString = e;
    // console.log(dateString, "dateChange");

    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // console.log("Month:", month);
    // console.log(`${day}-${month}-${year}`);
    // console.log("Year:", year);
    setTransferDate({ ...transferDate, startDate: `${year}-${month}-${day}` });
    // setState(year);
  };
  const handleDateChange2 = (e) => {
    const dateString = e;
    // console.log(dateString, "dateChange");

    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    // console.log("Month:", month);
    // console.log(`${day}-${month}-${year}`);
    // console.log("Year:", year);
    setTransferDate({ ...transferDate, endDate: `${year}-${month}-${day}` });
    // setState(year);
  };
  const handleTransferClick = (eventID) => {
    setShowForm(false);
    setShowTransferForm(true);
    setShowTable(false);
    dispatch(getEventDetailsOnId(eventID));
  };

  const handleTransfer = () => {
    if (!transferDate.startDate) {
      const notify = () =>
        toast.error("Start date is require!!", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      return;
    }
    if (!transferDate.endDate) {
      const notify = () =>
        toast.error("End date is require!!", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      return;
    }
    dispatch(transferDateEvent(transferDate, eventIdTransfer, setLoading));
  };
  const handleResetTransfer = () => {
    setTransferDate({
      startDate: "",
      endDate: "",
    });
  };

  useEffect(() => {
    if (res_transfer_date?.data?.status === true) {
      const notify = () =>
        toast.success(res_transfer_date?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      // console.log(res_transfer_date,'resee');
      setShowTransferForm(false);
      setTimeout(() => {
        setShowForm(false);
        // eventIdTransfer()
        res_transfer_date.loading = true;
        res_transfer_date.data = {};
        window.location.reload();
      }, 500);
    } else if (res_transfer_date?.data?.status === false) {
      setShowForm(false);
      const notify = () =>
        toast.error(res_transfer_date?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_transfer_date.loading = true;
        res_transfer_date.data = {};
      }, 1500);
    }
  }, [res_transfer_date]);

  return (
    <>
      {viewBillTable && (
        <ViewBillDetails
          viewBillTable={viewBillTable}
          setViewBillTable={setViewBillTable}
          viewBillTableID={viewBillTableID}
        />
      )}
      <style>
        {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "Name";
           
          }
          td:nth-of-type(3):before {
            content: "Date";
          }
          td:nth-of-type(4):before {
            content: "Action";
          }
          }
        `}
      </style>
      <Toaster />
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="container-fluid">
            <div className="">
              <div className="justify-content-center padding-left-21">
                <div
                  className={`  form-margin-768 ms-auto ms-sm-5 ${
                    eventTypePhone
                      ? "next-btn-event-clnder-true"
                      : "next-btn-event-clnder-false"
                  }`}
                >
                  <div className="col-12 col-xl-8 ms-0 ">
                    <div
                      style={{
                        height: `${rowSix ? "738px" : "650px"}`,
                        marginBottom: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <Calendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        // selectable
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleEventClick}
                        style={{ margin: "" }}
                        eventPropGetter={eventStyleGetter}
                        defaultDate={defaultDate}
                        // views={{ month: true, week: true, day: true }}
                        onNavigate={(date) => {
                          setDefaultDate(date);
                        }}
                        components={{
                          event: ({ event: calendarEvent, start, end }) => (
                            <>
                              <div
                                onMouseOver={() => {
                                  setHoveredEvent(calendarEvent.id);
                                }}
                                onMouseOut={() => setHoveredEvent(null)}
                                onClick={() => {
                                  if (calendarEvent.type === "Tentative") {
                                    handleEventClick(calendarEvent.id);
                                  }
                                }}
                                style={{
                                  height: "80px",
                                  width: "100%",
                                  zIndex: 100,
                                }}
                              >
                                {calendarEvent && (
                                  <div className="" key={calendarEvent.id}>
                                    {hoveredEvent === calendarEvent.id && (
                                      <div className="">
                                        {hoveredEvent === calendarEvent.id && (
                                          <>
                                            <div
                                              className={`d-flex ${
                                                rowSix
                                                  ? calendarEvent.type ===
                                                    "Tentative"
                                                    ? "mt-sm-4 mt-lg-3 mt-3"
                                                    : "mt-sm-4 mt-lg-3 mt-3  "
                                                  : calendarEvent.type ===
                                                    "Tentative"
                                                  ? " mt-sm-4 mt-lg-3 mt-3  "
                                                  : " mt-sm-4 mt-lg-3 mt-3"
                                              }`}
                                            >
                                              <span className="date-reservation me-sm-3 me-2 ">
                                                {calendarEvent.start.getDate() <
                                                10
                                                  ? "0" +
                                                    calendarEvent.start.getDate()
                                                  : calendarEvent.start.getDate()}
                                              </span>
                                              <div
                                                className={`${
                                                  calendarEvent.type ===
                                                  "Tentative"
                                                    ? "mt-2 "
                                                    : "mt-2"
                                                } btn-group d-block`}
                                              >
                                                <div
                                                // className={`${calendarEvent.type ===
                                                //   "Tentative"
                                                //   ? "d-none"
                                                //   : "d-block"
                                                //   }`}
                                                >
                                                  {calendarEvent.bill_id ===
                                                    null && (
                                                    <button
                                                      className="btn text-center btn-calender  "
                                                      onClick={() =>
                                                        handleAdd(
                                                          calendarEvent.id
                                                        )
                                                      }
                                                    >
                                                      Add bill
                                                    </button>
                                                  )}
                                                  {calendarEvent.bill_id !=
                                                    null && (
                                                    <button
                                                      className="btn text-center btn-calender "
                                                      onClick={() =>
                                                       { 
                                                        handleEdit(
                                                          calendarEvent?.id,
                                                          calendarEvent?.userRole
                                                        )
                                                      }
                                                      }
                                                    >
                                                      Edit Bill
                                                    </button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          ),
                          // month: {
                          //   dateCellWrapper: (props) => {
                          //     const { date, range } = props;

                          //     // Check if the current date is the first day of the month
                          //     const isFirstDayOfMonth = moment(date).isSame(range.start, 'month') && moment(date).date() === 1;

                          //     return isFirstDayOfMonth ? props.children : null;
                          //   },
                          // },

                          toolbar: CustomToolbar,
                        }}
                      />
                    </div>
                  </div>
                </div>
                {showTransferForm && (
                  <>
                    <div
                      className={`next-btn ${
                        eventTypePhone ? "next-btn-true" : "next-btn-false"
                      }`}
                    >
                      <div
                        className=" d-flex justify-content-end px-2 "
                        style={{ cursor: "pointer" }}
                      >
                        <p
                          className=" text-dark text-end"
                          onClick={() => setEventTypePhone(true)}
                        >
                          NEXT
                        </p>
                        <RiArrowRightSLine className="fs-2" />
                      </div>
                    </div>
                  </>
                )}
                {showTransferForm && (
                  <>
                    <div
                      className={
                        eventTypePhone
                          ? "next-btn-event-true"
                          : "next-btn-event-false"
                      }
                    >
                      <div
                        className={
                          eventTypePhone
                            ? "next-btn-event-true padding-left-17 form-margin-768 position-relative"
                            : "next-btn-event-false ms-5"
                        }
                      >
                        <h2 className="fw-bold  mb-3">Transfer event</h2>
                        <p className="mb-4 text-black">
                          Note : If you entered an event on the 28th of January,
                          then the start date will be the 28th and the end date
                          will be the 29th.{" "}
                        </p>
                        <div className="row ">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              START DATE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8 ">
                            <div className="input-group mb-3 jod_width price price-768 cal_width">
                              <input
                                type=""
                                className="form-control inpt-border-trans "
                                value={transferDate.startDate}
                              />
                              <DatePicker
                                // selected={selectedDate}
                                onChange={handleDateChange}
                                className="form-control inpt-border-trans"
                                showPopperArrow={false}
                                dateFormat="yyyy-MM-dd"
                                //   value={finalDate ? finalDate : "aaa"}
                                customInput={
                                  <span className="cursor-pointer">
                                    <FaCalendarDays />
                                  </span>
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-5 ">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              END DATE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8 ">
                            <div className="input-group mb-3 jod_width price price-768 cal_width">
                              <input
                                type=""
                                className="form-control inpt-border-trans "
                                value={transferDate.endDate}
                              />
                              <DatePicker
                                // selected={selectedDate}
                                onChange={handleDateChange2}
                                className="form-control inpt-border-trans"
                                showPopperArrow={false}
                                dateFormat="yyyy-MM-dd"
                                //   value={finalDate ? finalDate : "aaa"}
                                customInput={
                                  <span className="cursor-pointer">
                                    <FaCalendarDays />
                                  </span>
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
                          <button
                            type="button"
                            className="btn btn-g me-2  mb-4 btn-768"
                            onClick={backData}
                          >
                            BACK
                          </button>
                          <button
                            type="button"
                            className="btn btn-g me-2  mb-4 btn-768"
                            onClick={handleResetTransfer}
                          >
                            RESET
                          </button>
                          {loading ? (
                            <button
                              type="button"
                              className="btn btn-b me-2 mb-4 btn-768"
                            >
                              <span className="spinner-border disable-spinner me-2"></span>
                              SUBMIT
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-b me-2 mb-4 btn-768  "
                              onClick={handleTransfer}
                            >
                              SUBMIT
                            </button>
                          )}
                        </div>

                        <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-for-768 position-relative">
                          {loading ? (
                            <button
                              type="button"
                              className="btn btn-b me-2 mb-4 btn-768"
                            >
                              <span className="spinner-border disable-spinner me-2"></span>
                              SUBMIT
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-b me-2 mb-4 btn-768  "
                              onClick={handleTransfer}
                            >
                              SUBMIT
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-g me-2  mb-4 btn-768"
                            onClick={handleResetTransfer}
                          >
                            RESET
                          </button>
                          <button
                            type="button"
                            className="btn btn-g me-2  mb-4 btn-768"
                            onClick={backData}
                          >
                            BACK
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {showTable && (
                  <div className="col-12 col-xl-11">
                    <div className="role-table mt-5 mb-5">
                      {/* <h2 className="fw-bold h2-768 mb-0">Info</h2> */}
                      <table
                        id="showBill"
                        className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                      >
                        <thead>
                          <tr>
                            <th className="thead-color" scope="col">
                              #
                            </th>
                            <th className="thead-color" scope="col">
                              Name
                            </th>
                            <th className="thead-color" scope="col">
                              Date
                            </th>

                            <th className="thead-color" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table-body"></tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  // res_pdf_data: state?.Main_Kitchen_pdf_data_reducer,
  res_calendar_event: state?.get_Calendar_Account_Details_reducer,
  res_show_details: state?.Send_Show_Details_reducer,
  res_transfer_date: state?.transfer_event_date_reducer,
  res_event_on_id: state?.Event_Details__on_id_reducer,
});
export default connect(mapStateToProps)(AccountantDetailTable);
