import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdCloseCircle } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import pdf from "../../../../images/PDF.png";
import { connect } from "react-redux";
import { FiPaperclip } from "react-icons/fi";
import {
  AddBillData,
  EditBillData,
  GetEditBillingData,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../Loader";
import { el } from "date-fns/locale";
import SubContractPdf from "../Add/EventType/SubContractPdf";
import ContractPdf from "../Add/Reservation/ContractPdf";
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #000000",
  marginBottom: 8,
  marginRight: 8,
  width: "100px",
  height: "100px",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  position: "relative",
  overflow: "hidden",
  width: "100%",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};
const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const getRandomFourDigitNumber = () => {
  return Math.floor(1000 + Math.random() * 9000);
};

const EditSalesBilling = ({ dispatch, res_get_data, res_add_data }) => {
  const userId = sessionStorage.getItem("member_id");
  const [show, setShow] = useState(0);
  const id = useParams();
  const location = useLocation();
  const [processing, setProcessing] = useState(true);
  const navigate = useNavigate();
  const [thumbs, setThumbs] = useState([]);
  const [file, setFile] = useState();
  const [filePrev, setFilePrev] = useState();
  const [eventCheck, setEventCheck] = useState(false);
  const [finalFileData, setFinalFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateWH, setStateWH] = useState(false);
  const [stateExtraCost, setStateExtraCost] = useState(false);
  const [billingData, setBillingData] = useState();
  const [WHTotal, setWHTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [settingPer, setSettingPer] = useState(0);
  const [disPerUser, setDisPerUser] = useState(0);
  const [showError, setShowError] = useState("0");
  const [disPer, setDisPer] = useState(0);
  const [settingPerUser, setSettingPerUser] = useState(0);
  const [rowsData, setRowsData] = useState([
    {
      f_v_name: "FIRST", // Add your default values here
      price: "",
      formatdate: null,
      date: null,
      paymentIndex: "0",
      paymentType: "",
      i_payment_id: "",
    },
  ]);
  const [updatedWH, setUpdatedWH] = useState(null);
  const [totalMap, setTotalMap] = useState(0);
  const [unPaidData, setUnPaidData] = useState(0);
  const [paidData, setPaidData] = useState(0);
  const [taxMode, setTaxMode] = useState(false);
  const [totalGST, setTotalGST] = useState(0);
  const [charityTax, setCharityTax] = useState(0);
  const [charityTaxSL, setCharityTaxSL] = useState(0);
  // useEffect(()=>{
  //   res_get_data.data = {};
  //   res_get_data.loading = true;
  // },[])
  useEffect(() => {
    res_get_data.data = {};
    res_get_data.loading = true;
    dispatch(getToken());
    dispatch(GetEditBillingData(id.id));
  }, [id.id, dispatch]);
  useEffect(() => {
    toast.dismiss();
  }, []);
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    if (
      id.id &&
      res_get_data?.data?.status === true &&
      res_get_data?.data?.data?.bill_data?.length !== 0
    ) {
      setTimeout(() => {
        setProcessing(false);
      }, 3000);
      setCharityTax(
        Number(res_get_data?.data?.data?.bill_data[0]?.charity_tax)
      );
      setCharityTaxSL(
        Number(res_get_data?.data?.data?.bill_data[0]?.charity_SL)
      );
      setBillingData({
        event_id: id.id,
        bill_id: res_get_data?.data?.data?.bill_data[0]?.bill_id,
        tax_invoice_id: res_get_data?.data?.data?.bill_data[0]?.tax_invoice_id,

        m_price: res_get_data?.data?.data?.bill_data[0]?.m_price,
        s_price: res_get_data?.data?.data?.bill_data[0]?.s_price,
        reservationStatus:
          res_get_data?.data?.data?.bill_data[0]?.billreservationStatus,
        menu_price: Number(res_get_data?.data?.data?.bill_data[0]?.menu_price),
        discount_per_user: Number(
          res_get_data?.data?.data?.bill_data[0]?.discount_per_user
        ),
        service_charge: Number(
          res_get_data?.data?.data?.bill_data[0]?.service_charge
        ),
        extra_cost: Number(res_get_data?.data?.data?.bill_data[0]?.extra_cost),
        food_extra_cost: Number(
          res_get_data?.data?.data?.bill_data[0]?.food_extra_cost
        ),
        food_notes:
          res_get_data?.data?.data?.bill_data[0]?.food_notes === null
            ? ""
            : res_get_data?.data?.data?.bill_data[0]?.food_notes,
        sound_extra_cost: Number(
          res_get_data?.data?.data?.bill_data[0]?.sound_extra_cost
        ),
        sound_notes:
          res_get_data?.data?.data?.bill_data[0]?.sound_notes === null
            ? ""
            : res_get_data?.data?.data?.bill_data[0]?.sound_notes,
        noOfGuests: Number(res_get_data?.data?.data?.noOfGuests[0]?.noOfGuests),
        bill_invoice_no:
          res_get_data?.data?.data?.bill_data[0]?.bill_invoice_no,

        whitehall_payment: Number(
          res_get_data?.data?.data?.bill_data[0]?.white_total
        ),
        eventType: res_get_data?.data?.data?.bill_data[0]?.eventType,
        discount_per_user_total: Number(
          res_get_data?.data?.data?.bill_data[0]?.discount_per_user_total
        ),
        sound_light_payment: Number(
          res_get_data?.data?.data?.bill_data[0]?.sound_light_payment
        ),
        // extra_payment: Number(
        //   res_get_data?.data?.data?.bill_data[0]?.extra_payment
        // ),
        payment_type: res_get_data?.data?.data?.bill_data[0]?.payment_type,
        paid: Number(res_get_data?.data?.data?.bill_data[0]?.paid),
        unpaid: Number(res_get_data?.data?.data?.bill_data[0]?.unpaid),
        grand_total: Number(
          res_get_data?.data?.data?.bill_data[0]?.grand_total
        ),
        special_dis: res_get_data?.data?.data?.bill_data[0]?.spacial_dis,
        bill_type: res_get_data?.data?.data?.bill_data[0]?.bill_type,
        sub_grand_total: Number(
          res_get_data?.data?.data?.bill_data[0]?.sub_grand_total
        ),
      });
      // if (res_get_data?.data?.data?.bill_data[0]?.tax_mode == "0") {
      //   setTaxMode(true)
      //  }else {
      //   setTaxMode(false)
      //  }
      setWHTotal(
        Number(res_get_data?.data?.data?.bill_data[0]?.white_hall_payment)
      );
      setTaxMode(
        res_get_data?.data?.data?.bill_data[0]?.tax_mode == "0"
          ? true
          : res_get_data?.data?.data?.bill_data[0]?.tax_mode == "1"
          ? false
          : ""
      );
      setEventCheck(
        res_get_data?.data?.data?.bill_data[0]?.event_hide == "0"
          ? true
          : res_get_data?.data?.data?.bill_data[0]?.event_hide == "1"
          ? false
          : ""
      );
      // setEventCheck(
      //   res_get_data?.data?.data?.bill_data[0]?.tax_mode == "0"
      //     ? true
      //     : res_get_data?.data?.data?.bill_data[0]?.tax_mode == "1"
      //     ? false
      //     : ""
      // );
      setTotalGST(Number(res_get_data?.data?.data?.bill_data[0]?.total_gst));
      setDisPer(
        Number(res_get_data?.data?.data?.bill_data[0]?.discount_per_user)
      );
      setDisPerUser(
        Number(res_get_data?.data?.data?.noOfGuests[0]?.noOfGuests) *
          Number(res_get_data?.data?.data?.bill_data[0]?.discount_per_user)
      );
      setSettingPer({
        tax_dis: Number(res_get_data?.data?.data?.getsetting[0]?.tax_dis),
        tax_mode: res_get_data?.data?.data?.getsetting[0]?.tax_mode,
      });
      const arrayOfObjects = JSON.parse(
        res_get_data?.data?.data?.bill_data[0]?.installment_payments
      );
      // setTotal(Number(res_get_data?.data?.data?.bill_data[0]?.grand_total));
      setRowsData(JSON.parse(arrayOfObjects));
      setTotalMap(
        Number(res_get_data?.data?.data?.bill_data[0]?.sub_grand_total)
      );
      setPaidData(Number(res_get_data?.data?.data?.bill_data[0]?.paid));
      const arrayOfStrings = JSON.parse(
        res_get_data?.data?.data?.bill_data[0]?.files
      );
      const convertToArrayOfArrays = (arr) => {
        if (!Array.isArray(arr) || arr.length === 0) {
          return [];
        }
        return arr.map((string) => {
          return [string];
        });
      };
      const arrayOfArrays = convertToArrayOfArrays(arrayOfStrings);
      const abc = Object.values(arrayOfArrays);
      setFinalFileData(arrayOfArrays);
      setFilePrev(arrayOfArrays);
    } else if (res_get_data?.data?.data?.bill_data?.length === 0) {
      navigate(`/create_billing/${id.id}`);
    }
  }, [id.id, res_get_data]);
  useEffect(() => {
    if (
      id.id &&
      res_get_data?.data?.status === true &&
      res_get_data?.data?.data?.bill_data?.length !== 0 &&
      billingData
    ) {
      const m_price = Number(res_get_data.data.data.bill_data[0].m_price);
      const s_price = Number(res_get_data.data.data.bill_data[0].s_price);
      const sound_light_payment = Number(
        res_get_data.data.data.bill_data[0].sound_light_payment
      );
      const whitehall_payment = Number(
        res_get_data.data.data.bill_data[0].white_total
      );
      const GuestNumber =
        Number(billingData?.noOfGuests) === 0
          ? 1
          : Number(billingData?.noOfGuests);
      const eventType = res_get_data?.data?.data?.bill_data[0]?.eventType;
      if (res_get_data?.data?.data?.bill_data[0]?.tax_mode === "0") {
        if (eventType === "Charity") {
          setBillingData((prevBillingData) => ({
            ...prevBillingData,
            menu_price: Number(Number(m_price) + Number(s_price)) * GuestNumber,
            m_price: m_price,
            s_price: s_price,
            sound_light_payment: sound_light_payment,
            whitehall_payment:
              Number(Number(Number(m_price) + Number(s_price)) * GuestNumber) +
              Number(sound_light_payment),
          }));
        } else {
          setBillingData((prevBillingData) => ({
            ...prevBillingData,
            menu_price: taxMode
              ? Number(Number(m_price) + Number(s_price)) * GuestNumber
              : Number(Number(m_price) + Number(s_price)) * GuestNumber +
                Number(
                  Number(Number(m_price) + Number(s_price)) * GuestNumber * 0.16
                ),
            m_price: taxMode
              ? m_price
              : Math.round(m_price + Number(m_price * 0.16)),
            s_price: taxMode
              ? s_price
              : Math.round(s_price + Number(s_price * 0.16)),
            sound_light_payment: taxMode
              ? sound_light_payment
              : sound_light_payment + Number(sound_light_payment * 0.16),
            whitehall_payment: taxMode
              ? Number(
                  Number(Number(m_price) + Number(s_price)) * GuestNumber
                ) + Number(sound_light_payment)
              : Number(
                  Number(
                    Number(Number(m_price) + Number(s_price)) * GuestNumber +
                      Number(
                        Number(Number(m_price) + Number(s_price)) *
                          GuestNumber *
                          0.16
                      )
                  )
                ) +
                Number(
                  Number(
                    sound_light_payment + Number(sound_light_payment * 0.16)
                  )
                ),
          }));
        }
      } else if (res_get_data?.data?.data?.bill_data[0]?.tax_mode === "1") {
        if (eventType === "Charity") {
          setBillingData((prevBillingData) => ({
            ...prevBillingData,
            menu_price: Number(Number(m_price) + Number(s_price)) * GuestNumber,
            m_price: m_price,
            s_price: s_price,
            sound_light_payment: sound_light_payment,
            whitehall_payment:
              Number(Number(Number(m_price) + Number(s_price)) * GuestNumber) +
              Number(sound_light_payment),
          }));
        } else {
          setBillingData((prevBillingData) => ({
            ...prevBillingData,
            menu_price: taxMode
              ? (Number(Number(m_price) + Number(s_price)) * GuestNumber) / 1.16
              : Number(Number(m_price) + Number(s_price)) * GuestNumber,
            m_price: taxMode ? m_price / 1.16 : m_price,
            s_price: taxMode ? s_price / 1.16 : s_price,

            sound_light_payment: taxMode
              ? sound_light_payment / 1.16
              : sound_light_payment,
            whitehall_payment: taxMode
              ? Number(
                  (Number(Number(m_price) + Number(s_price)) * GuestNumber) /
                    1.16
                ) + Number(sound_light_payment / 1.16)
              : Number(
                  Number(
                    Number(Number(m_price) + Number(s_price)) * GuestNumber
                  )
                ) + Number(Number(sound_light_payment)),
          }));
        }
      }
    }
  }, [taxMode, res_get_data]);
  useEffect(() => {
    if (id.id && res_get_data?.data?.status === true) {
      const m_price = Number(res_get_data.data.data.bill_data[0].m_price);
      const s_price = Number(res_get_data.data.data.bill_data[0].s_price);
      const menu_price = Number(res_get_data.data.data.bill_data[0].menu_price);
      const sound_light_payment = Number(
        res_get_data.data.data.bill_data[0].sound_light_payment
      );
      const whitehall_payment = Number(
        res_get_data.data.data.bill_data[0].white_total
      );
      const GuestNumber =
        Number(billingData?.noOfGuests) === 0
          ? 1
          : Number(billingData?.noOfGuests);
      const eventType = res_get_data?.data?.data?.bill_data[0]?.eventType;

      if (res_get_data?.data?.data?.bill_data[0]?.tax_mode === "0") {
        if (eventType === "Charity") {
          setWHTotal(
            updatedWH === null
              ? Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                          : Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                      ) +
                        Number(
                          taxMode ? sound_light_payment : sound_light_payment
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge)
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost)
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost)
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                )
              : Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          taxMode
                            ? Number(Number(m_price) + Number(s_price)) *
                                GuestNumber
                            : Number(Number(m_price) + Number(s_price)) *
                                GuestNumber
                        )
                      ) +
                        Number(
                          taxMode ? sound_light_payment : sound_light_payment
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge)
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost)
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost)
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                ) - Number(Number(disPer * GuestNumber))
          );
          setTotalMap(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    ) - Number(Number(disPer * GuestNumber))
              ) -
                Number(
                  billingData?.special_dis === undefined ||
                    billingData?.special_dis === ""
                    ? 0
                    : billingData?.special_dis
                )
            ) - paidData
          );
          setTotalGST(0);
          setTotal(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    ) - Number(Number(disPer * GuestNumber))
              ) -
                Number(
                  billingData?.special_dis === undefined ||
                    billingData?.special_dis === ""
                    ? 0
                    : billingData?.special_dis
                )
            )
          );
        } else {
          setWHTotal(
            updatedWH === null
              ? Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                          : Number(Number(m_price) + Number(s_price)) *
                              GuestNumber +
                              Number(
                                Number(Number(m_price) + Number(s_price)) *
                                  GuestNumber *
                                  0.16
                              )
                      ) +
                        Number(
                          taxMode
                            ? sound_light_payment
                            : sound_light_payment +
                                Number(sound_light_payment * 0.16)
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge) / 1.16
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost) / 1.16
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost) / 1.16
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                )
              : Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          taxMode
                            ? Number(Number(m_price) + Number(s_price)) *
                                GuestNumber
                            : Number(Number(m_price) + Number(s_price)) *
                                GuestNumber +
                                Number(
                                  Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber *
                                    0.16
                                )
                        )
                      ) +
                        Number(
                          taxMode
                            ? sound_light_payment
                            : sound_light_payment +
                                Number(sound_light_payment * 0.16)
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge) / 1.16
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost) / 1.16
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost) / 1.16
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                ) -
                  Number(
                    taxMode
                      ? Number(disPer * GuestNumber) / 1.16
                      : Number(disPer * GuestNumber)
                  )
          );
          setTotalMap(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber +
                                    Number(
                                      Number(
                                        Number(m_price) + Number(s_price)
                                      ) *
                                        GuestNumber *
                                        0.16
                                    )
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment +
                                    Number(sound_light_payment * 0.16)
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge) / 1.16
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost) / 1.16
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost) / 1.16
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber +
                                    Number(
                                      Number(
                                        Number(m_price) + Number(s_price)
                                      ) *
                                        GuestNumber *
                                        0.16
                                    )
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment +
                                    Number(sound_light_payment * 0.16)
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge) / 1.16
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost) / 1.16
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost) / 1.16
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    ) -
                      Number(
                        taxMode
                          ? Number(disPer * GuestNumber) / 1.16
                          : Number(disPer * GuestNumber)
                      )
              ) +
                Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(
                            taxMode
                              ? Number(Number(m_price) + Number(s_price)) *
                                  GuestNumber
                              : 0
                          )
                        ) + Number(taxMode ? sound_light_payment : 0)
                      ) +
                        Number(
                          taxMode
                            ? Number(billingData?.service_charge) / 1.16
                            : 0
                        ) +
                        Number(
                          taxMode
                            ? Number(billingData?.food_extra_cost) / 1.16
                            : 0
                        ) +
                        Number(
                          taxMode
                            ? Number(billingData?.sound_extra_cost) / 1.16
                            : 0
                        )
                    ) -
                      Number(
                        taxMode ? Number(disPer * GuestNumber) / 1.16 : 0
                      ) -
                      Number(
                        taxMode
                          ? Number(
                              billingData?.special_dis === undefined ||
                                billingData?.special_dis === ""
                                ? 0
                                : billingData?.special_dis
                            ) / 1.16
                          : 0
                      )
                  ) * 0.16
                ) -
                Number(
                  taxMode
                    ? Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      ) / 1.16
                    : Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      )
                )
            ) - paidData
          );
          setTotalGST(
            Number(
              Number(
                Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                          : 0
                      )
                    ) + Number(taxMode ? sound_light_payment : 0)
                  ) +
                    Number(
                      taxMode ? Number(billingData?.service_charge) / 1.16 : 0
                    ) +
                    Number(
                      taxMode ? Number(billingData?.food_extra_cost) / 1.16 : 0
                    ) +
                    Number(
                      taxMode ? Number(billingData?.sound_extra_cost) / 1.16 : 0
                    )
                ) -
                  Number(taxMode ? Number(disPer * GuestNumber) / 1.16 : 0) -
                  Number(
                    taxMode
                      ? Number(
                          billingData?.special_dis === undefined ||
                            billingData?.special_dis === ""
                            ? 0
                            : billingData?.special_dis
                        ) / 1.16
                      : 0
                  )
              ) * 0.16
            )
          );
          setTotal(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber +
                                    Number(
                                      Number(
                                        Number(m_price) + Number(s_price)
                                      ) *
                                        GuestNumber *
                                        0.16
                                    )
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment +
                                    Number(sound_light_payment * 0.16)
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge) / 1.16
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost) / 1.16
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost) / 1.16
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber +
                                    Number(
                                      Number(
                                        Number(m_price) + Number(s_price)
                                      ) *
                                        GuestNumber *
                                        0.16
                                    )
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment +
                                    Number(sound_light_payment * 0.16)
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge) / 1.16
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost) / 1.16
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost) / 1.16
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    ) -
                      Number(
                        taxMode
                          ? Number(disPer * GuestNumber) / 1.16
                          : Number(disPer * GuestNumber)
                      )
              ) +
                Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(
                            taxMode
                              ? Number(Number(m_price) + Number(s_price)) *
                                  GuestNumber
                              : 0
                          )
                        ) + Number(taxMode ? sound_light_payment : 0)
                      ) +
                        Number(
                          taxMode
                            ? Number(billingData?.service_charge) / 1.16
                            : 0
                        ) +
                        Number(
                          taxMode
                            ? Number(billingData?.food_extra_cost) / 1.16
                            : 0
                        ) +
                        Number(
                          taxMode
                            ? Number(billingData?.sound_extra_cost) / 1.16
                            : 0
                        )
                    ) -
                      Number(
                        taxMode ? Number(disPer * GuestNumber) / 1.16 : 0
                      ) -
                      Number(
                        taxMode
                          ? Number(
                              billingData?.special_dis === undefined ||
                                billingData?.special_dis === ""
                                ? 0
                                : billingData?.special_dis
                            ) / 1.16
                          : 0
                      )
                  ) * 0.16
                ) -
                Number(
                  taxMode
                    ? Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      ) / 1.16
                    : Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      )
                )
            )
          );
        }
      } else if (res_get_data?.data?.data?.bill_data[0]?.tax_mode === "1") {
        if (eventType === "Charity") {
          setWHTotal(
            updatedWH === null
              ? Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                          : Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                      ) +
                        Number(
                          taxMode ? sound_light_payment : sound_light_payment
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge)
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost)
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost)
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                )
              : Number(
                  Number(
                    Number(
                      Number(
                        Number(
                          taxMode
                            ? Number(Number(m_price) + Number(s_price)) *
                                GuestNumber
                            : Number(Number(m_price) + Number(s_price)) *
                                GuestNumber
                        )
                      ) +
                        Number(
                          taxMode ? sound_light_payment : sound_light_payment
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge)
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost)
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost)
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                ) - Number(Number(disPer * GuestNumber))
          );
          setTotalMap(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    ) - Number(Number(disPer * GuestNumber))
              ) -
                Number(
                  billingData?.special_dis === undefined ||
                    billingData?.special_dis === ""
                    ? 0
                    : billingData?.special_dis
                )
            ) - paidData
          );
          setTotalGST(0);
          setTotal(
            Number(
              Number(
                updatedWH === null
                  ? Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    )
                  : Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            )
                          ) +
                            Number(
                              taxMode
                                ? sound_light_payment
                                : sound_light_payment
                            )
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge)
                              : Number(billingData?.service_charge)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost)
                              : Number(billingData?.food_extra_cost)
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost)
                              : Number(billingData?.sound_extra_cost)
                          )
                      )
                    ) - Number(Number(disPer * GuestNumber))
              ) -
                Number(
                  billingData?.special_dis === undefined ||
                    billingData?.special_dis === ""
                    ? 0
                    : billingData?.special_dis
                )
            )
          );
        } else {
          setWHTotal(
            updatedWH === null
              ? Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? (Number(Number(m_price) + Number(s_price)) *
                              GuestNumber) /
                              1.16
                          : Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                      ) +
                        Number(
                          taxMode
                            ? sound_light_payment / 1.16
                            : sound_light_payment
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge) / 1.16
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost) / 1.16
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost) / 1.16
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                )
              : Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? (Number(Number(m_price) + Number(s_price)) *
                              GuestNumber) /
                              1.16
                          : Number(Number(m_price) + Number(s_price)) *
                              GuestNumber
                      ) +
                        Number(
                          taxMode
                            ? sound_light_payment / 1.16
                            : sound_light_payment
                        )
                    ) +
                      Number(
                        taxMode
                          ? Number(billingData?.service_charge) / 1.16
                          : Number(billingData?.service_charge)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.food_extra_cost) / 1.16
                          : Number(billingData?.food_extra_cost)
                      ) +
                      Number(
                        taxMode
                          ? Number(billingData?.sound_extra_cost) / 1.16
                          : Number(billingData?.sound_extra_cost)
                      )
                  )
                ) -
                  Number(
                    taxMode
                      ? Number(disPer * GuestNumber) / 1.16
                      : Number(disPer * GuestNumber)
                  )
          );
          setTotalMap(
            Number(
              Number(
                Number(
                  updatedWH === null
                    ? Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? (Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber) /
                                    1.16
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            ) +
                              Number(
                                taxMode
                                  ? sound_light_payment / 1.16
                                  : sound_light_payment
                              )
                          ) +
                            Number(
                              taxMode
                                ? Number(billingData?.service_charge) / 1.16
                                : Number(billingData?.service_charge)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.food_extra_cost) / 1.16
                                : Number(billingData?.food_extra_cost)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.sound_extra_cost) / 1.16
                                : Number(billingData?.sound_extra_cost)
                            )
                        )
                      )
                    : Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? (Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber) /
                                    1.16
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            ) +
                              Number(
                                taxMode
                                  ? sound_light_payment / 1.16
                                  : sound_light_payment
                              )
                          ) +
                            Number(
                              taxMode
                                ? Number(billingData?.service_charge) / 1.16
                                : Number(billingData?.service_charge)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.food_extra_cost) / 1.16
                                : Number(billingData?.food_extra_cost)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.sound_extra_cost) / 1.16
                                : Number(billingData?.sound_extra_cost)
                            )
                        )
                      ) -
                        Number(
                          taxMode
                            ? Number(disPer * GuestNumber) / 1.16
                            : Number(disPer * GuestNumber)
                        )
                ) +
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? (Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber) /
                                    1.16
                                : 0
                            )
                          ) + Number(taxMode ? sound_light_payment / 1.16 : 0)
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge) / 1.16
                              : 0
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost) / 1.16
                              : 0
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost) / 1.16
                              : 0
                          )
                      ) -
                        Number(
                          taxMode ? Number(disPer * GuestNumber) / 1.16 : 0
                        ) -
                        Number(
                          taxMode
                            ? Number(
                                billingData?.special_dis === undefined ||
                                  billingData?.special_dis === ""
                                  ? 0
                                  : billingData?.special_dis
                              ) / 1.16
                            : 0
                        )
                    ) * 0.16
                  )
              ) -
                Number(
                  taxMode
                    ? Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      ) / 1.16
                    : Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      )
                )
            ) - paidData
          );
          setTotalGST(
            +Number(
              Number(
                Number(
                  Number(
                    Number(
                      Number(
                        taxMode
                          ? (Number(Number(m_price) + Number(s_price)) *
                              GuestNumber) /
                              1.16
                          : 0
                      )
                    ) + Number(taxMode ? sound_light_payment / 1.16 : 0)
                  ) +
                    Number(
                      taxMode ? Number(billingData?.service_charge) / 1.16 : 0
                    ) +
                    Number(
                      taxMode ? Number(billingData?.food_extra_cost) / 1.16 : 0
                    ) +
                    Number(
                      taxMode ? Number(billingData?.sound_extra_cost) / 1.16 : 0
                    )
                ) -
                  Number(taxMode ? Number(disPer * GuestNumber) / 1.16 : 0) -
                  Number(
                    taxMode
                      ? Number(
                          billingData?.special_dis === undefined ||
                            billingData?.special_dis === ""
                            ? 0
                            : billingData?.special_dis
                        ) / 1.16
                      : 0
                  )
              ) * 0.16
            )
          );
          setTotal(
            Number(
              Number(
                Number(
                  updatedWH === null
                    ? Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? (Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber) /
                                    1.16
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            ) +
                              Number(
                                taxMode
                                  ? sound_light_payment / 1.16
                                  : sound_light_payment
                              )
                          ) +
                            Number(
                              taxMode
                                ? Number(billingData?.service_charge) / 1.16
                                : Number(billingData?.service_charge)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.food_extra_cost) / 1.16
                                : Number(billingData?.food_extra_cost)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.sound_extra_cost) / 1.16
                                : Number(billingData?.sound_extra_cost)
                            )
                        )
                      )
                    : Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? (Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber) /
                                    1.16
                                : Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber
                            ) +
                              Number(
                                taxMode
                                  ? sound_light_payment / 1.16
                                  : sound_light_payment
                              )
                          ) +
                            Number(
                              taxMode
                                ? Number(billingData?.service_charge) / 1.16
                                : Number(billingData?.service_charge)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.food_extra_cost) / 1.16
                                : Number(billingData?.food_extra_cost)
                            ) +
                            Number(
                              taxMode
                                ? Number(billingData?.sound_extra_cost) / 1.16
                                : Number(billingData?.sound_extra_cost)
                            )
                        )
                      ) -
                        Number(
                          taxMode
                            ? Number(disPer * GuestNumber) / 1.16
                            : Number(disPer * GuestNumber)
                        )
                ) +
                  Number(
                    Number(
                      Number(
                        Number(
                          Number(
                            Number(
                              taxMode
                                ? (Number(Number(m_price) + Number(s_price)) *
                                    GuestNumber) /
                                    1.16
                                : 0
                            )
                          ) + Number(taxMode ? sound_light_payment / 1.16 : 0)
                        ) +
                          Number(
                            taxMode
                              ? Number(billingData?.service_charge) / 1.16
                              : 0
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.food_extra_cost) / 1.16
                              : 0
                          ) +
                          Number(
                            taxMode
                              ? Number(billingData?.sound_extra_cost) / 1.16
                              : 0
                          )
                      ) -
                        Number(
                          taxMode ? Number(disPer * GuestNumber) / 1.16 : 0
                        ) -
                        Number(
                          taxMode
                            ? Number(
                                billingData?.special_dis === undefined ||
                                  billingData?.special_dis === ""
                                  ? 0
                                  : billingData?.special_dis
                              ) / 1.16
                            : 0
                        )
                    ) * 0.16
                  )
              ) -
                Number(
                  taxMode
                    ? Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      ) / 1.16
                    : Number(
                        billingData?.special_dis === undefined ||
                          billingData?.special_dis === ""
                          ? 0
                          : billingData?.special_dis
                      )
                )
            )
          );
        }
      }
    }
  }, [total, billingData, WHTotal, paidData, taxMode]);
  useEffect(() => {
    if (WHTotal < 0) {
      const notify = () =>
        toast.error("White Hall Payment should not be Minus", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  }, [WHTotal]);

  useEffect(() => {
    if (Number(disPer) !== NaN && billingData?.noOfGuests !== undefined) {
      if (Number(disPer) !== 0) {
        // if (role === "0") {
        //   if (Number(billingData?.noOfGuests * Number(disPer)) === 0) {
        //     setShowError("0");
        //     setDisPerUser(0);
        //     setWHTotal(
        //       Number(billingData?.whitehall_payment) +
        //         Number(billingData?.service_charge) +
        //         Number(billingData?.food_extra_cost) +
        //         Number(billingData?.sound_extra_cost)
        //     );
        //     setUpdatedWH(null);
        //   } else if (Number(billingData?.noOfGuests * Number(disPer)) !== 0) {
        //     setShowError("0");
        //     setDisPerUser(Number(billingData?.noOfGuests * Number(disPer)));
        //     setWHTotal(
        //       Number(
        //         Number(
        //           Number(billingData?.m_price) <= 0
        //             ? 0
        //             : (Number(billingData?.m_price) - Number(disPer)) *
        //                 Number(billingData?.noOfGuests)
        //         ) +
        //           Number(
        //             Number(billingData?.s_price) <= 0
        //               ? 0
        //               : (Number(billingData?.s_price) - Number(disPer)) *
        //                   Number(billingData?.noOfGuests)
        //           ) +
        //           Number(billingData?.service_charge) +
        //           Number(billingData?.food_extra_cost) +
        //           Number(billingData?.sound_extra_cost) +
        //           Number(billingData?.sound_light_payment)
        //       )
        //     );
        //     setUpdatedWH(
        //       Number(
        //         Number(
        //           Number(billingData?.m_price) <= 0
        //             ? 0
        //             : (Number(billingData?.m_price) - Number(disPer)) *
        //                 Number(billingData?.noOfGuests)
        //         ) +
        //           Number(
        //             Number(billingData?.s_price) <= 0
        //               ? 0
        //               : (Number(billingData?.s_price) - Number(disPer)) *
        //                   Number(billingData?.noOfGuests)
        //           ) +
        //           Number(billingData?.service_charge) +
        //           Number(billingData?.food_extra_cost) +
        //           Number(billingData?.sound_extra_cost) +
        //           Number(billingData?.sound_light_payment)
        //       )
        //     );
        //   }
        // } else if (role !== "0") {
        if (settingPer?.tax_dis + 1 <= Number(disPer)) {
          setUpdatedWH(null);
          setDisPerUser(0);
          setShowError("1");
          setWHTotal(
            Number(billingData?.whitehall_payment) +
              Number(billingData?.service_charge) +
              Number(billingData?.food_extra_cost) +
              Number(billingData?.sound_extra_cost)
          );
          const notify = () =>
            toast.error(
              "Maximum discount exceeded. Please review your discount settings and try again!",
              {
                style: {
                  padding: "10px",
                  borderRadius: "30px",
                },
              }
            );
          notify();
        } else if (Number(billingData?.noOfGuests * Number(disPer)) === 0) {
          setShowError("0");
          setDisPerUser(0);
          setWHTotal(
            Number(billingData?.whitehall_payment) +
              Number(billingData?.service_charge) +
              Number(billingData?.food_extra_cost) +
              Number(billingData?.sound_extra_cost)
          );
          setUpdatedWH(null);
        } else if (Number(billingData?.noOfGuests * Number(disPer)) !== 0) {
          setShowError("0");
          setDisPerUser(Number(billingData?.noOfGuests * Number(disPer)));
          setWHTotal(
            Number(
              Number(
                Number(billingData?.m_price) <= 0
                  ? 0
                  : (Number(billingData?.m_price) - Number(disPer)) *
                      Number(billingData?.noOfGuests)
              ) +
                Number(
                  Number(billingData?.s_price) <= 0
                    ? 0
                    : (Number(billingData?.s_price) - Number(disPer)) *
                        Number(billingData?.noOfGuests)
                ) +
                Number(billingData?.service_charge) +
                Number(billingData?.food_extra_cost) +
                Number(billingData?.sound_extra_cost) +
                Number(billingData?.sound_light_payment)
            )
          );
          setUpdatedWH(
            Number(
              Number(
                Number(billingData?.m_price) <= 0
                  ? 0
                  : (Number(billingData?.m_price) - Number(disPer)) *
                      Number(billingData?.noOfGuests)
              ) +
                Number(
                  Number(billingData?.s_price) <= 0
                    ? 0
                    : (Number(billingData?.s_price) - Number(disPer)) *
                        Number(billingData?.noOfGuests)
                ) +
                Number(billingData?.service_charge) +
                Number(billingData?.food_extra_cost) +
                Number(billingData?.sound_extra_cost) +
                Number(billingData?.sound_light_payment)
            )
          );
        }
        // }
      } else if (Number(disPer) === 0) {
        setShowError("0");
        setDisPerUser(0);
        setWHTotal(
          Number(billingData?.whitehall_payment) +
            Number(billingData?.service_charge) +
            Number(billingData?.food_extra_cost) +
            Number(billingData?.sound_extra_cost)
        );
        setUpdatedWH(null);
      } else {
        setShowError("0");
      }
    }
  }, [billingData, disPer]);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "discount_per_user") {
      setDisPer(value);
      return;
    }
    setBillingData({ ...billingData, [name]: value });
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: [".jpg", ".jpeg", ".png", ".pdf"],
    onDrop: (acceptedFiles, rejectedFiles) => {
      const largeFiles = [];
      const newThumbs = [];
      acceptedFiles.forEach((file) => {
        if (file.size > MAX_FILE_SIZE) {
          largeFiles.push(file.name);
        } else {
          newThumbs.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
        }
      });

      const mergedThumbs = [...thumbs, ...newThumbs];
      setFinalFileData([]);
      setThumbs(mergedThumbs);
      setFile(mergedThumbs);
    },
  });
  useEffect(() => {
    return () => thumbs.forEach((thumb) => URL.revokeObjectURL(thumb.preview));
  }, [thumbs]);
  const installmentNames = [
    "FIRST",
    "SECOND",
    "THIRD",
    "FOURTH",
    "FIFTH",
    "SIXTH",
    "SEVENTH",
    "EIGHTH",
    "NINTH",
    "TENTH",
    "ELEVENTH",
    "TWELFTH",
    "THIRTEENTH",
    "FOURTEENTH",
    "FIFTEENTH",
    "SIXTEENTH",
    "SEVENTEENTH",
    "EIGHTEENTH",
    "NINETEENTH",
    "TWENTIETH",
    "TWENTY-FIRST",
    "TWENTY-SECOND",
    "TWENTY-THIRD",
    "TWENTY-FOURTH",
    "TWENTY-FIFTH",
    "TWENTY-SIXTH",
    "TWENTY-SEVENTH",
    "TWENTY-EIGHTH",
    "TWENTY-NINTH",
    "THIRTIETH",
    "THIRTY-FIRST",
    "THIRTY-SECOND",
    "THIRTY-THIRD",
    "THIRTY-FOURTH",
    "THIRTY-FIFTH",
    "THIRTY-SIXTH",
    "THIRTY-SEVENTH",
    "THIRTY-EIGHTH",
    "THIRTY-NINTH",
    "FORTIETH",
    "FORTY-FIRST",
    "FORTY-SECOND",
    "FORTY-THIRD",
    "FORTY-FOURTH",
    "FORTY-FIFTH",
    "FORTY-SIXTH",
    "FORTY-SEVENTH",
    "FORTY-EIGHTH",
    "FORTY-NINTH",
    "FIFTIETH",
    "FIFTY-FIRST",
    "FIFTY-SECOND",
    "FIFTY-THIRD",
    "FIFTY-FOURTH",
    "FIFTY-FIFTH",
    "FIFTY-SIXTH",
    "FIFTY-SEVENTH",
    "FIFTY-EIGHTH",
    "FIFTY-NINTH",
    "SIXTIETH",
    "SIXTY-FIRST",
    "SIXTY-SECOND",
    "SIXTY-THIRD",
    "SIXTY-FOURTH",
    "SIXTY-FIFTH",
    "SIXTY-SIXTH",
    "SIXTY-SEVENTH",
    "SIXTY-EIGHTH",
    "SIXTY-NINTH",
    "SEVENTIETH",
    "SEVENTY-FIRST",
    "SEVENTY-SECOND",
    "SEVENTY-THIRD",
    "SEVENTY-FOURTH",
    "SEVENTY-FIFTH",
    "SEVENTY-SIXTH",
    "SEVENTY-SEVENTH",
    "SEVENTY-EIGHTH",
    "SEVENTY-NINTH",
    "EIGHTIETH",
    "EIGHTY-FIRST",
    "EIGHTY-SECOND",
    "EIGHTY-THIRD",
    "EIGHTY-FOURTH",
    "EIGHTY-FIFTH",
    "EIGHTY-SIXTH",
    "EIGHTY-SEVENTH",
    "EIGHTY-EIGHTH",
    "EIGHTY-NINTH",
    "NINETIETH",
    "NINETY-FIRST",
    "NINETY-SECOND",
    "NINETY-THIRD",
    "NINETY-FOURTH",
    "NINETY-FIFTH",
    "NINETY-SIXTH",
    "NINETY-SEVENTH",
    "NINETY-EIGHTH",
    "NINETY-NINTH",
    "HUNDREDTH",
  ];

  const addTableRows = () => {
    const rowsInput = {
      f_v_name: `${installmentNames[rowsData?.length]}`,
      paymentIndex: "1",
      price: "",
      formatdate: "",
      date: "",
      paymentType: "",
      i_payment_id: Number(`${id.id}${getRandomFourDigitNumber().toString()}`),
    };

    setRowsData((prevRowsData) => [...prevRowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
    setStateWH(true);
  };

  const paymentHandleChange = (e, index) => {
    const { name, value } = e.target;
    setStateWH(true);
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);

    const updatedProductMap = [...rowsData];
    if ((name === "price" || name === "formatdate") && !isNaN(value)) {
      updatedProductMap[index] = {
        ...updatedProductMap[index],
        [name]: value,
      };
    }

    if (name === "formatdate" && !isNaN(value)) {
      const updatedItem = updatedProductMap[index];
      const updatedDate = updatedItem?.formatdate;
      const date = new Date(updatedDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formatDate = `${day}-${month}-${year}`;
      updatedProductMap[index] = {
        ...updatedItem,
        date: formatDate,
      };
    }

    const updatedProductArray = Object.keys(updatedProductMap).map(
      (key) => updatedProductMap[key]
    );
    setRowsData(updatedProductArray);
  };

  const calculateTotalAmount = () => {
    const totalSum = Object.values(rowsData)
      .map((row) => Number(row?.price))
      .filter((price) => !isNaN(price))
      .reduce((acc, price) => acc + price, 0);
    if (total) {
      if (totalSum !== 0) {
        if (totalSum >= total) {
          if (total - totalSum + 1 <= 0) {
            setShowError("2");
            const notify = () =>
              toast.error(
                "Total installment amount cannot exceed the total amount !",
                {
                  style: {
                    padding: "10px",
                    borderRadius: "30px",
                  },
                }
              );
            notify();
          } else {
            setShowError("0");
            setUnPaidData(0);
            setPaidData(totalSum);
          }
        } else if (totalSum <= total + 1 || totalSum === 0) {
          setShowError("0");
          const unpaid = total - totalSum;
          const Paid = totalSum;
          setUnPaidData(unpaid);
          setPaidData(Paid);
        } else {
          setShowError("0");
        }
      } else if (totalSum === 0) {
        setShowError("0");
        setUnPaidData(total);
        setPaidData(0);
      } else {
        setShowError("0");
      }
    }
  };
  useEffect(() => {
    calculateTotalAmount();
  }, [rowsData, total, totalMap, billingData]);
  const daaattaa = rowsData.map((e) => e.price);
  const length = daaattaa.filter((value) => value !== "").length;
  const handleSubmit = () => {
    const hasMissingFields =
      billingData?.reservationStatus === "Tentative"
        ? rowsData.some((row) => {
            return (row.price && !row.formatdate && !row.date) ||
              (!row.price && row.formatdate && !row.date) ||
              (!row.price && !row.formatdate && row.date)
              ? true
              : false;
          })
        : billingData?.reservationStatus === "FinalReserve"
        ? rowsData.some((row) => {
            return !row.price || !row.formatdate || !row.date;
          })
        : "";

    const updatedRowsData = rowsData.map((row) => {
      if (row.paymentType === "") {
        return { ...row, paymentType: billingData?.payment_type };
      }
      return row;
    });
    const jsonString = JSON.stringify(updatedRowsData);
    const formData = new FormData();
    formData.append("event_id", id.id);
    formData.append("noOfGuests", billingData?.noOfGuests);
    formData.append("tax_invoice_id", billingData?.tax_invoice_id);
    formData.append("menu_price", billingData?.menu_price);
    formData.append("discount_per_user", disPer);
    formData.append(
      "billreservationStatus",
      length < 1 ? billingData?.reservationStatus : "FinalReserve"
    );
    formData.append("service_charge", billingData?.service_charge);
    formData.append(
      "food_extra_cost",
      billingData?.food_extra_cost === (undefined || null)
        ? ""
        : billingData?.food_extra_cost
    );
    formData.append(
      "sound_extra_cost",
      billingData?.sound_extra_cost === (undefined || null)
        ? ""
        : billingData?.sound_extra_cost
    );
    formData.append("m_price", billingData?.m_price);
    formData.append("s_price", billingData?.s_price);
    formData.append("food_notes", billingData?.food_notes);
    formData.append("sound_notes", billingData?.sound_notes);
    formData.append("service_charge", billingData?.service_charge);
    formData.append("installment_payments", jsonString);
    formData.append("white_hall_payment", WHTotal);
    formData.append("sound_light_payment", billingData?.sound_light_payment);
    // formData.append("extra_payment", billingData?.extra_payment);
    formData.append("grand_total", totalMap);
    formData.append(
      "payment_type",
      billingData?.payment_type == null ? "" : billingData?.payment_type
    );
    formData.append("unpaid", unPaidData);
    formData.append("paid", paidData);
    formData.append("discount_per_user_total", disPerUser);
    formData.append("create_bill_user_id", userId);
    formData.append("sub_grand_total", total);
    formData.append("total_gst", totalGST);
    formData.append("charity_tax", charityTax);
    formData.append("charity_SL", charityTaxSL);
    formData.append("bill_invoice_no", billingData?.bill_invoice_no);
    // formData.append("gst_mode", taxMode ? "0" : "1");
    formData.append("tax_mode", taxMode ? "0" : "1");
    formData.append("event_hide", eventCheck ? "0" : "1");
    formData.append(
      "bill_type",
      billingData?.bill_type === "new"
        ? "old"
        : billingData?.bill_type === "regenerate"
        ? "oldregenerate"
        : ""
    );
    formData.append(
      "user_type",
      role == "0"
        ? "superadmin"
        : role == "1"
        ? "kitchen user"
        : role == "2"
        ? "sales user"
        : role == "3"
        ? "accounting user"
        : ""
    );
    formData.append(
      "white_total_dis",
      billingData?.whitehall_payment - disPerUser
    );
    formData.append("white_total", billingData?.whitehall_payment);
    formData.append("discount", disPerUser);
    formData.append(
      "spacial_dis",
      billingData?.special_dis === "" ? "" : billingData?.special_dis
    );
    if (file) {
      file.forEach((single) => formData.append("files[]", single));
    }
    if (settingPer?.tax_dis + 1 <= Number(disPer)) {
      const notify = () =>
        toast.error(
          "Maximum discount exceeded. Please review your discount settings and try again !",
          {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          }
        );
      notify();
    } else if (WHTotal < 0) {
      const notify = () =>
        toast.error("White Hall Payment should not be Minus", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (showError === "2") {
      const notify = () =>
        toast.error(
          "Total installment amount cannot exceed the total amount !",
          {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          }
        );
      notify();
    } else if (hasMissingFields) {
      const notify = () =>
        toast.error("All Installment Payment fields are required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      billingData?.reservationStatus === "FinalReserve" &&
      (billingData?.payment_type === "" || billingData?.payment_type === null)
    ) {
      const notify = () =>
        toast.error("Payment type is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      billingData?.reservationStatus === "Tentative" &&
      length < 1 === false &&
      (billingData?.payment_type === "" || billingData?.payment_type === null)
    ) {
      const notify = () =>
        toast.error("Payment type is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (showError === "0") {
      for (let pair of formData.entries()) {
        // console.log(pair[0] + ": " + pair[1]);
      }
      dispatch(EditBillData(formData, billingData?.bill_id, setLoading));
    }
  };
  useEffect(() => {
    if (res_add_data?.data?.status === true) {
      const notify = () =>
        toast.success(res_add_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setShow(1);
      setTimeout(() => {
        res_add_data.loading = true;
        res_add_data.data = {};
        res_get_data.loading = true;
        res_get_data.data = {};
        // if (location.state.type === "billing") {
        //   navigate("/billing");
        // } else if (location.state.type === "account") {
        //   navigate("/accounting_main");
        // }
      }, 1500);
    } else if (res_add_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_add_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_add_data.loading = true;
        res_add_data.data = {};
      }, 1500);
    }
  }, [res_add_data, navigate]);
  const backData = () => {
    res_add_data.loading = true;
    res_add_data.data = {};
    res_get_data.loading = true;
    res_get_data.data = {};
    if (location.state.type === "billing") {
      navigate("/billing");
    } else if (location.state.type === "account") {
      navigate("/accounting_main");
    }
  };

  return (
    <>
      {processing ? (
        <Loader />
      ) : (
        <>
          <Toaster />
          {show === 0 ? (
            <div className="">
              {/* <Header name="Billing" /> */}
              <div className=" container-fluid">
                <div className=" form-margin-768 padding-left-21">
                  <div className=" text-start mt-4 mt-sm-5 form-in-768">
                    <div className=" ms-md-0">
                      <div className="disply-manu-768 ">
                        <div className="row mb-3 width-100-768 ">
                          <div className="col-lg-4 col-12 ">
                            <label
                              for="basic-url"
                              className="form-label d-block text-start label"
                            >
                              FOOD AND DRINKS
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                value={
                                  role === "3"
                                    ? Number(
                                        Number(billingData?.m_price) +
                                          Number(billingData?.s_price)
                                      )?.toFixed(5)
                                    : Number(billingData?.menu_price)?.toFixed(
                                        5
                                      )
                                }
                                readOnly
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable justify-content-center ">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 width-second-768 ">
                          <div className="col-lg-4 col-12 ">
                            <label
                              for="basic-url"
                              className="form-label d-block text-start label "
                            >
                              DISCOUNT PER PERSON
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                name="discount_per_user"
                                value={disPer}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="disply-manu-768 ">
                        <div className="row mb-3 width-100-768 ">
                          <div className="col-lg-4 col-12">
                            <label
                              for="basic-url"
                              className="form-label label text-start d-block "
                            >
                              SERVICE CHARGE
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group  price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                name="service_charge"
                                value={billingData?.service_charge}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3 width-second-768">
                          <div className="col-lg-4 col-12">
                            <label
                              for="basic-url"
                              className="form-label label text-start d-block "
                            >
                              EXTRA COST
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group  price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                name="food_extra_cost"
                                value={billingData?.food_extra_cost}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="disply-manu-768 ">
                        {role === "3" && (
                          <div className="row mb-3 width-100-768 ">
                            <div className="col-lg-4 col-12">
                              <label
                                for="basic-url"
                                className="form-label label text-start d-block "
                              >
                                NUMBER OF GUESTS
                              </label>
                            </div>
                            <div className="col-lg-8 col-12">
                              <input
                                type="number"
                                className="form-control inpt inpt-number inpt-768"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                name="noOfGuests"
                                value={billingData?.noOfGuests}
                                // readOnly
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              {/* <div className="input-group  price-jod price-jod-768">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control inpt-border-trans"
                                  name="noOfGuests"
                                  value={billingData?.noOfGuests}
                                  //readOnly
                                   onChange={handleChange}
                                  autoComplete="off"
                                />
                                <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                  JOD
                                </span>
                              </div> */}
                            </div>
                          </div>
                        )}
                        {/* <div className="row mb-3 width-second-768">
                          <div className="col-lg-4 col-12">
                            <label
                              for="basic-url"
                              className="form-label label text-start d-block "
                            >
                              EXTRA COST
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group  price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                name="food_extra_cost"
                                value={billingData?.food_extra_cost}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-4 col-12">
                          <label
                            for="basic-url"
                            className="form-label  label text-black text-lg-white"
                          >
                            NOTES
                          </label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <div className="input-group  price-textarea">
                            <textarea
                              className=" form-control inpt-border-trans"
                              rows="6"
                              name="food_notes"
                              autoComplete="off"
                              placeholder="Why..."
                              onChange={handleChange}
                              value={billingData?.food_notes}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="disply-manu-768 ">
                        <div className="row mb-3 width-100-768 ">
                          <div className="col-lg-4 col-12 ">
                            <label
                              for="basic-url"
                              className="form-label d-block text-start label"
                            >
                              SOUND AND LIGHT COST
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                value={billingData?.sound_light_payment?.toFixed(
                                  5
                                )}
                                readOnly
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable justify-content-center ">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3 width-second-768 ">
                          <div className="col-lg-4 col-12 ">
                            <label
                              for="basic-url"
                              className="form-label d-block text-start label "
                            >
                              EXTRA COST
                            </label>
                          </div>
                          <div className="col-lg-8 col-12">
                            <div className="input-group price-jod price-jod-768">
                              <input
                                type="number"
                                min={0}
                                className="form-control inpt-border-trans"
                                name="sound_extra_cost"
                                value={billingData?.sound_extra_cost}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-4 col-12">
                          <label for="basic-url" className="form-label  label">
                            NOTES
                          </label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <div className="input-group  price-textarea">
                            <textarea
                              className=" form-control inpt-border-trans"
                              rows="6"
                              name="sound_notes"
                              autoComplete="off"
                              placeholder="Why..."
                              onChange={handleChange}
                              value={billingData?.sound_notes}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {role === "3" && (
                    <div className="form-in-768 mb-3">
                      <div className="row mb-2 mt-3">
                        <div className=" col-auto col-lg-4">
                          <label for="basic-url" className="form-label  label">
                            ADD TAX
                          </label>
                        </div>
                        <div className=" col-auto col-lg-8">
                          <div className="form-check ps-0">
                            <input
                              className="form-check-input cursor-pointer bg-transparant"
                              type="checkbox"
                              // role="switch"
                              // disabled
                              id="flexSwitchCheckDefault"
                              name="tax_mode"
                              checked={taxMode}
                              onChange={() => setTaxMode(!taxMode)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="">
                    <h3 style={{ fontWeight: 600 }} className="mb-4">
                      Payment
                    </h3>
                    <div className="form-in-768">
                      <h6>INSTALLMENT PAYMENT</h6>

                      {rowsData.map((data, index) => {
                        return (
                          <div key={index}>
                            <hr className="d-block d-sm-none" />
                            <div className="d-flex align-items-center">
                              <div className="col-10 col-lg-8 col-xl-6">
                                <div className="d-block d-sm-flex ">
                                  <div className="col-12 col-sm-6 pe-sm-2 pe-0">
                                    <label
                                      for="basic-url"
                                      className="form-label pe-5 payment-lable"
                                    >
                                      {rowsData[index]?.f_v_name} PAYMENT
                                    </label>
                                    <div className="input-group mb-3 price-payment price-payment-768 ">
                                      <input
                                        type="number"
                                        min={0}
                                        className="form-control inpt-border-trans "
                                        name="price"
                                        autoComplete="off"
                                        value={rowsData[index]?.price}
                                        onChange={(e) =>
                                          paymentHandleChange(e, index)
                                        }
                                      />
                                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                        JOD
                                      </span>
                                    </div>
                                  </div>

                                  <div className=" col-12 col-sm-6 pe-sm-2 pe-0">
                                    <label
                                      for="basic-url"
                                      className="form-label pe-5  payment-lable"
                                    >
                                      DATE
                                    </label>
                                    <div className="input-group mb-3 jod_width price-payment price-payment-768 ">
                                      <input
                                        type=""
                                        className="form-control inpt-border-trans "
                                        readOnly
                                        value={
                                          rowsData[index]?.date === null
                                            ? ""
                                            : rowsData[index]?.date
                                        }
                                      />
                                      <DatePicker
                                        selected={
                                          !rowsData[index]?.formatdate === null
                                            ? rowsData[index].formatdate
                                            : null
                                        }
                                        onChange={(date) =>
                                          paymentHandleChange(
                                            {
                                              target: {
                                                name: "formatdate",
                                                value: date,
                                              },
                                            },
                                            index
                                          )
                                        }
                                        className="form-control inpt-border-trans"
                                        showPopperArrow={false}
                                        name="formatdate"
                                        dateFormat="dd-MM-yyyy"
                                        customInput={
                                          <span>
                                            <FaCalendarDays className="cursor-pointer" />
                                          </span>
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {rowsData[index]?.paymentIndex === "0" ? (
                                <div className="col-2 col-sm-1 col-xl-6">
                                  <div className=" plus-icon ">
                                    <FaCirclePlus
                                      onClick={addTableRows}
                                      className="cursor-pointer"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="col-2 col-sm-4 col-xl-6">
                                  <div className="fs-1 ">
                                    <IoMdCloseCircle
                                      className="cursor-pointer"
                                      onClick={() => deleteTableRows(index)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {role === "3" && (
                    <div className="form-in-768 mb-3">
                      <div className="row mb-2 mt-3">
                        <div className=" col-auto col-lg-4">
                          <label for="basic-url" className="form-label  label">
                            MARK THIS EVENT TO BE HIDEN
                          </label>
                        </div>
                        <div className=" col-auto col-lg-8">
                          <div className="form-check form-switch ps-0">
                            <input
                              className="form-check-input on-off cursor-pointer"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              name="tax_mode"
                              checked={eventCheck}
                              onChange={() => setEventCheck(!eventCheck)}
                            />
                            {/* <input
                        className="form-check-input cursor-pointer bg-transparant"
                        type="checkbox"
                        // role="switch"
                        id="flexSwitchCheckDefault"
                        name="tax_mode"
                        checked={taxMode} // Set checked state based on tex_mode value
                        onChange={() => setTaxMode(!taxMode)}
                      /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="">
                    <div className=" mt-5 p-3 payment-box ">
                      <div className="form-in-768">
                        <div className="disply-manu-768">
                          <div className="row mb-3 width-100-768">
                            <div className="col-12 col-lg-5 ">
                              <label
                                for="basic-url"
                                className="form-label d-block text-start label "
                              >
                                WHITE HALL PAYMENT
                              </label>
                            </div>
                            <div className="col-12 col-lg-7 mt-auto mt-sm-1 mt-md-auto ">
                              <div className="input-group price-jod price-jod-768">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control inpt-border-trans"
                                  autoComplete="off"
                                  value={WHTotal?.toFixed(5)}
                                  readOnly
                                />
                                <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                  JOD
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-3 width-second-768">
                            <div className="col-lg-5 col-12 ">
                              <label
                                for="basic-url"
                                className="form-label d-block text-start label "
                              >
                                SPECIAL DISCOUNT
                              </label>
                            </div>
                            <div className="col-lg-7 col-12">
                              <div className="input-group price-jod price-jod-768">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control inpt-border-trans"
                                  name="special_dis"
                                  onChange={handleChange}
                                  // min={0}
                                  disabled={taxMode === true}
                                  // min={0}
                                  value={
                                    taxMode
                                      ? Number(
                                          Number(
                                            billingData?.special_dis ===
                                              undefined ||
                                              billingData?.special_dis === ""
                                              ? 0
                                              : billingData?.special_dis
                                          ) / 1.16
                                        ).toFixed(5)
                                      : Number(billingData?.special_dis)
                                  }
                                  // value={Number(billingData?.special_dis)}
                                  // readOnly
                                />
                                <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                  JOD
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="disply-manu-768">
                          {role === "3" && (
                            <>
                              <div className="row mb-3 width-100-768">
                                <div className="col-lg-5 col-12">
                                  <label
                                    for="basic-url"
                                    className="form-label label text-start d-block "
                                  >
                                    TAX
                                  </label>
                                </div>
                                <div className="col-12 col-lg-7">
                                  <div className="input-group price-jod price-jod-768">
                                    <input
                                      type="number"
                                      className="form-control inpt-border-trans"
                                      value={totalGST.toFixed(5)}
                                      readOnly
                                    />
                                    <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                      JOD
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div
                            className={`row mb-3 ${
                              role === "3"
                                ? "width-second-768"
                                : "width-100-768"
                            }`}
                          >
                            <div className="col-lg-5 col-12 ">
                              <label
                                for="basic-url"
                                className="form-label d-block text-start label "
                              >
                                GRAND TOTAL
                              </label>
                            </div>
                            <div className="col-lg-7 col-12">
                              <div className="input-group price-jod price-jod-768">
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control inpt-border-trans"
                                  value={totalMap?.toFixed(5)}
                                  readOnly
                                />
                                <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                  JOD
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-in-768">
                    <div className="row d-flex  mt-2 align-items-center  mb-3">
                      <div className="col-12 col-lg-3">
                        <label
                          for="basic-url"
                          className="form-label me-xxl-5 me-0   label"
                        >
                          PAYMENT TYPE
                        </label>
                      </div>
                      <div className="col-12 col-lg-9">
                        <div className="mt-2 row d-flex ms-2 ms-sm-auto">
                          <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex">
                            <input
                              className="form-check-input cursor-pointer mb-2"
                              type="radio"
                              name="payment_type"
                              value="1"
                              checked={billingData?.payment_type === "1"}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label check-label"
                              for="inlineRadio1"
                            >
                              Cash
                            </label>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ">
                            <input
                              className="form-check-input cursor-pointer mb-2"
                              type="radio"
                              name="payment_type"
                              value="2"
                              onChange={handleChange}
                              checked={billingData?.payment_type === "2"}
                            />
                            <label
                              className="form-check-label check-label"
                              for="inlineRadio2"
                            >
                              Cheque
                            </label>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ">
                            <input
                              className="form-check-input cursor-pointer mb-2"
                              type="radio"
                              name="payment_type"
                              value="3"
                              onChange={handleChange}
                              checked={billingData?.payment_type === "3"}
                            />
                            <label
                              className="form-check-label check-label"
                              for="inlineRadio3"
                            >
                              Bank Transfer
                            </label>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-6 col-xxl-3 form-check form-check-inline m-0 d-flex ">
                            <input
                              className="form-check-input cursor-pointer mb-2"
                              type="radio"
                              name="payment_type"
                              value="4"
                              onChange={handleChange}
                              checked={billingData?.payment_type == "4"}
                            />
                            <label
                              className="form-check-label check-label"
                              for="inlineRadio3"
                            >
                              E-Payment
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex  mt-2 align-items-center  ">
                      <div className="col-12 col-lg-3">
                        <label
                          for="basic-url"
                          className="form-label me-xxl-5 me-0   label"
                        >
                          UPLOAD
                        </label>
                      </div>
                      <div className="col-12 col-lg-9">
                        <div className="row d-flex">
                          <div className="col-auto">
                            <div
                              {...getRootProps({ className: "dropzone" })}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className="input-group jod_width_cal price cal_width price-upload-768"
                                id="upload"
                              >
                                <input
                                  type="file"
                                  className="d-none"
                                  id="upload"
                                />
                                <input {...getInputProps()} />
                                <input
                                  className="form-control inpt-border-trans text-info text-decoration-underline"
                                  value={"Attach File"}
                                  style={{ cursor: "pointer" }}
                                  readOnly
                                  // disabled
                                  onChange={(e) =>
                                    setFile(parseInt(e.target.value) || 0)
                                  }
                                />
                                <span className="input-group-text inpt-border-trans inpt-lable justify-content-center">
                                  <FiPaperclip
                                    className="fs-5"
                                    // onClick={incNum}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex  mt-2 align-items-center  mb-3">
                      <div className="col-12 col-lg-3"></div>
                      <div className="col-12 col-lg-9">
                        <div className="row d-flex justify-content-center justify-content-lg-start">
                          {filePrev &&
                            filePrev.map((fileArray, index) => (
                              <div style={thumb} key={index}>
                                <div style={thumbInner}>
                                  {fileArray[0].endsWith(".pdf") ? (
                                    <img
                                      src={pdf}
                                      style={img}
                                      onLoad={() => {
                                        URL.revokeObjectURL(fileArray[0]);
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={`http://whitehall.website/api/uploads/bill/${fileArray[0]}`}
                                      style={img}
                                      onLoad={() => {
                                        URL.revokeObjectURL(fileArray[0]);
                                      }}
                                      alt={fileArray[0]}
                                    />
                                  )}
                                </div>
                              </div>
                            ))}

                          {thumbs.map((file, index) => (
                            <div style={thumb} key={file.name}>
                              <div style={thumbInner}>
                                {file.name.endsWith(".pdf") ? (
                                  <img
                                    src={pdf}
                                    style={img}
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={file.preview}
                                    style={img}
                                    onLoad={() => {
                                      URL.revokeObjectURL(file.preview);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-in-768 ">
                    <div className="mt-xxl-3 mt-5 pt-xxl-4 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section position-relative display-above-768">
                      {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={() => setShowData(3)}
                  // onClick={backData}
                >
                  BACK
                </button> */}
                      {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={resetData}
                >
                  RESET
                </button> */}
                      {loading ? (
                        <button
                          type="button"
                          className="btn btn-b me-2 mb-4 btn-768"
                        >
                          <span className="spinner-border disable-spinner me-2"></span>
                          SUBMIT
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-b me-2 mb-4 btn-768"
                          onClick={handleSubmit}
                        >
                          SUBMIT
                        </button>
                      )}
                    </div>
                    <div className="mt-xxl-3 mt-5 pt-xxl-4 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section position-relative display-for-768">
                      {loading ? (
                        <button
                          type="button"
                          className="btn btn-b me-2 mb-4 btn-768"
                          disabled
                        >
                          <span className="spinner-border disable-spinner me-2"></span>
                          SUBMIT
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-b me-2 mb-4 btn-768"
                          onClick={handleSubmit}
                        >
                          SUBMIT
                        </button>
                      )}
                      {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={resetData}
                >
                  RESET
                </button> */}
                      {/* <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={() => setShowData(3)}
                >
                  BACK
                </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : show === 1 ? (
            <ContractPdf
              submitEventID={id.id}
              clientName={location.state.clientName}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_data: state?.Get_Edit_Billing_reducer,
  res_add_data: state?.Edit_Billing_reducer,
});
export default connect(mapStateToProps)(EditSalesBilling);
