import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { AddSoundLight, getToken } from "../../../../Store/Action/useFetchData";
const SoundAndLightModel = ({ dispatch, res_Sound,show,setShow }) => {
    const [loading,setLoading]=useState(false)
  const [Whitehall, setWhiteHall] = useState({
    package_name: "",
    package_price: "",
  });
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setWhiteHall({ ...Whitehall, [name]: value });
  };
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  const handlesubmit = () => {
  
      const { package_name, package_price } = Whitehall;
      const submitData = {
        company_type: "0",
        package_name: package_name,
        package_price: package_price,
        company_name: "",
        sound_light_cost: "",
        extra_sound_light_cost: "",
        sound_light_notes: "",
      };
      dispatch(AddSoundLight(submitData, setLoading));
  };
  useEffect(() => {
    if (res_Sound?.data?.status === true) {
      const notify = () =>
        toast.success(res_Sound?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_Sound.loading = true;
        res_Sound.data = {};
        setShow(false)
      }, 1500);
    } else if (res_Sound?.data?.status === false) {
      const notify = () =>
        toast.error(res_Sound?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_Sound.loading = true;
        res_Sound.data = {};
      }, 1500);
    }
  }, [res_Sound]);
  return (
    <>
      <div>
        {" "}
        <Toaster />
        <div
          className={`modal${
            show ? " fade show d-block " : " d-none"
          }`}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
            <div className="modal-content">
              <div className="modal-header modal-header-role">
                <h4 className="modal-title text-center fw-bold">
                  Sound and Light
                </h4>
                <button
                  type="button"
                  className="btn-close ms-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={()=>{
                    setShow(false)
                    res_Sound.loading = true;
                    res_Sound.data = {};
                  }}
                ></button>
              </div>
       
                <div className="modal-body modal-padd">                
                      <div className="row mb-2 mt-3 ">
                        <div className="col-lg-4 col-12">
                          <label for="basic-url" className="form-label  label">
                            {" "}
                            PACKAGE NAME
                          </label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control inpt inpt-768"
                            aria-label="Username"
                            name="package_name"
                            value={Whitehall?.package_name}
                            onChange={handleChange}
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label for="basic-url" className="form-label  label">
                            PACKAGE PRICE
                          </label>
                        </div>

                        <div className="col-lg-8 col-12">
                          <div className="input-group mb-2 price-jod price-jod-768">
                            <input
                              type="number"
                              autoComplete="off"
                              className="form-control inpt-border-trans"
                              name="package_price"
                              value={Whitehall?.package_price}
                              onChange={handleChange}
                            />
                            <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                              JOD
                            </span>
                          </div>
                        </div>
                      </div>
                   
                </div>
           
              <div className="modal-footer ">
                {loading ? (
                  <button
                    type="button"
                    className="btn btn-b me-2  mb-4 btn-768 "
                  >
                    <span className="spinner-border disable-spinner me-2"></span>
                    ADD
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2  mb-4 btn-768 "
                    onClick={handlesubmit}
                  >
                    ADD
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_Sound: state?.Add_sound_light_reducer,
});

export default connect(mapStateToProps)(SoundAndLightModel);
