import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import { AllUserNameData, getToken } from "../../Store/Action/useFetchData";
import { connect } from "react-redux";
import Pusher from "pusher-js";
const Transction = ({ dispatch, res_user_name }) => {
  const [filterData, setFilterData] = useState({
    startDate: "",
    eventType: "",
    createdBy: "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [finalDate, setFinalDate] = useState();
  const [selection, setSelection] = useState("EVENT TYPE");
  const [selection1, setSelection1] = useState("CREATED BY");
  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [length, setLength] = useState(0)
  const [socket, setSocket] = useState();
  const sidebarRef = useRef(null);
  const sidebarRef1 = useRef(null);
  const options = [
    { value: "Wedding", label: "Wedding" },
    { value: "Corporate", label: "Corporate" },
    { value: "Concert", label: "Concert" },
    { value: "Private", label: "Private" },
    { value: "Charity", label: "Charity" },
  ];

  useEffect(() => {
    dispatch(getToken());
    dispatch(AllUserNameData());
  }, []);
  useEffect(() => {
    TableDatatablesManaged.init();
  }, [filterData]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");

    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  const userOptions =
    res_user_name?.data?.user_data?.map((user) => ({
      value: user.id.toString(),
      label: user.name,
    })) || [];

  const handleClose = (e) => {
    setState(false);
    if (e.label !== selection && e.label !== "") {
      setSelection(e?.label);
      setFilterData({ ...filterData, eventType: e?.label });
    }
  };

  const handleClose1 = (e) => {
    setState1(false);
    if (e.label !== selection1 && e.label !== "") {
      setSelection1(e?.label);
      setFilterData({ ...filterData, createdBy: e?.label });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
      if (sidebarRef1.current && !sidebarRef1.current.contains(event.target)) {
        setState1(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setState, setState1]);

  const handleDateChange = (e) => {
    const dateString = e;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setSelectedDate(date);
    setFilterData({ ...filterData, startDate: `${year}-${month}-${day}` });
    setFinalDate(`${day}-${month}-${year}`);
  };

  const api_token = sessionStorage.getItem("api_token");
  const id = sessionStorage.getItem("member_id");

  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showReporting");
      var table1 = $("#showReporting").DataTable({
        columnDefs: [{ orderable: false, targets: 6 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: `/api/AllEvenBillTransaction`,
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          dataSrc: "user_data",
          data: {
            eventType: filterData?.eventType,
            name: filterData?.createdBy,
          },
        },

        columns: [
          { data: "event_id", width: "6%" },
          { data: "clientName", width: "12%" },
          { data: "created_user", width: "12%" },
          {
            data: "edited_user", width: "12%",
            render: function (data) {
              if (data === "") {
                return "-";
              } else {

                return data;
              }
            },
          },

          {
            data: "updated_at",
            width: "15%",
            render: function (data) {
              if (data === "") {
                return "-";
              } else {
                const date = new Date(data);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              }
            },
          },
          {
            data: "old_value",
            width: "20%",
            render: function (data) {
              const formattedData = Object.entries(data)
              .filter(([key, value]) => key !== 'updated_at' && key !== 'create_bill_user_id' && key != 'food_notes' && key != 'sound_notes' &&  key != 'grand_total' && key != 'paid'&& key != 'unpaid' && key != 'sub_grand_total' && key != 'tax_mode' && key != 'installment_payments' && key != 'extra_payment' && key != 'noOfGuests' && key != 'gst_mode')
              .map(([key, value]) => {
                if (key === 'total_gst') {
                  key = 'total tax';
                }
                return `${key}: ${value}`;  
              })
              .join("<br>");
            return formattedData;
            

            },
          },
          {
            data: "new_value",
            width: "25%",
            render: function (data) {
              const formattedData = Object.entries(data)
              .filter(([key, value]) => key !== 'updated_at' && key !== 'create_bill_user_id' && key != 'food_notes' && key != 'sound_notes' &&  key != 'grand_total' && key != 'paid'&& key != 'unpaid' && key != 'sub_grand_total' && key != 'tax_mode' && key != 'installment_payments' && key != 'extra_payment' && key != 'noOfGuests' && key != 'gst_mode' )
              .map(([key, value]) => {
                if (key === 'total_gst') {
                  key = 'total tax';
                }
                return `${key}: ${value}`;
              })
              .join("<br>");
            return formattedData;
          
            },
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength)
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showReporting_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();

  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });

  return (
    <>
     <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ?
            `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }

  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
    }.table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }.table td:nth-child(6) {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #cccccc;
        }
td:nth-of-type(1):before {
  ${length !== 0 ?
            `content: "#";` :
            ``
          }
 
}
td:nth-of-type(2):before {
  content: "Name of reserver";
 
}
td:nth-of-type(3):before {
  content: "Created user";
}
td:nth-of-type(4):before {
  content: "Updated user";
}
td:nth-of-type(5):before {
  content: "Date of change";
}
td:nth-of-type(6):before {
  content: "Old";
}
td:nth-of-type(7):before {
  content: "New change";
}
}
`}
      </style>
      <Toaster />
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 position-relative">
              <div className="container">
                <div className="row d-flex row-7 justify-content-center mt-4 mt-lg-auto">
                  <div className="col-12 col-sm-4 col-lg-6  col-xl-4 mb-3 mb-xl-5">
                    <div className="row d-flex">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label  d-none d-lg-block"
                        >
                          EVENT TYPE
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div className="" ref={sidebarRef}>
                          <div
                            className="form-select calender-select inpt inpt-768 w-auto"
                            onClick={() => setState(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <option
                              className="overflow-hidden"
                              style={{ padding: "4px" }}
                            >
                              {selection}
                            </option>
                          </div>
                          <div
                            className={`${state
                              ? "d-block select-box1 m-1 scrollbar-thin scroll-table scroll-main"
                              : "d-none"
                              }`}
                          >
                            {options?.map((item, index) => {
                              return (
                                <div key={index} className="p-1 py-0">
                                  <div
                                    onClick={(e) => handleClose(item)}
                                    className="select-box-option text-white"
                                  >
                                    {item.label}
                                  </div>
                                  <div className="" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-lg-6  col-xl-4 mb-2 mb-xl-4">
                    <div className="row d-flex">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label  d-none d-lg-block"
                        >
                          CREATED BY
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div className="" ref={sidebarRef1}>
                          <div
                            className="form-select calender-select inpt inpt-768 w-auto"
                            onClick={() => setState1(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <option
                              className="overflow-hidden"
                              style={{ padding: "4px" }}
                            >
                              {selection1}
                            </option>
                          </div>
                          <div
                            className={` ${state1
                              ? "d-block select-box1 m-1 scrollbar-thin scroll-table scroll-main"
                              : "d-none"
                              }`}
                          >
                            {userOptions?.length ? (
                              userOptions?.map((item, index) => {
                                return (
                                  <div key={index} className="p-1 py-0">
                                    <div
                                      onClick={(e) => handleClose1(item)}
                                      className="select-box-option text-white"
                                    >
                                      {item.label}
                                    </div>
                                    <div className="" />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="p-1 py-0">
                                <div
                                  className="select-box-option-nodata text-center"
                                  style={{ cursor: "default" }}
                                >
                                  No Data Found
                                </div>
                                <div className="" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="role-table">
                <table
                  id="showReporting"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>

                      {/* <th className="thead-color" scope="col">
                      Bill Edited user
                      </th> */}
                      <th className="thead-color" scope="col">
                        Name of reserver
                      </th>

                      <th className="thead-color" scope="col">
                        Created user
                      </th>
                      <th className="thead-color" scope="col">
                        Updated user
                      </th>
                      <th className="thead-color" scope="col">
                        Date of change
                      </th>
                      <th className="thead-color" scope="col">
                        Old
                      </th> <th className="thead-color" scope="col">
                        New change
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  // res_pdf_data: state?.Admin_reports_PDF_Data_reducer,
  res_user_name: state?.Add_user_name_data_reducer,
});

export default connect(mapStateToProps)(Transction);