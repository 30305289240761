import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import Pusher from "pusher-js";
const BillingTable = ({ dispatch, res_token }) => {
  const navigate = useNavigate();
  const [length, setLength] = useState(0);
  const [socket, setSocket] = useState();
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    // console.log(channel,"channel");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log(socket,"socket");
      socket.bind("tax_mode_on", async (data) => {
        window.location.reload();
      });
    }
  }, [socket]);
  const role = sessionStorage.getItem("role");
  const api_token = sessionStorage.getItem("api_token");
    var TableDatatablesManaged = (function () {
      var initTable1 = function () {
        var customLoader = $(
          '<div class="row justify-content-center "><span class="loader"></span></div>'
        ).appendTo("#showBill");
        var table1 = $("#showBill").DataTable({
          columnDefs: [{ orderable: false, targets: 6 }],
          autoWidth: false,
          processing: false,
          serverSide: true,
          destroy: true,
          language: {
            search: "",
            searchPlaceholder: "Search...",
            paginate: {
              next:
                window.innerWidth > 768
                  ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                  : '<span class="fw-bold text-black n-float">NEXT ></span>',
              previous:
                window.innerWidth > 768
                  ? '<i class="bi bi-caret-left-fill"></i>'
                  : '<span class="fw-bold p-float">< PREVIOUS</span>',
            },
          },
          ajax: {
            url: "/api/all_event_data",
            headers: {
              Authorization: "Bearer " + api_token,
            },
            type: "GET",
            dataSrc: "user_data",
          },
          columns: [
            {
              data: "eventID",
              width: "6%",
            },
            {
              data: "clientName",
              width: "19%",
            },
            {
              data: "clientPhone",
              width: "19%",
            },
            {
              data: "startDate",
              width: "19%",
            },
            {
              data: "eventType",
              width: "19%",
            },
            {
              data: "noOfGuests",
              width: "19%",
            },
            {
              data: null,
              width: "10%",
              render: function (data) {
                return (
                  '<div class="d-flex line-height"><span  id="createBill" value="' +
                  data.eventID +
                  '"><i class="bi bi-receipt fs-4 ps-2 me-0 text-primary cursor-pointer"></i><i class="fa-sharp fa-thin fa-file-invoice "></i></span> </div>'
                );
              },
              sorting: false,
            },
            // {
            //   data: null,
            //   width: "18%",
            //   render: function (data) {
            //     //   console.log(data, "data");

            //     return (
            //       '<div class="d-flex"><span  id="viewMenu" value="' +
            //       data.id +
            //       '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editMenu" value="' +
            //       data.id +
            //       '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteMenu" value="' +
            //       data.id +
            //       '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
            //     );
            //   },
            // },
          ],

          select: {
            style: "multi",
            selector: "td:first-child",
          },
          order: [[0, "desc"]],
          drawCallback: function () {
            var tableLength = table1.rows().count();
            // console.log("Table length:", tableLength);
            setLength(tableLength);
          },
        });
        table1.on("xhr.dt", function () {
          customLoader.css("display", "none");
        });
        $("#showBill tbody").on("click", "#createBill", function () {
          var row = $(this).closest("tr");
          const rowData = table1.row(row).data();

          if (rowData && rowData?.bill_id !== null) {
            // console.log("Edit bill");
            if (rowData && rowData?.userRole === "2") {
              rowData.eventID &&
                navigate(`/sale_edit_billing/${rowData.eventID}`, {
                  state: { type: "billing", clientName: rowData.clientName },
                });
            } else {
              rowData.eventID &&
                navigate(`/edit_billing/${rowData.eventID}`, {
                  state: { type: "billing", clientName: rowData.clientName },
                });
            }
          }
          if (rowData && rowData?.bill_id === null) {
            rowData.eventID &&
              navigate(`/create_billing/${rowData.eventID}`, {
                state: {
                  value: "billing",
                  clientName: rowData.clientName,
                  billType: "new",
                },
              });
          }
        });
        $("#showBill_processing").css("display", "none");
      };
      return {
        init: function () {
          if (!$().dataTable) {
            return;
          }
          initTable1();
        },
      };
    })();
    $(document).ready(function () {
      $.fn.dataTableExt.sErrMode = "throw";
      // TableDatatablesManaged.init();
    });

  return (
    <>
      <style>
        {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "Client Name";
           
          }
          td:nth-of-type(3):before {
            content: "Client Phone";
          }
          td:nth-of-type(4):before {
            content: "Event Date";
          }
          td:nth-of-type(5):before {
            content: "Event Type";
          }
          td:nth-of-type(6):before {
            content: "No. of Guests";
          }
          td:nth-of-type(7):before {
            content: "Action";
          }
          }
        `}
      </style>
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="role-table">
                <table
                  id="showBill"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Client Name
                      </th>
                      <th className="thead-color" scope="col">
                        Client Phone
                      </th>

                      <th className="thead-color" scope="col">
                        Event Date
                      </th>
                      <th className="thead-color" scope="col">
                        Event Type
                      </th>
                      <th className="thead-color" scope="col">
                        No. of Guests
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(BillingTable);
