import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  getAllExtrasData,
  getToken,
} from "../../../../Store/Action/useFetchData";
import { connect } from "react-redux";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightDoubleLine } from "react-icons/ri";
const EditExtrasEvent = ({
  setselectedMenu,
  setGetData,
  getData,
  dispatch,
  res_get_extras,
  handleFormSubmit,
  loading,
}) => {
  useEffect(() => {
    dispatch(getToken());
    // dispatch(getAllExtrasData());
  }, [dispatch]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setGetData((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };

  const handleReset = () =>{
    setGetData({
      ...getData,
      extrasNotes: "",
    waiters: "",
    napkins: "",
    contentOfScreens: "",
    liveStream: "",
    band: "",
    singer: "",
    ushers: "",
    generalNotes: "",
    })
  }
  return (
    <>
      <div className=" form-margin  padding-left-21 position-relative form-margin-768">
        <div className="  ">
          <h3 className="mb-4 mt-3 fw-bold  pt-3 pt-sm-0">Extras</h3>

          <div className="row mb-3 mt-3 ">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                WAITERS
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="number"
                autoComplete="off"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="waiters"
                value={Number(getData?.waiters)}
                onChange={handleChange}
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                NAPKINS
              </label>
            </div>

            <div className="col-12 col-lg-8">
            <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="napkins"
                value={getData?.napkins}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                CONTENT OF SCREENS
              </label>
            </div>
            <div className="col-12 col-lg-8">
            <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="contentOfScreens"
                value={getData?.contentOfScreens}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                LIVE STREAM
              </label>
            </div>

            <div className="col-12 col-lg-8">
            <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="liveStream"
                value={getData?.liveStream}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                BAND
              </label>
            </div>

            <div className="col-12 col-lg-8">
            <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="band"
                value={getData?.band}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                SINGER
              </label>
            </div>

            <div className="col-12 col-lg-8">
            <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="singer"
                value={getData?.singer}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                USHERS
              </label>
            </div>

            <div className="col-12 col-lg-8">
            <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="ushers"
                value={getData?.ushers}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row mb-3  ">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                GENERAL NOTES
              </label>
            </div>

            <div className="col-12 col-lg-8">
              <div className="input-group  price-textarea">
                <textarea
                 autoComplete="off"
                  className=" form-control inpt-border-trans"
                  id="exampleFormControlTextarea1"
                  rows="6"
                  name="generalNotes"
                  value={getData.generalNotes}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={() => setselectedMenu(3)}
              >
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={handleReset}
              >
                RESET
              </button>
              <button
                type="button"
                className="btn btn-b me-2 mb-4 btn-768"
                onClick={() => {setselectedMenu(7)
                  handleFormSubmit()
                }
                
                }
              >
                NEXT
                <RiArrowRightDoubleLine className="fs-5" />
              </button>
            </div>

            <div className={`next-btn mt-5  fixed-bottom`}>
            <div
              className=" d-flex justify-content-between p-2 "
              style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
            >
              <div className="d-flex">
                <RiArrowLeftSLine className=" fs-2 mt-1" />
                <p
                  className=" text-dark pt-1 mb-0 "
                  onClick={() => setselectedMenu(3)}
                >
                  PREVIOUS
                </p>
              </div>
              <div className="d-flex">
                <p
                  className=" text-dark pt-1 mb-0 "
                  onClick={() => {setselectedMenu(7)
                    handleFormSubmit()
                  
                  }}
                >
                  NEXT
                </p>
                <RiArrowRightSLine className=" fs-2 mt-1" />
              </div>
            </div>
          </div>
       
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  // res_get_extras: state?.Get_all_extras_reducer,
});
export default connect(mapStateToProps)(EditExtrasEvent);