import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GetSuppliersData, getToken } from "../../../Store/Action/useFetchData";
import MiniLoader from "../../../MiniLoader";

const ViewSuppliersData = ({
  viewSupplierModal,
  setViewSupplierModel,
  viewSupplierModalId,
  dispatch,
  res_supplier_data,
}) => {
  const [supplierData, setSupplierData] = useState({
    planner_name: "",
    planner_price: "",
    photo_name: "",
    photo_price: "",
    flower_name: "",
    flower_price: "",
    package_name: "",
    package_price: "",
  });
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetSuppliersData(viewSupplierModalId));
  }, [viewSupplierModalId, dispatch]);
  useEffect(() => {
    if (viewSupplierModalId && res_supplier_data?.data?.status === true) {
      setSupplierData({
        planner_name: res_supplier_data?.data?.data?.planner_name,
        planner_price: res_supplier_data?.data?.data?.planner_price,
        photo_name: res_supplier_data?.data?.data?.photographer_name,
        photo_price: res_supplier_data?.data?.data?.photographer_price,
        flower_name: res_supplier_data?.data?.data?.flower_planner_name,
        flower_price: res_supplier_data?.data?.data?.flower_planner_price,
        package_name: res_supplier_data?.data?.data?.furniture_package_name,
        package_price: res_supplier_data?.data?.data?.furniture_package_price,
      });
    }
  }, [viewSupplierModalId, res_supplier_data]);
  const handleClose = () => {
    setViewSupplierModel(false);
    res_supplier_data.loading = true;
    res_supplier_data.data = {};
  };
  return (
    <>
      <div
        className={`modal ${
          viewSupplierModal ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">View Supplier</h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_supplier_data?.loading ? (
              <MiniLoader />
            ) : (
              <div className=" modal-body modal-padd">
                <h3 className="mb-4 mt-5 ms-md-0 ms-sm-3 fw-bold">
                  Wedding Planner
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        PLANNER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="planner_name"
                        value={supplierData?.planner_name}
                        readOnly
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label label">
                        PLANNER PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group mb-3 price-jod price-jod-768">
                        <input
                          type="number"
                          name="planner_price"
                          value={supplierData?.planner_price}
                          readOnly
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Photographer
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label  label">
                        PHOTOGRAPHER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="photo_name"
                        value={supplierData?.photo_name}
                        readOnly
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4  ">
                      <label for="basic-url" className="form-label label ">
                        PHOTOGRAPHER PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group price-jod price-jod-768">
                        <input
                          type="number"
                          name="photo_price"
                          value={supplierData?.photo_price}
                          readOnly
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Flowers Planner
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        FLOWER PLANNER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="flower_name"
                        value={supplierData?.flower_name}
                        readOnly
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label label ">
                        FLOWER PLANNER PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group price-jod price-jod-768">
                        <input
                          type="number"
                          name="flower_price"
                          value={supplierData?.flower_price}
                          readOnly
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="mb-4 mt-3 mt-sm-2 ms-sm-3 ms-md-0 fw-bold">
                  Furniture
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        FURNITURE PACKAGE NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="package_name"
                        value={supplierData?.package_name}
                        readOnly
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label label">
                        PACKAGE PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group price-jod price-jod-768">
                        <input
                          type="number"
                          name="package_price"
                          value={supplierData?.package_price}
                          readOnly
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_supplier_data: state?.Get_suppliers_data_reducer,
});

export default connect(mapStateToProps)(ViewSuppliersData);
