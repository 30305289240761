import React from 'react'

const MiniLoader = () => {
  return (
    <>
       <div className="container-fluid bg-white" style={{height:"60vh"}}>
        <div className="row justify-content-center">
          <div className="col-1">
            <span className="loader"></span>
          </div>
        </div>
      </div>
    </>
  )
}

export default MiniLoader
