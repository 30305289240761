import React, { useEffect, useState } from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { FaRegClock } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import dayjs from "dayjs";
const EditInformationEvent = ({ setselectedMenu, setGetData, getData }) => {
  const [eventDate, setEventDate] = useState("");
  const [eventDate1, setEventDate1] = useState("");
  const [eventDate2, setEventDate2] = useState("");
  const [eventDate3, setEventDate3] = useState("");
  const [eventDate4, setEventDate4] = useState("");
  const [eventDate5, setEventDate5] = useState("");
  const [eventDate6, setEventDate6] = useState("");


  useEffect(() => {
    if (getData) {
      const dateFields = [
        { field: "timeToStart", stateSetter: setEventDate },
        { field: "timeOfArrival", stateSetter: setEventDate1 },
        { field: "firstGateEntryTime", stateSetter: setEventDate2 },
        { field: "zaffaTime", stateSetter: setEventDate3 },
        { field: "cakeShowTime", stateSetter: setEventDate4 },
        { field: "buffetTime", stateSetter: setEventDate5 },
        { field: "secondGateEntryTime", stateSetter: setEventDate6 },

        // Add other date fields here with their respective state setters
      ];

      dateFields.forEach(({ field, stateSetter }) => {
        if (getData[field]) {
          const [hours, minutes, seconds] = getData[field].split(":");
          const date = dayjs().set('hour', parseInt(hours)).set('minute', parseInt(minutes)).set('second', parseInt(seconds));
          stateSetter(date);
        }
      });
    }
  }, [getData]);


  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setGetData((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };
  const handlePhoneChange = (e) => {
    setGetData((prevTime) => ({
      ...prevTime,
      zaffaArtistPhoneNumber: e,
    }));
  };


  const handleTimeChange = (newTime, fieldName) => {
    // Extract hour, minute, and second components from the Day.js object
    const hours = newTime.hour();
    const minutes = newTime.minute();
    const seconds = newTime.second();

    // Format the time components with leading zeros if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Construct the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    // Update the state with the formatted time string
    setGetData((prevTime) => ({
      ...prevTime,
      [fieldName]: formattedTime,
    }));
  };
  // console.log(typeof eventDate, eventDate, "kkk");
 
  
  
  
  
  const handleReset = () => {
    //   if (timePickerInputs) {
    //     // Clear the value of the input element
    //     timePickerInputs.forEach(input => {
    //       input.value = '';
    //     });
    // console.log(timePickerInputs,'timePickerRef');

    //   }
    setGetData({
      ...getData,
      timeToStart: "",
      timeOfArrival: "",
      firstGateEntryTime: "",
      zaffaTime: "",
      zaffaArtistName: "",
      zaffaArtistPhoneNumber: "",
      cakeShowTime: "",
      buffetTime: "",
      secondGateEntryTime: "",
      cakeSource: "",
    });
  };
  // console.log(getData, "getData");
  return (
    <>
      <div className=" form-margin padding-left-21  form-margin-768 mb-5 pb-1">
        <div className="">
          <h3 className="mb-4 mt-3 fw-bold pt-3 pt-sm-0">
            Information of the event
          </h3>
          <div className="d-flex row">
            <div className="col-12 col-sm-6 col-lg-12">
              <div className="row mb-3 ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    TIME TO START THE EVENT
                  </label>
                </div>
                <div className="col-12 col-lg-8" id="time-picker">
                  <div className="input-group time-price  time-price-768 justify-content-between">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileTimePicker"]}>
                        <DemoItem>
                          <MobileTimePicker
                            value={dayjs(eventDate)}
                            name="timeToStart"
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "timeToStart")
                            }
                            className="text-info"
                          />
                        </DemoItem>
                      </DemoContainer>
                      <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                        <FaRegClock className="fs-3" />
                      </span>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-12">
              <div className="row mb-3 ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    TIME OF ARRIVAL
                  </label>
                </div>
                <div className="col-12 col-lg-8" id="time-picker">
                  <div className="input-group time-price time-price-768 justify-content-between">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileTimePicker"]}>
                        <DemoItem>
                          <MobileTimePicker
                            value={dayjs(eventDate1)}
                            name="timeOfArrival"
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "timeOfArrival")
                            }
                            className="text-info"
                          />
                        </DemoItem>
                      </DemoContainer>
                      <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                        <FaRegClock className="fs-3" />
                      </span>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex row">
            <div className="col-12 col-sm-6 col-lg-12">
              <div className="row mb-3 ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    FIRST GATE ENTRY TIME
                  </label>
                </div>
                <div className="col-12 col-lg-8" id="time-picker">
                  <div className="input-group time-price  time-price-768 justify-content-between">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileTimePicker"]}>
                        <DemoItem>
                          <MobileTimePicker
                            value={dayjs(eventDate2)}
                            name="firstGateEntryTime"
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "firstGateEntryTime")
                            }
                            className="text-info"
                          />
                        </DemoItem>
                      </DemoContainer>
                      <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                        <FaRegClock className="fs-3" />
                      </span>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-12">
              {getData?.eventType === "Wedding" && (
                <div className="row mb-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      ZAFFA TIME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8" id="time-picker">
                    <div className="input-group time-price time-price-768 justify-content-between">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["MobileTimePicker"]}>
                          <DemoItem>
                            <MobileTimePicker
                              value={dayjs(eventDate3)}
                              name="zaffaTime"
                              onChange={(newTime) =>
                                handleTimeChange(newTime, "zaffaTime")
                              }
                              className="text-info"
                            />
                          </DemoItem>
                        </DemoContainer>
                        <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                          <FaRegClock className="fs-3" />
                        </span>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {getData?.eventType === "Wedding" && (
            <div className="row mb-3 ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  NAME OF ZAFFA ARTIST/ PHONE NUMBER
                </label>
              </div>
              <div
                className="col-12 col-lg-8 d-block d-xl-flex"
                id="time-picker"
              >
                <div className="input-group time-price time-price-768 justify-content-between border-1">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control inpt border  inpt-768"
                    aria-describedby="basic-addon1"
                    name="zaffaArtistName"
                    value={getData?.zaffaArtistName}
                    onChange={handleChange}
                  />
                </div>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={getData?.zaffaArtistPhoneNumber}
                  country={"jo"}
                  className=" check-phone   inpt-768 mt-3 mt-xl-0 ms-0 ms-xl-4"
                  onChange={handlePhoneChange}
                />
              </div>
            </div>
          )}
          <div className="d-flex row">
            <div className="col-12 col-sm-6 col-lg-12">
              <div className="row mb-3 ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    TIME OF THE CAKE SHOW
                  </label>
                </div>
                <div className="col-12 col-lg-8" id="time-picker">
                  <div className="input-group time-price justify-content-between time-price-768">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileTimePicker"]}>
                        <DemoItem>
                          <MobileTimePicker
                            value={dayjs(eventDate4)}
                            name="cakeShowTime"
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "cakeShowTime")
                            }
                            className="text-info"
                          />
                        </DemoItem>
                      </DemoContainer>
                      <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                        <FaRegClock className="fs-3" />
                      </span>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 ">
            <div className=" col-lg-4 d-none d-lg-block">
              <label for="basic-url" className="form-label  label"></label>
            </div>
            <div className="col-12 col-lg-8" id="time-picker">
              <div className="">
                <div className="form-check form-check-inline  ps-0">
                  <input
                    className="form-check-input cursor-pointer"
                    type="radio"
                    autoComplete="off"
                    name="cakeSource"
                    value="0"
                    checked={String(getData?.cakeSource) === "0"}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label check-label"
                    for="inlineRadio1"
                  >
                    Whitehall Cake
                  </label>
                </div>
                <div className="form-check form-check-inline ps-0 ">
                  <input
                    autoComplete="off"
                    className="form-check-input cursor-pointer"
                    type="radio"
                    name="cakeSource"
                    value="1"
                    checked={String(getData?.cakeSource) === "1"}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label check-label"
                    for="inlineRadio2"
                  >
                    Outsource Cake
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 ">
            <div className="col-12 col-sm-6 col-lg-12">
              <div className="row mb-3 ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    BUFFET TIME
                  </label>
                </div>
                <div className="col-12 col-lg-8" id="time-picker">
                  <div className="input-group time-price justify-content-between  time-price-768">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileTimePicker"]}>
                        <DemoItem>
                          <MobileTimePicker
                            value={dayjs(eventDate5)}
                            name="buffetTime"
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "buffetTime")
                            }
                          />
                        </DemoItem>
                      </DemoContainer>
                      <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                        <FaRegClock className="fs-3" />
                      </span>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3 ">
            <div className="col-12 col-sm-6 col-lg-12">
              <div className="row mb-3 ">
                <div className="col-12 col-lg-4 ">
                  <label for="basic-url" className="form-label  label">
                    SECOND GATE ENTRY TIME
                  </label>
                </div>
                <div className="col-12 col-lg-8" id="time-picker">
                  <div className="input-group time-price justify-content-between  time-price-768  ">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileTimePicker"]}>
                        <DemoItem>
                          <MobileTimePicker
                            value={dayjs(eventDate6)}
                            name="secondGateEntryTime"
                            onChange={(newTime) =>
                              handleTimeChange(newTime, "secondGateEntryTime")
                            }
                          />
                        </DemoItem>
                      </DemoContainer>
                      <span className="input-group-text inpt-border-setting inpt-lable ps-4  justify-content-center">
                        <FaRegClock className="fs-3" />
                      </span>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
            <button
              type="button"
              className="btn btn-g me-2  mb-4 btn-768"
              onClick={() => setselectedMenu(5)}
            >
              BACK
            </button>
            <button
              type="button"
              className="btn btn-g me-2  mb-4 btn-768"
              onClick={handleReset}
            >
              RESET
            </button>
            <button
              type="button"
              className="btn btn-b me-2 mb-4 btn-768"
              onClick={() => setselectedMenu(3)}
            >
              NEXT
              <RiArrowRightDoubleLine className="fs-5" />
            </button>
          </div>
          {/* <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative  next-btn-768">
            <button
              type="button"
              className="btn btn-b me-2 mb-2"
              onClick={() => setselectedMenu(5)}
            >
              {" "}
              &lt;&lt; BACK
            </button>
            <button
              type="button"
              className="btn btn-b me-2 mb-2"
              onClick={() => setselectedMenu(3)}
            >
              NEXT &raquo;
            </button>
          </div> */}
        </div>
      </div>
      <div className={`next-btn mt-5  fixed-bottom`}>
        <div
          className=" d-flex justify-content-between p-2 "
          style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
        >
          <div className="d-flex">
            <RiArrowLeftSLine className=" fs-2 mt-1" />
            <p
              className=" text-dark pt-1 mb-0"
              onClick={() => setselectedMenu(5)}
            >
              PREVIOUS
            </p>
          </div>
          <div className="d-flex">
            <p
              className=" text-dark pt-1 mb-0"
              onClick={() => setselectedMenu(3)}
            >
              NEXT
            </p>
            <RiArrowRightSLine className=" fs-2 mt-1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInformationEvent;
