import React, { useEffect, useState } from "react";

import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";
import {
  AllUserNameData,
  getCalendarFilter,
  getToken,
} from "../../../Store/Action/useFetchData";
import pdf from "../../../../images/pdf.png";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import SuperAdminFilterEventsPDF from "../../../PDF/SuperAdminFilterEventsPDF";
const CalenderFilter = ({ dispatch, res_calendar_filter, res_user_name }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [finalDate, setFinalDate] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    dispatch(getToken());
    dispatch(AllUserNameData());
  }, [dispatch]);

  const handleDateChange = (e) => {
    const dateString = e;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setSelectedDate(date);
    setFinalDate(`${year}-${month}-${day}`);
  };
  const options = [
    { value: "Wedding", label: "Wedding" },
    { value: "Engagement", label: "Engagement" },
    { value: "Corporate", label: "Corporate" },
    { value: "Concert", label: "Concert" },
    { value: "Private", label: "Private" },
    { value: "Charity", label: "Charity" },
  ];

  const customStyles = {
    backgroundColor: "#4d4d4d",
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4d4d4d" : "transparent",
      color: state.isSelected ? "white" : "#333",
      ":hover": {
        backgroundColor: "#676767 ",
        color: "white",
        cursor: "pointer",
      },
    }),
  };

  const handleSubmit = () => {
    setUrl([]);

    const submitData = {
      eventType: selectedOption?.label,
      startDate: finalDate,
      createdByUser: selectedOption1?.value,
    };
    dispatch(getCalendarFilter(submitData, setLoading));
  };
  const handleReset = () => {
    setSelectedDate(null);
    setFinalDate("");
    setSelectedOption([]);
    setSelectedOption1([]);
  };
  useEffect(() => {
    if (res_calendar_filter?.data?.status === true) {
      setUrl(res_calendar_filter?.data?.url);
      const notify = () =>
        toast.success(res_calendar_filter?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_calendar_filter.loading = true;
        res_calendar_filter.data = {};
      }, 1500);
    } else if (res_calendar_filter?.data?.status === false) {
      const notify = () =>
        toast.error(res_calendar_filter?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_calendar_filter.loading = true;
        res_calendar_filter.data = {};
      }, 1500);
    }
  }, [res_calendar_filter]);
  const handleImageClick = async () => {
    window.open(url,"_blank");
    // Create an off-screen container
    // try {
    //   const offScreenContainer = document.createElement("div");
    //   offScreenContainer.style.position = "absolute";
    //   offScreenContainer.style.left = "-9999px";
    //   document.body.appendChild(offScreenContainer);
    //   offScreenContainer.style.width = "768px";

    //   createRoot(offScreenContainer).render(
    //     <SuperAdminFilterEventsPDF getData={url} />
    //   );

    //   // Wait for rendering to finish before generating PDF
    //   await new Promise((resolve) => setTimeout(resolve, 0));

    //   const class1 = offScreenContainer.querySelectorAll(".actual-receipt");
    //   // const class2 = offScreenContainer.querySelectorAll(".actual-receipt");
    //   const combinedElements = class1;

    //   if (combinedElements.length === 0) {
    //     return;
    //   }

    //   const doc = new jsPDF("p", "mm", "a4");
    //   let yOffset = 0;

    //   for (let index = 0; index < combinedElements.length; index++) {
    //     const element = combinedElements[index];
    //     const className = element.classList[0];

    //     await new Promise((resolve) => {
    //       setTimeout(() => {
    //         html2canvas(element, {
    //           scale: 2,
    //           logging: false,
    //           useCORS: true,
    //         }).then((canvas) => {
    //           const imgData = canvas.toDataURL("image/png");
    //           const width = doc.internal.pageSize.getWidth();
    //           const height = doc.internal.pageSize.getHeight();

    //           if (index > 0) {
    //             doc.addPage();
    //             yOffset = 0;
    //           }

    //           // Add the class name to the PDF
    //           if (className === "class1") {
    //             doc.text("Class 1", 10, 10);
    //           } else if (className === "class2") {
    //             doc.text("Class 2", 10, 10);
    //           }

    //           doc.addImage(imgData, "PNG", 0, yOffset, width, height);
    //           yOffset += height;

    //           resolve();
    //         });
    //       }, 100);
    //     });
    //   }

    //   doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
    //   document.body.removeChild(offScreenContainer);
    // } catch (error) {
    //   console.error(error);
    // }
  };

  // const handleImageClick =async () => {

  //     // Create an off-screen container
  //     try {

  //       const offScreenContainer = document.createElement("div");
  //       offScreenContainer.style.position = "absolute";
  //       offScreenContainer.style.left = "-9999px";
  //       document.body.appendChild(offScreenContainer);
  //       offScreenContainer.style.width = "768px";

  //       createRoot(offScreenContainer).render(<SuperAdminFilterEventsPDF getData={url} />);

  //       // Wait for rendering to finish before generating PDF
  //       await new Promise((resolve) => setTimeout(resolve, 0));

  //       const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

  //       if (receipts.length === 0) {
  //         return;
  //       }

  //       const doc = new jsPDF("p", "mm", "a4");
  //       let yOffset = 0;

  //       for (let index = 0; index < receipts.length; index++) {
  //         const receipt = receipts[index];
  //         await new Promise((resolve) => {
  //           setTimeout(() => {
  //             html2canvas(receipt, { scale: 2, logging: false, useCORS: true }).then(
  //               (canvas) => {
  //                 const imgData = canvas.toDataURL("image/png");
  //                 const width = doc.internal.pageSize.getWidth();
  //                 const height = doc.internal.pageSize.getHeight();

  //                 if (index > 0) {
  //                   doc.addPage();
  //                   yOffset = 0;
  //                 }

  //                 doc.addImage(imgData, "PNG", 0, yOffset, width, height);
  //                 yOffset += height;

  //                 resolve();
  //               }
  //             );
  //           }, 100);
  //         });
  //       }

  //       doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
  //       document.body.removeChild(offScreenContainer);
  //     } catch (error) {
  //       console.error(error)
  //     }

  // };
  const userOptions =
    res_user_name?.data?.user_data?.map((user) => ({
      value: user.id.toString(),
      label: user.name,
    })) || [];
  const backData = () => {
    res_calendar_filter.loading = true;
    res_calendar_filter.data = {};
    navigate("/calender");
  };
  return (
    <>
      <Toaster />
      <div className="">
        <div className=" container-fluid">
          <div className="  padding-left-21 form-margin-768">
            <div className=" form-in-768">
              <div className="row mb-3 mt-0 mt-lg-5 pt-0 pt-lg-5">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    PER EVENT TYPE
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  <Select
                    onChange={(e) => setSelectedOption(e)}
                    options={options}
                    className=" inpt inpt-768"
                    placeholder="Event Type"
                    value={selectedOption}
                    styles={customStyles}
                    // isClearable
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    PER DATE
                  </label>
                </div>
                <div className="col-12 col-lg-8 ">
                  <div className="input-group mb-3 jod_width price price-768 cal_width">
                    <input
                      type=""
                      className="form-control inpt-border-trans "
                      value={finalDate}
                    />
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      className="form-control inpt-border-trans"
                      showPopperArrow={false}
                      dateFormat="yyyy-MM-dd"
                      customInput={
                        <span className="cursor-pointer">
                          <FaCalendarDays />
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    FILTER PER USER
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  <Select
                    onChange={(e) => setSelectedOption1(e)}
                    options={userOptions}
                    className=" inpt inpt-768"
                    value={selectedOption1}
                    placeholder="User"
                    styles={customStyles}
                  />
                </div>
              </div>
              {url?.length ? (
                <div className="row">
                  <div className="col-12 col-lg-4 mt-5">
                    <label for="basic-url" className="form-label  label">
                      DOWNLOAD FILE
                    </label>
                  </div>
                  <div className="col-12 col-lg-8 cursor-pontor mt-5">
                    <img
                      src={pdf}
                      onClick={handleImageClick}
                      alt="profile_image"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768">
                <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={backData}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={handleReset}
                >
                  RESET
                </button>

                {loading ? (
                  <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768  "
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768">
                {loading ? (
                  <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768  "
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={handleReset}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2  mb-4 btn-768"
                  onClick={backData}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_calendar_filter: state?.Calendar_Filter_reducer,
  res_user_name: state?.Add_user_name_data_reducer,
});

export default connect(mapStateToProps)(CalenderFilter);
