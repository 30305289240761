import React from 'react'
const Loader = ({heightValue}) => {
  return (
    <>
     <div className="container-fluid" style={{ height: heightValue ? heightValue : '100vh', backgroundColor: "#e5e5e5" }}>
  <div className="row justify-content-center">
    <div className="col-1">
      <span className="loader"></span>
    </div>
  </div>
</div>

    </>
  )
}

export default Loader
