import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import {
  Createstation,
  getToken,
} from "../../../../Store/Action/useFetchData";
const StationModel = ({ dispatch, res_user, show, setShow }) => {
  const [loading, setLoading] = useState(false);
  const [createstation, setCreatestation] = useState({
    station_name: "",
    station_price: "",
  });
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setCreatestation({ ...createstation, [name]: value });
  };

  const handlesubmit = () => {
    const submitData = {
      sname: createstation?.station_name,
      sprice: createstation?.station_price,
    };
    dispatch(Createstation(submitData, setLoading));
  };
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    if (res_user?.data?.status === true) {
      const notify = () =>
        toast.success(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
        setShow(false);
        // navigate("/station");
      }, 1500);
    } else if (res_user?.data?.status === false) {
      const notify = () =>
        toast.error(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
      }, 1500);
    }
  }, [res_user]);
  return (
    <>
      <div>
        {" "}
        <Toaster />
        <div
          className={`modal${show ? " fade show d-block " : " d-none"}`}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
            <div className="modal-content">
              <div className="modal-header modal-header-role">
                <h4 className="modal-title text-center fw-bold">
                  ADD STATION
                </h4>
                <button
                  type="button"
                  className="btn-close ms-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setShow(false);
                    res_user.loading = true;
                    res_user.data = {};
                  }}
                ></button>
              </div>

              <div className="modal-body modal-padd">
                <div className="row mb-2 mt-3 ">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      {" "}
                      STATION NAME
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                  <input
                  type="text"
                  autoComplete="off"
                  name="station_name"
                  onChange={handleChange}
                  value={createstation.station_name}
                  className="form-control inpt inpt-768 "
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                    STATION PRICE
                    </label>
                  </div>

                  <div className="col-lg-8 col-12">
                    <div className="input-group mb-2 price-jod price-jod-768">
                      <input
                        type="number"
                        name="station_price"
                        autoComplete="off"
                        onChange={handleChange}
                        value={createstation.station_price}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer ">
                {loading ? (
                  <button
                    type="button"
                    className="btn btn-b me-2  mb-4 btn-768 "
                  >
                    <span className="spinner-border disable-spinner me-2"></span>
                    ADD
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2  mb-4 btn-768 "
                    onClick={handlesubmit}
                  >
                    ADD
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user: state?.Create_station_reducer,
});

export default connect(mapStateToProps)(StationModel);
