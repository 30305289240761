import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken, sendRoleStatus } from "../../Store/Action/useFetchData";
import UseRoleView from "../Forms/View/UseRoleView";
import toast, { Toaster } from "react-hot-toast";
const UserRoles = ({ dispatch, res_token, res_change_status }) => {
  const [viewRoleModal, setViewRoleModel] = useState(false);
  const [viewRoleModalId, setViewRoleModelId] = useState();
  const [toggleStatus, setToggleStatus] = useState();
  const [length, setLength] = useState(0)
  const navigate = useNavigate();
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, []);
  useEffect(()=>{
     toast.dismiss();
  },[])
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $('<div class="row justify-content-center "><span class="loader"></span></div>').appendTo("#showuserrole");
      var table1 = $("#showuserrole").DataTable({
        columnDefs: [{ orderable: false, targets: 3 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          // processing:
          //   '<div class="container-fluid bg-white"><div class="row justify-content-center"><div class="col-1"><span class="loader"></span></div></div></div>',
          // '<div class="custom-processing-indicator">Loading...</div>'
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_userrole_data",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "role_id",
            width: "6%",
          },
          {
            data: "role_name",
            width: "45%",
          },
          {
            data: null,
            width: "45%",
            render: function (data) {
              if (data.status === "0") {
                return (
                  '<div class="d-flex line-height"><span  id="changeRole" value="' +
                  data.role_id +
                  '"><i class="bi bi-toggle-on fs-3 text-info ps-2 cursor-pointer "></i></span></div>'
                );
              } else if (data.status === "1") {
                return (
                  '<div class="d-flex line-height"><span  id="changeRole" value="' +
                  data.role_id +
                  '"><i class="bi bi-toggle-off fs-3 text-info ps-2 cursor-pointer "></i> </span></div>'
                );
              }
            },
          },
          {
            data: null,
            width: "45%",

            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="viewRole" value="' +
                data.role_id +
                '"><i class="bi bi-eye fs-4 ps-2 text-success cursor-pointer"></i></span>' +
                '<span  id="editRole" value="' +
                data.role_id +
                '"><i i class="bi bi-pencil-square fs-4 ps-2 text-warning cursor-pointer" ></i > </span></div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength)
        },
      });

        table1.on('xhr.dt', function () {
          customLoader.css("display", "none");
        });

      $("#showuserrole tbody").on("click", "#viewRole", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.role_id) {
          setViewRoleModel(true);
          setViewRoleModelId(rowData.role_id);
        }
      });
      $("#showuserrole tbody").on("click", "#editRole", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row) && table1.row(row).data();
        if (id && id.role_id) {
          id && navigate(`/edit_user_role/${id.role_id}`);
        }
      });
      $("#showuserrole tbody").on("click", "#changeRole", function () {
        var row = $(this).parents("tr")[0];
        const rowData = table1.row(row).data();
        if (rowData && rowData.status && rowData.role_id) {
          const status = rowData.status === "1" ? 0 : 1;
          handleToggleStatus(rowData.role_id, status); 
        }
      });
      const handleToggleStatus = (id, s) => {
    
        let finalStatus;
        if (s === 1) {
          finalStatus = "Off";
        } else if (s === 0) {
          finalStatus = "On";
        }
        setToggleStatus(s);
        const toggleStatus = {
          status: finalStatus,
        };
        dispatch(sendRoleStatus(id, toggleStatus)); // 
        $("#showuserrole_processing").css("display", "none");
      };
      $("#showuserrole_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  useEffect(() => {
    if (res_change_status?.data?.status === true) {
      const notify = () =>
        toast.success(res_change_status?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_change_status.loading = true;
        res_change_status.data = {};
      }, 1500);
    } else if (res_change_status?.data?.status === false) {
      const notify = () =>
        toast.error(res_change_status?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_change_status.loading = true;
        res_change_status.data = {};
      }, 1500);
    }
  }, [res_change_status]);

  return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ?
            `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }

td:nth-of-type(1):before {
  ${length !== 0 ?
            `content: "#";` :
            ``
          }
 
}
td:nth-of-type(2):before {
  content: " Name";
 
}
td:nth-of-type(3):before {
  content: "Type Event";
}
td:nth-of-type(4):before {
  content: "Action";
}
}
`}
      </style>
      <Toaster />
      {viewRoleModal && (
        <UseRoleView
          setViewRoleModel={setViewRoleModel}
          viewRoleModal={viewRoleModal}
          viewRoleModalId={viewRoleModalId}
        />
      )}
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="float-right add-role-btn-768 mt-3 ">
                <Link to="/add_user_role">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                  >
                    + ADD USER ROLES
                  </button>
                </Link>
              </div>
              <div className="role-table">
                <table
                  id="showuserrole"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Name
                      </th>
                      <th className="thead-color" scope="col">
                        Type Event
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_change_status: state?.Send_Role_Status_reducer,
});

export default connect(mapStateToProps)(UserRoles);