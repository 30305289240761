import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getAllSoundLightsData,
  getAllSuppliersData,
  getToken,
} from "../../../../Store/Action/useFetchData";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import RegenerateModal from "./RegenerateModal";
import SoundAndLightModel from "./SoundAndLightModel";
import toast, { Toaster } from "react-hot-toast";
const SoundAndLight = ({
  setselectedMenu,
  setEventData,
  eventData,
  setShowData,
  res_get_suppliers,
  dispatch,
  res_get_sound,
  handleFormSubmit,
  edit,
  setLoading,
  loading,
}) => {
  const [soundLight, setSoundLight] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedOptionFood, setSelectedOptionFood] = useState("Sound...");

  const [selectedOption1, setSelectedOption1] = useState("Sound Name  ...");
  const [selectedOption5, setSelectedOption5] = useState("Company Name  ...");
  const [show, setShow] = useState(false);
  const options = [
    { id: "0", name: "White Hall" },
    { id: "1", name: "OutSource" },
  ];

  const [stateFood, setStateFood] = useState(false);
  const [stateWHFood, setStateWHFood] = useState(false);
  const [stateOutFood, setStateOutFood] = useState(false);

  const sidebarRefFood = useRef(null);
  const sidebarRefWFood = useRef(null);
  const sidebarRefOFood = useRef(null);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    dispatch(getToken());
    // dispatch(getAllSuppliersData());
    dispatch(getAllSoundLightsData());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAllSoundLightsData());
  }, [show]);
  const whiteSound = res_get_sound?.data?.data
    ?.map((data) => ({
      id: data.id,
      name: data?.package_name,
      company_type: data?.company_type,
    }))
    .filter(
      (entry) =>
        entry.company_type && entry.company_type === "0" && entry.name !== null
    );

  const outSourceSound = res_get_sound?.data?.data
    ?.map((data) => ({
      id: data.id,
      name: data?.company_name,
      company_type: data?.company_type,
    }))
    .filter(
      (entry) =>
        entry.company_type && entry.company_type === "1" && entry.name !== null
    );
  // console.log(showModal,"showModal");
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRefWFood.current &&
        !sidebarRefWFood.current.contains(event.target)
      ) {
        setStateWHFood(false);
      }
      if (
        sidebarRefOFood.current &&
        !sidebarRefOFood.current.contains(event.target)
      ) {
        setStateOutFood(false);
      }
      if (
        sidebarRefFood.current &&
        !sidebarRefFood.current.contains(event.target)
      ) {
        setStateFood(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setStateWHFood, setStateOutFood, setSelectedOptionFood]);

  useEffect(() => {
    setSelectedOptionFood(
      String(eventData?.soundSource) === "0"
        ? "White Hall"
        : String(eventData?.soundSource) === "1"
        ? "OutSource"
        : "Sound..."
    );

    setSoundLight(
      String(eventData?.soundSource) === "0"
        ? "0"
        : String(eventData?.soundSource) === "1"
        ? "1"
        : ""
    );

    const selectedOption1 = whiteSound?.find(
      (data) => data?.id === Number(eventData?.whiteSoundsID)
    );
    if (selectedOption1) {
      setSelectedOption1(selectedOption1?.name);
    }
    const selectedOption2 = outSourceSound?.find(
      (data) => data?.id === eventData?.outsourceSoundsID
    );
    if (selectedOption2) {
      setSelectedOption5(selectedOption2?.name);
    }
  }, [res_get_suppliers, eventData, whiteSound, outSourceSound]);
  const handleCloseFood = (e) => {
    setStateFood(false);
    if (e.name !== selectedOptionFood && e.name !== "") {
      setSelectedOptionFood(e?.name);
      // console.log(e);
      if (e?.id === "0") {
        setEventData((prevTime) => ({
          ...prevTime,
          outsourceSoundsID: "",
        }));
        setSelectedOption5("Company Name  ...");
      } else if (e?.id === "1") {
        setEventData((prevTime) => ({
          ...prevTime,
          whiteSoundsID: "",
        }));
        setSelectedOption1("Sound Name  ...");
      }
      setSoundLight(e?.id);
      setEventData((prevTime) => ({
        ...prevTime,
        soundSource: e?.id,
      }));
    }
  };
  const handleCloseWFood = (e) => {
    setStateWHFood(false);
    if (e.name !== selectedOption1 && e.name !== "") {
      setSelectedOption1(e?.name);
      setEventData((prevTime) => ({
        ...prevTime,
        whiteSoundsID: e?.id,
      }));
    }
  };
  const handleCloseOFood = (e) => {
    setStateOutFood(false);
    if (e.name !== selectedOption5 && e.name !== "") {
      setSelectedOption5(e?.name);
      setEventData((prevTime) => ({
        ...prevTime,
        outsourceSoundsID: e?.id,
      }));
    }
  };
  const handleChangePhone = (phone) => {
    // console.log(phone);
    setEventData((prevTime) => ({
      ...prevTime,
      extraPhoneNumber: phone,
    }));
  };
  // const handleChange = (e)=>{
  //   let name = e.target.name
  //   let value = e.target.value
  //   setEventData((prevTime) => ({
  //     ...prevTime,
  //     outsourceSoundsID: e?.id,
  //   }));
  // }
  // console.log(eventData, "eventdata");

  const handleReset = () => {
    setEventData({
      ...eventData,
      soundSource: "",
      whiteSoundsID: "",
      outsourceSoundsID: "",
      soundnotes: "",
    });
  };
  // console.log(eventData,"eventData");
  return (
    <div>
      <div className="  form-margin padding-left-21 position-relative form-margin-768 mb-5 pb-1">
        <div className="  ">
          <h3 className="mb-4 mt-3 fw-bold pt-sm-0">Sound and Lights</h3>
          <div className="row mb-3 mt-3 ">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                SOUND SOURCE
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <div className="" ref={sidebarRefFood}>
                <div
                  className="form-select inpt inpt-768 select-ios-div"
                  onClick={() => setStateFood(true)}
                  style={{ cursor: "pointer" }}
                >
                  <option
                    className="overflow-hidden select-ios"
                    style={{ padding: "6px" }}
                  >
                    {selectedOptionFood}
                  </option>
                </div>
                <div
                  className={`${
                    stateFood
                      ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                      : "d-none"
                  }`}
                >
                  {options?.map((item, index) => {
                    return (
                      <div key={index} className="p-1 py-0">
                        <div
                          onClick={() => handleCloseFood(item)}
                          className="select-box-option text-white"
                        >
                          {item.name}
                        </div>
                        <div className="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {show == true && soundLight === "0" ? (
            <>
              <SoundAndLightModel show={show} setShow={setShow} />
              {/* <div className="row mb-3">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    SOUNDS AND LIGHTS
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  <input
                    type="text"
                    className="form-control inpt inpt-768 "
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="hardDrinksID"
                    autoComplete="off"
                    placeholder="Package Name"
                    // value={eventData.hardDrinksID}
                    onChange={(e) => {
                      setEventData((prevTime) => ({
                        ...prevTime,
                        outsourceSoundsID: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-lg-4 ">
                  <label for="basic-url" className="form-label  label"></label>
                </div>
                <div className="col-12 col-lg-8  d-flex">
                  <input
                    type="text"
                    className="form-control inpt inpt-768 "
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    name="hardDrinksID"
                    autoComplete="off"
                    placeholder="Package Price"
                    // value={eventData.hardDrinksID}
                    onChange={(e) => {
                      setEventData((prevTime) => ({
                        ...prevTime,
                        outsourceSoundsID: e.target.value,
                      }));
                    }}
                  />
                  <div>
                    <p
                      className="text-black mb-0 p-2 fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(false)}
                    >
                      Manually Add
                    </p>
                  </div>
                </div>
              </div> */}
            </>
          ) : show == false && soundLight === "0" ? (
            <div className="row mb-3">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  SOUNDS AND LIGHTS
                </label>
              </div>

              <div className="col-12 col-lg-8">
                <div className="d-flex" ref={sidebarRefWFood}>
                  <div>
                    <div
                      className="form-select inpt inpt-768 select-ios-div"
                      onClick={() => setStateWHFood(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <option
                        className="overflow-hidden select-ios"
                        style={{ padding: "6px" }}
                      >
                        {selectedOption1}
                      </option>
                    </div>
                    <div
                      className={`${
                        stateWHFood
                          ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                          : "d-none"
                      }`}
                    >
                      {whiteSound?.length ? (
                        whiteSound?.map((item, index) => {
                          return (
                            <div key={index} className="p-1 py-0">
                              <div
                                onClick={() => handleCloseWFood(item)}
                                className="select-box-option text-white"
                              >
                                {item.name}
                              </div>
                              <div className="" />
                            </div>
                          );
                        })
                      ) : (
                        <div className="p-1 py-0">
                          <div
                            className="select-box-option-nodata text-center"
                            style={{ cursor: "default" }}
                          >
                            No Data Found
                          </div>
                          <div className="" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p
                      className="text-black mb-0 p-2 fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(true)}
                    >
                      Manually Add
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {soundLight === "1" ? (
            <div className="row mb-3">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  COMPANY NAME
                </label>
              </div>

              <div className="col-12 col-lg-8">
                <div className="" ref={sidebarRefOFood}>
                  <div
                    className="form-select inpt inpt-768 select-ios-div"
                    onClick={() => setStateOutFood(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <option
                      className="overflow-hidden select-ios"
                      style={{ padding: "6px" }}
                    >
                      {selectedOption5}
                    </option>
                  </div>
                  <div
                    className={`${
                      stateOutFood
                        ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                        : "d-none"
                    }`}
                  >
                    {outSourceSound?.length ? (
                      outSourceSound?.map((item, index) => {
                        // console.log(item, "item");
                        return (
                          <div key={index} className="p-1 py-0">
                            <div
                              onClick={() => handleCloseOFood(item)}
                              className="select-box-option text-white"
                            >
                              {item.name}
                            </div>
                            <div className="" />
                          </div>
                        );
                      })
                    ) : (
                      <div className="p-1 py-0">
                        <div
                          className="select-box-option-nodata text-center"
                          style={{ cursor: "default" }}
                        >
                          No Data Found
                        </div>
                        <div className="" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row mb-3  ">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                SOUND & LIGHT NOTES
              </label>
            </div>

            <div className="col-12 col-lg-8">
              <div className="input-group price-textarea">
                <textarea
                  className=" form-control inpt-border-trans"
                  id="exampleFormControlTextarea1"
                  rows="6"
                  autoComplete="off"
                  value={eventData?.soundnotes}
                  onChange={(e) => {
                    setEventData((prevTime) => ({
                      ...prevTime,
                      soundnotes: e?.target?.value,
                    }));
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
            <button
              type="button"
              className="btn btn-g me-2  mb-4 btn-768"
              onClick={() => setShowData(2)}
            >
              BACK
            </button>
            <button
              type="button"
              className="btn btn-g me-2  mb-4 btn-768"
              onClick={handleReset}
            >
              RESET
            </button>
            {edit ? (
              <>
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={() => setShowModal(true)}
                >
                  REGENERATE BILL
                </button>
              </>
            ) : (
              <>
                {loading ? (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768"
                    disabled
                  >
                    <span className="spinner-border disable-spinner me-2"></span>{" "}
                    NEXT
                    <RiArrowRightDoubleLine className="fs-5" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768"
                    onClick={() => {
                      handleFormSubmit();
                    }}
                  >
                    NEXT
                    <RiArrowRightDoubleLine className="fs-5" />
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`next-btn mt-5 fixed-bottom`}>
        <div
          className=" d-flex justify-content-between p-2 "
          style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
        >
          <div className="d-flex">
            <RiArrowLeftSLine className="fs-2 mt-1" />
            <p className=" text-dark pt-1 mb-0" onClick={() => setShowData(2)}>
              PREVIOUS
            </p>
          </div>
          <div className="d-flex">
            {edit ? (
              <>
                <p
                  className=" text-dark pt-1 mb-0"
                  onClick={() => setShowModal(true)}
                >
                  REGENERATE BILL
                </p>
              </>
            ) : (
              <>
                {loading ? (
                  <p className=" text-dark pt-1 mb-0" disabled>
                    <span className="spinner-border disable-spinner me-2"></span>{" "}
                    NEXT
                  </p>
                ) : (
                  <p
                    className=" text-dark pt-1 mb-0"
                    onClick={() => handleFormSubmit()}
                  >
                    NEXT
                  </p>
                )}
                <RiArrowRightSLine className="fs-2 mt-1" />
              </>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <RegenerateModal
          setShowModal={setShowModal}
          handleFormSubmit={handleFormSubmit}
          eventId={eventData.eventID}
          showModal={showModal}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_suppliers: state?.Get_all_suppliers_reducer,
  res_get_sound: state?.Get_all_sound_and_light_reducer,
});

export default connect(mapStateToProps)(SoundAndLight);
