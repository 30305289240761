import React, { useState, useRef, useEffect } from "react";
import { LuUser } from "react-icons/lu";
import { GrUserSettings } from "react-icons/gr";
import { PiNotepadBold } from "react-icons/pi";
import { LuCalendarHeart } from "react-icons/lu";
import { LiaGlassCheersSolid } from "react-icons/lia";
import { ImProfile } from "react-icons/im";
import { CiMusicNote1 } from "react-icons/ci";
import { GoChecklist } from "react-icons/go";
import { CiSettings } from "react-icons/ci";
import image from "../images/images.png";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { BsStars } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { RiArrowUpSLine } from "react-icons/ri";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoMdPower } from "react-icons/io";
import { MdOutlineNoteAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { RiHistoryFill } from "react-icons/ri";
import Texmode from "./Pages/Forms/View/Texmode";
import { RiGovernmentLine } from "react-icons/ri";
const Sidebar = ({ setSidebarOpen, isSidebarOpen }) => {
  const role = sessionStorage.getItem("role");
  const profileImg = sessionStorage.getItem("Profile_picture");
  const permission = sessionStorage.getItem("permission");
  const userName = sessionStorage.getItem("Uname");
  const location = useLocation();
  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);
  const [state4, setState4] = useState(false);
  const [state5, setState5] = useState(false);
  const [state6, setState6] = useState(false);
  const [state7, setState7] = useState(false);
  const [state8, setState8] = useState(false);
  const [state9, setState9] = useState(false);

  const [searchSidebar, setSearchSidebar] = useState({ reserver: "" });
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState([]);
  const getState = () => {
    setState(!state);
    setState4(!state4);
  };
  const getState1 = () => {
    setState1(!state1);
    setState5(!state5);
  };
  const getState2 = () => {
    setState2(!state2);
    setState3(!state3);
  };
  const getState3 = () => {
    setState6(!state6);
    setState7(!state7);
  };
  const getState4 = () => {
    setState8(!state8);
    setState9(!state9);
  };
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const sidebarRef = useRef(null);
  useEffect(() => {
    allFilterPermission();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setSidebarOpen]);
  const handleLinkClick = (path) => {
    return location.pathname === path;
  };
  const allFilterPermission = async () => {
    const originalPermisstion = [0, 1, 2, 3, 4, 5];
    const numbersArray = await permission.split(",").map(Number);
    const initialCheckboxState = [];
    originalPermisstion.forEach((item) => {
      initialCheckboxState[item] = numbersArray?.includes(item);
    });

    setFilterValue(initialCheckboxState);
  };
  const logoutButton = () => {
    sessionStorage.removeItem("api_token");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("member_id");
    sessionStorage.removeItem("Uname");
    sessionStorage.removeItem("Profile_picture");
    sessionStorage.removeItem("permission");
    window.location.reload();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    navigate("./reporting", { state: { searchSidebar: value } });
    setSearchSidebar(value);
  };

  const [showText, setShowText] = useState(false);
  const [model, setmodel] = useState(false);

  useEffect(() => {
    const keyMap = {
      Shift: false,
      Alt: false,
      Digit3: false,
      Numpad3: false,
    };

    const handleKeyDown = (event) => {
      if (event.key === "Shift") keyMap.Shift = true;
      if (event.key === "Alt") keyMap.Alt = true;
      if (event.code === "Digit3") keyMap.Digit3 = true;
      if (event.code === "Numpad3") keyMap.Numpad3 = true;

      if (
        (keyMap.Shift && keyMap.Alt && keyMap.Digit3) ||
        (keyMap.Shift && keyMap.Alt && keyMap.Numpad3)
      ) {
        setShowText(true);

        setTimeout(() => {
          setShowText(false);
          setmodel(false);
        }, 15000);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") keyMap.Shift = false;
      if (event.key === "Alt") keyMap.Alt = false;
      if (event.code === "Digit3") keyMap.Digit3 = false;
      if (event.code === "Numpad3") keyMap.Numpad3 = false;
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <>
      {model && <Texmode model={model} setmodel={setmodel} />}
      <div className="bg" ref={sidebarRef}>
        <div id="sidebar" className={`sidebar`}>
          <p className="d-flex justify-content-center">
            {role === "0"
              ? "SUPER ADMIN"
              : role === "1"
              ? "KITCHEN USER"
              : role === "2"
              ? "SALES USER"
              : role === "3"
              ? "ACCOUNTING USER"
              : ""}
          </p>
          <div
            className="d-block text-center"
            // style={{ height: "105px" }}
          >
            <img
              className="sidebar-img"
              style={{ height: "100%" }}
              src={
                profileImg
                  ? `http://whitehall.website/api/uploads/profile_image/${profileImg}`
                  : image
              }
              alt="Avatar"
            />
          </div>
          <p className="d-flex justify-content-center p-2 fs-5 fw-bold ">
            {userName}
          </p>
          {role === "0" && (
            <div className="search-bar d-flex justify-content-center">
              <input
                type="text"
                id="searchInput"
                autoComplete="off"
                placeholder="Search client name / bride name / groom name"
                className="text-white"
                name="reserver"
                value={searchSidebar.reserver}
                onChange={handleChange}
              />
            </div>
          )}

          <div id="manubar" className="scrollbar-thin scroll-table scroll-main">
            {(role === "0" || role === "3" || filterValue[5]) && showText && (
              <a onClick={() => setmodel(true)}>
                <RiGovernmentLine className="me-4 fs-4" />
                <span>Tax Mode</span>
              </a>
            )}
            {role !== "0" && (
              <Link
                to={
                  role === "1"
                    ? "/kitchen_main"
                    : role === "2"
                    ? "/sales_main"
                    : role === "3"
                    ? "/accounting_main"
                    : ""
                }
                className={`  ${
                  (handleLinkClick("/kitchen_main") ||
                    handleLinkClick("/accounting_main") ||
                    handleLinkClick("/sales_main") ||
                    handleLinkClick("/")) &&
                  " active-font active"
                }`}
              >
                <FaHome className={`  me-4 fs-4  `} />
                <span>Home</span>
              </Link>
            )}
            {role === "0" && (
              <>
                <Link
                  to="/calender"
                  className={`  ${
                    (handleLinkClick("/calender") || handleLinkClick("/")) &&
                    " active-font active"
                  }`}
                >
                  <MdOutlineNoteAlt className={`  me-4 fs-4  `} />
                  <span>Reservation</span>
                </Link>
                {/* <Link
                  to="/user"
                  className={`  ${
                    (handleLinkClick("/user") ||
                      handleLinkClick("/add_user")) &&
                    " active-font active"
                  }`}
                >
                  <LuUser className={`  me-4 fs-4  `} />
                  <span>User</span>
                </Link>
                <Link
                  to="/user_role"
                  className={`  ${
                    (handleLinkClick("/user_role") ||
                      location.pathname.startsWith("/edit_user_role") ||
                      handleLinkClick("/add_user_role")) &&
                    " active-font active"
                  }`}
                >
                  <GrUserSettings className={`me-4 fs-4 `} />
                  <span>User Role</span>
                </Link> */}
              </>
            )}
            {/* { && (
              <Link
                to="/billing"
                className={`  ${
                  (handleLinkClick("/billing") ||
                    location.pathname.startsWith("/edit_billing") ||
                    location.pathname.startsWith("/create_billing")) &&
                  " active-font active"
                }`}
              >
                <PiNotepadBold className={` me-4 fs-4`} />
                <span>Billing</span>
              </Link>
            )} */}
            {/* {role === "0" && (
              <Link
                to="/invoice_history"
                className={`  ${
                  handleLinkClick("/invoice_history") && " active-font active"
                }`}
              >
                <RiHistoryFill className={` me-4 fs-4`} />
                <span className={``}>Invoice History</span>
              </Link>
            )} */}

            {(role === "0" || filterValue[0]) && (
              <div className="">
                <div
                  onClick={getState1}
                  className={`sidebar-a ${
                    (handleLinkClick("/billing") ||
                      location.pathname.startsWith("/edit_billing") ||
                      location.pathname.startsWith("/create_billing") ||
                      location.pathname.startsWith("/sub_invoice_history") ||
                      handleLinkClick("/invoice_history")) &&
                    "active"
                  }`}
                >
                  <PiNotepadBold className={` me-4 fs-4`} />
                  <span className={`  ${state1 && " active-font"}`}>
                    Billing
                  </span>
                  <span className="up-arrow">
                    {!state5 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state1 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/billing"
                      className={`sub-sidebar ${
                        (handleLinkClick("/billing") ||
                          location.pathname.startsWith("/edit_billing") ||
                          location.pathname.startsWith("/create_billing")) &&
                        " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/billing") ||
                            location.pathname.startsWith("/edit_billing") ||
                            location.pathname.startsWith("/create_billing")) &&
                          " active-font "
                        }`}
                      >
                        Billing
                      </span>
                    </Link>
                    <Link
                      to="/invoice_history"
                      className={`sub-sidebar ${
                        (handleLinkClick("/invoice_history") ||
                          location.pathname.startsWith(
                            "/sub_invoice_history"
                          )) &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/invoice_history") ||
                            location.pathname.startsWith(
                              "/sub_invoice_history"
                            )) &&
                          " active-font  "
                        }`}
                      >
                        Invoice History
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {role !== "1" && (
              <Link
                to="/calender_filter"
                className={`  ${
                  handleLinkClick("/calender_filter") && " active-font active"
                }`}
              >
                <LuCalendarHeart className={` me-4 fs-4`} />
                <span className={``}>Calender Filter</span>
              </Link>
            )}
            {role === "1" && (
              <Link
                to="/kcalender_filter"
                className={`  ${
                  handleLinkClick("/kcalender_filter") && " active-font active"
                }`}
              >
                <LuCalendarHeart className={` me-4 fs-4`} />
                <span className={``}>Calender Filter</span>
              </Link>
            )}
            {(role === "0" || filterValue[2]) && (
              <div className="">
                <div
                  onClick={getState}
                  className={`sidebar-a ${
                    (handleLinkClick("/add_menu") ||
                      handleLinkClick("/menu") ||
                      location.pathname.startsWith("/edit_all_menu") ||
                      handleLinkClick("/station") ||
                      handleLinkClick("/create_station") ||
                      location.pathname.startsWith("/edit_menu_type") ||
                      location.pathname.startsWith("/edit_station") ||
                      handleLinkClick("/food_drink") ||
                      handleLinkClick("/add_food_drink") ||
                      handleLinkClick("/create_menu") ||
                      handleLinkClick("/menu_type")) &&
                    "active"
                  }`}
                >
                  <LiaGlassCheersSolid className={` me-4 fs-4`} />
                  <span className={`  ${state && " active-font"}`}>Menu</span>
                  <span className="up-arrow">
                    {!state4 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/menu"
                      className={`sub-sidebar ${
                        (handleLinkClick("/add_menu") ||
                          location.pathname.startsWith("/edit_all_menu") ||
                          handleLinkClick("/menu")) &&
                        " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/add_menu") ||
                            location.pathname.startsWith("/edit_all_menu") ||
                            handleLinkClick("/menu")) &&
                          " active-font "
                        }`}
                      >
                        Add Menu
                      </span>
                    </Link>
                    <Link
                      to="/menu_type"
                      className={`sub-sidebar ${
                        (handleLinkClick("/menu_type") ||
                          location.pathname.startsWith("/edit_menu_type") ||
                          handleLinkClick("/create_menu")) &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/menu_type") ||
                            location.pathname.startsWith("/edit_menu_type") ||
                            handleLinkClick("/create_menu")) &&
                          " active-font  "
                        }`}
                      >
                        Create Menu Type{" "}
                      </span>
                    </Link>

                    <Link
                      to="/station"
                      className={`sub-sidebar ${
                        (handleLinkClick("/station") ||
                          location.pathname.startsWith("/edit_station") ||
                          handleLinkClick("/create_station")) &&
                        " active-sub-link"
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/station") ||
                            location.pathname.startsWith("/edit_station") ||
                            handleLinkClick("/create_station")) &&
                          " active-font "
                        }`}
                      >
                        Create Station
                      </span>
                    </Link>
                    {/* <Link
                      to="/food_drink"
                      className={`sub-sidebar ${
                        (handleLinkClick("/food_drink") ||
                          handleLinkClick("/add_food_drink")) &&
                        " active-sub-link"
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/food_drink") ||
                            handleLinkClick("/add_food_drink")) &&
                          " active-font  "
                        }`}
                      >
                        Food Menu
                      </span>
                    </Link> */}
                  </div>
                )}
              </div>
            )}
            {role !== "0" && (
              <div className="">
                <div
                  onClick={getState2}
                  className={`sidebar-a ${
                    (handleLinkClick("/profile") ||
                      handleLinkClick("/change_password")) &&
                    "active"
                  }`}
                >
                  <ImProfile className={` me-4 fs-4`} />
                  <span className={`  ${state2 && " active-font"}`}>
                    Profile
                  </span>
                  <span className="up-arrow">
                    {!state3 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state2 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/profile"
                      className={`sub-sidebar ${
                        handleLinkClick("/profile") && " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/profile") && " active-font "
                        }`}
                      >
                        Change Proﬁle Picture
                      </span>
                    </Link>
                    <Link
                      to="/change_password"
                      className={`sub-sidebar ${
                        handleLinkClick("/change_password") &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/change_password") &&
                          " active-font  "
                        }`}
                      >
                        Change Password
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {/* {role === "0" && (
              <Link
                to="/profile"
                className={`  ${
                  handleLinkClick("/profile") && " active-font active"
                }`}
              >
                <ImProfile className={` me-4 fs-4`} />
                <span>Profile</span>
              </Link>
            )} */}
            {(role === "0" || filterValue[1]) && (
              <Link
                to="/sound_light"
                className={`  ${
                  (handleLinkClick("/sound_light") ||
                    handleLinkClick("/add_sound_light")) &&
                  " active-font active"
                }`}
              >
                <CiMusicNote1 className={` me-4 fs-4`} />
                <span>Sound & Light</span>
              </Link>
            )}
            {(role === "0" || filterValue[3]) && (
              <Link
                to="/Create_event_type"
                className={`  ${
                  handleLinkClick("/Create_event_type") && " active-font active"
                }`}
              >
                <BsStars className={` me-4 fs-4`} />
                <span>Check List Event</span>
              </Link>
            )}

            {role !== "0" && role !== "3" && (
              <Link
                to={
                  role === "0"
                    ? "/reporting"
                    : role === "1"
                    ? "/kitchen_reporting"
                    : role === "2"
                    ? "/sales_reporting"
                    // : role === "3"
                    // ? "/accounting_reporting"
                    : "/reporting"
                }
                className={`  ${
                  (handleLinkClick("/reporting") ||
                    handleLinkClick("/sales_reporting") ||
                    handleLinkClick("/accounting_reporting") ||
                    handleLinkClick("/kitchen_reporting")) &&
                  " active-font active"
                }`}
              >
                <GoChecklist className={` me-4 fs-4`} />
                <span>Reporting</span>
              </Link>
            )}
            {role === "0" && (
              <div className="">
                <div
                  onClick={getState4}
                  className={`sidebar-a ${
                    (handleLinkClick("/reporting") ||
                      handleLinkClick("/income_reports")) &&
                    "active"
                  }`}
                >
                  <GoChecklist className={` me-4 fs-4`} />
                  <span className={`  ${state8 && " active-font"}`}>
                    Reports
                  </span>
                  <span className="up-arrow">
                    {!state9 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state8 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/reporting"
                      className={`sub-sidebar ${
                        handleLinkClick("/reporting") && " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/reporting") && " active-font "
                        }`}
                      >
                        Reporting
                      </span>
                    </Link>
                    <Link
                      to="/transction"
                      className={`sub-sidebar ${
                        handleLinkClick("/transction") && " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/transction") && " active-font "
                        }`}
                      >
                        Transaction
                      </span>
                    </Link>
                    <Link
                      to="/income_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/income_reports") &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/income_reports") && " active-font  "
                        }`}
                      >
                        Income Reports
                      </span>
                    </Link>
                    <Link
                      to="/discount_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/discount_reports") &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/discount_reports") &&
                          " active-font  "
                        }`}
                      >
                        Discount Reports
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {role === "3" && (
              <div className="">
                <div
                  onClick={getState4}
                  className={`sidebar-a ${
                    (handleLinkClick("/reporting") ||
                      handleLinkClick("/income_reports")) &&
                    "active"
                  }`}
                >
                  <GoChecklist className={` me-4 fs-4`} />
                  <span className={`  ${state8 && " active-font"}`}>
                    Reports
                  </span>
                  <span className="up-arrow">
                    {!state9 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state8 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/accounting_reporting"
                      className={`sub-sidebar ${
                        handleLinkClick("/accounting_reporting") &&
                        " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/accounting_reporting") &&
                          " active-font "
                        }`}
                      >
                        Reporting
                      </span>
                    </Link>
                    <Link
                      to="/income_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/income_reports") &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/income_reports") && " active-font  "
                        }`}
                      >
                        Income Reports
                      </span>
                    </Link>
                    <Link
                      to="/discount_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/discount_reports") &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/discount_reports") &&
                          " active-font  "
                        }`}
                      >
                        Discount Reports
                      </span>
                    </Link>
                    <Link
                      to="/account_sub_invoice_history"
                      className={`sub-sidebar ${
                        (handleLinkClick("/account_sub_invoice_history")) &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/account_sub_invoice_history")) &&
                          " active-font  "
                        }`}
                      >
                        History for Invoice
                      </span>
                    </Link>
                    <Link
                      to="/history_payment"
                      className={`sub-sidebar ${
                        (handleLinkClick("/history_payment") ||
                          location.pathname.startsWith(
                            "/sub_history_payment"
                          )) &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/history_payment") ||
                            location.pathname.startsWith(
                              "/sub_history_payment"
                            )) &&
                          " active-font  "
                        }`}
                      >
                        History of Payment
                      </span>
                    </Link>
                    <Link
                      to="/account_statement"
                      className={`sub-sidebar ${
                        handleLinkClick("/account_statement") &&
                        " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/account_statement") &&
                          " active-font  "
                        }`}
                      >
                        Account Statement
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}

            {role === "0" && (
              <div className="">
                <div
                  onClick={getState3}
                  className={`sidebar-a ${
                    (handleLinkClick("/user") ||
                      handleLinkClick("/add_user") ||
                      location.pathname.startsWith("/edit_user_role") ||
                      handleLinkClick("/user_role") ||
                      handleLinkClick("/add_user_role") ||
                      handleLinkClick("/profile") ||
                      handleLinkClick("/setting")) &&
                    "active"
                  }`}
                >
                  <CiSettings className={` me-4 fs-4`} />
                  <span className={`  ${state6 && " active-font"}`}>
                    All Settings
                  </span>
                  <span className="up-arrow">
                    {!state7 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state6 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/setting"
                      className={`sub-sidebar ${
                        handleLinkClick("/setting") && " active-sub-link   "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/setting") && " active-font  "
                        }`}
                      >
                        Settings
                      </span>
                    </Link>
                    <Link
                      to="/user"
                      className={`sub-sidebar ${
                        (handleLinkClick("/user") ||
                          handleLinkClick("/add_user")) &&
                        " active-sub-link "
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/user") ||
                            handleLinkClick("/add_user")) &&
                          " active-font "
                        }`}
                      >
                        User
                      </span>
                    </Link>

                    <Link
                      to="/user_role"
                      className={`sub-sidebar ${
                        (handleLinkClick("/user_role") ||
                          location.pathname.startsWith("/edit_user_role") ||
                          handleLinkClick("/add_user_role")) &&
                        " active-sub-link"
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/user_role") ||
                            location.pathname.startsWith("/edit_user_role") ||
                            handleLinkClick("/add_user_role")) &&
                          " active-font "
                        }`}
                      >
                        User Role
                      </span>
                    </Link>
                    <Link
                      to="/profile"
                      className={`sub-sidebar ${
                        handleLinkClick("/profile") && " active-sub-link"
                      }`}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/profile") && " active-font  "
                        }`}
                      >
                        Profile
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            <a onClick={logoutButton}>
              <IoMdPower className={` me-4 fs-4`} /> <span>Logout</span>
            </a>
          </div>
        </div>
        <div
          id="sidebar1"
          className={`sidebar scrollbar-thin scroll-table scroll-main  ${
            isSidebarOpen ? "sidebar-open" : ""
          }`}
        >
          <IoClose
            className="fa fa-times "
            id="close-icon"
            aria-hidden="true"
            onClick={closeSidebar}
          />

          <p className="d-flex justify-content-center">
            {" "}
            {role === "0"
              ? "SUPER ADMIN"
              : role === "1"
              ? "KITCHEN USER"
              : role === "2"
              ? "SALES USER"
              : role === "3"
              ? "ACCOUNTING USER"
              : ""}
          </p>
          <div className="d-block text-center">
            <img
              className="sidebar-img"
              style={{ height: "100%" }}
              src={
                profileImg
                  ? `http://whitehall.website/api/uploads/profile_image/${profileImg}`
                  : image
              }
              alt="Avatar"
            />
          </div>

          <p className="d-flex justify-content-center p-2 fs-5 fw-bold ">
            {" "}
            {userName}
          </p>
          {role === "0" && (
            <div className="search-bar d-flex justify-content-center">
              <input
                type="text"
                id="searchInput"
                autoComplete="off"
                placeholder="Search client name/bride name/groom name"
                name="reserver"
                value={searchSidebar.reserver}
                onChange={handleChange}
              />
            </div>
          )}

          <div
            id="manubar1"
            className="scrollbar-thin scroll-table scroll-main"
          >
            {(role === "0" || role === "3" || filterValue[1]) && showText && (
              <a onClick={() => setmodel(true)}>
                <CiMusicNote1 className="me-4 fs-4" />
                <span>Tax Mode</span>
              </a>
            )}
            {role !== "0" && (
              <Link
                to={
                  role === "1"
                    ? "/kitchen_main"
                    : role === "2"
                    ? "/sales_main"
                    : role === "3"
                    ? "/accounting_main"
                    : ""
                }
                onClick={closeSidebar}
                className={`  ${
                  (handleLinkClick("/kitchen_main") ||
                    handleLinkClick("/accounting_main") ||
                    handleLinkClick("/sales_main") ||
                    handleLinkClick("/")) &&
                  " active-font active"
                }`}
              >
                <FaHome className={`  me-4 fs-4  `} />
                <span>Home</span>
              </Link>
            )}
            {role === "0" && (
              <>
                <Link
                  to="/calender"
                  className={`  ${
                    (handleLinkClick("/calender") || handleLinkClick("/")) &&
                    " active-font active"
                  }`}
                  onClick={closeSidebar}
                >
                  <MdOutlineNoteAlt className={`  me-4 fs-4 `} />
                  <span>Reservation</span>
                </Link>
                {/* <Link
                  to="/user"
                  className={`  ${
                    (handleLinkClick("/user") ||
                      handleLinkClick("/add_user")) &&
                    " active-font active"
                  }`}
                  onClick={closeSidebar}
                >
                  <LuUser className={`  me-4 fs-4 `} />
                  <span>User</span>
                </Link>
                <Link
                  to="/user_role"
                  className={`  ${
                    (handleLinkClick("/user_role") ||
                      location.pathname.startsWith("/edit_user_role") ||
                      handleLinkClick("/add_user_role")) &&
                    " active-font active"
                  }`}
                  onClick={closeSidebar}
                >
                  <GrUserSettings className={` me-4 fs-4`} />
                  <span>User Role</span>
                </Link> */}
              </>
            )}
            {(role === "0" || filterValue[0]) && (
              <div className="">
                <div
                  onClick={getState1}
                  className={`sidebar-a ${
                    (handleLinkClick("/billing") ||
                      location.pathname.startsWith("/edit_billing") ||
                      location.pathname.startsWith("/create_billing") ||
                      location.pathname.startsWith("/sub_invoice_history") ||
                      handleLinkClick("/invoice_history")) &&
                    "active"
                  }`}
                >
                  <PiNotepadBold className={` me-4 fs-4`} />
                  <span className={`  ${state1 && " active-font"}`}>
                    Billing
                  </span>
                  <span className="up-arrow">
                    {!state5 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state1 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/billing"
                      className={`sub-sidebar ${
                        (handleLinkClick("/billing") ||
                          location.pathname.startsWith("/edit_billing") ||
                          location.pathname.startsWith("/create_billing")) &&
                        " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/billing") ||
                            location.pathname.startsWith("/edit_billing") ||
                            location.pathname.startsWith("/create_billing")) &&
                          " active-font "
                        }`}
                      >
                        Billing
                      </span>
                    </Link>
                    <Link
                      to="/invoice_history"
                      className={`sub-sidebar ${
                        (handleLinkClick("/invoice_history") ||
                          location.pathname.startsWith(
                            "/sub_invoice_history"
                          )) &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/invoice_history") ||
                            location.pathname.startsWith(
                              "/sub_invoice_history"
                            )) &&
                          " active-font  "
                        }`}
                      >
                        Invoice History
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {role !== "1" && (
              <Link
                to="/calender_filter"
                className={`  ${
                  handleLinkClick("/calender_filter") && " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <LuCalendarHeart className={` me-4 fs-4`} />
                <span className={``}>Calender Filter</span>
              </Link>
            )}
            {role === "1" && (
              <Link
                to="/kcalender_filter"
                className={`  ${
                  handleLinkClick("/kcalender_filter") && " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <LuCalendarHeart className={` me-4 fs-4`} />
                <span className={``}>Calender Filter</span>
              </Link>
            )}
            {(role === "0" || filterValue[2]) && (
              <div className="">
                <div
                  onClick={getState}
                  className={`sidebar-a ${
                    (handleLinkClick("/add_menu") ||
                      handleLinkClick("/menu") ||
                      location.pathname.startsWith("/edit_all_menu") ||
                      handleLinkClick("/station") ||
                      handleLinkClick("/create_station") ||
                      location.pathname.startsWith("/edit_menu_type") ||
                      location.pathname.startsWith("/edit_station") ||
                      handleLinkClick("/food_drink") ||
                      handleLinkClick("/add_food_drink") ||
                      handleLinkClick("/create_menu") ||
                      handleLinkClick("/menu_type")) &&
                    "active"
                  }`}
                >
                  <LiaGlassCheersSolid className={` me-4 fs-4`} />
                  <span className={`  ${state && " active-font"}`}>Menu</span>
                  <span className="up-arrow">
                    {!state4 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/menu"
                      className={`sub-sidebar ${
                        (handleLinkClick("/add_menu") ||
                          location.pathname.startsWith("/edit_all_menu") ||
                          handleLinkClick("/menu")) &&
                        " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/add_menu") ||
                            location.pathname.startsWith("/edit_all_menu") ||
                            handleLinkClick("/menu")) &&
                          " active-font "
                        }`}
                      >
                        Add Menu
                      </span>
                    </Link>
                    <Link
                      to="/menu_type"
                      className={`sub-sidebar ${
                        (handleLinkClick("/menu_type") ||
                          location.pathname.startsWith("/edit_menu_type") ||
                          handleLinkClick("/create_menu")) &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/menu_type") ||
                            location.pathname.startsWith("/edit_menu_type") ||
                            handleLinkClick("/create_menu")) &&
                          " active-font  "
                        }`}
                      >
                        Create Menu Type{" "}
                      </span>
                    </Link>

                    <Link
                      to="/station"
                      className={`sub-sidebar ${
                        (handleLinkClick("/station") ||
                          location.pathname.startsWith("/edit_station") ||
                          handleLinkClick("/create_station")) &&
                        " active-sub-link"
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/station") ||
                            location.pathname.startsWith("/edit_station") ||
                            handleLinkClick("/create_station")) &&
                          " active-font "
                        }`}
                      >
                        Create Station
                      </span>
                    </Link>
                    {/* <Link
                      to="/food_drink"
                      className={`sub-sidebar ${
                        (handleLinkClick("/food_drink") ||
                          handleLinkClick("/add_food_drink")) &&
                        " active-sub-link"
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/food_drink") ||
                            handleLinkClick("/add_food_drink")) &&
                          " active-font  "
                        }`}
                      >
                        Food Menu
                      </span>
                    </Link> */}
                  </div>
                )}
              </div>
            )}
            {role !== "0" && (
              <div className="">
                <div
                  onClick={getState2}
                  className={`sidebar-a ${
                    (handleLinkClick("/profile") ||
                      handleLinkClick("/change_password")) &&
                    "active"
                  }`}
                >
                  <ImProfile className={` me-4 fs-4`} />
                  <span className={`  ${state2 && " active-font"}`}>
                    Profile
                  </span>
                  <span className="up-arrow">
                    {!state3 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state2 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/profile"
                      className={`sub-sidebar ${
                        handleLinkClick("/profile") && " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/profile") && " active-font "
                        }`}
                      >
                        Change Proﬁle Picture
                      </span>
                    </Link>
                    <Link
                      to="/change_password"
                      className={`sub-sidebar ${
                        handleLinkClick("/change_password") &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/change_password") &&
                          " active-font  "
                        }`}
                      >
                        Change Password
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {/* {role === "0" && (
              <Link
                to="/profile"
                className={`  ${
                  handleLinkClick("/profile") && " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <ImProfile className={` me-4 fs-4`} />
                <span>Profile</span>
              </Link>
            )} */}
            {(role === "0" || filterValue[1]) && (
              <Link
                to="/sound_light"
                className={`  ${
                  (handleLinkClick("/sound_light") ||
                    handleLinkClick("/add_sound_light")) &&
                  " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <CiMusicNote1 className={` me-4 fs-4`} />
                <span>Sound & Light</span>
              </Link>
            )}
            {(role === "0" || filterValue[3]) && (
              <Link
                to="/Create_event_type"
                className={`  ${
                  handleLinkClick("/Create_event_type") && " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <BsStars className={` me-4 fs-4`} />
                <span>Check List Event</span>
              </Link>
            )}
            {/* {(role === "0" || filterValue[3]) && (
              <div className="">
                <div
                  onClick={getState1}
                  className={`sidebar-a ${
                    (handleLinkClick("/Create_event_type") ||
                      handleLinkClick("/supplires") ||
                      handleLinkClick("/add_suppliers") ||
                      location.pathname.startsWith("/edit_suppliers") ||
                      location.pathname.startsWith("/edit_extras") ||
                      handleLinkClick("/add_extras") ||
                      handleLinkClick("/extras")) &&
                    "active"
                  }`}
                >
                  <BsStars className={` me-4 fs-4`} />
                  <span className={`  ${state1 && " active-font"}`}>
                    Check List Event
                  </span>
                  <span className="up-arrow">
                    {!state5 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state1 === true && (
                  <div className="dropdown-content">
                    <div className="">
                      <Link
                        to="/Create_event_type"
                        className={`sub-sidebar ${
                          handleLinkClick("/Create_event_type") &&
                          " active-sub-link "
                        }`}
                        onClick={closeSidebar}
                      >
                        <span
                          className={` ms-5 ${
                            handleLinkClick("/Create_event_type") &&
                            " active-font  "
                          }`}
                        >
                          Create Event Types
                        </span>
                      </Link>
                    </div>
                    <Link
                      to="/supplires"
                      className={`sub-sidebar ${
                        (handleLinkClick("/supplires") ||
                          location.pathname.startsWith("/edit_suppliers") ||
                          handleLinkClick("/add_suppliers")) &&
                        " active-sub-link  "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/supplires") ||
                            location.pathname.startsWith("/edit_suppliers") ||
                            handleLinkClick("/add_suppliers")) &&
                          " active-font "
                        }`}
                      >
                        Suppliers{" "}
                      </span>
                    </Link>
                    <Link
                      to="/extras"
                      className={`sub-sidebar ${
                        (handleLinkClick("/extras") ||
                          location.pathname.startsWith("/edit_extras") ||
                          handleLinkClick("/add_extras")) &&
                        " active-sub-link"
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/extras") ||
                            location.pathname.startsWith("/edit_extras") ||
                            handleLinkClick("/add_extras")) &&
                          " active-font  "
                        }`}
                      >
                        Extras
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )} */}
            {role !== "0" && role !== "3" && (
              <Link
                to={
                  role === "0"
                    ? "/reporting"
                    : role === "1"
                    ? "/kitchen_reporting"
                    : role === "2"
                    ? "/sales_reporting"
                    : // : role === "3"
                      // ? "/accounting_reporting"
                      "/reporting"
                }
                className={`  ${
                  (handleLinkClick("/reporting") ||
                    handleLinkClick("/sales_reporting") ||
                    // handleLinkClick("/reporting") ||
                    handleLinkClick("/kitchen_reporting")) &&
                  " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <GoChecklist className={` me-4 fs-4`} />
                <span> Reporting</span>
              </Link>
            )}
            {role === "0" && (
              <div className="">
                <div
                  onClick={getState4}
                  className={`sidebar-a ${
                    (handleLinkClick("/reporting") ||
                      handleLinkClick("/income_reports")) &&
                    "active"
                  }`}
                >
                  <GoChecklist className={` me-4 fs-4`} />
                  <span className={`  ${state8 && " active-font"}`}>
                    Reports
                  </span>
                  <span className="up-arrow">
                    {!state9 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state8 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/reporting"
                      className={`sub-sidebar ${
                        handleLinkClick("/reporting") && " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/reporting") && " active-font "
                        }`}
                      >
                        Reporting
                      </span>
                    </Link>
                    <Link
                      to="/transction"
                      className={`sub-sidebar ${
                        handleLinkClick("/transction") && " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/transction") && " active-font "
                        }`}
                      >
                        Transaction
                      </span>
                    </Link>
                    <Link
                      to="/income_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/income_reports") &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/income_reports") && " active-font  "
                        }`}
                      >
                        Income Reports
                      </span>
                    </Link>
                    <Link
                      to="/discount_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/discount_reports") &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/discount_reports") &&
                          " active-font  "
                        }`}
                      >
                        Discount Reports
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {role === "3" && (
              <div className="">
                <div
                  onClick={getState4}
                  className={`sidebar-a ${
                    (handleLinkClick("/accounting_reporting") ||
                      handleLinkClick("/income_reports")) &&
                    "active"
                  }`}
                >
                  <GoChecklist className={` me-4 fs-4`} />
                  <span className={`  ${state8 && " active-font"}`}>
                    Reports
                  </span>
                  <span className="up-arrow">
                    {!state9 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state8 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/accounting_reporting"
                      className={`sub-sidebar ${
                        handleLinkClick("/accounting_reporting") &&
                        " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/accounting_reporting") &&
                          " active-font "
                        }`}
                      >
                        Reporting
                      </span>
                    </Link>
                    <Link
                      to="/income_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/income_reports") &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/income_reports") && " active-font  "
                        }`}
                      >
                        Income Reports
                      </span>
                    </Link>
                    <Link
                      to="/discount_reports"
                      className={`sub-sidebar ${
                        handleLinkClick("/discount_reports") &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/discount_reports") &&
                          " active-font  "
                        }`}
                      >
                        Discount Reports
                      </span>
                    </Link>
                    <Link
                      to="/account_sub_invoice_history"
                      className={`sub-sidebar ${
                        (handleLinkClick("/account_sub_invoice_history")) &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/account_sub_invoice_history") ) &&
                          " active-font  "
                        }`}
                      >
                        History for Invoice
                      </span>
                    </Link>
                    <Link
                      to="/history_payment"
                      className={`sub-sidebar ${
                        (handleLinkClick("/history_payment") ||
                          location.pathname.startsWith(
                            "/sub_history_payment"
                          )) &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/history_payment") ||
                            location.pathname.startsWith(
                              "/sub_history_payment"
                            )) &&
                          " active-font  "
                        }`}
                      >
                        History of Payment
                      </span>
                    </Link>
                    <Link
                      to="/account_statement"
                      className={`sub-sidebar ${
                        handleLinkClick("/account_statement") &&
                        " active-sub-link   "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/account_statement") &&
                          " active-font  "
                        }`}
                      >
                        Account Statement
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {/* {role !== "0" && filterValue[5] && (
              <Link
                to="/setting"
                className={`  ${
                  handleLinkClick("/setting") && " active-font active"
                }`}
                onClick={closeSidebar}
              >
                <CiSettings className={` me-4 fs-4`} /> <span>Settings</span>
              </Link>
            )} */}
            {role === "0" && (
              <div className="">
                <div
                  onClick={getState3}
                  className={`sidebar-a ${
                    (handleLinkClick("/user") ||
                      handleLinkClick("/add_user") ||
                      location.pathname.startsWith("/edit_user_role") ||
                      handleLinkClick("/user_role") ||
                      handleLinkClick("/profile") ||
                      handleLinkClick("/add_user_role") ||
                      handleLinkClick("/setting")) &&
                    "active"
                  }`}
                >
                  <CiSettings className={` me-4 fs-4`} />
                  <span className={`  ${state6 && " active-font"}`}>
                    {" "}
                    All Settings
                  </span>
                  <span className="up-arrow">
                    {!state7 ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </span>
                </div>
                {state6 === true && (
                  <div className="dropdown-content">
                    <Link
                      to="/setting"
                      className={`sub-sidebar ${
                        handleLinkClick("/setting") && " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/setting") && " active-font "
                        }`}
                      >
                        Settings
                      </span>
                    </Link>
                    <Link
                      to="/user"
                      className={`sub-sidebar ${
                        (handleLinkClick("/user") ||
                          handleLinkClick("/add_user")) &&
                        " active-sub-link "
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/user") ||
                            handleLinkClick("/add_user")) &&
                          " active-font "
                        }`}
                      >
                        User
                      </span>
                    </Link>

                    <Link
                      to="/user_role"
                      className={`sub-sidebar ${
                        (handleLinkClick("/user_role") ||
                          location.pathname.startsWith("/edit_user_role") ||
                          handleLinkClick("/add_user_role")) &&
                        " active-sub-link"
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          (handleLinkClick("/user_role") ||
                            location.pathname.startsWith("/edit_user_role") ||
                            handleLinkClick("/add_user_role")) &&
                          " active-font "
                        }`}
                      >
                        User Role
                      </span>
                    </Link>
                    <Link
                      to="/profile"
                      className={`sub-sidebar ${
                        handleLinkClick("/profile") && " active-sub-link"
                      }`}
                      onClick={closeSidebar}
                    >
                      <span
                        className={` ms-5 ${
                          handleLinkClick("/profile") && " active-font  "
                        }`}
                      >
                        Profile
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            <a onClick={logoutButton}>
              <IoMdPower className={` me-4 fs-4`} /> <span>Logout</span>
            </a>
          </div>
        </div>
        <div className="svg-container"></div>
      </div>
    </>
  );
};

export default Sidebar;
