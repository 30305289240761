import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import {
  MainKitchenPdfDownload,
  SalesreportingPdfDownload,
  getToken,
} from "../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import SalesGetEventsPDF from "../../PDF/SalesGetEventsPDF";
import Pusher from "pusher-js";


const SalesReportingTable = ({ dispatch, res_token, res_pdf_data }) => {
  const [length, setLength] = useState(0);
  const [socket, setSocket] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, []);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  const api_token = sessionStorage.getItem("api_token");
  const id = sessionStorage.getItem("member_id");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showBill");
      var table1 = $("#showBill").DataTable({
        columnDefs: [{ orderable: false, targets: 5 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: `/api/all_sales_reporting_data/${id}`,
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "eventID",
            width: "6%",
          },
          {
            data: "clientName",
            width: "23%",
          },
          {
            data: "startDate",
            width: "23%",
          },
          {
            data: "eventday",
            width: "23%",
          },
          {
            data: "noOfGuests",
            width: "23%",
          },
          {
            data: null,
            width: "10%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-download fs-4 ps-2 me-0 fw-bold cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showBill tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.eventID) {
          dispatch(SalesreportingPdfDownload(rowData.eventID));
        }
      });
      $("#showBill_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  useEffect(() => {
    if (res_pdf_data?.data?.status === true) {
      const pdfUrl = res_pdf_data?.data?.url;
      window.open(pdfUrl);
      // const getData = res_pdf_data?.data?.data[0];
      // downloadPDFData(getData);
      // const fileName = "report.pdf";

      // axios
      //   .get(res_pdf_data?.data?.url, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     fileDownload(res.data, fileName);
      //   })
      //   .catch((error) => {
      //     console.error("Error downloading the file:", error);
      //   });

      // setTimeout(() => {
      //   res_pdf_data.loading = true;
      //   res_pdf_data.data = {};
      // }, 1500);
    } else if (res_pdf_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    }
  }, [res_pdf_data]);
 return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ? `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
    }
    .table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }

td:nth-of-type(1):before {
  ${length !== 0 ? `content: "#";` : ``}
 
}
td:nth-of-type(2):before {
  content: "Name of Resever";
 
}
td:nth-of-type(3):before {
  content: "Event Date";
}
td:nth-of-type(4):before {
  content: "Event Day";
}
td:nth-of-type(5):before {
  content: "No. of Guests";
}
td:nth-of-type(6):before {
  content: "Action";
}
}
`}
      </style>
      <Toaster />
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="role-table">
                <table
                  id="showBill"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Name of Resever
                      </th>
                      <th className="thead-color" scope="col">
                        Event Date
                      </th>
                      <th className="thead-color" scope="col">
                        Event Day
                      </th>
                      <th className="thead-color" scope="col">
                        No. of Guests
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf_data: state?.Sales_reporting_PDF_Data_reducer,
});

export default connect(mapStateToProps)(SalesReportingTable);
