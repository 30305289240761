import React from 'react'

const SmallLoader = () => {
  return (
    <> <div className="container-fluid bg-white" style={{height:"10vh"}}>
       <div className="row justify-content-center">
    <div className="col-1">
      <span className="mini-loader"></span>
    </div>
    </div>
  </div>
    </>
  )
}

export default SmallLoader
