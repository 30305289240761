import React from "react";
import logo from "../../images/pdf_logo.png";
const GetEventInvoicePDF = ({ getData }) => {
  const totalSum = Object.values(getData?.stations)
    .map((value) => Number(value))
    .reduce((acc, curr) => acc + curr, 0);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to get the correct month (January is 0)
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const totalDiscount =
    Number(getData?.spacial_dis) + Number(getData?.discount_per_user_total);

  // const total =
  //   Number(
  //     Number(getData?.white_hall_payment) +
  //       Number(getData?.total_gst) +
  //       Number(getData?.spacial_dis)
  //   ) - totalDiscount;

  // const totalGST = (total * 16) / 100;
  // const Subtotal = total - totalGST - totalDiscount;
  const MPrice = Number(getData?.m_price) /1.16;

  const total =
    (getData?.eventType === "Charity"
      ? Number(getData?.noOfGuests) * Number(Number(getData.m_price))
      : Number(getData?.noOfGuests) * Number(getData.m_price)) +
    Number(Number(getData?.noOfGuests) * Number(getData.s_price)) +
    Number(getData.sound_light_payment) +
    Number(getData?.food_extra_cost) +
    Number(getData?.sound_extra_cost) +
    Number(getData.service_charge);
  return (
    <>
      <style>
        {`
    .wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        background-color: white !important;
        // font-family: auto !important;
      }
      
      .receipt-box {
        width: 760px;
        display: flex;
        flex-direction: column;
        margin: 35px 35px;
      }
      
     .th,.td{
        padding:2px!important;
        border: 3px double ;
      }
      
      .p{
        margin :0 !important;
        color: black !important;
        padding:0px 5px!important;
        font-size:14px;
      }
      th,td{
        padding: 1px 4px !important;
        text-align:center;
        font-size: 12px;
        
        border:0.5px solid gray;
      }
    `}
      </style>
      <div className="wrapper">
        <div className="receipt-box">
          <div className="actual-receipt px-4" style={{ height: "750px" }}>
            <div className="">
              <div className="container p-2 text-center">
                <img src={logo} style={{ width: "190px" }} />
                <h5 className="mt-3 fw-bold text-muted">INVOICE</h5>
              </div>
              <div className="container-fluid">
                <p className="text-black mb-0" style={{ fontSize: "13px" }}>
                  {" "}
                  Invoice : {getData?.bill_invoice_no}
                </p>
                <p className="text-black mb-0" style={{ fontSize: "13px" }}>
                  {" "}
                  Date :{formatDate(getData.created_at)}
                </p>
              </div>
              <div className="container-fluid text-end">
                <p className="text-black mb-0 " style={{ fontSize: "13px" }}>
                  المحترمين {getData?.clientName}
                </p>
              </div>
              <div className="container mt-2">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%", borderRight: "0px" }}>
                        Clarification
                      </th>
                      <th style={{ width: "15%", borderRight: "0px" }}>Time</th>
                      <th style={{ width: "15%", borderRight: "0px" }}>Site</th>
                      <th style={{ width: "30%", borderRight: "0px" }}>
                        DESCRIPTION
                      </th>
                      <th style={{ width: "15%" }}>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        1
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {getData.noOfGuests}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {" "}
                        {getData?.eventType === "Charity"
                          ? Number(Number(getData.m_price)).toFixed(2)
                          : Number(getData.m_price).toFixed(2)}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        Menu
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {getData?.eventType === "Charity"
                          ? (
                              Number(getData?.noOfGuests) *
                              Number(Number(getData.m_price))
                            ).toFixed(2)
                          : (
                              Number(getData?.noOfGuests) *
                              Number(getData.m_price)
                            ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        2
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {getData.noOfGuests}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {Number(getData.s_price).toFixed(2)}
                      </td>

                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        Station
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {(
                          Number(getData?.noOfGuests) * Number(getData.s_price)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        3
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        -
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {Number(getData.sound_light_payment).toFixed(2)}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        Sound & Light
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {Number(getData.sound_light_payment).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        4
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        -
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {Number(getData?.food_extra_cost).toFixed(2)}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        Food Extra Cost
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {Number(getData?.food_extra_cost).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        5
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        -
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {Number(getData?.sound_extra_cost).toFixed(2)}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        Sound Extra Cost{" "}
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {Number(getData?.sound_extra_cost).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        6
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        -
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        {Number(getData.service_charge).toFixed(2)}
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        Service Charge
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {Number(getData.service_charge).toFixed(2)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        7
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        -
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                      {getData?.eventType === "Charity"?
                        totalGST.toFixed(2):
                        Number(getData?.total_gst).toFixed(2)
                        }
                      </td>
                      <td style={{ borderTop: "0px", borderRight: "0px" }}>
                        GST
                      </td>
                      <td style={{ borderTop: "0px" }}>
                        {getData?.eventType === "Charity"?
                        totalGST.toFixed(2):
                        Number(getData?.total_gst).toFixed(2)
                        }
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        colspan="4"
                        className="text-end fw-bold"
                        style={{
                          fontSize: "11px",
                          borderTop: "0px",
                          borderRight: "0px",
                        }}
                      >
                        SUB TOTAL
                      </td>
                      <td style={{ fontSize: "11px", borderTop: "0px" }}>
                        {total.toFixed(2)}

                        {/* {(Number(getData.white_hall_payment)+Number(getData.discount_per_user_total)+Number(getData.total_gst)).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="4"
                        className="text-end fw-bold"
                        style={{
                          fontSize: "11px",
                          borderTop: "0px",
                          borderRight: "0px",
                        }}
                      >
                        DISCOUNT
                      </td>
                      <td style={{ fontSize: "11px", borderTop: "0px" }}>
                        {(
                          Number(getData?.spacial_dis) +
                          Number(Number(getData.discount_per_user_total))
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="4"
                        className="text-end fw-bold"
                        style={{
                          fontSize: "11px",
                          borderTop: "0px",
                          borderRight: "0px",
                        }}
                      >
                        TAX 16%
                      </td>
                      <td style={{ fontSize: "11px", borderTop: "0px" }}>
                        {Number(getData?.total_gst).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="4"
                        className="text-end fw-bold"
                        style={{
                          fontSize: "11px",
                          borderTop: "0px",
                          borderRight: "0px",
                        }}
                      >
                        TOTAL DUE
                      </td>
                      <td style={{ fontSize: "11px", borderTop: "0px" }}>
                        {Number(getData?.paid).toFixed(5)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p
                  className="mb-0  text-black text-center fw-bold"
                  style={{ fontSize: "12px" }}
                >
                  Thank you for your business!
                </p>
              </div>
              <div className="container-fluid  text-center mt-5">
                <p className="text-black mb-0">
                  قاعة الساحة البيضاء للافراح والمناسبات
                </p>
                <p className="text-black mb-0">طريق المطار – عمان – الاردن</p>
                <p className="text-black mb-0">
                  رقم التليفون : 0779767666 / 0799767666
                </p>
                <p className="text-black mb-0">ضريبة المبيعات : 1262491</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetEventInvoicePDF;
