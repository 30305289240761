import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import { GetUserByIdData, getToken } from "../../../Store/Action/useFetchData";
import personImage from "../../../../images/image.png";
const ViewUser = ({
  setViewUserModel,
  viewUserModal,
  viewUserModalId,
  dispatch,
  res_get_menu,
}) => {
  const [userData, setUserData] = useState({
    user_name: "",
    user_password: "",
    email: "",
    user_image: "",
    phone: "",
    role: "",
  });

  useEffect(() => {
    dispatch(getToken());
    dispatch(GetUserByIdData(viewUserModalId));
  }, [dispatch, viewUserModalId]);
  // console.log(res_get_menu, "res_get_menu");
  useEffect(() => {
    if (viewUserModalId && res_get_menu?.data?.status === true) {
      setUserData({
        user_name: res_get_menu?.data?.data?.name,
        email: res_get_menu?.data?.data?.email,
        user_image: res_get_menu?.data?.data?.profilePicture,
        phone: `+ ${res_get_menu?.data?.data?.phoneNumber}`,
        role: res_get_menu?.data?.data?.userRole,
      });
    }
  }, [viewUserModalId, res_get_menu]);
  const handleClose = () => {
    setViewUserModel(false);
    res_get_menu.loading = true;
    res_get_menu.data = {};
  };
  return (
    <div>
      <div
        className={`modal ${viewUserModal ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">View User</h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_menu?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                <div className="row mb-3 mt-2 mt-sm-5">
                  <div className="col-lg-4 col-12 ">
                    <label for="basic-url" className="form-label  label">
                      NAME
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      // type="text"
                      autoComplete="off"
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      name="user_name"
                      readOnly
                      //   onChange={handleChange}
                      value={userData?.user_name}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                {/* <div className="row mb-3">
                  <div className="col-lg-4 col-12 ">
                    <label for="basic-url" className="form-label  label">
                      PASSWORD
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="input-group price price-768">
                      <input
                        type={passwordType}
                        autoComplete="off"
                        className="form-control inpt-border-trans"
                        name="user_password"
                        onChange={handleChange}
                        value={userData?.user_password}
                      />
                      <span
                        className="input-group-text input-group-text-768 inpt-border-trans-pass inpt-lable justify-content-center "
                        style={{ cursor: "pointer" }}
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <BsFillEyeSlashFill
                            className="fs-5"
                            style={{ margin: "2px" }}
                          />
                        ) : (
                          <BsFillEyeFill
                            className="fs-5"
                            style={{ margin: "2px" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      EMAIL
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      // type="email"
                      autoComplete="off"
                      className="form-control inpt inpt-768 "
                      id="floatingInput"
                      name="email"
                      readOnly
                      //   onChange={handleChange}
                      value={userData?.email}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      PHONE
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      // type="email"
                      autoComplete="off"
                      className="form-control inpt inpt-768 "
                      id="floatingInput"
                      // name="email"
                      readOnly
                      //   onChange={handleChange}
                      value={userData?.phone}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      PROFILE PICTURE
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <label
                      for="formFile"
                      className="col-form-label  fw-bold  "
                    >
                      <img
                        src={
                          userData?.user_image !== ""
                            ? `http://whitehall.website/api/uploads/profile_image/${userData?.user_image}`
                            : personImage
                        }
                        alt="profile_image"
                        className="rounded-3  profile-image"
                      />
                    </label>
                    {/* <input
                      className="form-control d-none"
                      type="file"
                      id="formFile"
                      name="user_image"
                      //   onChange={handleChange}
                    /> */}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      USER ROLES
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      // type="email"
                      autoComplete="off"
                      className="form-control inpt inpt-768 "
                      id="floatingInput"
                      name="email"
                      readOnly
                      //   onChange={handleChange}
                      value={
                        userData?.role === "0"
                          ? "Super Admin"
                          : userData?.role === "1"
                          ? "Kitchen User"
                          : userData?.role === "2"
                          ? "Sales User"
                          : userData?.role === "3"
                          ? "Accounting User"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_menu: state?.get_User_by_ID_Data_reducer,
});
export default connect(mapStateToProps)(ViewUser);
