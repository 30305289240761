import React, { useState, useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";

const DrinkTable = ({
  dispatch,
  res_token,
  setCompanyType,
  companyType,
  setEditFoodAndDrink,
  setType,
  setDrinkType,
  setViewFoodAndDrink,
  setDrinkCompanyType,
  editFoodAndDrink,
}) => {
  const [length, setLength] = useState(0);
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, [editFoodAndDrink]);
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showextra");
      var table1 = $("#showextra").DataTable({
        columnDefs: [{ orderable: false, targets: 3 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        type: ["0"],
        ajax: {
          url: "/api/all_foodanddrinks_data?type=0",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          dataSrc: "food_drink_data",
        },
        columns: [
          {
            data: "id",
            width: "6%",
          },
          {
            data: null,
            width: "45%",
            render: function (data) {
              // if (data?.food_menu_name !== null) {
                return `${data.food_menu_name}`;
              // } else {
              // }
            },
          },
          {
            data: null,
            width: "45%",
            render: function (data) {
              // if (data.food_menu_price !== 0.0)
                return `${data.food_menu_price} JOD`;
            },
          },
          // {
          //   data: null,
          //   width: "45%",
          //   render: function (data) {
          //     if (data) {
          //       if (data?.food_menu_name !== null) {
          //         return `${data?.food_menu_name}`;
          //       } else if (data?.hard_drink_menu_name !== null) {
          //         return `${data?.hard_drink_menu_name}`;
          //       } else if (data?.soft_drink_menu_name !== null) {
          //         return `${data?.soft_drink_menu_name}`;
          //       }
          //     }
          //   },
          // },
          // {
          //   data: null,
          //   width: "45%",
          //   render: function (data) {
          //     if (data) {
          //       if (data?.food_menu_name !== null) {
          //         return `${data?.food_menu_price} JOD`;
          //       } else if (data?.hard_drink_menu_name !== null) {
          //         return `${data?.hard_drink_menu_price} JOD`;
          //       } else if (data?.soft_drink_menu_name !== null) {
          //         return `${data?.soft_drink_menu_price} JOD`;
          //       }
          //     }
          //   },
          // },
          {
            data: null,
            width: "45%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="viewExtra" value="' +
                data.id +
                '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editExtra" value="' +
                data.id +
                '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
              );
            },
            sorting: false,
          },
          // {
          //   data: null,
          //   width: "18%",
          //   render: function (data) {
          //     //   console.log(data, "data");

          //     return (
          //       '<div class="d-flex"><span  id="viewExtra" value="' +
          //       data.id +
          //       '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editExtra" value="' +
          //       data.id +
          //       '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteExtra" value="' +
          //       data.id +
          //       '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
          //     );
          //   },
          // },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          // console.log("Table length:", tableLength);
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showextra tbody").on("click", "#deleteExtra", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          //   setDeleteExtraModel(true);
          //   setDeleteExtraModelId(rowData.id);
        }
      });
      $("#showextra tbody").on("click", "#viewExtra", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setViewFoodAndDrink(true);
          setDrinkType(rowData?.id);
        }
      });

      $("#showextra tbody").on("click", "#editExtra", function () {
        var row = $(this).parents("tr")[0];
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setEditFoodAndDrink(true);
          setType(rowData.id);
          setCompanyType("1");
        }
      });
      $("#showextra_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (
    <>
     <style>
          {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "Food Name";
           
          }
          td:nth-of-type(3):before {
            content: "Food Price";
          }
          td:nth-of-type(4):before {
            content: "Action";
          }
          }
        `}
        </style>
      <div className="role-table">
        <table
          id="showextra"
          className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
        >
          <thead>
            <tr>
              <th className="thead-color" scope="col">
                #
              </th>
              {/* <th className="thead-color" scope="col">
                Type
              </th> */}
              <th className="thead-color" scope="col">
                Food Name
              </th>
              <th className="thead-color" scope="col">
                Food Price
              </th>
              <th className="thead-color" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="table-body"></tbody>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(DrinkTable);
