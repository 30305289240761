import React, { useEffect, useState } from "react";
import pdf from "../../../../../images/pdf.png";
import axios from "axios";
import fileDownload from "js-file-download";
import {
  AddSubInvoiceData,
  contract1PdfDownload,
  FinalAccountPayPdf,
  getInvoicePDF,
} from "../../../../Store/Action/useFetchData";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import GetEventContractPDF from "../../../../PDF/GetEventContractPDF";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import CatchReceiptPDF from "../../../../PDF/CatchReceiptPDF";
import GetEventInvoicePDF from "../../../../PDF/GetEventInvoicePDF";
import Loader from "../../../../Loader";
import FileSaver from "file-saver";
const ContractPdf = ({
  submitEventID,
  clientName,
  dispatch,
  res_pdf,
  res_pdf_invoice,
  res_add_invoice,
  res_Account_pdf
}) => {
  const role = sessionStorage.getItem("role");
  const [fileUrl, setFileUrl] = useState("");
  const [fileUrl2, setFileUrl2] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfBlob1, setPdfBlob1] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    toast.dismiss();
    res_add_invoice.loading = true
    res_add_invoice.data = {}
    res_pdf_invoice.loading = true
    res_pdf_invoice.data = {}
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(contract1PdfDownload(submitEventID));
    dispatch(getInvoicePDF(submitEventID, setLoading));
  if (role === "3") {
    dispatch(FinalAccountPayPdf(submitEventID));
  }
  }, []);

  useEffect(() => {
    if (res_pdf_invoice?.data?.status === true) {
      setLoading(true);
      handleSuccess(res_pdf_invoice.data.data);
    } else if (res_pdf_invoice?.data?.status === false) {
      setLoading(true);
      handleError(res_pdf_invoice?.data?.message);
    }
  }, [res_pdf_invoice]);

  useEffect(() => {
    if (res_add_invoice.loading === false) {
      setLoading(false);
    }
  }, [res_add_invoice]);

  useEffect(() => {
    if (res_pdf?.data?.status === true) {
      setFileUrl(res_pdf?.data?.data);
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
        // window.location.reload();
      }, 500);
    } else if (res_pdf?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
      }, 1500);
    }
  }, [res_pdf]);

  const handleSuccess = async (data) => {
    setFileUrl2(data);
    await GenerateCatchReciept(data);

    if (Number(data?.unpaid) === 0) {
      await GenerateInvoice(data);
    }

    setTimeout(() => {
      res_pdf_invoice.loading = true;
      res_pdf_invoice.data = {};
      // setLoading(false);
      // window.location.reload();
    }, 500);
  };

  const handleError = (message) => {
    const notify = () =>
      toast.error(message, {
        style: {
          padding: "10px",
          borderRadius: "30px",
        },
      });
    notify();
    setTimeout(() => {
      res_pdf_invoice.loading = true;
      res_pdf_invoice.data = {};
      // setLoading(false);
    }, 1500);
  };

  const GenerateCatchReciept = async (data) => {
    setLoading(true);
    try {
      const offScreenContainer = document.createElement("div");
      offScreenContainer.style.position = "absolute";
      offScreenContainer.style.left = "-9999px";
      document.body.appendChild(offScreenContainer);
      offScreenContainer.style.width = "768px";

      createRoot(offScreenContainer).render(<CatchReceiptPDF getData={data} />);

      // Wait for rendering to finish before generating PDF
      await new Promise((resolve) => setTimeout(resolve, 0));

      const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

      if (receipts.length === 0) {
        return;
      }

      const doc = new jsPDF("p", "mm", "a4");
      let yOffset = 0;

      for (let index = 0; index < receipts.length; index++) {
        const receipt = receipts[index];
        await new Promise((resolve) => {
          setTimeout(() => {
            html2canvas(receipt, {
              scale: 2,
              logging: false,
              useCORS: true,
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const width = doc.internal.pageSize.getWidth();
              const height = doc.internal.pageSize.getHeight();

              if (index > 0) {
                doc.addPage();
                yOffset = 0;
              }

              doc.addImage(imgData, "PNG", 0, yOffset, width, height);
              yOffset += height;

              resolve();
            });
          }, 100);
        });
      }

      const pdfBlob = doc.output("blob");
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfBlob(pdfBlobUrl);

      const formData = new FormData();
      formData.append("invoice_name", pdfBlob, "White_hall_catch_reciept.pdf");
      formData.append("bill_invoice_no", data?.bill_invoice_no);
      formData.append("bill_id", data?.bill_id);
      formData.append("event_id", data?.event_id);
      formData.append("client_name", data?.clientName);

      dispatch(AddSubInvoiceData(formData, setLoading));
      document.body.removeChild(offScreenContainer);
    } catch (error) {
      console.error(error);
    }
  };

  const GenerateInvoice = async (data) => {
    setLoading(true);
    try {
      const offScreenContainer = document.createElement("div");
      offScreenContainer.style.position = "absolute";
      offScreenContainer.style.left = "-9999px";
      document.body.appendChild(offScreenContainer);
      offScreenContainer.style.width = "768px";

      createRoot(offScreenContainer).render(
        <GetEventInvoicePDF getData={data} />
      );

      // Wait for rendering to finish before generating PDF
      await new Promise((resolve) => setTimeout(resolve, 0));

      const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

      if (receipts.length === 0) {
        return;
      }

      const doc = new jsPDF("p", "mm", "a4");
      let yOffset = 0;

      for (let index = 0; index < receipts.length; index++) {
        const receipt = receipts[index];
        await new Promise((resolve) => {
          setTimeout(() => {
            html2canvas(receipt, {
              scale: 2,
              logging: false,
              useCORS: true,
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const width = doc.internal.pageSize.getWidth();
              const height = doc.internal.pageSize.getHeight();

              if (index > 0) {
                doc.addPage();
                yOffset = 0;
              }

              doc.addImage(imgData, "PNG", 0, yOffset, width, height);
              yOffset += height;

              resolve();
            });
          }, 100);
        });
      }

      const pdfBlob = doc.output("blob");
      const pdfBlobUrl = URL.createObjectURL(pdfBlob);
      setPdfBlob1(pdfBlobUrl);

      const formData = new FormData();
      formData.append("invoice_name", pdfBlob, "White_hall_Invoice.pdf");
      formData.append("bill_invoice_no", data?.bill_invoice_no);
      formData.append("bill_id", data?.bill_id);
      formData.append("event_id", data?.event_id);
      formData.append("client_name", data?.clientName);

      dispatch(AddSubInvoiceData(formData, setLoading));
      document.body.removeChild(offScreenContainer);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageClick2 = async () => {
    if (pdfBlob) {
      const link = document.createElement("a");
      link.href = pdfBlob;
      link.download = "White_hall_catch_reciept.pdf";
      link.click();
    }
  };

  const handleImageClick3 = async () => {
    if (pdfBlob1) {
      const link = document.createElement("a");
      link.href = pdfBlob1;
      link.download = "White_hall_Invoice.pdf";
      link.click();
    }
  };

  const handleImageClick = async () => {
    try {
      const offScreenContainer = document.createElement("div");
      offScreenContainer.style.position = "absolute";
      offScreenContainer.style.left = "-9999px";
      document.body.appendChild(offScreenContainer);
      offScreenContainer.style.width = "768px";

      createRoot(offScreenContainer).render(
        <GetEventContractPDF getData={fileUrl} />
      );

      // Wait for rendering to finish before generating PDF
      await new Promise((resolve) => setTimeout(resolve, 0));

      const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

      if (receipts.length === 0) {
        return;
      }

      const doc = new jsPDF("p", "mm", "a4");
      let yOffset = 0;

      for (let index = 0; index < receipts.length; index++) {
        const receipt = receipts[index];
        await new Promise((resolve) => {
          setTimeout(() => {
            html2canvas(receipt, {
              scale: 2,
              logging: false,
              useCORS: true,
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const width = doc.internal.pageSize.getWidth();
              const height = doc.internal.pageSize.getHeight();

              if (index > 0) {
                doc.addPage();
                yOffset = 0;
              }

              doc.addImage(imgData, "PNG", 0, yOffset, width, height);
              yOffset += height;

              resolve();
            });
          }, 100);
        });
      }

      doc.save("White_Hall_Contract.pdf");
      document.body.removeChild(offScreenContainer);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccountDownloadPdf = async ()=>{
    const response = await axios({
      method: "get",
      url: res_Account_pdf?.data?.url, 
      responseType: "blob",
    });

    const blob = new Blob([response.data], { type: response.data.type });
    FileSaver.saveAs(blob, "White_Hall_Contract.pdf");
  }
  return (
    <>
      <Toaster />
      {loading ? (
        <Loader />
      ) : (
        loading === false && (
          <div className="">
            <div className=" container-fluid">
              <div className="  padding-left-21 form-margin-768">
                <div className=" form-in-768">
                  <div className="row mt-5">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label  label">
                        CONTRACT
                      </label>
                    </div>

                    <div className="col-12 col-lg-8 cursor-pontor">
                      <img
                        src={pdf}
                        onClick={handleImageClick}
                        alt="profile_image"
                      />
                    </div>
                  </div>
                  {role !== "3" ? (
                    <>
                      {Number(fileUrl2?.unpaid) === 0 ? (
                        <>
                          <div className="row mt-5">
                            <div className="col-12 col-lg-4 ">
                              <label
                                for="basic-url"
                                className="form-label  label"
                              >
                                CATCH RECEIPT
                              </label>
                            </div>

                            <div className="col-12 col-lg-8 cursor-pontor">
                              <img
                                src={pdf}
                                onClick={handleImageClick2}
                                alt="profile_image"
                              />
                            </div>
                          </div>

                          <div className="row mt-5">
                            <div className="col-12 col-lg-4 ">
                              <label
                                for="basic-url"
                                className="form-label  label"
                              >
                                INVOICE
                              </label>
                            </div>

                            <div className="col-12 col-lg-8 cursor-pontor">
                              <img
                                src={pdf}
                                onClick={handleImageClick3}
                                alt="profile_image"
                              />
                            </div>
                          </div>

                        </>
                      ) : (
                        <div className="row mt-5">
                          <div className="col-12 col-lg-4 ">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              RECEIPT
                            </label>
                          </div>

                          <div className="col-12 col-lg-8 cursor-pontor">
                            <img
                              src={pdf}
                              onClick={handleImageClick2}
                              alt="profile_image"
                            />
                          </div>
                        </div>
                      )}

                  <div
                    className="pt-xxl-0 pt-5 mb-5 centerText bottom-section fixed-bottom"
                    style={{ marginTop: "23px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768  "
                      onClick={handleImageClick}
                    >
                      CONTRACT
                    </button>
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768  "
                      onClick={handleImageClick2}
                    >
                      RECEIPT
                    </button>
                  </div>
                    </>
                  ) : role === "3" ? (
                    <>
                    <div className="row mt-5">
                      <div className="col-12 col-lg-4 ">
                        <label for="basic-url" className="form-label  label">
                          RECEIPT
                        </label>
                      </div>

                      <div className="col-12 col-lg-8 cursor-pontor">
                        <img
                          src={pdf}
                          onClick={handleAccountDownloadPdf}
                          alt="profile_image"
                        />
                      </div>
                    </div>

                  <div
                    className="pt-xxl-0 pt-5 mb-5 centerText bottom-section fixed-bottom"
                    style={{ marginTop: "23px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768  "
                      onClick={handleImageClick}
                    >
                      CONTRACT
                    </button>
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768  "
                      onClick={handleAccountDownloadPdf}
                    >
                      RECEIPT
                    </button>
                  </div>
                    </>
                  ) : (
                    ""
                  )}

                  {/* <div
                    className="pt-xxl-0 pt-5 mb-5 centerText bottom-section fixed-bottom"
                    style={{ marginTop: "23px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768  "
                      onClick={handleImageClick}
                    >
                      CONTRACT
                    </button>
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768  "
                      onClick={handleImageClick2}
                    >
                      RECEIPT
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  res_pdf: state?.contract_1_PDF_Data_reducer,
  res_pdf_invoice: state?.get_invoice_reducer,
  res_add_invoice: state?.Add_sub_invoice_data_reducer,
  res_Account_pdf: state?.Final_account_pay_PDF_data_reducer,
});

export default connect(mapStateToProps)(ContractPdf);
