import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Createmenu, getToken } from "../../../Store/Action/useFetchData";

const CreateMenu = ({ dispatch, res_user }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [createmenu, setCreateMenu] = useState({
    menu_name: "",
    menu_price: "",
  });
  useEffect(()=>{
     toast.dismiss();
  },[])
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setCreateMenu({ ...createmenu, [name]: value });
  };

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);

  const handlesubmit = () => {
    const submitData = {
      mname: createmenu?.menu_name,
      mprice: createmenu?.menu_price,
    };
    dispatch(Createmenu(submitData, setLoading));
  };
  useEffect(() => {
    if (res_user?.data?.status === true) {
      const notify = () =>
        toast.success(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
        navigate("/menu_type");
      }, 1500);
    } else if (res_user?.data?.status === false) {
      const notify = () =>
        toast.error(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
      }, 1500);
    }
  }, [res_user, navigate]);
  const handleReset = () => {
    setCreateMenu({
      menu_name: "",
      menu_price: "",
    });
  };
  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className="form-margin-768 padding-left-21 ">
          <h3 className="mb-4 mt-3 pt-5 fw-bold">Create Menu Type</h3>
          <div className="form-in-768">
            <div className="row ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  MENU NAME
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <input
                  type="text"
                  name="menu_name"
                  onChange={handleChange}
                  autoComplete="off"
                  value={createmenu.menu_name}
                  className="form-control inpt inpt-768 "
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  MENU PRICE
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <div className="input-group mb-3 price-jod price-jod-768">
                  <input
                    type="number"
                    autoComplete="off"
                    name="menu_price"
                    onChange={handleChange}
                    value={createmenu.menu_price}
                    className="form-control
                  inpt-border-trans"
                  />
                  <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable">
                    JOD
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section  display-above-768">
              <button
                type="button"
                className="btn btn-g me-2 btn-768  mb-4"
                onClick={() => {
                  res_user.loading = true;
                  res_user.data = {};
                  navigate("/menu_type");
                }}
              >
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-2 btn-768 mb-4"
                onClick={handleReset}
              >
                RESET
              </button>

              {loading ? (
                <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SAVE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handlesubmit}
                >
                  SAVE
                </button>
              )}
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768 ">
              {loading ? (
                <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SAVE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handlesubmit}
                >
                  SAVE
                </button>
              )}
              <button
                type="button"
                className="btn btn-g me-2 btn-768 mb-4"
                onClick={handleReset}
              >
                RESET
              </button>
              <button
                type="button"
                className="btn btn-g me-2 btn-768  mb-4"
                onClick={() => {
                  res_user.loading = true;
                  res_user.data = {};
                  navigate("/menu_type");
                }}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user: state?.Create_menu_reducer,
});
export default connect(mapStateToProps)(CreateMenu);
