import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DrinkTable from "./DrinkTable";
import FoodTable from "./FoodTable";
import EditFoodAndDrinks from "../Forms/Edit/EditFoodAndDrinks";
import ViewFoodAndDrink from "../Forms/View/ViewFoodAndDrink";

const FoodAndDrinkTable = () => {
  const [selectedMenu, setselectedMenu] = useState(0);
  const [companyType, setCompanyType] = useState("");
  const [editFoodAndDrink, setEditFoodAndDrink] = useState(false);
  const [viewFoodAndDrink, setViewFoodAndDrink] = useState(false);
  const [type, setType] = useState("");
  const [drinkType, setDrinkType] = useState("");
  const [DrinkCompanyType, setDrinkCompanyType] = useState("");
  return (
    <>
      {editFoodAndDrink && (
        <EditFoodAndDrinks
          editFoodAndDrink={editFoodAndDrink}
          type={type}
          companyType={companyType}
          setEditFoodAndDrink={setEditFoodAndDrink}
        />
      )}
      {viewFoodAndDrink && (
        <ViewFoodAndDrink
          viewFoodAndDrink={viewFoodAndDrink}
          drinkType={drinkType}
          setViewFoodAndDrink={setViewFoodAndDrink}
        />
      )}
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className=" form-margin-768">

              <div className={`d-grid gap-2 d-md-block `}>
                <div className="mt-0 mt-sm-3 btn_center">
                  <button
                    className={`btn ${
                      selectedMenu === 0 ? "large-black-btn" : " large-gray-btn"
                    } large-btn-768  ms-1 `}
                    type="button"
                    onClick={() => setselectedMenu(0)}
                  >
                    WHITE HALL
                  </button>
                  <button
                    className={`btn  btn_outsource large-btn-768  ${
                      selectedMenu === 1 ? "large-black-btn" : " large-gray-btn"
                    }  ms-1`}
                    type="button"
                    onClick={() => setselectedMenu(1)}
                  >
                    OUTSOURCE
                  </button>
                </div>
              </div>
              <div className="float-right add-role-btn-768">
                <Link to="/add_food_drink">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                    style={{ width: "203px" }}
                  >
                    + ADD FOOD MENU
                  </button>
                </Link>
              </div>
              </div>
              {selectedMenu === 0 && (
                <DrinkTable
                editFoodAndDrink={editFoodAndDrink}
                  setDrinkCompanyType={setDrinkCompanyType}
                  DrinkCompanyType={DrinkCompanyType}
                  setViewFoodAndDrink={setViewFoodAndDrink}
                  setDrinkType={setDrinkType}
                  setCompanyType={setCompanyType}
                  companyType={companyType}
                  setEditFoodAndDrink={setEditFoodAndDrink}
                  setType={setType}
                />
              )}
              {selectedMenu === 1 && (
                <FoodTable
                editFoodAndDrink={editFoodAndDrink}
                  setCompanyType={setCompanyType}
                  companyType={companyType}
                  setEditFoodAndDrink={setEditFoodAndDrink}
                  setType={setType}
                  setDrinkCompanyType={setDrinkCompanyType}
                  DrinkCompanyType={DrinkCompanyType}
                  setViewFoodAndDrink={setViewFoodAndDrink}
                  setDrinkType={setDrinkType}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FoodAndDrinkTable;
