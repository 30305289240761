import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  AddUserRoleData,
  AllUserNameData,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";

const AddUserRole = ({ dispatch, res_user_role, res_token, res_user_name }) => {
  const navigate = useNavigate();
  const [permission, setPermisstion] = useState([
    { id: "0", role: "Billing" },
    { id: "1", role: "Menu" },
    { id: "2", role: "Sound and Light" },
    { id: "3", role: "Check List Event" },
    { id: "4", role: "Send Notification" },
    { id: "5", role: "Show Tax Mode" },

    // { id: "4", role: "Reporting" },
  ]);
  const [state, setState] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [selection, setSelection] = useState("Select User");
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState({
    role_name: "",
    status: "",
  });
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());
    dispatch(AllUserNameData());
  }, [dispatch]);
  const sidebarRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setState]);
  const userOptions =
    res_user_name?.data?.user_data?.map((user) => ({
      value: user.id.toString(),
      label: user.name,
    })) || [];

  const handleClose = (e) => {
    setState(false);
    if (e.label !== selection && e.label !== "") {
      setSelection(e?.label);
      setSelectedOption(e?.label);
    }
  };

  const handleChange2 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRoleData({ ...roleData, [name]: value });
  };

  const handleChangeTerms = (e) => {
    const { name, checked } = e.target;
    let tempUser = [];
    if (name === "checkall") {
      tempUser = permission.map((user) => {
        return { ...user, isChecked: checked };
      });
      setPermisstion(tempUser);
    } else {
      tempUser = permission.map((user) => {
        if (user.id === name) {
          return { ...user, isChecked: checked };
        } else {
          return user;
        }
      });
      setPermisstion(tempUser);
    }
  };
  const checkInput = [];
  for (let i = 0; i < permission.length; i++) {
    if (permission[i].isChecked === true) {
      checkInput.push(permission[i].id);
    }
  }
  const checkInputString = checkInput.join(", ");
  const handleSubmit = () => {
    if (selectedOption == "" || !roleData?.status || !checkInputString) {
      console.error("Please fill in all required fields.");
      const notify = () =>
        toast.error("Please fill in all required fields.", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      return;
    }
    const submitData = {
      role_name: selectedOption,
      status: roleData?.status,
      permission: checkInputString,
    };
    dispatch(AddUserRoleData(submitData, setLoading));
  };
  const handleReset = () => {
    setRoleData({ role_name: "", status: "" });
    let tempUser = [];

    tempUser = permission.map((user) => {
      return { ...user, isChecked: false };
    });
    setPermisstion(tempUser);
    setSelection("Select User");
    setSelectedOption("");
  };

  useEffect(() => {
    if (res_user_role?.data?.status === true) {
      const notify = () =>
        toast.success(res_user_role?.data?.message);
      notify();
      setTimeout(() => {
        res_user_role.loading = true;
        res_user_role.data = {};
        navigate("/user_role");
      }, 1500);
    } else if (res_user_role?.data?.status === false) {
      const notify = () =>
        toast.error(res_user_role?.data?.message);
      notify();
      setTimeout(() => {
        res_user_role.loading = true;
        res_user_role.data = {};
      }, 1500);
    }
  }, [res_user_role, navigate]);

  // const customStyles = {
  //   backgroundColor: "#4d4d4d",
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isSelected ? "#4d4d4d" : "transparent",
  //     color: state.isSelected ? "white" : "#333",
  //     ":hover": {
  //       backgroundColor: "#676767 ",
  //       color: "white",
  //       cursor: "pointer",
  //     },
  //   }),
  // };

  return (
    <>
      <Toaster />
      <div className="container-fluid">
        <div className=" padding-left-21 form-margin form-margin-768">
          <div className=" form-in-768 ">
            <div className="row mb-4 mt-5 ">
              <div className="col-lg-3 col-12">
                <label for="basic-url " className="form-label label">
                  NAME
                </label>
              </div>
              <div className="col-lg-9 col-12 ">
                {/* <Select
                  onChange={handleChange}
                  value={roleData.name}
                  className=" inpt inpt-768"
                  placeholder="Select User"
                  styles={customStyles}
                  options={userOptions}
                ></Select> */}
                <div className="col-12 col-lg-8 ">
                  <div className="" ref={sidebarRef}>
                    <div
                      className="form-select inpt inpt-768 select-ios-div" 
                      onClick={() => setState(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <option
                        className="overflow-hidden select-ios"
                        style={{ padding: "6px" }}
                      >
                        {selection}
                      </option>
                    </div>
                    <div
                      className={`${
                        state
                          ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                          : "d-none"
                      }`}
                    >
                      {userOptions?.length ? (
                        userOptions?.map((item, index) => {
                          return (
                            <div key={index} className="p-1 py-0">
                              <div
                                onClick={(e) => handleClose(item)}
                                className="select-box-option text-white"
                              >
                                {item.label}
                              </div>
                              <div className="" />
                            </div>
                          );
                        })
                      ) : (
                        <div className="p-1 py-0">
                          <div
                            className="select-box-option-nodata text-center"
                            style={{ cursor: "default" }}
                          >
                            No Data Found
                          </div>
                          <div className="" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4 ">
              <div className="col-lg-3 col-12">
                <label for="basic-url" className="form-label  label">
                  PERMISSION
                </label>
              </div>
              <div className="col-lg-9 col-12">
                <div className="card width bg-transparant">
                  <div className="card-header header_color p-1">
                    <div className="form-check my-2 p-0 ">
                      <input
                        className="form-check-input bg-transparant cursor-pointer"
                        type="checkbox"
                        name="checkall"
                        autoComplete="off"
                        checked={
                          permission.filter((user) => user.isChecked !== true)
                            .length < 1
                        }
                        onChange={handleChangeTerms}
                        id="headerCheckbox"
                      />
                      <label
                        className="form-check-label form_check_size "
                        for="headerCheckbox"
                      >
                        All
                      </label>
                    </div>
                  </div>
                  <ul className="list-group list-group-flush bg-transparant mb-4">
                    {permission.map((e) => {
                      return (
                        <li className="list-group-item border-0 p-1 bg-transparant">
                          <div className="form-check p-0">
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              name={e.id}
                              onChange={handleChangeTerms}
                              checked={e?.isChecked || false}
                             
                            />
                            <label
                              className="form-check-label form_check_size"
                              
                            >
                              {e.role}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row mb-3 ">
              <div className="col-lg-3 col-12 ">
                <label for="basic-url" className="form-label label mt-3">
                  STATUS
                </label>
              </div>
              <div className="col-lg-9 col-12 ">
                <div className="my-2">
                  <div className="form-check form-check-inline ps-0">
                    <input
                      className="form-check-input cursor-pointer"
                      type="radio"
                      name="status"
                      onChange={handleChange2}
                      id="inlineRadio1"
                      checked={roleData?.status === "0"}
                      value="0"
                    />
                    <label
                      className="form-check-label cash_lable"
                      for="inlineRadio1"
                    >
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline ms-sm-4 ms-0 ms-lg-auto ">
                    <input
                      className="form-check-input cursor-pointer"
                      type="radio"
                      name="status"
                      onChange={handleChange2}
                      id="inlineRadio1"
                      checked={roleData?.status === "1"}
                      value="1"
                    />
                    <label
                      className="form-check-label cash_lable "
                      for="inlineRadio1"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative  display-above-768 ">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={() => {
                  res_user_role.loading = true;
                  res_user_role.data = {};
                  navigate("/user_role");
                }}
              >
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={handleReset}
              >
                RESET
              </button>
              {loading ? (
                <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SAVE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handleSubmit}
                >
                  SAVE
                </button>
              )}
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768">
              {loading ? (
                <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SAVE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768"
                  onClick={handleSubmit}
                >
                  SAVE
                </button>
              )}
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={handleReset}
              >
                RESET
              </button>
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768"
                onClick={() => {
                  res_user_role.loading = true;
                  res_user_role.data = {};
                  navigate("/user_role");
                }}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user_role: state?.Add_user_role_data_reducer,
  res_user_name: state?.Add_user_name_data_reducer,
});

export default connect(mapStateToProps)(AddUserRole);
