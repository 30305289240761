import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import {
  MainKitchenPdfDownload,
  getToken,
} from "../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import Pusher from "pusher-js";
import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import KitchenGetEventsPDF from "../../PDF/KitchenGetEventsPDF";
const KitchenReportingTable = ({ dispatch, res_token, res_pdf_data }) => {
  const [length, setLength] = useState(0);
  const [socket, setSocket] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, []);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    // console.log(channel,"channel");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log(socket,"socket");
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  // console.log(res_token, "res_token");
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $('<div class="row justify-content-center "><span class="loader"></span></div>').appendTo("#showBill");
      var table1 = $("#showBill").DataTable({
        columnDefs: [{ orderable: false, targets: 5 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_kitchen_reporting_data",
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "eventID",
            width: "10%",
          },
          {
            data: "eventday",
            width: "23%",
          },
          {
            data: "startDate",
            width: "23%",
          },
          {
            data: "noOfGuests",
            width: "17%",
          },
          {
            data: "m_name",
            width: "23%",
          },
          // {
          //   data: "s_name",
          //   width: "23%",
          // },

          {
            data: null,
            width: "10%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-download fs-4 ps-2 me-0 fw-bold cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          // console.log("Table length:", tableLength);
          setLength(tableLength);
        },
      });
      table1.on('xhr.dt', function () {
        customLoader.css("display", "none");
      });
      $("#showBill tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.eventID) {
          dispatch(MainKitchenPdfDownload(rowData.eventID));
        }
      });
      $("#showBill_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  // const handleDownload = async (id) => {
  //   try {
  //     const res = await fetch(`/api/getEventPDF/${id}`, {
  //       method: "GET",
  //       headers: {
  //         "X-CSRF-TOKEN": res_token?.data,
  //         Authorization: `Bearer ${api_token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (!res.ok) {
  //       throw new Error(`HTTP error! Status: ${res.status}`);
  //     }
  //     const data = await res.json();
  //     console.log(data, "data");

  //     if (data?.status === true) {
  //       const pdfUrl = data?.url;
  //       const link = document.createElement("a");
  //       link.href = pdfUrl;
  //       link.target = "_blank";
  //       // link.download = "document.pdf"; // specify the filename
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else if (data?.status === false) {
  //       const notify = () =>
  //         toast.error(data?.message, {
  //           style: {
  //             padding: "10px",
  //             borderRadius: "30px",
  //           },
  //         });
  //       notify();
  //     }

  //   } catch (error) {
  //     const notify = () =>
  //       toast.error("Something went wrong", {
  //         style: {
  //           padding: "10px",
  //           borderRadius: "30px",
  //         },
  //       });
  //     notify();
  //   }
  // };

  useEffect(() => {
    if (res_pdf_data?.data?.status === true) {
      const pdfUrl = res_pdf_data?.data?.url;
      window.open(pdfUrl);
      // const fileName = "report.pdf";
      // const getData = res_pdf_data?.data?.data;
      // downloadPDFData(getData);
      // axios
      //   .get(res_pdf_data?.data?.url, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     fileDownload(res.data, fileName);
      //   })
      //   .catch((error) => {
      //     console.error("Error downloading the file:", error);
      //   });

      // const link = document.createElement("a");
      // link.href = pdfUrl;
      // link.target = "_blank";
      // link.download = "document.pdf";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    } else if (res_pdf_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    }
  }, [res_pdf_data]);
  // const downloadPDFData = async (getData) => {
  //   // Create an off-screen container
  //   try {

  //     const offScreenContainer = document.createElement("div");
  //     offScreenContainer.style.position = "absolute";
  //     offScreenContainer.style.left = "-9999px";
  //     document.body.appendChild(offScreenContainer);
  //     offScreenContainer.style.width = "768px";

  //     createRoot(offScreenContainer).render(<KitchenGetEventsPDF getData={getData} />);

  //     // Wait for rendering to finish before generating PDF
  //     await new Promise((resolve) => setTimeout(resolve, 0));

  //     const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

  //     if (receipts.length === 0) {
  //       return;
  //     }

  //     const doc = new jsPDF("p", "mm", "a4");
  //     let yOffset = 0;

  //     for (let index = 0; index < receipts.length; index++) {
  //       const receipt = receipts[index];
  //       await new Promise((resolve) => {
  //         setTimeout(() => {
  //           html2canvas(receipt, { scale: 2, logging: false, useCORS: true }).then(
  //             (canvas) => {
  //               const imgData = canvas.toDataURL("image/png");
  //               const width = doc.internal.pageSize.getWidth();
  //               const height = doc.internal.pageSize.getHeight();

  //               if (index > 0) {
  //                 doc.addPage();
  //                 yOffset = 0;
  //               }

  //               doc.addImage(imgData, "PNG", 0, yOffset, width, height);
  //               yOffset += height;

  //               resolve();
  //             }
  //           );
  //         }, 100);
  //       });
  //     }

  //     doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
  //     document.body.removeChild(offScreenContainer);
  //   } catch (error) {
  //     console.error(error)
  //   }
  // };
  return (
    <>
      {" "}
      <Toaster />
      <style>
          {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "Day";
           
          }
          td:nth-of-type(3):before {
            content: "Event Date";
          }
          td:nth-of-type(4):before {
            content: "No. of Guests";
          }
          td:nth-of-type(5):before {
            content: "Menu Type";
          }
          td:nth-of-type(6):before {
            content: "Action";
          }
          }
        `}
        </style>
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="role-table">
                <table
                  id="showBill"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Day
                      </th>
                      <th className="thead-color" scope="col">
                        Event Date
                      </th>
                      <th className="thead-color" scope="col">
                        No. of Guests
                      </th>
                      <th className="thead-color" scope="col">
                        Menu Type
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf_data: state?.Main_Kitchen_pdf_data_reducer,
});

export default connect(mapStateToProps)(KitchenReportingTable);