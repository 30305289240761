import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteCalendarEvent,
  getEventsSummaryPDF,
  getKitchenEventsSummaryPDF,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import pdf from "../../../../images/pdf.png";
import Loader from "../../../Loader";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import GetEventSummaryPDF from "../../../PDF/GetEventSummaryPDF";
import KitchenGetEventSummaryPDF from "../../../PDF/KitchenGetEventSummaryPDF";

const EventSummary = ({
  dispatch,
  res_pdf,
  setEventSummary,
  summaryEventId,
  showEventSummary,
  setShowForm,
  res_kitchen_pdf,
}) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState("");
  const role = sessionStorage.getItem("role");
  useEffect(() => {
    toast.dismiss();
  }, []);

  useEffect(() => {
    dispatch(getToken());
    if (role === "0" || role === "2") {
      dispatch(getEventsSummaryPDF(summaryEventId, setLoading));
    } else if (role === "1") {
      dispatch(getKitchenEventsSummaryPDF(summaryEventId, setLoading));
    }
  }, [dispatch]);

  const handleClose = () => {
    setEventSummary(false);
    res_pdf.loading = true;
    res_pdf.data = {};
  };
  // console.log(res_pdf, "pes_pdf");
  useEffect(() => {
    if (res_pdf?.data?.status === true) {
      // const notify = () =>
      //   toast.success(res_pdf?.data?.message, {
      //     style: {
      //       padding: "10px",
      //       borderRadius: "30px",
      //     },
      //   });
      // notify();
      setUrl(res_pdf?.data?.url);

      // setTimeout(() => {
      //   // setEventSummary(false);
      //   res_pdf.loading = true;
      //   res_pdf.data = {};
      // }, 1500);
    } else if (res_pdf?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
      }, 1500);
    }
  }, [res_pdf, setEventSummary]);
  // console.log(res_kitchen_pdf,"res_kitchen_pdf");
  useEffect(() => {
    if (res_kitchen_pdf?.data?.status === true) {
      // const notify = () =>
      //   toast.success(res_kitchen_pdf?.data?.message, {
      //     style: {
      //       padding: "10px",
      //       borderRadius: "30px",
      //     },
      //   });
      // notify();
      setUrl(res_kitchen_pdf?.data?.url);

      // setTimeout(() => {
      //   // setEventSummary(false);
      //   res_kitchen_pdf.loading = true;
      //   res_kitchen_pdf.data = {};
      // }, 1500);
    } else if (res_kitchen_pdf?.data?.status === false) {
      const notify = () =>
        toast.error(res_kitchen_pdf?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_kitchen_pdf.loading = true;
        res_kitchen_pdf.data = {};
      }, 1500);
    }
  }, [res_kitchen_pdf, setEventSummary]);

  const handleImageClick = async () => {
    window.open(url, "_blank");
    // try {
    //   const offScreenContainer = document.createElement("div");
    //   offScreenContainer.style.position = "absolute";
    //   offScreenContainer.style.left = "-9999px";
    //   document.body.appendChild(offScreenContainer);
    //   offScreenContainer.style.width = "768px";
    //   if (role === "0" || role === "2" || role === "3") {
    //     createRoot(offScreenContainer).render(
    //       <GetEventSummaryPDF getData={url} />
    //     );
    //   } else if (role === "1") {
    //     createRoot(offScreenContainer).render(
    //       <KitchenGetEventSummaryPDF getData={url} />
    //     );
    //   }
    //   // Wait for rendering to finish before generating PDF
    //   await new Promise((resolve) => setTimeout(resolve, 0));

    //   const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

    //   if (receipts.length === 0) {
    //     return;
    //   }

    //   const doc = new jsPDF("p", "mm", "a4");
    //   let yOffset = 0;

    //   for (let index = 0; index < receipts.length; index++) {
    //     const receipt = receipts[index];
    //     await new Promise((resolve) => {
    //       setTimeout(() => {
    //         html2canvas(receipt, {
    //           scale: 2,
    //           logging: false,
    //           useCORS: true,
    //         }).then((canvas) => {
    //           const imgData = canvas.toDataURL("image/png");
    //           const width = doc.internal.pageSize.getWidth();
    //           const height = doc.internal.pageSize.getHeight();

    //           if (index > 0) {
    //             doc.addPage();
    //             yOffset = 0;
    //           }

    //           doc.addImage(imgData, "PNG", 0, yOffset, width, height);
    //           yOffset += height;

    //           resolve();
    //         });
    //       }, 100);
    //     });
    //   }

    //   doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
    //   document.body.removeChild(offScreenContainer);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  return (
    <>
      <Toaster />

      <div
        className={`modal${
          showEventSummary ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-xl-role">
          <div className="modal-content">
            {loading ? (
              <Loader heightValue="50vh" />
            ) : (
              <>
                <div className="modal-header modal-header-role ">
                  <h4 className="modal-title text-center fw-bold">
                    Event Summary
                  </h4>

                  <div className="justify-content-end">
                    <button
                      type="button"
                      className="btn-close ms-0"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    ></button>
                  </div>
                </div>

                <div className="modal-body modal-padd p-1 px-4">
                  {url ? (
                    <div className="row mb-4 mt-4 mx-4">
                      <div className="col-12 col-lg-6 ">
                        <label for="basic-url" className="form-label  label">
                          DOWNLOAD FILE
                        </label>
                      </div>
                      <div className="col-12 col-lg-6 cursor-pontor">
                        <img
                          src={pdf}
                          onClick={handleImageClick}
                          alt="profile_image"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="modal-footer ">
                  <button
                    type="button"
                    className="btn btn-b me-2  mb-4 btn-768 "
                    onClick={handleClose}
                  >
                    BACK
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_pdf: state?.get_event_summary_reducer,
  res_kitchen_pdf: state?.get_kitchen_event_summary_PDF_reducer,
});
export default connect(mapStateToProps)(EventSummary);
