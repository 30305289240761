import React from "react";

const SuperAdminReportsPDF = ({ getData }) => {
  return (
    <>
      <style>
        {`
    .wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        background-color: white !important;
        font-family: auto !important;
      }
      
      .receipt-box {
        width: 760px;
        display: flex;
        flex-direction: column;
        margin: 55px 35px;
      }
      
     .th,.td{
        padding:2px!important;
        border: 3px double ;
      }
      
      .p{
        margin :0 !important;
        color: black !important;
        padding:0px 5px!important;
        font-size:14px;
      }
    `}
      </style>
      <div className="wrapper">
        <div className="receipt-box">
          <div className="actual-receipt" style={{ height: "850px" }}>
            <div className="container p-2">
              <h6 className="text-black fw-bold text-center mb-1 mt-5">Event Detail</h6>

              <table className="tables " style={{ padding: "25px",width:"90%", margin:"auto" }}>
                <tbody>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">
                        {" "}
                        <span>Event Type</span>{" "}
                      </p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.eventType}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p"> Client Name </p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.clientName}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Client Phone</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.clientPhone}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Address</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.address}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Event Day</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.eventday}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Event Start Date</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.startDate}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Event End Date</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.endDate}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Reservation Status</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.reservationStatus}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Menu Name</p>
                    </th>
                    <td className="td">
                      <p className="p">
                        <span>{getData?.m_name}</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Station Name</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.stations}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Soft Drink Menu Name</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.softDrinksID}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Hard Drink Menu Name</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.hardDrinksID}</p>
                    </td>
                  </tr>

                  {getData?.eventType === "Wedding" && (
                    <>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Groom Name</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.groomName}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Bride Name</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.brideName}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Type Of Wedding</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.typeOfWedding}</p>
                        </td>
                      </tr>
                    </>
                  )}

                  {getData?.eventType === "Engagement" && (
                    <>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Groom Name</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.groomName}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Bride Name</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.brideName}</p>
                        </td>
                      </tr>
                    </>
                  )}

                  {getData?.eventType === "Corporate" && (
                    <>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Company Name</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.companyName}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Company ID</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.companyID}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Tax No</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.taxNo}</p>
                        </td>
                      </tr>
                    </>
                  )}

                  {getData?.eventType === "Concert" && (
                    <>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Company Name</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.companyName}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Company ID</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.companyID}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Tax No</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.taxNo}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Singers Names 1</p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.singersNames1}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Singers Names 2 </p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.singersNames2}</p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row" className="th">
                          <p className="p">Singers Names 3 </p>
                        </th>
                        <td className="td">
                          <p className="p">{getData?.singersNames3}</p>
                        </td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <th scope="row" className="th">
                      <p className="p">No Of Guests</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.noOfGuests}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">No Of Free Guests</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.noOfFreeGuests}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">Notes</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.notes}</p>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="th">
                      <p className="p">User Name</p>
                    </th>
                    <td className="td">
                      <p className="p">{getData?.name}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminReportsPDF;
