import React, { useEffect ,useState} from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
const WhiteHallSL = ({
  dispatch,
  res_token,
  setEditSoundAndLight,
  setViewSoundAndLight,
  setDeleteSoundLight,
  deleteSoundLight,
  setDeleteType,
  setViewType,
  setEditType,
  editSoundAndLight,
}) => {
  const [length, setLength] = useState(0)

  useEffect(() => {
    dispatch(getToken());
  }, []);
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, [editSoundAndLight,deleteSoundLight]);
  const role = sessionStorage.getItem("role");
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $('<div class="row justify-content-center "><span class="loader"></span></div>').appendTo("#showextra");
      var table1 = $("#showextra").DataTable({
        columnDefs: [{ orderable: false, targets: 3 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        type: ["0"],
        ajax: {
          url: "/api/all_sound_and_light_data?type=0",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          dataSrc: "sound_light_data",
        },
        columns: [
          {
            data: "id",
            width: "20%",
          },
          {
            data: "package_name",
            width: "40%",
          },
          {
            data: null,
            width: "40%",
            render: function (data) {
              return `${data.package_price} JOD`
            },
          },
          // {
          //   data: "package_price",
          //   width: "40%",
          // },
          {
            data: null,
            width: "40%",
            render: function (data) {
              if (role === "0") {
                     return (
                '<div class="d-flex line-height"><span  id="viewExtra" value="' +
                data.id +
                '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editExtra" value="' +
                data.id +
                '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteExtra" value="' +
                data.id +
                '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
              );
              }else{

                return (
                  '<div class="d-flex line-height"><span  id="viewExtra" value="' +
                  data.id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editExtra" value="' +
                  data.id +
                  '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
                );
              }
            },
            sorting: false,
          },
        
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength)
        },
      });
      table1.on('xhr.dt', function () {
        customLoader.css("display", "none");
      });
        $("#showextra tbody").on("click", "#deleteExtra", function () {
          var row = $(this).closest("tr");
          const rowData = table1.row(row).data();
          // setViewRoleModel(true)
          if (rowData && rowData.id) {
            setDeleteSoundLight(true);
            setDeleteType(rowData?.id);
          }
        });
      $("#showextra tbody").on("click", "#viewExtra", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setViewSoundAndLight(true);
          setViewType(rowData?.id);
        }
      });

      $("#showextra tbody").on("click", "#editExtra", function () {
        var row = $(this).parents("tr")[0];
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setEditSoundAndLight(true);
          setEditType(rowData?.id);
        }
      });
      $("#showextra_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (
    <>
       <style>
        {`


          @media only screen and (max-width: 767px) {
          
            .table td:nth-child(1) {
              background-color: #cccccc !important;
              height: 100%;
              top: 0;
              left: 0;
              font-weight: bold;
              ${length === 0 ?
                `padding-left:9px!important;` : ``}
    }
        
          .table td:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #cccccc;
            }
          .table td:nth-child(3) {
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #cccccc;
            }
       
            td:nth-of-type(1):before {
              ${length !== 0 ?
                        `content: "#";` :
                        ``
                      }
             
            }
          td:nth-of-type(2):before {
            content: "Package Name";
           
          }
          td:nth-of-type(3):before {
            content: "Package Price";
          }
          td:nth-of-type(4):before {
            content: "Action";
          }
          }
        `}
      </style>
      <div className="role-table ">
        <table
          id="showextra"
          className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
        >
          <thead>
            <tr>
              <th className="thead-color" scope="col">
                #
              </th>
              <th className="thead-color" scope="col">
                Package Name
              </th>
              <th className="thead-color" scope="col">
                Package Price
              </th>
              <th className="thead-color" scope="col">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="table-body"></tbody>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(WhiteHallSL);