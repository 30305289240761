import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  GetSuppliersData,
  UpdateSuppliersData,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../Loader";
const EditSuppliers = ({ dispatch, res_get_supplier, res_edit_supplier }) => {
  const navigate = useNavigate();
  const id = useParams();
  const [loading, setLoading] = useState(false);
  const [supplierData, setSupplierData] = useState({
    planner_name: "",
    planner_price: "",
    photo_name: "",
    photo_price: "",
    flower_name: "",
    flower_price: "",
    package_name: "",
    package_price: "",
  });

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetSuppliersData(id?.id));
  }, [id?.id, dispatch]);
  useEffect(() => {
    if (id.id && res_get_supplier?.data?.status === true) {
      setSupplierData({
        planner_name: res_get_supplier?.data?.data?.planner_name,
        planner_price: res_get_supplier?.data?.data?.planner_price,
        photo_name: res_get_supplier?.data?.data?.photographer_name,
        photo_price: res_get_supplier?.data?.data?.photographer_price,
        flower_name: res_get_supplier?.data?.data?.flower_planner_name,
        flower_price: res_get_supplier?.data?.data?.flower_planner_price,
        package_name: res_get_supplier?.data?.data?.furniture_package_name,
        package_price: res_get_supplier?.data?.data?.furniture_package_price,
      });
    }
  }, [id.id, res_get_supplier]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    if (res_edit_supplier?.data?.status === true) {
      const notify = () =>
        toast.success(res_edit_supplier?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_edit_supplier.loading = true;
        res_edit_supplier.data = {};
        res_get_supplier.loading = true;
        res_get_supplier.data = {};
        navigate("/supplires");
      }, 1500);
    } else if (res_edit_supplier?.data?.status === false) {
      const notify = () =>
        toast.error(res_edit_supplier?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_edit_supplier.loading = true;
        res_edit_supplier.data = {};
      }, 1500);
    }
  }, [res_edit_supplier, navigate, res_get_supplier]);
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSupplierData({ ...supplierData, [name]: value });
  };
  const handleSubmit = () => {
    const {
      planner_name,
      planner_price,
      photo_name,
      photo_price,
      flower_name,
      flower_price,
      package_name,
      package_price,
    } = supplierData;

    var submitData;
    if (
      (planner_name !== null &&
        planner_name !== "" &&
        (planner_price === null || planner_price === "")) ||
      ((planner_name === null || planner_name === "") &&
        planner_price !== null &&
        planner_price !== "")
    ) {
      const notify = () =>
        toast.error("Planner Field is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      (photo_name !== null &&
        photo_name !== "" &&
        (photo_price === null || photo_price === "")) ||
      ((photo_name === null || photo_name === "") &&
        photo_price !== null &&
        photo_price !== "")
    ) {
      const notify = () =>
        toast.error("Photographer Field is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      (flower_name !== null &&
        flower_name !== "" &&
        (flower_price === null || flower_price === "")) ||
      ((flower_name === null || flower_name === "") &&
        flower_price !== null &&
        flower_price !== "")
    ) {
      const notify = () =>
        toast.error("Flower Planner Field is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else if (
      (package_name !== null &&
        package_name !== "" &&
        (package_price === null || package_price === "")) ||
      ((package_name === null || package_name === "") &&
        package_price !== null &&
        package_price !== "")
    ) {
      const notify = () =>
        toast.error("Furniture Package Field is required", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else {
      submitData = {
        planner_name: planner_name,
        planner_price: planner_price,
        photographer_name: photo_name,
        photographer_price: photo_price,
        flower_planner_name: flower_name,
        flower_planner_price: flower_price,
        furniture_package_name: package_name,
        furniture_package_price: package_price,
      };
      dispatch(UpdateSuppliersData(submitData, id.id, setLoading));
    }
  };

  return (
    <>
      <div className="">
        <Toaster />
        {res_get_supplier?.loading ? (
          <Loader />
        ) : (
          <div className=" container-fluid">
            <div className="form-margin-768 form-margin padding-left-21 ">
              <div className="">
                <h3 className="mb-4 mt-5 ms-md-0 ms-sm-3 fw-bold">
                  Wedding Planner
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        PLANNER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        autoComplete="off"
                        name="planner_name"
                        value={supplierData?.planner_name}
                        onChange={handleChange}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label label">
                        PLANNER PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group mb-3 price-jod price-jod-768">
                        <input
                          type="number"
                          autoComplete="off"
                          name="planner_price"
                          value={supplierData?.planner_price}
                          onChange={handleChange}
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Photographer
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label  label">
                        PHOTOGRAPHER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="photo_name"
                        autoComplete="off"
                        value={supplierData?.photo_name}
                        onChange={handleChange}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4  ">
                      <label for="basic-url" className="form-label label ">
                        PHOTOGRAPHER PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group price-jod price-jod-768">
                        <input
                          type="number"
                          autoComplete="off"
                          name="photo_price"
                          value={supplierData?.photo_price}
                          onChange={handleChange}
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className="mb-4 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Flowers Planner
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        FLOWER PLANNER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        autoComplete="off"
                        name="flower_name"
                        value={supplierData?.flower_name}
                        onChange={handleChange}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label label ">
                        FLOWER PLANNER PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group price-jod price-jod-768">
                        <input
                          type="number"
                          autoComplete="off"
                          name="flower_price"
                          value={supplierData?.flower_price}
                          onChange={handleChange}
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="mb-4 mt-3 mt-sm-2 ms-sm-3 ms-md-0 fw-bold">
                  Furniture
                </h3>
                <div className="form-in-768">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        FURNITURE PACKAGE NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        autoComplete="off"
                        name="package_name"
                        value={supplierData?.package_name}
                        onChange={handleChange}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label label">
                        PACKAGE PRICE
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="input-group price-jod price-jod-768">
                        <input
                          type="number"
                          autoComplete="off"
                          name="package_price"
                          value={supplierData?.package_price}
                          onChange={handleChange}
                          className="form-control inpt-border-trans"
                        />
                        <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                          JOD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-in-768">
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative  display-above-768">
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_edit_supplier.loading = true;
                      res_edit_supplier.data = {};
                      res_get_supplier.loading = true;
                      res_get_supplier.data = {};
                      navigate("/supplires");
                    }}
                  >
                    BACK
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SAVE
                    </button>
                  )}
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  pe-lg-5 centerText bottom-section text-lg-end position-relative me-lg-5 text-center display-for-768">
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SAVE
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_edit_supplier.loading = true;
                      res_edit_supplier.data = {};
                      res_get_supplier.loading = true;
                      res_get_supplier.data = {};
                      navigate("/supplires");
                    }}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_supplier: state?.Get_suppliers_data_reducer,
  res_edit_supplier: state?.Update_suppliers_data_reducer,
});
export default connect(mapStateToProps)(EditSuppliers);
