import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import ViewExtraData from "../Forms/View/ViewExtraData";
import DeleteExtra from "../Forms/Delete/DeleteExtra";

const ExtraTable = ({ dispatch, res_token }) => {
  const [viewExtraModal, setViewExtraModel] = useState(false);
  const [viewExtraModalId, setViewExtraModelId] = useState();
  const [deleteExtraModal, setDeleteExtraModel] = useState(false);
  const [deleteExtraModalId, setDeleteExtraModelId] = useState();
  const [length, setLength] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, []);
  const api_token = sessionStorage.getItem("api_token");
  // console.log(res_token, "res_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showextra");
      var table1 = $("#showextra").DataTable({
        columnDefs: [{ orderable: false, targets: 7 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_extra_data",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          // data:
          //   searchData === ""
          //     ? { submit: "" }
          //     : { submit: "custom", status: searchData },
          dataSrc: "extras_data",
        },
        columns: [
          {
            data: "id",
            width: "6%",
          },
          {
            data: "napkin_name",
            width: "15%",
          },
          {
            data: "content_of_screen_name",
            width: "15%",
          },
          {
            data: "live_stream_name",
            width: "15%",
          },
          {
            data: "band_name",
            width: "15%",
          },
          {
            data: "singer_name",
            width: "15%",
          },
          {
            data: "usher_name",
            width: "15%",
          },
          {
            data: null,
            width: "18%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="viewExtra" value="' +
                data.id +
                '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editExtra" value="' +
                data.id +
                '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
              );
            },
            sorting: false,
          },
          // {
          //   data: null,
          //   width: "18%",
          //   render: function (data) {
          //     //   console.log(data, "data");

          //     return (
          //       '<div class="d-flex"><span  id="viewExtra" value="' +
          //       data.id +
          //       '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editExtra" value="' +
          //       data.id +
          //       '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteExtra" value="' +
          //       data.id +
          //       '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
          //     );
          //   },
          // },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          // console.log("Table length:", tableLength);
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });

      $("#showextra tbody").on("click", "#deleteExtra", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setDeleteExtraModel(true);
          setDeleteExtraModelId(rowData.id);
        }
      });
      $("#showextra tbody").on("click", "#viewExtra", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setViewExtraModel(true);
          setViewExtraModelId(rowData.id);
        }
      });

      $("#showextra tbody").on("click", "#editExtra", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row) && table1.row(row).data();
        if (id && id.id) {
          id && navigate(`/edit_extras/${id.id}`);
        }
      });
      $("#showextra_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (
    <>
      {viewExtraModal && (
        <ViewExtraData
          viewExtraModal={viewExtraModal}
          setViewExtraModel={setViewExtraModel}
          viewExtraModalId={viewExtraModalId}
        />
      )}
      {deleteExtraModal && (
        <DeleteExtra
          deleteExtraModal={deleteExtraModal}
          setDeleteExtraModel={setDeleteExtraModel}
          deleteExtraModalId={deleteExtraModalId}
        />
      )}
      <style>
        {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "Napkin Name";
           
          }
          td:nth-of-type(3):before {
            content: "Content of Screen Name";
          }
          td:nth-of-type(4):before {
            content: "Live Stream Name";
          }
          td:nth-of-type(5):before {
            content: "Band Name";
          }
          td:nth-of-type(6):before {
            content: "Singer Name";
          }
          td:nth-of-type(7):before {
            content: "Usher Name";
          }
          td:nth-of-type(8):before {
            content: "Action";
          }
          }
        `}
      </style>
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="float-right add-role-btn-768 mt-4">
                <Link to="/add_extras">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                  >
                    + ADD EXTRAS
                  </button>
                </Link>
              </div>
              <div className="role-table">
                <table
                  id="showextra"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Napkin Name
                      </th>

                      <th className="thead-color" scope="col">
                        Content of Screen Name
                      </th>
                      <th className="thead-color" scope="col">
                        Live Stream Name
                      </th>
                      <th className="thead-color" scope="col">
                        Band Name
                      </th>
                      <th className="thead-color" scope="col">
                        Singer Name
                      </th>
                      <th className="thead-color" scope="col">
                        Usher Name
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(ExtraTable);
