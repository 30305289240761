import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  deleteCalendarEvent,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
const DeleteEvent = ({
  dispatch,
  res_delete_events,
  setShowDeleteModal,
  deleteEventId,
  showDeleteModal,
  setEvents,
  events,
  setEventSummary
}) => {
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  const handleDeleteData = () => {
    dispatch(deleteCalendarEvent(deleteEventId));
    const updatedEvents = events.filter((event) => event.id !== deleteEventId);
    setEvents(updatedEvents);
    setShowDeleteModal(false);
    setEventSummary(false)
  };
  const handleClose = () => {
    setShowDeleteModal(false);
    res_delete_events.loading = true;
    res_delete_events.data = {};
    setEventSummary(false)
  };
  useEffect(() => {
    if (res_delete_events?.data?.status === true) {
      const notify = () =>
        toast.success(res_delete_events?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();

      setTimeout(() => {
        setShowDeleteModal(false);
        res_delete_events.loading = true;
        res_delete_events.data = {};
      }, 1500);
    } else if (res_delete_events?.data?.status === false) {
      const notify = () =>
        toast.error(res_delete_events?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_delete_events.loading = true;
        res_delete_events.data = {};
      }, 1500);
    }
  }, [res_delete_events, setShowDeleteModal]);
  return (
    <>
      <Toaster />
      <div
        className={`modal${
          showDeleteModal ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-xl-role">
          <div className="modal-content">
            <div className="modal-header border-0 justify-content-end">
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body modal-padd p-1 px-4">
              <h5 className="fw-bold">
                {" "}
                Confirming the deletion of the chosen event. Are you sure?
              </h5>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                NO
              </button>
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleDeleteData}
              >
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_delete_events: state?.delete_Calendar_Event_reducer,
});
export default connect(mapStateToProps)(DeleteEvent);
