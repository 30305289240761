import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  GetStationData,
  getAllFoodAndDrinksData,
  getAllMenuData,
} from "../../../../Store/Action/useFetchData";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import toast, { Toaster } from "react-hot-toast";
import StationModel from "./StationModel";
import Pusher from "pusher-js";
import Loader from "../../../../Loader";
const FoodAndDrink = ({
  setShowData,
  dispatch,
  setselectedMenu,
  setEventData,
  tax_mode_final,
  eventData,
  editEventData,
  res_get_menu,
  setBack,
  res_station,
}) => {
  const [show, setShow] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [selectedStations, setSelectedStations] = useState([]);
  // const [taxMode, setTaxMode] = useState(true);
  const [socket, setSocket] = useState();
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    // console.log(channel, "channel");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log(socket, "socket");
      socket.bind("tax_mode_on", async (data) => {
        window.location.reload();
      });
    }
  }, [socket]);
  useEffect(() => {
    res_get_menu.data = {};
    res_get_menu.loading = true;
    res_station.data = {};
    res_station.loading = true;
    dispatch(GetStationData());
    dispatch(getAllMenuData());
    setSelectedStations();
    if (editEventData) {
      setEventData(editEventData);
      var stations = editEventData?.station_id;
      const stationsArray = stations?.split(",").map((id) => +id);
      // console.log(stationsArray,'stationsArray');
      // setTaxMode(editEventData?.tax_mode === "0" ? true : false);
      setSelectedStations(stationsArray);
    } 
    if (eventData) {
      
      var stations = eventData?.station_id;
      const stationsArray = stations?.split(",").map((id) => +id) || [];
      // console.log(stationsArray,'stationsArray');
      // setTaxMode(editEventData?.tax_mode === "0" ? true : false);
      setSelectedStations(stationsArray || []);
    }
    
  }, [dispatch]);
  // useEffect(() => {
  //   if (editEventData) {
  //     setEventData((prevData) => ({
  //       ...prevData,
  //       tax_mode: editEventData?.tax_mode,
  //     }));
  //   }
  // }, [dispatch]);

  // console.log(eventData?.tax_mode, "tax_mode_final");
  // console.log(editEventData, "editEventData");
  const menuData = res_get_menu?.data?.data;

  const handleCheckboxChange = (event, stationId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      if (selectedStations.length < 5) {
        setSelectedStations((prevSelected) => [...prevSelected, stationId]);
      } else {
        const notify = () =>
          toast.error("Maximum limit of 5 selections reached!", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      }
    } else {
      setSelectedStations((prevSelected) =>
        prevSelected.filter((id) => id !== stationId)
      );
    }
  };

  useEffect(() => {
    setEventData((prevData) => ({
      ...prevData,
      station_id:
        selectedStations?.length > 0 &&
        JSON.stringify(selectedStations).replace(/[\[\]']+/g, ""),
    }));
  }, [selectedStations]);
  // useEffect(() => {
  //   setEventData((prevData) => ({
  //     ...prevData,
  //     tax_mode: taxMode ? "0" : "1",
  //   }));
  // }, [taxMode]);
  // console.log(
  //   selectedStations.length > 0 &&
  //     JSON.stringify(selectedStations).replace(/[\[\]']+/g, ""),
  //   "selectedStations"
  // );
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setEventData((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };
  const handleChange2 = (item) => {
    // setEventData((prevTime) => ({
    //   ...prevTime,
    //   menu: item.id,
    // }));
    if (eventData.menu === item.id) {
      setEventData((prevData) => ({
        ...prevData,
        menu: null,
      }));
    } else {
      // Otherwise, select the clicked item
      setEventData((prevData) => ({
        ...prevData,
        menu: item.id,
      }));
    }
  };
  const itemsToShow = showAll ? menuData.length : 10;
  // console.log(eventData, "eventData");

  const handleReset = () => {
    setEventData({
      ...eventData,
      FoodDrinksSource: "",
      menu: "",
      foodnotes: "",
      softDrinksID: "",
      hardDrinksID: "",
      whiteFoodId: "",
      outsourceFoodDrinkId: "",
    });
  };
  const handleNext = () => {
    setShowData(3);
  };
  const itemsToShowStation = showAll ? res_station?.data?.data.length : 10;
  return (
    <>
      {res_get_menu.loading ? (
        <Loader />
      ) : (
        <>
          <Toaster />
          {show == true && <StationModel show={show} setShow={setShow} />}
          <div className="position-relative">
            <div className=" form-margin  padding-left-21 position-relative form-margin-768 mb-5 pb-2">
              <div className=" ">
                <h3 className="mb-4 mt-3 fw-bold  pt-3 pt-sm-0">
                  Food and Drinks
                </h3>
                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      MENU
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="row">
                      {menuData &&
                        menuData.slice(0, itemsToShow).map((item, index) => (
                          <div className="col-12 col-sm-6 mb-3" key={index}>
                            <div className="form-check form-check-inline ps-0">
                              <input
                                className="form-check-input cursor-pointer"
                                type="radio"
                                name="menu"
                                autoComplete="off"
                                value={item.id}
                                checked={Number(eventData?.menu) === item.id}
                                onClick={() => handleChange2(item)}
                              />
                              <label
                                className="form-check-label check-label"
                                htmlFor={`inlineRadio${index}`}
                              >
                                {item.m_name}
                              </label>
                            </div>
                          </div>
                        ))}
                      {menuData?.length > 10 && !showAll && (
                        <span
                          className="fw-bold text-end more-pading border-0 price-textarea"
                          onClick={() => setShowAll(true)}
                        >
                          MORE <RiArrowRightDoubleLine className="fs-5" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-3  ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      NOTES
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="input-group mb-3  price-textarea">
                      <textarea
                        className=" form-control inpt-border-trans"
                        name="foodnotes"
                        autoComplete="off"
                        onChange={handleChange}
                        value={eventData?.foodnotes}
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      STATIONS
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="row" style={{ width: "80%" }}>
                      {res_station?.data?.data &&
                        res_station?.data?.data
                          .slice(0, itemsToShowStation)
                          .map((item, index) => (
                            <div className="col-12 col-md-6 mb-3" key={index}>
                              <div className="form-check form-check-inline ps-0">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  name={`station-${item.s_id}`}
                                  checked={selectedStations?.includes(
                                    item.s_id
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item.s_id)
                                  }
                                  // disabled={selectedStations.length >= 5}
                                />
                                <label
                                  className="form-check-label check-label"
                                  htmlFor={`inlineCheckbox${index}`}
                                >
                                  {item.s_name}
                                </label>
                              </div>
                            </div>
                          ))}
                      {res_station?.data?.data?.length > 10 && !showAll && (
                        <span
                          className="fw-bold text-end more-pading border-0 price-textarea"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowAll(true)}
                        >
                          MORE <RiArrowRightDoubleLine className="fs-5" />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      SOFT DRINKS
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="softDrinksID"
                      autoComplete="off"
                      value={eventData.softDrinksID}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      HARD DRINKS
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="hardDrinksID"
                      autoComplete="off"
                      value={eventData.hardDrinksID}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* {tax_mode_final === "1" && (
              <div className="row mb-2 mt-3">
                <div className=" col-auto col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    ADD TAX
                  </label>
                </div>
                <div className=" col-auto col-lg-8">
                  <div className="form-check ps-0">
                    {editEventData ? (
                      <input
                        className="form-check-input cursor-pointer bg-transparant"
                        type="checkbox"
                        // role="switch"
                        disabled
                        id="flexSwitchCheckDefault"
                        name="tax_mode"
                        checked={taxMode} // Set checked state based on tex_mode value
                        // onChange={() => setTaxMode(!taxMode)}
                      />
                    ) : (
                      <input
                        className="form-check-input cursor-pointer bg-transparant"
                        type="checkbox"
                        // role="switch"
                        id="flexSwitchCheckDefault"
                        name="tax_mode"
                        checked={taxMode} // Set checked state based on tex_mode value
                        onChange={() => setTaxMode(!taxMode)}
                      />
                    )}
                  </div>
                </div>
              </div>
            )} */}
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      setShowData(1);
                      setBack(true);
                    }}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={handleReset}
                  >
                    RESET
                  </button>
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768"
                    onClick={handleNext}
                  >
                    NEXT
                    <RiArrowRightDoubleLine className="fs-5" />
                  </button>
                </div>
                {/* <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative next-btn-768">
              <button
                type="button"
                className="btn btn-b me-2 mb-2"
                onClick={() => setShowData(1)}
              >
                {" "}
                &lt;&lt; BACK
              </button>
              <button
                type="button"
                className="btn btn-b me-2 mb-2"
                onClick={handleNext}
              >
                NEXT &raquo;
              </button>
            </div> */}
              </div>
            </div>
            <div className={`next-btn mt-5 fixed-bottom`}>
              <div
                className=" d-flex justify-content-between p-2 "
                style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
              >
                <div className="d-flex">
                  <RiArrowLeftSLine className="fs-2 mt-1" />
                  <p
                    className=" text-dark pt-1 mb-0"
                    onClick={() => {
                      setShowData(1);
                      setBack(true);
                    }}
                  >
                    PREVIOUS
                  </p>
                </div>
                <div className="d-flex">
                  <p className=" text-dark pt-1 mb-0" onClick={handleNext}>
                    NEXT
                  </p>
                  <RiArrowRightSLine className="fs-2 mt-1" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_fad: state?.Get_station_data_reducer,
  res_get_menu: state?.get_all_menu_data,
  res_station: state?.Get_station_data_reducer,
});
export default connect(mapStateToProps)(FoodAndDrink);
