import React, { useEffect, useState } from "react";
import pdf from "../../../../../images/pdf.png";
import axios from "axios";
import fileDownload from "js-file-download";
import {
  contract1PdfDownload,
  getInvoicePDF,
  subContractPdfDownload,
} from "../../../../Store/Action/useFetchData";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import GetEventSubContractPDF from "../../../../PDF/GetEventSubContractPDF";

const SubContractPdf = ({
  submitEventID,
  clientName,
  dispatch,
  res_pdf,
  res_pdf_invoice,
}) => {
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    dispatch(subContractPdfDownload(submitEventID));

  }, []);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    if (res_pdf?.data?.status === true) {
      setFileUrl(res_pdf?.data?.data  );
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
        // window.location.reload();
      }, 500);
    } else if (res_pdf?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
      }, 1500);
    }
  }, [res_pdf]);

  const handleSubmit = () => {
    const fileName = clientName
      ? `White_hall_contract(${clientName.replace(/\s+/g, "_")})-2.pdf`
      : "White_hall_contract-2.pdf";

    axios
      .get(fileUrl, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  const handleImageClick = async () => {
    // window.open(fileUrl, "_blank");
    if (fileUrl !== "") {
      
      try {
        const offScreenContainer = document.createElement("div");
        offScreenContainer.style.position = "absolute";
        offScreenContainer.style.left = "-9999px";
        document.body.appendChild(offScreenContainer);
        offScreenContainer.style.width = "768px";
  
        createRoot(offScreenContainer).render(
          <GetEventSubContractPDF getData={fileUrl} />
        );
  
        // Wait for rendering to finish before generating PDF
        await new Promise((resolve) => setTimeout(resolve, 0));
  
        const receipts = offScreenContainer.querySelectorAll(".actual-receipt");
  
        if (receipts.length === 0) {
          return;
        }
  
        const doc = new jsPDF("p", "mm", "a4");
        let yOffset = 0;
  
        for (let index = 0; index < receipts.length; index++) {
          const receipt = receipts[index];
          await new Promise((resolve) => {
            setTimeout(() => {
              html2canvas(receipt, {
                scale: 2,
                logging: false,
                useCORS: true,
              }).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const width = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();
  
                if (index > 0) {
                  doc.addPage();
                  yOffset = 0;
                }
  
                doc.addImage(imgData, "PNG", 0, yOffset, width, height);
                yOffset += height;
  
                resolve();
              });
            }, 100);
          });
        }
  
        doc.save("White_Hall_Sub_Contract.pdf");
        document.body.removeChild(offScreenContainer);
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }
  };
  // const handleImageClick2 = () => {
  //   window.open(fileUrl2, "_blank");
  // };
  return (
    <>
      <Toaster />
      <div className="">
        <div className=" container-fluid">
          <div className="  padding-left-21 form-margin-768">
            <div className=" form-in-768">
              <div className="row mt-5">
                <div className="col-12 col-lg-4 ">
                  <label for="basic-url" className="form-label  label">
                    Contract
                  </label>
                </div>
                <div className="col-12 col-lg-8 cursor-pontor">
                  <img
                    src={pdf}
                    onClick={handleImageClick}
                    alt="profile_image"
                  />
                </div>
              </div>

           

              <div
                className="pt-xxl-0 pt-5 mb-5 centerText bottom-section fixed-bottom"
                style={{ marginTop: "23px" }}
              >
                <button
                  type="button"
                  className="btn btn-b me-2 mb-4 btn-768  "
                  onClick={handleImageClick}
                >
                  CONTRACT
                </button>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_pdf: state?.sub_contract_PDF_Data_reducer,
  res_pdf_invoice: state?.get_invoice_reducer,
});

export default connect(mapStateToProps)(SubContractPdf);
