import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import fileDownload from "js-file-download";
import {
  AdminReportsPdfDownload,
  AllUserNameData,
  getToken,
} from "../../Store/Action/useFetchData";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import Pusher from "pusher-js";
import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import SuperAdminReportsPDF from "../../PDF/SuperAdminReportsPDF";
const Reporting = ({ dispatch, res_token, res_pdf_data, res_user_name }) => {
  const location = useLocation();
  const [filterData, setFilterData] = useState({
    startDate: "",
    eventType: "",
    createdBy: "",
  });
  const [length, setLength] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [finalDate, setFinalDate] = useState();
  const [selection, setSelection] = useState("EVENT TYPE");
  const [selection1, setSelection1] = useState("CREATED BY");
  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [socket, setSocket] = useState();
  const sidebarRef = useRef(null);
  const sidebarRef1 = useRef(null);
  const options = [
    { value: "Wedding", label: "Wedding" },
    { value: "Corporate", label: "Corporate" },
    { value: "Concert", label: "Concert" },
    { value: "Private", label: "Private" },
    { value: "Charity", label: "Charity" },
  ];
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
    dispatch(AllUserNameData());
  }, []);
  useEffect(() => {
    TableDatatablesManaged.init();
  }, [filterData]);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
      if (sidebarRef1.current && !sidebarRef1.current.contains(event.target)) {
        setState1(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setState, setState1]);
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");

    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  const userOptions =
    res_user_name?.data?.user_data?.map((user) => ({
      value: user.id.toString(),
      label: user.name,
    })) || [];
  const handleClose = (e) => {
    setState(false);
    if (e.label !== selection && e.label !== "") {
      setSelection(e?.label);
      setFilterData({ ...filterData, eventType: e?.label });
    }
  };
  const handleClose1 = (e) => {
    setState1(false);
    if (e.label !== selection1 && e.label !== "") {
      setSelection1(e?.label);
      setFilterData({ ...filterData, createdBy: e?.label });
    }
  };

  const handleDateChange = (e) => {
    const dateString = e;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setSelectedDate(date);
    setFilterData({ ...filterData, startDate: `${year}-${month}-${day}` });
    setFinalDate(`${day}-${month}-${year}`);
  };
  const { searchSidebar } = location.state || {};

  const api_token = sessionStorage.getItem("api_token");
  const id = sessionStorage.getItem("member_id");

  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showReporting");
      var table1 = $("#showReporting").DataTable({
        columnDefs: [{ orderable: false, targets: 9 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: `/api/all_event_report_data`,
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          dataSrc: "user_data",
          data: {
            reserver: searchSidebar,
            startDate: filterData?.startDate,
            eventType: filterData?.eventType,
            name: filterData?.createdBy,
          },
        },

        columns: [
          {
            data: "eventID",
            width: "6%",
          },
          {
            data: "clientName",
            width: "16%",
          },
          {
            data: "startDate",
            width: "16%",
          },
          {
            data: "noOfGuests",
            width: "16%",
          },
          {
            data: "eventType",
            width: "16%",
          },
          {
            data: null,
            width: "16%",
            render: function (data) {
              if (data.total_spacial === null) {
                return `-`;
              } else {
                return `${parseFloat(data.total_spacial).toFixed(2)} JOD`;
              }
            },
            //sorting: false,
            // searchable:false
          },
          // {
          //   data: null,
          //   width: "16%",
          //   render: function (data) {
          //     if (data.spacial_dis === null) {
          //       return `-`;
          //     } else {
          //       return `${data.spacial_dis} JOD`;
          //     }
          //   },
          // },
          {
            data: null,
            width: "16%",
            render: function (data) {
              if (data.total_grand_total === null) {
                return `-`;
              } else {
                return `${parseFloat(data.total_grand_total).toFixed(2)} JOD`;
              }
            },
          },

          {
            data: null,
            width: "16%",
            render: function (data) {
              if (data.paid === null) {
                return `-`;
              } else {
                return `${data.paid} JOD`;
              }
            },
          },
          {
            data: null,
            width: "16%",
            render: function (data) {
              if (data.unpaid === null) {
                return `-`;
              } else {
                return `${parseFloat(data.unpaid).toFixed(2)} JOD`;
              }
            },
          },

          // {
          //   data: "noOfGuests",
          //   width: "12%",
          // },
          // {
          //   data: "noOfGuests",
          //   width: "12%",
          // },

          {
            data: null,
            width: "10%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-download fs-4 ps-2 me-0 fw-bold cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showReporting tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
       
        if (rowData && rowData.eventID) {
          dispatch(AdminReportsPdfDownload(rowData.eventID));
        }
      });
      $("#showReporting_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });

  useEffect(() => {
    if (res_pdf_data?.data?.status === true) {
      const pdfUrl = res_pdf_data?.data?.url;
      window.open(pdfUrl);
      // const getData = res_pdf_data?.data?.data;
      // downloadPDFData(getData);
      // setFileUrl(res_pdf_data?.data?.url)
      // const fileName = "report.pdf";

      // axios
      //   .get(res_pdf_data?.data?.url, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     fileDownload(res.data, fileName);
      //   })
      //   .catch((error) => {
      //     console.error("Error downloading the file:", error);
      //   });
      // const link = document.createElement("a");
      // link.href = pdfUrl;
      // link.target = "_blank";
      // link.download = "document.pdf";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    } else if (res_pdf_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    }
  }, [res_pdf_data]);

  // const downloadPDFData = async (getData) => {
  //   // Create an off-screen container
  //   try {

  //     const offScreenContainer = document.createElement("div");
  //     offScreenContainer.style.position = "absolute";
  //     offScreenContainer.style.left = "-9999px";
  //     document.body.appendChild(offScreenContainer);
  //     offScreenContainer.style.width = "768px";

  //     createRoot(offScreenContainer).render(<SuperAdminReportsPDF getData={getData} />);

  //     // Wait for rendering to finish before generating PDF
  //     await new Promise((resolve) => setTimeout(resolve, 0));

  //     const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

  //     if (receipts.length === 0) {
  //       return;
  //     }

  //     const doc = new jsPDF("p", "mm", "a4");
  //     let yOffset = 0;

  //     for (let index = 0; index < receipts.length; index++) {
  //       const receipt = receipts[index];
  //       await new Promise((resolve) => {
  //         setTimeout(() => {
  //           html2canvas(receipt, { scale: 2, logging: false, useCORS: true }).then(
  //             (canvas) => {
  //               const imgData = canvas.toDataURL("image/png");
  //               const width = doc.internal.pageSize.getWidth();
  //               const height = doc.internal.pageSize.getHeight();

  //               if (index > 0) {
  //                 doc.addPage();
  //                 yOffset = 0;
  //               }

  //               doc.addImage(imgData, "PNG", 0, yOffset, width, height);
  //               yOffset += height;

  //               resolve();
  //             }
  //           );
  //         }, 100);
  //       });
  //     }

  //     doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
  //     document.body.removeChild(offScreenContainer);
  //   } catch (error) {
  //     console.error(error)
  //   }
  // };

  // const [progress, setProgress] = useState(0);

  // const downloadPDFData = async (getData) => {
  //   try {
  //     const offScreenContainer = document.createElement("div");
  //     offScreenContainer.style.position = "absolute";
  //     offScreenContainer.style.left = "-9999px";
  //     document.body.appendChild(offScreenContainer);
  //     offScreenContainer.style.width = "768px";

  //     createRoot(offScreenContainer).render(
  //       <SuperAdminReportsPDF getData={getData} />
  //     );

  //     // Wait for rendering to finish before generating PDF
  //     await new Promise((resolve) => setTimeout(resolve, 0));

  //     const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

  //     if (receipts.length === 0) {
  //       return;
  //     }

  //     const doc = new jsPDF("p", "mm", "a4");
  //     let yOffset = 0;

  //     for (let index = 0; index < receipts.length; index++) {
  //       const receipt = receipts[index];

  //       const canvas = await html2canvas(receipt, {
  //         scale: 2,
  //         logging: false,
  //         useCORS: true,
  //       });
  //       const imgData = canvas.toDataURL("image/png");
  //       const width = doc.internal.pageSize.getWidth();
  //       const height = doc.internal.pageSize.getHeight();

  //       if (index > 0) {
  //         doc.addPage();
  //         yOffset = 0;
  //       }

  //       const config = {
  //         responseType: "arraybuffer",
  //         onDownloadProgress: (progressEvent) => {
  //           const percentage = Math.round(
  //             (progressEvent.loaded * 100) / progressEvent.total
  //           );
  //           setProgress(percentage);
  //         },
  //       };

  //       axios.get(imgData, config).then((response) => {
  //         doc.addImage(response.data, "PNG", 0, yOffset, width, height);
  //         yOffset += height;

  //         if (index === receipts.length - 1) {
  //           doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
  //           document.body.removeChild(offScreenContainer);
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ? `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }

  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
    }.table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }.table td:nth-child(6) {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #cccccc;
        }
        .table td:nth-child(7) {
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid #cccccc;
          }
          .table td:nth-child(8) {
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #cccccc;
            }
            .table td:nth-child(9) {
              overflow: hidden;
              text-overflow: ellipsis;
              border-bottom: 1px solid #cccccc;
              }
td:nth-of-type(1):before {
  ${length !== 0 ? `content: "#";` : ``}
 
}
td:nth-of-type(2):before {
  content: "Name of Reserver";
}
td:nth-of-type(3):before {
  content: "Date";
}
td:nth-of-type(4):before {
  content: "No. of Guests";
}
td:nth-of-type(5):before {
  content: "Event type";
}
td:nth-of-type(6):before {
  content: "Total Dis.";
}
td:nth-of-type(7):before {
  content: "Total Amount";
}
td:nth-of-type(8):before {
  content: "Paid";
}
td:nth-of-type(9):before {
  content: "Unpaid";
}
td:nth-of-type(10):before {
  content: "Action";
}
}
`}
      </style>
      <Toaster />
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 position-relative">
              <div className="container">
                <div className="row d-flex row-7 justify-content-center mt-4 mt-lg-auto">
                  <div className="col-12 col-sm-4 col-lg-6 col-xl-4  mb-xl-4">
                    <div className="row d-flex ">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label d-none d-lg-block"
                        >
                          EVENT DATE
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div
                          className="input-group mb-3 jod_width price price-768 cal_width w-auto kitchen-calender"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            type=""
                            className="form-control inpt-border-trans "
                            value={finalDate}
                            placeholder="EVENT DATE"
                            readOnly
                          />
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            className="form-control inpt-border-trans "
                            showPopperArrow={false}
                            dateFormat="yyyy-MM-dd"
                            //   value={finalDate ? finalDate : "aaa"}
                            customInput={
                              <span className="cursor-pointer">
                                <FaCalendarDays />
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-lg-6  col-xl-4 mb-3 mb-xl-4">
                    <div className="row d-flex">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label  d-none d-lg-block"
                        >
                          EVENT TYPE
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div className="" ref={sidebarRef}>
                          <div
                            className="form-select calender-select inpt inpt-768 w-auto"
                            onClick={() => setState(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <option
                              className="overflow-hidden"
                              style={{ padding: "4px" }}
                            >
                              {selection}
                            </option>
                          </div>
                          <div
                            className={`${
                              state
                                ? "d-block select-box1 m-1 scrollbar-thin scroll-table scroll-main"
                                : "d-none"
                            }`}
                          >
                            {options?.map((item, index) => {
                              return (
                                <div key={index} className="p-1 py-0">
                                  <div
                                    onClick={(e) => handleClose(item)}
                                    className="select-box-option text-white"
                                  >
                                    {item.label}
                                  </div>
                                  <div className="" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-4 col-lg-6  col-xl-4 mb-2 mb-xl-4">
                    <div className="row d-flex">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label  d-none d-lg-block"
                        >
                          CREATED BY
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div className="" ref={sidebarRef1}>
                          <div
                            className="form-select calender-select inpt inpt-768 w-auto"
                            onClick={() => setState1(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <option
                              className="overflow-hidden"
                              style={{ padding: "4px" }}
                            >
                              {selection1}
                            </option>
                          </div>
                          <div
                            className={` ${
                              state1
                                ? "d-block select-box1 m-1 scrollbar-thin scroll-table scroll-main"
                                : "d-none"
                            }`}
                          >
                            {userOptions?.length ? (
                              userOptions?.map((item, index) => {
                                return (
                                  <div key={index} className="p-1 py-0">
                                    <div
                                      onClick={(e) => handleClose1(item)}
                                      className="select-box-option text-white"
                                    >
                                      {item.label}
                                    </div>
                                    <div className="" />
                                  </div>
                                );
                              })
                            ) : (
                              <div className="p-1 py-0">
                                <div
                                  className="select-box-option-nodata text-center"
                                  style={{ cursor: "default" }}
                                >
                                  No Data Found
                                </div>
                                <div className="" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="role-table">
                <table
                  id="showReporting"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Name of Reserver
                      </th>
                      <th className="thead-color" scope="col">
                        Date
                      </th>
                      <th className="thead-color" scope="col">
                        No. of Guests
                      </th>
                      <th className="thead-color" scope="col">
                        Event type
                      </th>
                      <th className="thead-color" scope="col">
                        Total Dis.
                      </th>
                      {/* <th className="thead-color" scope="col">
                        Total Special Dis.
                      </th> */}
                      <th className="thead-color" scope="col">
                        Total Amount
                      </th>
                      <th className="thead-color" scope="col">
                        Paid
                      </th>
                      <th className="thead-color" scope="col">
                        Unpaid
                      </th>

                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf_data: state?.Admin_reports_PDF_Data_reducer,
  res_user_name: state?.Add_user_name_data_reducer,
});

export default connect(mapStateToProps)(Reporting);
