import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  UpdateAllMenuTypeData,
  getToken,
  getViewAllMenuTypeData,
} from "../../../Store/Action/useFetchData";
import Loader from "../../../Loader";

const EditManuType = ({ dispatch, res_get_menu, res_update_menu }) => {
  const id = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [createmenu, setCreateMenu] = useState({
    menu_name: "",
    menu_price: "",
  });

  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setCreateMenu({ ...createmenu, [name]: value });
  };
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getViewAllMenuTypeData(id.id));
  }, [id.id, dispatch]);
  useEffect(() => {
    if (id.id && res_get_menu?.data?.status === true) {
      setCreateMenu({
        menu_name: res_get_menu?.data?.data[0]?.m_name,
        menu_price: res_get_menu?.data?.data[0]?.m_price,
      });
    }
  }, [id.id, res_get_menu]);
  const handlesubmit = () => {
    const submitData = {
      mname: createmenu?.menu_name,
      mprice: createmenu?.menu_price,
    };
    dispatch(UpdateAllMenuTypeData(submitData, id.id, setLoading));
  };
  useEffect(() => {
    if (res_update_menu?.data?.status === true) {
      const notify = () =>
        toast.success(res_update_menu?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_update_menu.loading = true;
        res_update_menu.data = {};
        res_get_menu.loading = true;
        res_get_menu.data = {};

        navigate("/menu_type");
      }, 1500);
    } else if (res_update_menu?.data?.status === false) {
      const notify = () =>
        toast.error(res_update_menu?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_update_menu.loading = true;
        res_update_menu.data = {};
      }, 1500);
    }
  }, [res_update_menu, navigate, res_get_menu]);
  return (
    <>
      <Toaster />
      {res_get_menu.loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="form-margin-768 padding-left-21 ">
            <h3 className="mb-4 mt-3 pt-5 fw-bold">Edit Menu Type</h3>
            <div className="form-in-768">
              <div className="row ">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    MENU NAME
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  <input
                    type="text"
                    autoComplete="off"
                    name="menu_name"
                    onChange={handleChange}
                    value={createmenu.menu_name}
                    className="form-control inpt inpt-768 "
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    MENU PRICE
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="input-group mb-3 price-jod price-jod-768">
                    <input
                      type="number"
                      autoComplete="off"
                      name="menu_price"
                      onChange={handleChange}
                      value={createmenu.menu_price}
                      className="form-control
                  inpt-border-trans"
                    />
                    <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable">
                      JOD
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section  display-above-768">
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  onClick={() => {
                    res_update_menu.loading = true;
                    res_update_menu.data = {};
                    res_get_menu.loading = true;
                    res_get_menu.data = {};
                    navigate("/menu_type");
                  }}
                >
                  BACK
                </button>
                {loading ? (
                  <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SAVE
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768"
                    onClick={handlesubmit}
                  >
                    SAVE
                  </button>
                )}
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768 ">
                {loading ? (
                  <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SAVE
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768"
                    onClick={handlesubmit}
                  >
                    SAVE
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  onClick={() => {
                    res_update_menu.loading = true;
                    res_update_menu.data = {};
                    res_get_menu.loading = true;
                    res_get_menu.data = {};
                    navigate("/menu_type");
                  }}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_menu: state?.Get_view_all_menu_type_reducer,
  res_update_menu: state?.Update_all_menu_type_reducer,
});
export default connect(mapStateToProps)(EditManuType);
