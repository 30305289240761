import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  getAllFoodAndDrinksData,
  getAllMenuData,
} from "../../../../Store/Action/useFetchData";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";

const EditFoodAndDtinkEvent = ({
  dispatch,
  res_get_fad,
  setselectedMenu,
  setGetData,
  getData,
  res_get_menu,
}) => {
  const [sourceDrink, setSourceDrink] = useState();
  const [state, setState] = useState(false);
  const [state3, setState3] = useState(false);
  const [state4, setState4] = useState(false);
  const [state5, setState5] = useState(false);
  const [state6, setState6] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Drinks...");
  const [selectedOption3, setSelectedOption3] = useState("Soft Drinks...");
  const [selectedOption4, setSelectedOption4] = useState("Hard Drinks...");
  const [selectedOption5, setSelectedOption5] = useState("Foof Menu...");
  const [selectedOption6, setSelectedOption6] = useState("Company Name...");

  const options = [
    { id: "0", name: "White Hall" },
    { id: "1", name: "OutSource" },
  ];

  useEffect(() => {
    dispatch(getAllFoodAndDrinksData());
    dispatch(getAllMenuData());
  }, [dispatch]);
  const sidebarRef = useRef(null);
  const sidebarRef3 = useRef(null);
  const sidebarRef4 = useRef(null);
  const sidebarRef5 = useRef(null);
  const sidebarRef6 = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
      if (sidebarRef3.current && !sidebarRef3.current.contains(event.target)) {
        setState3(false);
      }
      if (sidebarRef4.current && !sidebarRef4.current.contains(event.target)) {
        setState4(false);
      }
      if (sidebarRef5.current && !sidebarRef5.current.contains(event.target)) {
        setState5(false);
      }
      if (sidebarRef6.current && !sidebarRef6.current.contains(event.target)) {
        setState6(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    setState,
    setState3,
    setState4,
    setState5,
    setState6,
  ]);

  const handleClose = (e) => {
    setState(false);
    if (e.name !== selectedOption && e.name !== "") {
      setSelectedOption(e?.name);
      setSourceDrink(e?.id);
      setGetData((prevTime) => ({
        ...prevTime,
        FoodDrinksSource: e?.id,
      }));
    }
  };

  const softDrinkData = res_get_fad?.data?.data
    ?.filter(
      (data) =>
        data?.company_type === "0" && data?.soft_drink_menu_name !== null
    )
    .map(({ id, soft_drink_menu_name }) => ({ id, soft_drink_menu_name }));

  const hardDrinkData = res_get_fad?.data?.data
    ?.filter(
      (data) =>
        data?.company_type === "0" && data?.hard_drink_menu_name !== null
    )
    .map(({ id, hard_drink_menu_name }) => ({ id, hard_drink_menu_name }));

  const foodData = res_get_fad?.data?.data
    ?.filter(
      (data) => data?.company_type === "0" && data?.food_menu_name !== null
    )
    .map(({ id, food_menu_name }) => ({ id, food_menu_name }));
  const companyData = res_get_fad?.data?.data
    ?.filter((data) => data.company_type === "1")
    .map(({ id, company_name }) => ({ id, company_name }));
  const menuData = res_get_menu?.data?.data;

  useEffect(() => {
    setSelectedOption(
      getData?.FoodDrinksSource === "0"
        ? "White Hall"
        : getData?.FoodDrinksSource === "1"
        ? "OutSource"
        : "Drinks..."
    );
    setSourceDrink(
      getData?.FoodDrinksSource === "0"
        ? "0"
        : getData?.FoodDrinksSource === "1"
        ? "1"
        : ""
    );
    const selectedOption = softDrinkData?.find(
      (data) => data?.id === Number(getData?.softDrinksID)
    );
    if (selectedOption) {
      setSelectedOption3(selectedOption?.soft_drink_menu_name);
    }
    const selectedOption1 = hardDrinkData?.find(
      (data) => data?.id === Number(getData?.hardDrinksID)
    );
    if (selectedOption1) {
      setSelectedOption4(selectedOption1?.hard_drink_menu_name);
    }
    const selectedOption2 = foodData?.find(
      (data) => data?.id === Number(getData?.whiteFoodId)
    );
    if (selectedOption2) {
      setSelectedOption5(selectedOption2?.food_menu_name);
    }
    const selectedOption3 = companyData?.find(
      (data) => data?.id === Number(getData?.outsourceFoodDrinkId)
    );
    if (selectedOption3) {
      setSelectedOption6(selectedOption3?.company_name);
    }

  }, [getData,res_get_fad,softDrinkData,companyData,foodData,hardDrinkData]);

  const handleClose3 = (e) => {
    setState3(false);
    if (
      e.soft_drink_menu_name !== selectedOption3 &&
      e.soft_drink_menu_name !== ""
    ) {
      setSelectedOption3(e?.soft_drink_menu_name);
      setGetData((prevTime) => ({
        ...prevTime,
        softDrinksID: e?.id,
      }));
    }
  };
  const handleClose4 = (e) => {
    setState4(false);
    if (
      e.hard_drink_menu_name !== selectedOption4 &&
      e.hard_drink_menu_name !== ""
    ) {
      setSelectedOption4(e?.hard_drink_menu_name);
      setGetData((prevTime) => ({
        ...prevTime,
        hardDrinksID: e?.id,
      }));
    }
  };
  const handleClose5 = (e) => {
    setState5(false);
    if (e.food_menu_name !== selectedOption5 && e.food_menu_name !== "") {
      setSelectedOption5(e?.food_menu_name);
      setGetData((prevTime) => ({
        ...prevTime,
        whiteFoodId: e?.id,
      }));
    }
  };
  const handleClose6 = (e) => {
    setState6(false);
    if (e.company_name !== selectedOption6 && e.company_name !== "") {
      setSelectedOption6(e?.company_name);
      setGetData((prevTime) => ({
        ...prevTime,
        outsourceFoodDrinkId: e?.id,
      }));
    }
  };

  // const handleClose7 = (e) => {
  //   setState7(false);
  //   if (
  //     e.hard_drink_menu_name !== selectedOption7 &&
  //     e.hard_drink_menu_name !== ""
  //   ) {
  //     setSelectedOption7(e?.hard_drink_menu_name);
  //     setGetData((prevTime) => ({
  //       ...prevTime,
  //       outsourceFoodId: e?.id,
  //     }));
  //   }
  // };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setGetData((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };
  const handleChange2 = (item) => {
    // let name = e.target.name;
    // let value = e.target.value;
    setGetData((prevTime) => ({
      ...prevTime,
      menu: item.id,
    }));
  };
  return (
    <>
      <div className="position-relative">
        <div className=" form-margin  padding-left-21 position-relative form-margin-768 mb-5 pb-2">
          <div className=" ">
            <h3 className="mb-4 mt-3 fw-bold  pt-3 pt-sm-0">Food and Drinks</h3>

            <div className="row mb-3 mt-3 ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  DRINKS SOURCE
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <div className="" ref={sidebarRef}>
                  <div
                    className="form-select inpt inpt-768 select-ios-div"
                    onClick={() => setState(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <option
                      className="overflow-hidden select-ios"
                      style={{ padding: "6px" }}
                    >
                      {selectedOption}
                    </option>
                    {/* <KeyboardArrowDownIcon /> */}
                  </div>
                  <div
                    className={`${
                      state
                        ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                        : "d-none"
                    }`}
                  >
                    {options?.map((item, index) => {
                      return (
                        <div key={index} className="p-1 py-0">
                          <div
                            onClick={() => handleClose(item)}
                            className="select-box-option text-white"
                          >
                            {item.name}
                          </div>
                          <div className="" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  MENU
                </label>
              </div>

              <div className="col-12 col-lg-8">
                <div className="row">
                  {menuData &&
                    menuData.map((item, index) => (
                      <div className="col-12 col-sm-6 mb-3">
                        <div
                          className="form-check form-check-inline ps-0"
                          key={index}
                        >
                          <input
                            className="form-check-input 
                            cursor-pointer"
                            type="radio"
                            autoComplete="off"
                            name="menu"
                            value={item.id}
                            checked={Number(getData?.menu) === item?.id}
                            onChange={() => handleChange2(item)}
                          />
                          <label
                            className="form-check-label check-label"
                            htmlFor={`inlineRadio${index}`}
                          >
                            {item.m_name}
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="row mb-3  ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  NOTES
                </label>
              </div>

              <div className="col-12 col-lg-8">
                <div className="input-group mb-3  price-textarea">
                  <textarea
                   autoComplete="off"
                    className=" form-control inpt-border-trans"
                    name="notes"
                    onChange={handleChange}
                    value={getData?.notes}
                    rows="6"
                  ></textarea>
                </div>
              </div>
            </div>
            {sourceDrink === "0" && (
              <>
                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      SOFT DRINKS
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="" ref={sidebarRef3}>
                      <div
                        className="form-select inpt inpt-768 select-ios-div"
                        onClick={() => setState3(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="overflow-hidden select-ios"
                          style={{ padding: "6px" }}
                        >
                          {selectedOption3}
                        </option>
                        {/* <KeyboardArrowDownIcon /> */}
                      </div>
                      <div
                        className={`${
                          state3
                            ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                            : "d-none"
                        }`}
                      >
                        {softDrinkData?.length ? (
                          softDrinkData?.map((item, index) => {
                            return (
                              <div key={index} className="p-1 py-0">
                                <div
                                  onClick={() => handleClose3(item)}
                                  className="select-box-option text-white"
                                >
                                  {item.soft_drink_menu_name}
                                </div>
                                <div className="" />
                              </div>
                            );
                          })
                        ) : (
                          <div className="p-1 py-0">
                            <div
                              className="select-box-option-nodata text-center"
                              style={{ cursor: "default" }}
                            >
                              No Data Found
                            </div>
                            <div className="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      HARD DRINKS
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="" ref={sidebarRef4}>
                      <div
                        className="form-select inpt inpt-768 select-ios-div"
                        onClick={() => setState4(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="overflow-hidden select-ios"
                          style={{ padding: "6px" }}
                        >
                          {selectedOption4}
                        </option>
                        {/* <KeyboardArrowDownIcon /> */}
                      </div>
                      <div
                        className={`${
                          state4
                            ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                            : "d-none"
                        }`}
                      >
                        {hardDrinkData?.length ? (
                          hardDrinkData?.map((item, index) => {
                            return (
                              <div key={index} className="p-1 py-0">
                                <div
                                  onClick={() => handleClose4(item)}
                                  className="select-box-option text-white"
                                >
                                  {item.hard_drink_menu_name}
                                </div>
                                <div className="" />
                              </div>
                            );
                          })
                        ) : (
                          <div className="p-1 py-0">
                            <div
                              className="select-box-option-nodata text-center"
                              style={{ cursor: "default" }}
                            >
                              No Data Found
                            </div>
                            <div className="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {sourceDrink === "0" && (
              <>
                <div className="row mb-3 mt-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      FOOD MENU
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="" ref={sidebarRef5}>
                      <div
                        className="form-select inpt inpt-768 select-ios-div"
                        onClick={() => setState5(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="overflow-hidden select-ios"
                          style={{ padding: "6px" }}
                        >
                          {selectedOption5}
                        </option>
                        {/* <KeyboardArrowDownIcon /> */}
                      </div>
                      <div
                        className={`${
                          state5
                            ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                            : "d-none"
                        }`}
                      >
                        {foodData?.length ? (
                          foodData?.map((item, index) => {
                            // console.log(item, "temmmm");
                            return (
                              <div key={index} className="p-1 py-0">
                                <div
                                  onClick={() => handleClose5(item)}
                                  className="select-box-option text-white"
                                >
                                  {item.food_menu_name}
                                </div>
                                <div className="" />
                              </div>
                            );
                          })
                        ) : (
                          <div className="p-1 py-0">
                            <div
                              className="select-box-option-nodata text-center"
                              style={{ cursor: "default" }}
                            >
                              No Data Found
                            </div>
                            <div className="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {sourceDrink === "1" && (
              <>
                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      COMPANY NAME
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="" ref={sidebarRef6}>
                      <div
                        className="form-select inpt inpt-768 select-ios-div"
                        onClick={() => setState6(true)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="overflow-hidden select-ios"
                          style={{ padding: "6px" }}
                        >
                          {selectedOption6}
                        </option>
                        {/* <KeyboardArrowDownIcon /> */}
                      </div>
                      <div
                        className={`${
                          state6
                            ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                            : "d-none"
                        }`}
                      >
                        {companyData?.length ? (
                          companyData?.map((item, index) => {
                            return (
                              <div key={index} className="p-1 py-0">
                                <div
                                  onClick={() => handleClose6(item)}
                                  className="select-box-option text-white"
                                >
                                  {item.company_name}
                                </div>
                                <div className="" />
                              </div>
                            );
                          })
                        ) : (
                          <div className="p-1 py-0">
                            <div
                              className="select-box-option-nodata text-center"
                              style={{ cursor: "default" }}
                            >
                              No Data Found
                            </div>
                            <div className="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative next-btn-768">
              <button
                type="button"
                className="btn btn-b me-2 mb-2"
                onClick={() => setselectedMenu(1)}
              >
                {" "}
                &lt;&lt; BACK
              </button>
              <button
                type="button"
                className="btn btn-b me-2 mb-2"
                onClick={() => setselectedMenu(3)}
              >
                NEXT &raquo;
              </button>
            </div>
          </div>
        </div>
        <div className={`next-btn mt-5 fixed-bottom`}>
          <div
            className=" d-flex justify-content-between p-2 "
            style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
          >
            <div className="d-flex">
              <RiArrowLeftSLine className="fs-2 mt-1" />
              <p
                className=" text-dark pt-1 mb-0"
                onClick={() => setselectedMenu(1)}
              >
                PREVIOUS
              </p>
            </div>
            <div className="d-flex">
              <p
                className=" text-dark pt-1 mb-0"
                onClick={() => setselectedMenu(3)}
              >
                NEXT
              </p>
              <RiArrowRightSLine className="fs-2 mt-1" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_fad: state?.Get_all_food_and_drinks_reducer,
  res_get_menu: state?.get_all_menu_data,
});
export default connect(mapStateToProps)(EditFoodAndDtinkEvent);