import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { UpdateChangePassword, getToken } from "../Store/Action/useFetchData";
import { useNavigate } from "react-router-dom";
const ChangePassword = ({ dispatch, res_change_pass }) => {
  const navigate = useNavigate();
  const [changeData, setChangeData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getToken());
  }, []);
  useEffect(()=>{
     toast.dismiss();
  },[])
  const id = sessionStorage.getItem("member_id");
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setChangeData({ ...changeData, [name]: value });
  };

  const handleSubmit = () => {
    const { old_password, new_password, confirm_password } = changeData;

    const submitData = {
      user_id: id,
      old_password,
      new_password,
      confirm_password,
    };
    dispatch(UpdateChangePassword(submitData, setLoading));

  };
  const resetData = () => {
    setChangeData({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });
  };
  useEffect(() => {
    if (res_change_pass?.data?.status === true) {
      const notify = () =>
        toast.success(res_change_pass?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_change_pass.loading = true;
        res_change_pass.data = {};
        navigate("/login");
      }, 1500);
    } else if (res_change_pass?.data?.status === false) {
      const notify = () =>
        toast.error(res_change_pass?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_change_pass.loading = true;
        res_change_pass.data = {};
      }, 1500);
    }
  }, [res_change_pass]);
  return (
    <>
      <Toaster />
      <div className="">
        <div className="container-fluid ">
          <div className=" form-margin-768 padding-left-21 form-margin">
            <div className="mt-auto mt-md-5  form-in-768 ">
              <div className="row mb-3 ">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    CURRENT PASSWORD
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="password"
                    name="old_password"
                    value={changeData?.old_password}
                    onChange={handleChange}
                    className="form-control inpt inpt-768 "
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="row mb-3 ">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    NEW PASSWORD
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="password"
                    name="new_password"
                    value={changeData?.new_password}
                    onChange={handleChange}
                    className="form-control inpt inpt-768 "
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="row mb-3 ">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    CONFIRM NEW PASSWORD
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="password"
                    name="confirm_password"
                    value={changeData?.confirm_password}
                    onChange={handleChange}
                    className="form-control inpt inpt-768 "
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>

            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768 ">
              <button
                type="button"
                className="btn btn-g me-2 btn-768  mb-4"
                onClick={() => navigate("/login")}
              >
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-2 btn-768 mb-4"
                onClick={resetData}
              >
                RESET
              </button>
              {loading ? (
                <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SUBMIT
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 btn-768 mb-4"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              )}
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section  display-for-768">
              <button type="button" className="btn btn-g me-2 btn-768  mb-4">
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-2 btn-768 mb-4"
                onClick={resetData}
              >
                RESET
              </button>
              {loading ? (
                <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SUBMIT
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 btn-768 mb-4"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state.CrcToken_reducer,
  res_change_pass: state?.Update_Chnage_Password_reducer,
});

export default connect(mapStateToProps)(ChangePassword);
