import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import MiniLoader from "../../../MiniLoader";
import {
  UpdateFoodAndDrinksData,
  getViewFoodAndDrinksData,
} from "../../../Store/Action/useFetchData";
const EditFoodAndDrinks = ({
  dispatch,
  res_get_food,
  res_edit_food,
  editFoodAndDrink,
  type,
  companyType,
  setEditFoodAndDrink,
}) => {
  const [loading, setLoading] = useState(false);
  const [Whitehall, setWhiteHall] = useState({
    sd_menu_name: "",
    sd_menu_price: "",
    hd_menu_name: "",
    hd_menu_price: "",
    f_menu_name: "",
    f_menu_price: "",
  });
  const [outsource, setOutsource] = useState({
    c_name: "",
    fd_cost: "",
    e_fd_cost: "",
    fd_notes: "",
  });
  useEffect(() => {
    dispatch(getViewFoodAndDrinksData(type));
  }, [type, dispatch]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    if (type && res_get_food?.data?.status === true) {
      setWhiteHall({
        sd_menu_name: res_get_food?.data?.data?.soft_drink_menu_name,
        sd_menu_price: res_get_food?.data?.data?.soft_drink_menu_price,
        hd_menu_name: res_get_food?.data?.data?.hard_drink_menu_name,
        hd_menu_price: res_get_food?.data?.data?.hard_drink_menu_price,
        f_menu_name: res_get_food?.data?.data?.food_menu_name,
        f_menu_price: res_get_food?.data?.data?.food_menu_price,
      });
      setOutsource({
        c_name: res_get_food?.data?.data?.company_name,
        fd_cost: res_get_food?.data?.data?.food_drinks_cost,
        e_fd_cost: res_get_food?.data?.data?.extra_food_drinks_cost,
        fd_notes: res_get_food?.data?.data?.food_drinks_notes,
      });
    }
  }, [type, res_get_food]);
  const handleChange1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOutsource({ ...outsource, [name]: value });
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setWhiteHall({ ...Whitehall, [name]: value });
  };
  const handleClose = () => {
    setEditFoodAndDrink(false);
    res_get_food.loading = true;
    res_get_food.data = {};
  };

  const handlesubmit = () => {
    const {
      sd_menu_name,
      sd_menu_price,
      hd_menu_name,
      hd_menu_price,
      f_menu_name,
      f_menu_price,
    } = Whitehall;
    const { c_name, fd_cost, e_fd_cost, fd_notes } = outsource;
    let submitData;
    let isValid = true;

    if (res_get_food?.data?.data?.company_type === "0") {
      if(f_menu_name === "" || f_menu_price === "") {
        const notify = () =>
          toast.error("Food Menu Field is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
          return;
      }else{
        submitData = {
          soft_drink_menu_name: sd_menu_name,
          soft_drink_menu_price: sd_menu_price,
          hard_drink_menu_name: hd_menu_name,
          hard_drink_menu_price: hd_menu_price,
          food_menu_name: f_menu_name,
          food_menu_price: f_menu_price,
        };

      }
    } else if (res_get_food?.data?.data?.company_type === "1") {
      submitData = {
        company_name: c_name,
        food_drinks_cost: fd_cost,
        extra_food_drinks_cost: e_fd_cost,
        food_drinks_notes: fd_notes,
      };
    }
    if (isValid) {
      dispatch(UpdateFoodAndDrinksData(submitData, type, setLoading));
    }
  };
  useEffect(() => {
    if (res_edit_food?.data?.status === true) {
      const notify = () =>
        toast.success(res_edit_food?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_edit_food.loading = true;
        res_edit_food.data = {};
        res_get_food.loading = true;
        res_get_food.data = {};
        setEditFoodAndDrink(false);
      }, 1500);
    } else if (res_edit_food?.data?.status === false) {
      const notify = () =>
        toast.error(res_edit_food?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_edit_food.loading = true;
        res_edit_food.data = {};
      }, 1500);
    }
  }, [res_edit_food, setEditFoodAndDrink, res_get_food]);
  return (
    <>
      <Toaster />
      <div
        className={`modal${
          editFoodAndDrink ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">
                 Food Menu
              </h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_food?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                {/* <h3 className="mb-4  fw-bold">Food and Drinks</h3> */}
                {res_get_food?.data?.data?.company_type === "0" ? (
                  <>
                    {/* {res_get_food?.data?.data?.soft_drink_menu_name !==
                      null && (
                      <>
                        <div className="row mb-3 ">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              SOFT DRINK MENU NAME
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control inpt inpt-768 "
                              aria-label="Username"
                              name="sd_menu_name"
                              onChange={handleChange}
                              value={Whitehall?.sd_menu_name}
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-lg-4 ">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              SOFT DRINK MENU PRICE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8 ">
                            <div className="input-group price-jod price-jod-768">
                              <input
                                type="number"
                                autoComplete="off"
                                className="form-control inpt-border-trans"
                                name="sd_menu_price"
                                value={Whitehall?.sd_menu_price}
                                onChange={handleChange}
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                    {/* {res_get_food?.data?.data?.hard_drink_menu_name !==
                      null && (
                      <>
                        <div className="row mb-3">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              HARD DRINK MENU NAME
                            </label>
                          </div>
                          <div className="col-12 col-lg-8 ">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control inpt inpt-768 "
                              name="hd_menu_name"
                              value={Whitehall?.hd_menu_name}
                              onChange={handleChange}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-lg-4 ">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              HARD DRINK MENU PRICE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <div className="input-group price-jod price-jod-768 ">
                              <input
                                type="number"
                                autoComplete="off"
                                name="hd_menu_price"
                                onChange={handleChange}
                                value={Whitehall?.hd_menu_price}
                                className="form-control inpt-border-trans"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                    {res_get_food?.data?.data?.food_menu_name !== null && (
                      <>
                        <div className="row mb-3">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              FOOD MENU NAME
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control inpt inpt-768 "
                              aria-label="Username"
                              name="f_menu_name"
                              value={Whitehall?.f_menu_name}
                              onChange={handleChange}
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              FOOD MENU PRICE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <div className="input-group price-jod price-jod-768 ">
                              <input
                                type="number"
                                autoComplete="off"
                                name="f_menu_price"
                                onChange={handleChange}
                                value={Whitehall?.f_menu_price}
                                className="form-control inpt-border-trans"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : res_get_food?.data?.data?.company_type === "1" ? (
                  <>
                    <div className="row mb-3 ">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          COMPANY NAME
                        </label>
                      </div>
                      <div className="col-12 col-lg-8">
                        <input
                          type="text"
                          className="form-control inpt inpt-768"
                          name="c_name"
                          autoComplete="off"
                          onChange={handleChange1}
                          value={outsource.c_name}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          FOOD COST
                        </label>
                      </div>

                      <div className="col-12 col-lg-8">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            name="fd_cost"
                            autoComplete="off"
                            onChange={handleChange1}
                            value={outsource.fd_cost}
                            className="form-control inpt-border-trans"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          EXTRA FOOD COST
                        </label>
                      </div>

                      <div className="col-12 col-lg-8">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            autoComplete="off"
                            className="form-control inpt-border-trans"
                            name="e_fd_cost"
                            onChange={handleChange1}
                            value={outsource.e_fd_cost}
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3  ">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          FOOD NOTES
                        </label>
                      </div>

                      <div className="col-12 col-lg-8">
                        <div className="input-group price-textarea">
                          <textarea
                            className=" form-control inpt-border-trans"
                            name="fd_notes"
                            autoComplete="off"
                            value={outsource.fd_notes}
                            onChange={handleChange1}
                            id="exampleFormControlTextarea1"
                            rows="6"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="modal-footer ">
              {loading ? (
                <button type="button" className="btn btn-b me-2  mb-4 btn-768 ">
                  <span className="spinner-border disable-spinner me-2"></span>
                  UPDATE
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2  mb-4 btn-768 "
                  onClick={handlesubmit}
                >
                  UPDATE
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_food: state?.Get_view_food_and_drinks_reducer,
  res_edit_food: state?.Update_food_and_drinks_reducer,
});

export default connect(mapStateToProps)(EditFoodAndDrinks);
