import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SendSetting,
  getSetting,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../Loader";
import MiniLoader from "../../../MiniLoader";
import SmallLoader from "../../../SmallLoader";
const Texmode = ({
  model,
  setmodel,
  dispatch,
  res_get_setting,
  res_setting,
}) => {
  const handleClose = () => {
    setmodel(false);
  };

  const [settingData, setSettingData] = useState({
    // tax_dis: "",
    tax_mode: "1",
  });
  const [loading, setLoading] = useState(false);
  const [checkMode, setCheckMode] = useState(false);
  useEffect(() => {
    dispatch(getToken());
    dispatch(getSetting());
  }, []);
  useEffect(() => {
    toast.dismiss();
  }, []);
  useEffect(() => {
    if (res_get_setting?.data?.status === true) {
      setSettingData({
        // tax_dis: res_get_setting?.data?.data?.tax_dis,
        tax_mode: res_get_setting?.data?.data?.tax_mode,
      });

      //   sessionStorage.setItem("tax_mode", res_get_setting?.data?.data?.tax_mode);

      // setTimeout(() => {
      //   res_get_setting.loading = true;
      //   res_get_setting.data = {};
      // }, 1500);
    } else if (res_get_setting?.data?.status === false) {
      const notify = () =>
        toast.error(res_get_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      // setTimeout(() => {
      //   res_get_setting.loading = true;
      //   res_get_setting.data = {};
      // }, 1500);
    }
  }, [res_get_setting]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update tex_mode based on checkbox
    if (type === "checkbox") {
      setCheckMode(true);
      const newValue = checked ? "0" : "1";
      setSettingData({ ...settingData, [name]: newValue });
    }
  };

  useEffect(() => {
    if (checkMode === true) {
      dispatch(SendSetting(settingData, setLoading));
      setCheckMode(false);
    }
  }, [checkMode]);
  //   const handleSubmit = () => {
  //     // sessionStorage.setItem("tax_mode", settingData.tax_mode);
  //     dispatch(SendSetting(settingData, setLoading));
  //   };
  useEffect(() => {
    if (res_setting?.data?.status === true) {
      const notify = () =>
        toast.success(res_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_setting.loading = true;
        res_setting.data = {};
        res_get_setting.loading = true;
        res_get_setting.data = {};
        window.location.reload();
      }, 1500);
    } else if (res_setting?.data?.status === false) {
      const notify = () =>
        toast.error(res_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_setting.loading = true;
        res_setting.data = {};
      }, 1500);
    }
  }, [res_setting]);
  return (
    <>
    <Toaster/>
      <div
        className={`modal${model ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-xl-role">
          <div className="modal-content">
            <div className="modal-header border-0 justify-content-end">
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body modal-padd p-1 px-4 d-flex justify-content-center align-items-center mb-4">
              {res_get_setting.loading ?<SmallLoader/>:
              <div className="d-flex align-items-center">
                <h5 className="fw-bold me-2 pt-2">TAX MODE:</h5>
                <div className="form-check form-switch text-center p-0 ms-3">
                  <input
                    className="form-check-input on-off cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    name="tax_mode"
                    checked={settingData.tax_mode === "0"} // Set checked state based on tex_mode value
                    onChange={handleChange}
                  />
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_setting: state?.send_setting_reducer,
  res_get_setting: state?.get_setting_reducer,
});

export default connect(mapStateToProps)(Texmode);
