import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import { getToken, getViewAllMenuTypeData } from "../../../Store/Action/useFetchData";

const ViewMenuType = ({viewMenuTypeModal,setViewMenuTypeModal,viewMenuTypeModalId,dispatch,res_get_menu}) => {
    const [createmenu, setCreateMenu] = useState({
        menu_name: "",
        menu_price: ""
      })
      useEffect(() => {
        dispatch(getToken());
      }, [dispatch]);
      useEffect(() => {
        dispatch(getViewAllMenuTypeData(viewMenuTypeModalId));
      }, [viewMenuTypeModalId,dispatch]);
      useEffect(() => {
        if (viewMenuTypeModalId && res_get_menu?.data?.status === true) {
          setCreateMenu({
            menu_name: res_get_menu?.data?.data[0]?.m_name,
            menu_price: res_get_menu?.data?.data[0]?.m_price
            });
        }
      }, [viewMenuTypeModalId, res_get_menu]);
      const handleClose = () => {
        setViewMenuTypeModal(false);
        res_get_menu.loading = true;
        res_get_menu.data = {};
      };
  return (
    <>
       <div
        className={`modal ${viewMenuTypeModal ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">View Menu Type</h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_menu?.loading ? (
              <MiniLoader />
            ) : (   
              <div className="modal-body modal-padd">
            <div className="row ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  MENU NAME
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <input
                  type="text"
                  name="menu_name"
                  value={createmenu?.menu_name}
                  readOnly
                  className="form-control inpt inpt-768 "
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  MENU PRICE
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <div className="input-group mb-2 price-jod price-jod-768">
                  <input
                    type="number"
                    name="menu_price"
                    value={createmenu?.menu_price}
                    readOnly
                    className="form-control
                  inpt-border-trans"
                  />
                  <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable">
                    JOD
                  </span>
                </div>
              </div>
            </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
    res_token: state?.CrcToken_reducer,
    res_get_menu: state?.Get_view_all_menu_type_reducer,
  });
  export default connect(mapStateToProps)(ViewMenuType);