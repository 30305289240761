import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SendSetting, getSetting, getToken } from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";

 const AccountantSetting = ({dispatch, res_setting ,res_get_setting}) => {

  // on-0
  // off-1


  const [settingData, setSettingData] = useState({
    // tax_dis: "",
    tax_mode: "1", // Default value for tex_mode
  });
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    dispatch(getToken())
    dispatch(getSetting())
  },[])
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    if (res_get_setting?.data?.status === true) {
     
      setSettingData({tax_dis:  res_get_setting?.data?.data?.tax_dis , tax_mode:res_get_setting?.data?.data?.tax_mode})
      // sessionStorage.setItem("tax_mode",res_get_setting?.data?.data?.tax_mode );

      setTimeout(() => {
        res_get_setting.loading = true;
        res_get_setting.data = {};
      }, 1500);
    } else if (res_get_setting?.data?.status === false) {
      const notify = () =>
        toast.error(res_get_setting?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_get_setting.loading = true;
        res_get_setting.data = {};
      }, 1500);
    }
  }, [res_get_setting]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update tex_mode based on checkbox
    if (type === "checkbox") {
      const newValue = checked ? "0" : "1";
      setSettingData({ ...settingData, [name]: newValue });
    }
  };
const handleSubmit = () =>{
  // sessionStorage.setItem("tax_mode",settingData.tax_mode );

  dispatch(SendSetting(settingData,setLoading))
  
}
useEffect(() => {
  if (res_setting?.data?.status === true) {
    const notify = () =>
      toast.success(res_setting?.data?.message, {
        style: {
          padding: "10px",
          borderRadius: "30px",
        },
      });
    notify();
    setTimeout(() => {
      window.location.reload()
      res_setting.loading = true;
      res_setting.data = {};
    }, 1500);
  } else if (res_setting?.data?.status === false) {
    const notify = () =>
      toast.error(res_setting?.data?.message, {
        style: {
          padding: "10px",
          borderRadius: "30px",
        },
      });
    notify();
    setTimeout(() => {
      res_setting.loading = true;
      res_setting.data = {};
    }, 1500);
  }
}, [res_setting]);
  return (
    <>
    <Toaster/>
      <div className="">
        <div className="container-fluid ">
          <div className="form-margin-setting-768  form-margin padding-left-21">
            <div className=" ps-5 mt-5  form-in-768">
              <div>
                <p className="text-black mb-5">NOTE: Any event you made hidden will not appear when Tax Mode is activated!</p>
              </div>

              <div className="row mb-3 ">
                <div className="col-6 col-sm-4">
                  <label htmlFor="basic-url" className="form-label  label">
                    TAX MODE
                  </label>
                </div>
                <div className="col-6 col-sm-8">
                  <div className="form-check form-switch ps-0">
                    <input
                      className="form-check-input on-off cursor-pointer"
                      type="checkbox"
                      role="switch"
                      autoComplete="off"
                      id="flexSwitchCheckDefault"
                      name="tax_mode"
                      checked={settingData.tax_mode === "0"} // Set checked state based on tex_mode value
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768 ">
                <button type="button" className="btn btn-g me-2 btn-768 mb-4">
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4 "
                >
                  RESET
                </button>

                <button
                  type="button"
                  className="btn btn-b me-2 btn-768 mb-4"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768 ">
                <button type="button" className="btn btn-g me-2 btn-768 mb-4">
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4 "
                >
                  RESET
                </button>

                <button
                  type="button"
                  className="btn btn-b me-2 btn-768 mb-4"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
    res_token: state?.CrcToken_reducer,
    res_setting: state?.send_setting_reducer,
    res_get_setting: state?.get_setting_reducer,
  
  });
  
  export default connect(mapStateToProps)(AccountantSetting);
