import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  DeleteSuppliersData,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
const DeleteSupplier = ({
  dispatch,
  deleteSupplierModal,
  setDeleteSupplierModel,
  deleteSupplierModalId,
  res_supplier,
}) => {
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  const handleDeleteData = () => {
    dispatch(DeleteSuppliersData(deleteSupplierModalId));
  };
  const handleClose = () => {
    setDeleteSupplierModel(false);
    res_supplier.loading = true;
    res_supplier.data = {};
  };
  useEffect(() => {
    if (res_supplier?.data?.status === true) {
      const notify = () =>
        toast.success(res_supplier?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        setDeleteSupplierModel(false);
        res_supplier.loading = true;
        res_supplier.data = {};
      }, 1500);
    } else if (res_supplier?.data?.status === false) {
      const notify = () =>
        toast.error(res_supplier?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_supplier.loading = true;
        res_supplier.data = {};
      }, 1500);
    }
  }, [res_supplier,setDeleteSupplierModel]);
  return (
    <>
      <Toaster />
      <div
        className={`modal${
          deleteSupplierModal ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-xl-role">
          <div className="modal-content">
            <div className="modal-header border-0 justify-content-end">
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body modal-padd p-1 px-4">
              <h5 className="fw-bold">
                {" "}
                Are you sure you want to delete the selected Supplier?
              </h5>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                NO
              </button>
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleDeleteData}
              >
                YES
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_supplier: state?.Delete_suppliers_data_reducer,
});
export default connect(mapStateToProps)(DeleteSupplier);
