import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  FoodAndDrinksData,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";

const FoodDrink = ({ dispatch, res_user }) => {
  const [selectedMenu, setselectedMenu] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  // white hall
  const [Whitehall, setWhiteHall] = useState({
    sd_menu_name: "",
    sd_menu_price: "",
    hd_menu_name: "",
    hd_menu_price: "",
    f_menu_name: "",
    f_menu_price: "",
  });
  useEffect(()=>{
     toast.dismiss();
  },[])
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setWhiteHall({ ...Whitehall, [name]: value });
  };
  const handlesubmit = () => {
    const {
      sd_menu_name,
      sd_menu_price,
      hd_menu_name,
      hd_menu_price,
      f_menu_name,
      f_menu_price,
    } = Whitehall;
    const { c_name, fd_cost, e_fd_cost, fd_notes } = outsource;

    let submitData;
    let isValid = true;

    if (selectedMenu === 0) {
      // if (
      //   (sd_menu_name !== "" && sd_menu_price === "") ||
      //   (sd_menu_name === "" && sd_menu_price !== "")
      // ) {
      //   const notify = () =>
      //     toast.error("Soft Drink Field is required", {
      //       style: {
      //         padding: "10px",
      //         borderRadius: "30px",
      //       },
      //     });
      //   notify();
      // } else if (
      //   (hd_menu_name !== "" && hd_menu_price === "") ||
      //   (hd_menu_name === "" && hd_menu_price !== "")
      // ) {
      //   const notify = () =>
      //     toast.error("Hard Drink Field is required", {
      //       style: {
      //         padding: "10px",
      //         borderRadius: "30px",
      //       },
      //     });
      //   notify();
      // } else 
      
      if (
        // (f_menu_name !== "" && f_menu_price === "") ||
        // (f_menu_name === "" && f_menu_price !== "")
        (f_menu_name === "" || f_menu_price === "") 
      ) {
        const notify = () =>
          toast.error("Food Menu Field is required", {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
      } else {
        isValid = false;
        submitData = {
          company_type: "0",
          soft_drink_menu_name: sd_menu_name,
          soft_drink_menu_price: sd_menu_price,
          hard_drink_menu_name: hd_menu_name,
          hard_drink_menu_price: hd_menu_price,
          food_menu_name: f_menu_name,
          food_menu_price: f_menu_price,
        };
      }
    } else if (selectedMenu === 1) {
      isValid = false;
      submitData = {
        company_type: "1",
        company_name: c_name,
        food_drinks_cost: fd_cost,
        extra_food_drinks_cost: e_fd_cost,
        food_drinks_notes: fd_notes,
      };
    }

    if (!isValid) {
      dispatch(FoodAndDrinksData(submitData, setLoading));
    }
  };

  const handleReset = () => {
    setWhiteHall({
      sd_menu_name: "",
      sd_menu_price: "",
      hd_menu_name: "",
      hd_menu_price: "",
      f_menu_name: "",
      f_menu_price: "",
    });
    setOutsource({
      c_name: "",
      fd_cost: "",
      e_fd_cost: "",
      fd_notes: "",
    });
  };
  //outsource
  const [outsource, setOutsource] = useState({
    c_name: "",
    fd_cost: "",
    e_fd_cost: "",
    fd_notes: "",
  });
  const handleChange1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOutsource({ ...outsource, [name]: value });
  };

  useEffect(() => {
    if (res_user?.data?.status === true) {
      const notify = () =>
        toast.success(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
        navigate("/food_drink");
      }, 1500);
    } else if (res_user?.data?.status === false) {
      const notify = () =>
        toast.error(res_user?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_user.loading = true;
        res_user.data = {};
      }, 1500);
    }
  }, [res_user,navigate]);
  return (
    <div className="">
      <Toaster />
      <div className="container-fluid">
        <div className=" form-margin-768 padding-left-21 form-margin">
          <div className="   form-in-768">
            <div className={`d-grid gap-2 d-md-block `}>
              <div className="mt-3 btn_center">
                <button
                  className={`btn ${
                    selectedMenu === 0 ? "large-black-btn" : " large-gray-btn"
                  } large-btn-768  ms-1 `}
                  type="button"
                  onClick={() => setselectedMenu(0)}
                >
                  WHITE HALL
                </button>
                <button
                  className={`btn  btn_outsource large-btn-768  ${
                    selectedMenu === 1 ? "large-black-btn" : " large-gray-btn"
                  }  ms-1`}
                  type="button"
                  onClick={() => setselectedMenu(1)}
                >
                  OUTSOURCE
                </button>
              </div>
            </div>

            {/* -----------------------------------white hall ---------------------- */}

            <div className={`${selectedMenu === 0 ? "" : " d-none"}  `}>
              <h3 className="mb-4 mt-5  fw-bold">Food Menu</h3>
              <div className="form-in-768">
                {/* <div className="row mb-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      SOFT DRINK MENU NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      autoComplete="off"
                      name="sd_menu_name"
                      onChange={handleChange}
                      value={Whitehall.sd_menu_name}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-lg-4 ">
                    <label for="basic-url" className="form-label  label">
                      SOFT DRINK MENU PRICE
                    </label>
                  </div>
                  <div className="col-12 col-lg-8 ">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        type="number"
                        className="form-control inpt-border-trans"
                        name="sd_menu_price"
                        autoComplete="off"
                        value={Whitehall.sd_menu_price}
                        onChange={handleChange}
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div> */}

                {/* <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      HARD DRINK MENU NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8 ">
                    <input
                      type="text"
                      className="form-control inpt inpt-768 "
                      name="hd_menu_name"
                      autoComplete="off"
                      value={Whitehall.hd_menu_name}
                      onChange={handleChange}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-lg-4 ">
                    <label for="basic-url" className="form-label  label">
                      HARD DRINK MENU PRICE
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="input-group price-jod price-jod-768 ">
                      <input
                        type="number"
                        name="hd_menu_price"
                        onChange={handleChange}
                        autoComplete="off"
                        value={Whitehall.hd_menu_price}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div> */}

                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      FOOD MENU NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      name="f_menu_name"
                      autoComplete="off"
                      value={Whitehall.f_menu_name}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      FOOD MENU PRICE
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="input-group price-jod price-jod-768 ">
                      <input
                        type="number"
                        autoComplete="off"
                        name="f_menu_price"
                        onChange={handleChange}
                        value={Whitehall.f_menu_price}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* -------------------------------------------------------Outsource ---------------------------------------- */}

            <div className={` ${selectedMenu === 1 ? "" : " d-none"} `}>
              <h3 className="mb-4 mt-5 fw-bold">Food and Drinks</h3>
              <div className="form-in-768">
                <div className="row mb-3 ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      COMPANY NAME
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <input
                      type="text"
                      className="form-control inpt inpt-768"
                      name="c_name"
                      autoComplete="off"
                      onChange={handleChange1}
                      value={outsource.c_name}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      FOOD COST
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        type="number"
                        name="fd_cost"
                        autoComplete="off"
                        onChange={handleChange1}
                        value={outsource.fd_cost}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      EXTRA FOOD COST
                    </label>
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        type="number"
                        autoComplete="off"
                        className="form-control inpt-border-trans"
                        name="e_fd_cost"
                        onChange={handleChange1}
                        value={outsource.e_fd_cost}
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3  ">
                  <div className="col-12 col-lg-4">
                    <label for="basic-url" className="form-label  label">
                      FOOD NOTES
                    </label>  
                  </div>

                  <div className="col-12 col-lg-8">
                    <div className="input-group price-textarea">
                      <textarea
                        className=" form-control inpt-border-trans"
                        name="fd_notes"
                        autoComplete="off"
                        value={outsource.fd_notes}
                        onChange={handleChange1}
                        id="exampleFormControlTextarea1"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative   display-above-768">
              <button
                type="button"
                className="btn btn-g me-4 btn-768  mb-2"
                onClick={() => {
                  res_user.loading = true;
                  res_user.data = {};
                  navigate("/food_drink");
                }}
              >
                BACK
              </button>
              <button
                type="button"
                className="btn btn-g me-4 btn-768 mb-2"
                onClick={handleReset}
              >
                RESET
              </button>
              {loading ? (
                <button type="button" className="btn btn-b me-4 btn-768 mb-2">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SUBMIT
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-4 btn-768 mb-2"
                  onClick={handlesubmit}
                >
                  SUBMIT
                </button>
              )}
            </div>
            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-m d-5 centerText bottom-section  display-for-768">
              {loading ? (
                <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                  <span className="spinner-border disable-spinner me-2"></span>
                  SUBMIT
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2 btn-768 mb-4"
                  onClick={handlesubmit}
                >
                  SUBMIT
                </button>
              )}
              <button
                type="button"
                className="btn btn-g me-2 btn-768 mb-4"
                onClick={handleReset}
              >
                RESET
              </button>

              <button
                type="button"
                className="btn btn-g me-2 btn-768  mb-4"
                onClick={() => {
                  res_user.loading = true;
                  res_user.data = {};
                  navigate("/food_drink");
                }}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user: state?.Food_and_Drinks_data_reducer,
});

export default connect(mapStateToProps)(FoodDrink);
