import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  getToken,
  updateProfilePicture,
} from "../../../Store/Action/useFetchData";
import { useNavigate } from "react-router-dom";
const Profile = ({ dispatch, res_profile }) => {
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [profileData, setProfileData] = useState({
    profile_img: "",
    profile_name: "",
  });
  const navigate = useNavigate();
  const profilePictureURL = sessionStorage.getItem("Profile_picture");
  const userName = sessionStorage.getItem("Uname");
  const userId = sessionStorage.getItem("member_id");
  useEffect(() => {
    dispatch(getToken());
    if (profilePictureURL || userName) {
      setProfileData({
        profile_img: profilePictureURL,
        profile_name: userName,
      });
    }
  }, [dispatch, userName, profilePictureURL]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (name === "profile_img") {
      value = e.target.files[0];
      const imageUrl = URL.createObjectURL(value);
      setImgUrl(imageUrl);
    } else {
      value = e.target.value;
    }
    setProfileData({ ...profileData, [name]: value });
  };
  const handleSubmit = () => {
    if (
      userName === profileData?.profile_name &&
      profileData?.profile_img === profilePictureURL
    ) {
      const notify = () =>
        toast.error("Profile not updated. Please try again.", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    } else {
      const submitData = new FormData();
      submitData.append("name", profileData?.profile_name);
      submitData.append("user_id", userId);
      submitData.append(
        "profilePicture",
        profileData?.profile_img
      );

      dispatch(updateProfilePicture(submitData, setLoading));
    }
  };
  const handleReset = () => {
    setImgUrl("");
    if (profilePictureURL || userName) {
      setProfileData({
        profile_img: `http://whitehall.website/api/uploads/profile_image/${profilePictureURL}`,
        profile_name: userName,
      });
    }
  };

  useEffect(() => {
    if (res_profile?.data?.status === true) {
      const notify = () =>
        toast.success(res_profile?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      sessionStorage.setItem(
        "Profile_picture",
        res_profile?.data?.data?.profilePicture
      );
      sessionStorage.setItem("Uname", res_profile?.data?.data?.name);
      setTimeout(() => {
        window.location.reload();
        res_profile.loading = true;
        res_profile.data = {};
        // navigate("/user-role");
      }, 1500);
    } else if (res_profile?.data?.status === false) {
      const notify = () =>
        toast.error(res_profile?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_profile.loading = true;
        res_profile.data = {};
      }, 1500);
    }
  }, [res_profile]);

  return (
    <>
      <Toaster />
      <div className="">
        <div className="container-fluid ">
          <div className=" form-margin-768 padding-left-21 form-margin">
            <div className="   form-in-768">
              <div className="row mb-3 mb-sm-2 mt-4 mt-sm-5">
                <div className="col-lg-4 col-12 text-center text-lg-start">
                  <label for="basic-url" className="form-label  label">
                    PROFILE PICTURE
                  </label>
                </div>
                <div className="col-lg-8 col-12 text-center text-lg-start">
                  <label
                    for="formFile"
                    className="col-form-label  fw-bold  cursor-pointer"
                  >
                    <img
                      src={
                        imgUrl
                          ? imgUrl
                          : `http://whitehall.website/api/uploads/profile_image/${profilePictureURL}`
                      }
                      alt="profile_image"
                      className=" profile-image"
                    />
                  </label>
                  <input
                    className="form-control d-none"
                    type="file"
                    id="formFile"
                    name="profile_img"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row mb-3 ">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    NAME
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="text"
                    name="profile_name"
                    autoComplete="off"
                    value={profileData?.profile_name}
                    onChange={handleChange}
                    className="form-control inpt inpt-768 "
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-above-768 ">
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  onClick={() => {
                    res_profile.loading = true;
                    res_profile.data = {};
                    navigate("/calender");
                  }}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                {loading ? (
                  <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 btn-768 mb-4"
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section  display-for-768">
                {loading ? (
                  <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 btn-768 mb-4"
                    onClick={handleSubmit}
                  >
                    SUBMIT
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  onClick={() => {
                    res_profile.loading = true;
                    res_profile.data = {};
                    navigate("/calender");
                  }}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_profile: state?.Update_Profile_reducer,
});
export default connect(mapStateToProps)(Profile);
