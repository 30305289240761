import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaHeart } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import moment from "moment";
import Loader from "../../Loader";
import { connect } from "react-redux";
import {
  EditOrDeleteNotification,
  getNotification,
  getToken,
} from "../../Store/Action/useFetchData";
const Notification = ({ dispatch, res_notification_data }) => {
  const [likes, setLikes] = useState({});
  useEffect(() => {
    dispatch(getToken());
    dispatch(getNotification());
  }, []);

  const handleDeleteNotification = async (notificationId) => {
    try {
      const sendData = {
        type: "1",
      };
      await dispatch(EditOrDeleteNotification(notificationId, sendData));
      await dispatch(getNotification());
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const handleLikeToggle = async (notificationId, currentType) => {
    try {
      const newType = currentType === "0" ? "2" : "0";
      setLikes((prevLikes) => ({
        ...prevLikes,
        [notificationId]: newType,
      }));
      const sendData = {
        type: newType,
      };
      await dispatch(EditOrDeleteNotification(notificationId, sendData));
    } catch (error) {
      console.error("Error updating type:", error);
      setLikes((prevLikes) => ({
        ...prevLikes,
        [notificationId]: currentType,
      }));
    }
  };

  return (
    <>
      {res_notification_data.loading ? (
        <Loader />
      ) : (
        <div className="container-fluid ">
          <div className="col-12  mt-2 ">
            <div className=" padding-left-21 role-table ">
              <div className="">
                {res_notification_data?.data?.data?.length ? (
                  res_notification_data?.data?.data?.map((data) => {
                    const originalDate = moment(data?.created_at);

                    // Format the date as 'DD-MM-YYYY h:mm A'
                    const formattedDate = originalDate.format("DD-MM-YYYY");
                    const formattedTime = originalDate.format("h:mm A");
                    const likeType = likes[data?.id] || data?.type;
                    var SData = "";
                    if (
                      data?.header === "Add Bill" ||
                      data?.header === "Update Bill"
                    ) {
                      SData = "bI";
                    } else if (
                      data?.header === "Event" ||
                      data?.header === "Update Subdetails" ||
                      data?.header === "Add Subdetails" ||
                      data?.header === "Add Event" ||
                      data?.header === "Update Event"
                    ) {
                      SData = "AE";
                    } else if (data?.header === "Login") {
                      SData = "LO";
                    } else if (data?.header === "Password") {
                      SData = "PW";
                    } else if (data?.header === "Create User") {
                      SData = "CU";
                    } else if (data?.header === "Menu") {
                      SData = "ME";
                    } else if (data?.header === "Add Station") {
                      SData = "AS";
                    } else if (data?.header === "Sound and Light") {
                      SData = "SL";
                    } else if (data?.header === "Added Supplier") {
                      SData = "SP";
                    }else if (data?.header === "Event Reminder") {
                      SData = "ER";
                    }else if (data?.header === "Payment Reminder") {
                      SData = "PR";
                    }
                    
                    return (
                      <div className="">
                        <div className="card noti-card w-100">
                          <div className="card-body p-3">
                            <div className="row d-flex justfy-content-between">
                              <div className="col-8 ">
                                <div className="d-flex">
                                  <div className="mt-3">
                                    <span className="notification-img noti-span rounded-circle">
                                      {SData}
                                    </span>
                                  </div>
                                  <div>
                                    <h5 className="text-secondary ps-2 ps-sm-4 fw-bold pb-0">
                                      {data?.header}
                                    </h5>
                                    <p className="text-secondary ps-2 ps-sm-4 mb-0">
                                      {data?.message}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4 text-end my-auto">
                                <div className="d-flex justify-content-end my-auto">
                                  <span className="border-end border-dark py-2 px-2 px-sm-3">
                                    <RiDeleteBin6Line
                                      className="fs-3 cursor-pointer"
                                      onClick={() =>
                                        handleDeleteNotification(data?.id)
                                      }
                                    />
                                  </span>
                                  <span className=" py-2 px-2 px-sm-3">
                                    {likeType === "2" ? (
                                      <FaHeart
                                        className="fs-3 cursor-pointer"
                                        onClick={() =>
                                          handleLikeToggle(data?.id, likeType)
                                        }
                                      />
                                    ) : (
                                      <FaRegHeart
                                        className="fs-3 cursor-pointer"
                                        onClick={() =>
                                          handleLikeToggle(data?.id, likeType)
                                        }
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-end text-black">
                          <p className="text-secondary py-1">
                            {formattedDate}{" "}
                          </p>
                          <p className="text-secondary py-1 ms-4 me-2">
                            {formattedTime}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div
                      className="container"
                      style={{ margin: "59px 0px", maxWidth: "100%" }}
                    >
                      <div className="row text-center">
                        <h2>No Data Found</h2>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_notification_data: state?.Get_Notification_data_reducer,
});

export default connect(mapStateToProps)(Notification);