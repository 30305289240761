import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import personImage from "../../../../images/image.png";
import Select from "react-select";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { UserAdd, getToken } from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import { isValidPhoneNumber } from "react-phone-number-input";

const AddUser = ({ dispatch, res_user }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [imgUrl, setImgUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  

  const [userData, setUserData] = useState({
    user_name: "",
    user_password: "",
    email: "",
    user_image: "",
  });
  const options = [
    { value: "0", label: "Super Admin" },
    { value: "1", label: "Kitchen User" },
    { value: "2", label: "Sales User" },
    { value: "3", label: "Accounting User" },
  ]

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  const customStyles = {
    backgroundColor: "#4d4d4d",
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4d4d4d" : "transparent",
      color: state.isSelected ? "white" : "#333",
      ":hover": {
        backgroundColor: "#676767 ",
        color: "white",
        cursor: "pointer",
      },
    }),
  };
  useEffect(()=>{
     toast.dismiss();
  },[])
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  let name, value;
  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (name === "user_image") {
      value = e.target.files[0];
      const imageUrl = URL.createObjectURL(value);
      setImgUrl(imageUrl);
    } else {
      value = e.target.value;
    }
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmitData = () => {
    const { user_name, user_password, email, user_image } = userData;
    const submitData = new FormData();
    submitData.append("name", user_name);
    submitData.append("password", user_password);
    submitData.append("email", email);
    submitData.append("phoneNumber", phone);
    submitData.append("userRole", selectedOption.value);
    submitData.append("profilePicture", user_image);
    submitData.append("submit", "register");

    dispatch(UserAdd(submitData, setLoading));
  };
  const handleReset = () => {
    setUserData({
      user_name: "",
      user_password: "",
      email: "",
      user_image: "",
    });
    setImgUrl("");
    setPhone("962");
    setSelectedOption([]);
  };
    useEffect(() => {
      if (res_user?.data?.status === true) {
        const notify = () =>
          toast.success(res_user?.data?.message, {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
        setTimeout(() => {
          res_user.loading = true;
          res_user.data = {};
          navigate("/user")
        }, 1500);
      } else if (res_user?.data?.status === false) {
        const notify = () =>
          toast.error(res_user?.data?.message, {
            style: {
              padding: "10px",
              borderRadius: "30px",
            },
          });
        notify();
        setTimeout(() => {
          res_user.loading = true;
          res_user.data = {};
        }, 1500);
      }
    }, [res_user]);

  return (
    <>
      <Toaster />
      <div className="">
        <div className="container-fluid ">
          <div className=" padding-left-21 form-margin-768">
            <div className="form-in-768">
              <div className="row mb-3 mt-2 mt-sm-5">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    NAME
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control inpt inpt-768 "
                    aria-label="Username"
                    
                    name="user_name"
                    onChange={handleChange}
                    value={userData?.user_name}
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    PASSWORD
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="input-group price price-768">
                    <input
                      type={passwordType}
                      autoComplete="off"
                      className="form-control inpt-border-trans"
                      name="user_password"
                      onChange={handleChange}
                      value={userData?.user_password}
                    />
                    <span
                      className="input-group-text input-group-text-768 inpt-border-trans-pass inpt-lable justify-content-center "
                      style={{ cursor: "pointer" }}
                      onClick={togglePassword}
                    >
                      {passwordType === "password" ? (
                        <BsFillEyeSlashFill
                          className="fs-5"
                          style={{ margin: "2px" }}
                        />
                      ) : (
                        <BsFillEyeFill
                          className="fs-5"
                          style={{ margin: "2px" }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-4 col-12">
                  <label for="basic-url" className="form-label  label">
                    EMAIL
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="email"
                    autoComplete="off"
                    className="form-control inpt inpt-768 "
                    id="floatingInput"
                    name="email"

                    onChange={handleChange}
                    value={userData?.email}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-4 col-12">
                  <label for="basic-url" className="form-label  label">
                    PHONE
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    country={"jo"}
                    className=" inpt inpt-768"
                    onChange={setPhone}
                    // onChange={(value, country) => handlePhoneChange(value, country)}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-4 col-12">
                  <label for="basic-url" className="form-label  label">
                    PROFILE PICTURE
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <label
                    for="formFile"
                    className="col-form-label  fw-bold  cursor-pointer"
                  >
                    <img
                      src={imgUrl ? imgUrl : personImage}
                      alt="profile_image"
                      className="rounded-3  profile-image"
                    />
                  </label>
                  <input
                    className="form-control d-none"
                    type="file"
                    id="formFile"
                    name="user_image"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-lg-4 col-12">
                  <label for="basic-url" className="form-label  label">
                    USER ROLES
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <Select
                    onChange={(e) => setSelectedOption(e)}
                    options={options}
                    className=" inpt inpt-768"
                    placeholder="Roles"
                    value={selectedOption}
                    styles={customStyles}
                    // isClearable
                  />
                </div>
              </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative display-above-768">
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={() => {
                    res_user.loading = true;
                    res_user.data = {};
                    navigate("/user");
                  }}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                {loading ? (
                  <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button "
                    className="btn btn-b me-2 mb-4 btn-768  "
                    onClick={handleSubmitData}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768">
                {loading ? (
                  <button type="button" className="btn btn-b me-2 mb-4 btn-768">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 mb-4 btn-768  "
                    onClick={handleSubmitData}
                  >
                    SUBMIT
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={() => {
                    res_user.loading = true;
                    res_user.data = {};
                    navigate("/user");
                  }}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user: state?.Add_user_reducer,
});

export default connect(mapStateToProps)(AddUser);