import React from "react";
import main from "../images/main.png";
import image from "../images/main_admin.png";
import image1 from "../images/main_kitchen.png";
import image2 from "../images/main_sales.png";
import image3 from "../images/main_account.png";
import { useLocation, useNavigate } from "react-router-dom";
const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loginData = () => {
    sessionStorage.setItem("api_token", location?.state?.api_token);
    sessionStorage.setItem("role", location?.state?.role);
    sessionStorage.setItem("member_id", location?.state?.member_id);
    sessionStorage.setItem("Uname", location?.state?.Uname);
    sessionStorage.setItem("email", location?.state?.email);
    sessionStorage.setItem("Profile_picture", location?.state?.Profile_picture);
    sessionStorage.setItem("permission", location?.state?.permission);
    // sessionStorage.setItem("tax_mode", location?.state?.tax_mode);
    window.location.reload();
  };
  return (
    <>
      <div className="container-fluid p-0 position-fixed">
        {/* <Loader/> */}
        <div className="home-bg-image">
          <div className="container d-flex justify-content-center align-items-center h-100">
            <div className="image-container">
              {/* <Link to="/login"> */}
              {location?.state?.role === "0" ? (
                <img
                  src={image}
                  alt="Super_Admin"
                  className="hero_head"
                  onClick={loginData}
                />
              ) : location?.state?.role === "1" ? (
                <img
                  src={image1}
                  alt="Kitchen_User"
                  className="hero_head"
                  onClick={loginData}
                />
              ) : location?.state?.role === "2" ? (
                <img
                  src={image2}
                  alt="Sales_User"
                  className="hero_head"
                  onClick={loginData}
                />
              ) : location?.state?.role === "3" ? (
                <img
                  src={image3}
                  alt="Accounting_User"
                  className="hero_head"
                  onClick={loginData}
                />
              ) : (
                <img
                  src={main}
                  alt="Super_Admin"
                  className="hero_head"
                  onClick={() => navigate("/login")}
                />
              )}
              {/* </Link> */}
            </div>
            <div className="background-animation"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
