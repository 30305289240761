import React, { useEffect, useState } from "react";
import image from "../../images/main_logo.png";
// import { ForgetPassword } from "./ForgetPassword";
import { Link, useNavigate } from "react-router-dom";
import { UserLogin, getToken } from "../Store/Action/useFetchData";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
const Login = ({ dispatch, res_token, res_login }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());

    const rememberedUser = localStorage.getItem("rememberedUser");
    if (rememberedUser) {
      const { email: rememberedUsername, password: rememberedPassword } =
        JSON.parse(rememberedUser);
      setLoginData({
        email: rememberedUsername,
        password: rememberedPassword,
      });
      // setRememberMe(true);
    }

    if (!rememberMe) {
      localStorage.removeItem("rememberedUser");
    }
  }, []);
  const handleLogin = () => {
    const submitData = {
      email: loginData?.email,
      password: loginData?.password,
    };
    dispatch(UserLogin(submitData, setLoading));
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };
  useEffect(() => {
    if (res_login?.data?.status === true) {
      const notify = () =>
        toast.success(res_login?.data?.title, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();

      if (rememberMe) {
        localStorage.setItem(
          "rememberedUser",
          JSON.stringify({
            email: loginData?.email,
            password: loginData?.password,
          })
        );
      }
      if (!rememberMe) {
        localStorage.removeItem("rememberedUser");
      }
      setTimeout(() => {
        // window.location.reload();
        navigate("/", {
          state: {
            role: res_login?.data?.message?.userRole,
            api_token: res_login?.data?.message?.api_token,
            member_id: res_login?.data?.message?.id,
            Uname: res_login?.data?.message?.name,
            email: res_login?.data?.message?.email,
            Profile_picture: res_login?.data?.message?.profilePicture,
            permission:
              res_login?.data?.message?.status === "0"
                ? res_login?.data?.message?.permission
                : null,
            tax_mode: res_login?.data?.tax_data?.tax_mode,
          },
        });
        res_login.loading = true;
        res_login.data = {};
      }, 1000);
    } else if (res_login?.data?.status === false) {
      const notify = () =>
        toast.error(res_login?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_login.loading = true;
        res_login.data = {};
      }, 1500);
    }
  }, [res_login]);

  const onKeyPressHandler = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    } else {
    }
  };

  return (
    <>
      <Toaster />
      <div className="g-0 row" style={{ minHeight: "100vh" }}>
        <div className="col-lg-6 home-image text-center">
          <div className="container h-100">
            {/* <div className=""> */}

            <Link to="/">
              <img
                src={image}
                alt="login form"
                className="rounded-start card-image card-login"
              />
            </Link>

            <span className="h1 d-block d-lg-none  mb-0 text-white">Login</span>
            {/* </div> */}
          </div>
        </div>
        <div className="col-lg-6 bg-white" onKeyDown={onKeyPressHandler}>
          <div className="container login">
            <div className="d-flex flex-column card-body login-body px-0 px-md-5 px-lg-0">
              <div className="d-flex flex-row my-5 justify-content-center d-none d-lg-block ">
                <span className="h1  mb-0">Login</span>
              </div>
              <div className="mb-3 my-5">
                <input
                  type="text"
                  placeholder="EMAIL"
                  name="email"
                  onChange={handleChange}
                  value={loginData?.email}
                  className="form-control login-input ps-4 mx-auto"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  wrapperclassName="mb-4"
                  placeholder="PASSWORD"
                  name="password"
                  value={loginData?.password}
                  onChange={handleChange}
                  className="form-control login-input ps-4 mx-auto"
                  autoComplete="off"
                  required
                />
              </div>
              <div className="mb-3 justify-content-between d-flex  margin-both">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <p className="mb-1 ps-2 p-color">Remember</p>
                </div>
                <Link
                  to="/forget_password"
                  className="small  text-decoration-none cursor-pontor fs-16 blue-color"
                >
                  Reset Password
                </Link>
              </div>
              <div className="mt-4 mb-3">
                {loading ? (
                  <button className="mb-4 text-light login-btn">
                    <span className="spinner-border disable-spinner me-2"></span>
                    Login
                  </button>
                ) : (
                  <button
                    className="mb-4 text-light login-btn"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_login: state?.Loggin_reducer,
});

export default connect(mapStateToProps)(Login);
