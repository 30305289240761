import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GetExtraData, getToken } from "../../../Store/Action/useFetchData";
import MiniLoader from "../../../MiniLoader";

const ViewExtraData = ({
  viewExtraModal,
  setViewExtraModel,
  viewExtraModalId,
  dispatch,
  res_get_extra,
}) => {
  const [extras, setExtras] = useState({
    n_name: "",
    content_name: "",
    stream_name: "",
    band_name: "",
    singer_name: "",
    usher_name: "",
  });
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    dispatch(GetExtraData(viewExtraModalId));
  }, [viewExtraModalId,dispatch]);
  useEffect(() => {
    if (viewExtraModalId && res_get_extra?.data?.status === true) {
      setExtras({
        n_name: res_get_extra?.data?.data?.napkin_name,
        content_name: res_get_extra?.data?.data?.content_of_screen_name,
        stream_name: res_get_extra?.data?.data?.live_stream_name,
        band_name: res_get_extra?.data?.data?.band_name,
        singer_name: res_get_extra?.data?.data?.singer_name,
        usher_name: res_get_extra?.data?.data?.usher_name,
      });
    }
  }, [viewExtraModalId, res_get_extra]);
  const handleClose = () => {
    setViewExtraModel(false);
    res_get_extra.loading = true;
    res_get_extra.data = {};
  };
  return (
    <>
      <div
        className={`modal${viewExtraModal ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">View Extras</h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_extra?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                <h3 className="mb-2 ms-md-0 ms-sm-3 fw-bold">Napkins</h3>
                <div className="">
                  <div className="row mb-2  ">
                    <div className="col-12 col-lg-4 ">
                      <label for="basic-url" className="form-label  label">
                        NAPKIN NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="n_name"
                        value={extras?.n_name}
                        readOnly
                        className="form-control inpt inpt-768 mb-lg-0 mb-3  "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
                <h3 className="mb-2 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Content of Screen
                </h3>
                <div className="">
                  <div className="row mb-3">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        CONTENT OF SCREEN NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        className="form-control inpt inpt-768 mb-lg-0 mb-3"
                        name="content_name"
                        value={extras?.content_name}
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <h3 className="mb-2 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Live Stream Type
                </h3>
                <div className="">
                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        LIVE STREAM NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="stream_name"
                        value={extras?.stream_name}
                        className="form-control inpt inpt-768 mb-lg-0 mb-3"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <h3 className="mb-2 mt-3 mt-sm-2 ms-md-0 ms-sm-3 fw-bold">
                  Band
                </h3>
                <div className="">
                  <div className="row mb-3  ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        BAND NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="band_name"
                        value={extras?.band_name}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        SINGER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="singer_name"
                        value={extras?.singer_name}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-12 col-lg-4">
                      <label for="basic-url" className="form-label  label">
                        USHER NAME
                      </label>
                    </div>
                    <div className="col-12 col-lg-8">
                      <input
                        type="text"
                        name="usher_name"
                        value={extras?.usher_name}
                        className="form-control inpt inpt-768 "
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_extra: state?.Get_extra_data_reducer,
});
export default connect(mapStateToProps)(ViewExtraData);
