import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import { getViewFoodAndDrinksData } from "../../../Store/Action/useFetchData";
const ViewFoodAndDrink = ({
  dispatch,
  res_get_food,
  viewFoodAndDrink,
  drinkType,
  setViewFoodAndDrink,
}) => {
  const [Whitehall, setWhiteHall] = useState({
    sd_menu_name: "",
    sd_menu_price: "",
    hd_menu_name: "",
    hd_menu_price: "",
    f_menu_name: "",
    f_menu_price: "",
  });
  const [outsource, setOutsource] = useState({
    c_name: "",
    fd_cost: "",
    e_fd_cost: "",
    fd_notes: "",
  });
  useEffect(() => {
    dispatch(getViewFoodAndDrinksData(drinkType));
  }, [drinkType,dispatch]);
  useEffect(() => {
    if (drinkType && res_get_food?.data?.status === true) {
      setWhiteHall({
        sd_menu_name: res_get_food?.data?.data?.soft_drink_menu_name,
        sd_menu_price: res_get_food?.data?.data?.soft_drink_menu_price,
        hd_menu_name: res_get_food?.data?.data?.hard_drink_menu_name,
        hd_menu_price: res_get_food?.data?.data?.hard_drink_menu_price,
        f_menu_name: res_get_food?.data?.data?.food_menu_name,
        f_menu_price: res_get_food?.data?.data?.food_menu_price,
      });
      setOutsource({
        c_name: res_get_food?.data?.data?.company_name,
        fd_cost: res_get_food?.data?.data?.food_drinks_cost,
        e_fd_cost: res_get_food?.data?.data?.extra_food_drinks_cost,
        fd_notes: res_get_food?.data?.data?.food_drinks_notes,
      });
    }
  }, [drinkType, res_get_food]);
  const handleClose = () => {
    setViewFoodAndDrink(false);
    res_get_food.loading = true;
    res_get_food.data = {};
  };
  return (
    <>
      <div
        className={`modal${
          viewFoodAndDrink ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">
                 Food Menu
              </h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_food?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                {/* <h3 className="mb-4  fw-bold">Food and Drinks</h3> */}
                {res_get_food?.data?.data?.company_type === "0" ? (
                  <>
                    {/* {res_get_food?.data?.data?.soft_drink_menu_name !==
                      null && (
                      <>
                        <div className="row mb-3 ">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              SOFT DRINK MENU NAME
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <input
                              type="text"
                              className="form-control inpt inpt-768 "
                              name="sd_menu_name"
                              readOnly
                              value={Whitehall?.sd_menu_name}
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-lg-4 ">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              SOFT DRINK MENU PRICE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8 ">
                            <div className="input-group price-jod price-jod-768">
                              <input
                                type="number"
                                className="form-control inpt-border-trans"
                                name="sd_menu_price"
                                value={Whitehall?.sd_menu_price}
                                readOnly
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                    {/* {res_get_food?.data?.data?.hard_drink_menu_name !==
                      null && (
                      <>
                        <div className="row mb-3">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              HARD DRINK MENU NAME
                            </label>
                          </div>
                          <div className="col-12 col-lg-8 ">
                            <input
                              type="text"
                              className="form-control inpt inpt-768 "
                              name="hd_menu_name"
                              value={Whitehall?.hd_menu_name}
                              readOnly
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-lg-4 ">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              HARD DRINK MENU PRICE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <div className="input-group price-jod price-jod-768 ">
                              <input
                                type="number"
                                name="hd_menu_price"
                                readOnly
                                value={Whitehall?.hd_menu_price}
                                className="form-control inpt-border-trans"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                    {res_get_food?.data?.data?.food_menu_name !== null && (
                      <>
                        <div className="row mb-3">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              FOOD MENU NAME
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <input
                              type="text"
                              className="form-control inpt inpt-768 "
                              aria-label="Username"
                              name="f_menu_name"
                              value={Whitehall?.f_menu_name}
                              readOnly
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-lg-4">
                            <label
                              for="basic-url"
                              className="form-label  label"
                            >
                              FOOD MENU PRICE
                            </label>
                          </div>
                          <div className="col-12 col-lg-8">
                            <div className="input-group price-jod price-jod-768 ">
                              <input
                                type="number"
                                name="f_menu_price"
                                readOnly
                                value={Whitehall?.f_menu_price}
                                className="form-control inpt-border-trans"
                              />
                              <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                                JOD
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : res_get_food?.data?.data?.company_type === "1" ? (
                  <>
                    <div className="row mb-3 ">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          COMPANY NAME
                        </label>
                      </div>
                      <div className="col-12 col-lg-8">
                        <input
                          type="text"
                          className="form-control inpt inpt-768"
                          name="c_name"
                          readOnly
                          value={outsource.c_name}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          FOOD COST
                        </label>
                      </div>

                      <div className="col-12 col-lg-8">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            name="fd_cost"
                            readOnly
                            value={outsource.fd_cost}
                            className="form-control inpt-border-trans"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          EXTRA FOOD COST
                        </label>
                      </div>

                      <div className="col-12 col-lg-8">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            className="form-control inpt-border-trans"
                            name="e_fd_cost"
                            readOnly
                            value={outsource.e_fd_cost}
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3  ">
                      <div className="col-12 col-lg-4">
                        <label for="basic-url" className="form-label  label">
                          FOOD NOTES
                        </label>
                      </div>

                      <div className="col-12 col-lg-8">
                        <div className="input-group price-textarea">
                          <textarea
                            className=" form-control inpt-border-trans"
                            name="fd_notes"
                            autoComplete="off"
                            value={outsource.fd_notes}
                            readOnly
                            id="exampleFormControlTextarea1"
                            rows="6"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
    res_token: state?.CrcToken_reducer,
    res_get_food: state?.Get_view_food_and_drinks_reducer,
  });
  
  export default connect(mapStateToProps)(ViewFoodAndDrink);
