import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AddSoundLight, getToken } from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
const SoundLight = ({ dispatch, res_Sound }) => {
  const [selectedMenu, setselectedMenu] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Whitehall, setWhiteHall] = useState({
    package_name: "",
    package_price: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setWhiteHall({ ...Whitehall, [name]: value });
  };
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  const handleReset = () => {
    setWhiteHall({
      package_name: "",
      package_price: "",
    });
    setOutsource({
      c_name: "",
    sl_cost: "",
    e_sl_cost: "",
    sl_notes: "",
    })
  };

  const [outsource, setOutsource] = useState({
    c_name: "",
    sl_cost: "",
    e_sl_cost: "",
    sl_notes: "",
  });
  const handleChange1 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setOutsource({ ...outsource, [name]: value });
  };
  useEffect(()=>{
     toast.dismiss();
  },[])
  const handlesubmit = () => {
    if (selectedMenu === 0) {
      const { package_name, package_price } = Whitehall;
      const submitData = {
        company_type: "0",
        package_name: package_name,
        package_price: package_price,
        company_name: "",
        sound_light_cost: "",
        extra_sound_light_cost: "",
        sound_light_notes: "",
      };
      dispatch(AddSoundLight(submitData, setLoading));
    } else if (selectedMenu === 1) {
      const { c_name, sl_cost, e_sl_cost, sl_notes } = outsource;
      const submitData = {
        company_type: "1",
        package_name: "",
        package_price: "",
        company_name: c_name,
        sound_light_cost: sl_cost,
        extra_sound_light_cost: e_sl_cost,
        sound_light_notes: sl_notes,
      };
      dispatch(AddSoundLight(submitData, setLoading));
    }
  };
  useEffect(() => {
    if (res_Sound?.data?.status === true) {
      const notify = () =>
        toast.success(res_Sound?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_Sound.loading = true;
        res_Sound.data = {};
        navigate("/sound_light");
      }, 1500);
    } else if (res_Sound?.data?.status === false) {
      const notify = () =>
        toast.error(res_Sound?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_Sound.loading = true;
        res_Sound.data = {};
      }, 1500);
    }
  }, [res_Sound, navigate]);

  return (
    <>
      <Toaster />
      <div className="">
        <div className="container-fluid">
          <div className="form-margin-768 form-margin padding-left-21 ">
            <div className="   form-in-768">
              <div className={`d-grid gap-2 d-md-block `}>
                <div className="mt-3 btn_center">
                  <button
                    className={`btn ${
                      selectedMenu === 0 ? "large-black-btn" : " large-gray-btn"
                    } large-btn-768  ms-1 `}
                    type="button"
                    onClick={() => setselectedMenu(0)}
                  >
                    WHITE HALL
                  </button>
                  <button
                    className={`btn  btn_outsource large-btn-768  ${
                      selectedMenu === 1 ? "large-black-btn" : " large-gray-btn"
                    }  ms-1`}
                    type="button"
                    onClick={() => setselectedMenu(1)}
                  >
                    OUTSOURCE
                  </button>
                </div>
              </div>
              <h3 className="mb-4 mt-5  fw-bold">Sound and Light</h3>
              {/* {/ {/ {/ ----------------------------white-hall-----------------------------------------    /} /} /} */}
              <div
                className={` ${
                  selectedMenu === 0 ? "" : " d-none"
                } form-in-768`}
              >
                <div className="row mb-3 mt-3 pt-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      {" "}
                      PACKAGE NAME
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control inpt inpt-768"
                      aria-label="Username"
                      name="package_name"
                      value={Whitehall?.package_name}
                      onChange={handleChange}
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      PACKAGE PRICE
                    </label>
                  </div>

                  <div className="col-lg-8 col-12">
                    <div className="input-group mb-3 price-jod price-jod-768">
                      <input
                        type="number"
                        autoComplete="off"
                        className="form-control inpt-border-trans"
                        name="package_price"
                        value={Whitehall?.package_price}
                        onChange={handleChange}
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* {/ {/ {/ -----------------------------------Outsource------------------------------------ /} /} /} */}

              <div
                className={` ${
                  selectedMenu === 1 ? "" : " d-none"
                } form-in-768 `}
              >
                <div className="row mb-3 mt-3 pt-3 ">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      COMPANY NAME
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input
                      type="text"
                      name="c_name"
                      autoComplete="off"
                      value={outsource?.c_name}
                      onChange={handleChange1}
                      className="form-control inpt inpt-768"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      SOUND & LIGHT COST
                    </label>
                  </div>

                  <div className="col-lg-8 col-12">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        type="number"
                        name="sl_cost"
                        autoComplete="off"
                        value={outsource?.sl_cost}
                        onChange={handleChange1}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      EXTRA SOUND & LIGHT COST
                    </label>
                  </div>

                  <div className="col-lg-8 col-12">
                    <div className="input-group price-jod price-jod-768">
                      <input
                        type="number"
                        name="e_sl_cost"
                        autoComplete="off"
                        value={outsource?.e_sl_cost}
                        onChange={handleChange1}
                        className="form-control inpt-border-trans"
                      />
                      <span className="input-group-text input-group-text-768  inpt-border-trans inpt-lable  justify-content-center">
                        JOD
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row mb-3  ">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      SOUND & LIGHT NOTES
                    </label>
                  </div>

                  <div className="col-lg-8 col-12">
                    <div className="input-group  price-textarea text_area">
                      <textarea
                        className=" form-control inpt-border-trans"
                        id="exampleFormControlTextarea1"
                        name="sl_notes"
                        autoComplete="off"
                        value={outsource?.sl_notes}
                        onChange={handleChange1}
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative  display-above-768">
                <button
                  type="button"
                  className="btn btn-g me-4 btn-768  mb-2"
                  onClick={() => {
                    res_Sound.loading = true;
                    res_Sound.data = {};
                    navigate("/sound_light");
                  }}
                >
                  BACK
                </button>
                <button
                  type="button"
                  className="btn btn-g me-4 btn-768 mb-2"
                  onClick={handleReset}
                >
                  RESET
                </button>
                {loading ? (
                  <button type="button" className="btn btn-b me-4 btn-768 mb-2">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-4 btn-768 mb-2"
                    onClick={handlesubmit}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
              <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-m d-5 centerText bottom-section  display-for-768">
                {loading ? (
                  <button type="button" className="btn btn-b me-2 btn-768 mb-4">
                    <span className="spinner-border disable-spinner me-2"></span>
                    SUBMIT
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-b me-2 btn-768 mb-4"
                    onClick={handlesubmit}
                  >
                    SUBMIT
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768 mb-4"
                  onClick={handleReset}
                >
                  RESET
                </button>
                <button
                  type="button"
                  className="btn btn-g me-2 btn-768  mb-4"
                  onClick={() => {
                    res_Sound.loading = true;
                    res_Sound.data = {};
                    navigate("/sound_light");
                  }}
                >
                  BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_Sound: state?.Add_sound_light_reducer,
});
export default connect(mapStateToProps)(SoundLight);
