import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  AllUserNameData,
  GetUserRole,
  UpdateUserRoleData,
  getToken,
} from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../Loader";

const EditUserRole = ({
  dispatch,
  res_user_role,
  res_token,
  res_user_name,
  res_update_role,
}) => {
  const id = useParams();
  const navigate = useNavigate();
  const permission = [
    { id: 0, role: "Billing" },
    { id: 1, role: "Menu" },
    { id: 2, role: "Sound and Light" },
    { id: 3, role: "Check List Event" },
    { id: 4, role: "Send Notification" },
    { id: 5, role: "Show Tax Mode" },
  ];
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState({
    role_name: "",
    status: "",
  });
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedTermIds, setSelectedTermIds] = useState([]);
  const [allCheckboxChecked, setAllCheckboxChecked] = useState(false);

  useEffect(() => {
    if (id.id && res_user_role?.data?.status === true && res_user_name) {
      setRoleData({
        status: res_user_role?.data?.data?.status,
        role_name: res_user_role?.data?.data?.role_name,
      });
    }
    handleAllTermsData();
  }, [id.id, res_user_role,res_user_name,]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(AllUserNameData(setLoading));
    dispatch(GetUserRole(id.id));
    dispatch(getToken())
  }, [id.id,dispatch]);

  const handleChange2 = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRoleData({ ...roleData, [name]: value });
  };
  const numberArray = res_user_role?.data?.data?.permission
    ?.split(",")
    .map(Number);
    // console.log(numberArray,"numberArray");
  const handleAllTermsData = async () => {
    const initialCheckboxState = {};
    permission.forEach((item) => {
      initialCheckboxState[item?.id] = numberArray?.includes(item?.id);
    });
    setCheckboxes(initialCheckboxState);
    setAllCheckboxChecked(numberArray?.length === 6);
    updateSelectedTermIds(numberArray);
  };

  const handleChangeTerms = (id) => {
    if (id === "checkall") {
      const newCheckboxState = {};
      const newAllCheckboxChecked = !allCheckboxChecked;
      permission.forEach((item) => {
        newCheckboxState[item?.id] = newAllCheckboxChecked;
      });
      setCheckboxes(newCheckboxState);
      // console.log(newCheckboxState,"newCheckboxState");

      setAllCheckboxChecked(newAllCheckboxChecked);
      updateSelectedTermIds(newAllCheckboxChecked ? [0, 1, 2, 3, 4,5] : []);
    } else {
      const newCheckboxState = { ...checkboxes, [id]: !checkboxes[id] };
      setCheckboxes(newCheckboxState);
      const selectedIds = Object.keys(newCheckboxState).filter(
        (key) => newCheckboxState[key]
      );
      // console.log(selectedIds.length,"selectedIds.length");
      // console.log(numberArray.length,"numberArray.length");
      setAllCheckboxChecked(selectedIds.length === 6);
      updateSelectedTermIds(selectedIds);
    }
  };

  const updateSelectedTermIds = (selectedIds) => {
    const checkInputString = selectedIds?.join(", ");
    setSelectedTermIds(checkInputString);
  };

  const handleSubmit = () => {
    const submitData = {
      role_name: roleData?.role_name,
      status: roleData?.status,
      permission: selectedTermIds,
    };
    // console.log(submitData,"submitData");
    dispatch(UpdateUserRoleData(submitData, id.id, setLoading));
  };
  useEffect(() => {
    if (res_update_role?.data?.status === true) {
      const notify = () =>
        toast.success(res_update_role?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_update_role.loading = true;
        res_update_role.data = {};
        res_user_role.loading = true;
        res_user_role.data = {};
        navigate("/user_role");
      }, 1500);
    } else if (res_update_role?.data?.status === false) {
      const notify = () =>
        toast.error(res_update_role?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_update_role.loading = true;
        res_update_role.data = {};
      }, 1500);
    }
  }, [res_update_role,navigate,res_user_role]);
  return (
    <>
      {res_user_role?.loading ? (
        <Loader />
      ) : (
        <>
          <Toaster />
          <div className="container-fluid">
            <div className=" form-margin form-margin-768  padding-left-21">
              <div className="  form-in-768">
                <div className="row mb-4 mt-5 ">
                  <div className="col-lg-3 col-12">
                    <label for="basic-url " className="form-label label">
                      NAME
                    </label>
                  </div>
                  <div className="col-lg-9 col-12 ">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control inpt inpt-768 "
                      aria-label="Username"
                      value={roleData.role_name}
                      aria-describedby="basic-addon1"
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mb-4 ">
                  <div className="col-lg-3 col-12">
                    <label for="basic-url" className="form-label  label">
                      PERMISSION
                    </label>
                  </div>
                  <div className="col-lg-9 col-12">
                    <div className="card width bg-transparant">
                      <div className="card-header header_color p-1">
                        <div className="form-check my-2 p-0 ">
                          <input
                            className="form-check-input bg-transparant cursor-pointer"
                            type="checkbox"
                            name="checkall"
                            checked={allCheckboxChecked}
                            onChange={() => handleChangeTerms("checkall")}
                            id="headerCheckbox"
                          />
                          <label
                            className="form-check-label form_check_size "
                            for="headerCheckbox"
                          >
                            All
                          </label>
                        </div>
                      </div>
                      <ul className="list-group list-group-flush bg-transparant mb-4">
                        {permission.map((e) => {
                          return (
                            <li className="list-group-item border-0 p-1 bg-transparant">
                              <div className="form-check p-0">
                                <input
                                  className="form-check-input cursor-pointer"
                                  type="checkbox"
                                  checked={checkboxes[e.id] || false}
                                  onChange={() => handleChangeTerms(e.id)}
                                  id={`checkbox${e.id}`}
                                />
                                <label
                                  className="form-check-label form_check_size"
                                  // for="checkbox2"
                                >
                                  {e.role}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row mb-3 ">
                  <div className="col-lg-3 col-12 ">
                    <label for="basic-url" className="form-label label mt-3">
                      STATUS
                    </label>
                  </div>
                  <div className="col-lg-9 col-12 ">
                    <div className="my-2">
                      <div className="form-check form-check-inline ps-0">
                        <input
                          className="form-check-input cursor-pointer"
                          type="radio"
                          name="status"
                          onChange={handleChange2}
                          id="inlineRadio1"
                          checked={roleData?.status === "0"}
                          value="0"
                        />
                        <label
                          className="form-check-label cash_lable"
                          for="inlineRadio1"
                        >
                          Active
                        </label>
                      </div>
                      <div className="form-check form-check-inline ms-sm-4 ms-0 ms-lg-auto ">
                        <input
                          className="form-check-input cursor-pointer"
                          type="radio"
                          name="status"
                          onChange={handleChange2}
                          id="inlineRadio1"
                          checked={roleData?.status === "1"}
                          value="1"
                        />
                        <label
                          className="form-check-label cash_lable "
                          for="inlineRadio1"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative  display-above-768 ">
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_update_role.loading = true;
                      res_update_role.data = {};
                      res_user_role.loading = true;
                      res_user_role.data = {};
                      navigate("/user_role");
                    }}
                  >
                    BACK
                  </button>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SAVE
                    </button>
                  )}
                </div>
                <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 pe-lg-5 centerText bottom-section display-for-768">
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                    >
                      <span className="spinner-border disable-spinner me-2"></span>
                      SAVE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-b me-2 mb-4 btn-768"
                      onClick={handleSubmit}
                    >
                      SAVE
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-g me-2  mb-4 btn-768"
                    onClick={() => {
                      res_update_role.loading = true;
                      res_update_role.data = {};
                      res_user_role.loading = true;
                      res_user_role.data = {};
                      navigate("/user_role");
                    }}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_user_role: state?.Getuser_role_data_reducer,
  res_user_name: state?.Add_user_name_data_reducer,
  res_update_role: state?.Update_user_role_data_reducer,
});

export default connect(mapStateToProps)(EditUserRole);
