import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightDoubleLine } from "react-icons/ri";
const EditFirstEvent = ({
  setselectedMenu,
  getData,
  setGetData,
  setHideAndShow,
  hideAndShow,
}) => {
  const [state, setState] = useState(false);
  const [getEventType, setGetEventType] = useState();
  const [selection, setSelection] = useState("Event Type");
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();
  const [value, setValue] = useState();
  const [eventData, setEventData] = useState({});
  const sidebarRef = useRef(null);

  useEffect(() => {
    setGetEventType(getData.eventType);
  }, [getData]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setState]);

  const options = [
    { id: "1", name: "Wedding" },
    { id: "2", name: "Corporate" },
    { id: "3", name: "Concert" },
    { id: "4", name: "Private" },
  ];
  const handleClose = (e) => {
    setState(false);
    // console.log(e, "e.target.innerText");
    if (e.name !== selection && e.name !== "") {
      setSelection(e?.name);
      // console.log("data get");
      setGetEventType(e?.id);
      // setPersonCharge(e.id);
    }
    // setSearchText("");
  };

  const customStyles = {
    backgroundColor: "#4d4d4d",
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#4d4d4d" : "transparent",
      color: state.isSelected ? "white" : "#333",
      ":hover": {
        backgroundColor: "#676767 ",
        color: "white",
        cursor: "pointer",
      },
    }),
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [num, setNum] = useState(0);

  const incNum = () => {
    setNum((prevNum) => prevNum + 1);
  };

  const decNum = () => {
    setNum((prevNum) => Math.max(0, prevNum - 1));
  };
  // console.log(getData, "getEventType");

  const handlChangePhone = (phone) => {
    setGetData((prevTime) => ({
      ...prevTime,
      phoneNumber1: phone,
    }));
  };

  const handlChangePhone2 = (phone) => {
    setGetData((prevTime) => ({
      ...prevTime,
      phoneNumber2: phone,
    }));
  };
  return (
    <>
      <div className="">
        <div className=" position-relative form-margin-768 mb-5 pb-1">
          <form>
            <div
              className={`${
                hideAndShow
                  ? "true-event-type-clnder"
                  : "false-event-type-clnder"
              }`}
            >
              <div className="row mb-3">
                <div className="col-lg-4 col-12 ">
                  <label for="basic-url" className="form-label  label">
                    NAME OF THE RESERVE
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control inpt  inpt-768"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={getData.clientName}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12 col-lg-4">
                  <label for="basic-url" className="form-label  label">
                    EVENT TYPE
                  </label>
                </div>
                <div className="col-12 col-lg-8">
                  {/* <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
                className=" inpt"
                placeholder="Event Type"
                styles={customStyles}
              /> */}
                  {/* <div className="" ref={sidebarRef}>
                <div
                  className="form-select inpt inpt-768"
                  onClick={() => setState(true)}
                  style={{ cursor: "pointer" }}
                >
                  <option
                    className="overflow-hidden"
                    style={{ padding: "6px" }}
                  >
                    {selection}
                  </option>
                </div>
                <div
                  className={`${
                    state
                      ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                      : "d-none"
                  }`}
                >
                  {options?.length ? (
                    options?.map((item, index) => {
                      return (
                        <div key={index} className="p-1 py-0">
                          <div
                            onClick={(e) => handleClose(item)}
                            className="select-box-option text-white"
                          >
                            {item.name}
                          </div>
                          <div className="" />
                        </div>
                      );
                    })
                  ) : (
                    <div className="p-1 py-0">
                      <div
                        className="select-box-option-nodata text-center"
                        style={{ cursor: "default" }}
                      >
                        No Data Found
                      </div>
                      <div className="" />
                    </div>
                  )}
                </div>
              </div> */}
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control inpt  inpt-768"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={getData.eventType}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-lg-4 col-12">
                  <label for="basic-url" className="form-label  label">
                    PHONE NUMBER
                  </label>
                </div>
                <div className="col-lg-8 col-12">
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={getData.clientPhone}
                    country={"jo"}
                    className=" inpt  inpt-768"
                    onChange={setValue}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div
              className={`padding-top-768 ${
                hideAndShow
                  ? "true-first-event-type-clnder"
                  : "false-first-event-type-clnder"
              }`}
            >
              {getEventType == "Wedding" ? (
                <>
                  <h3 className="mb-4 mt-0 mt-sm-3 fw-bold  pt-3 pt-sm-0">
                    If the event is wedding, Please fill below
                  </h3>
                  <div className="row mb-3 pt-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        GROOM NAME
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt   inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.groomName}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        BRIDE NAME
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt  inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.brideName}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-4 col-12">
                      <label for="basic-url" className="form-label  label">
                        PHONE NUMBERS FOR BOTH
                      </label>
                    </div>
                    <div className="col-lg-8 col-12 d-block d-xl-flex">
                      <div>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={getData.phoneNumber1}
                          country={"jo"}
                          className=" inpt  inpt-768"
                          onChange={handlChangePhone}
                        />
                      </div>

                      <div className="mt-3 mt-xl-0 ms-0 ms-xl-4">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={getData.phoneNumber2}
                          country={"jo"}
                          className=" inpt  inpt-768"
                          onChange={handlChangePhone2}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-lg-4 col-12 ">
                      <label
                        for="basic-url"
                        className="form-label d-block text-start label"
                      >
                        NUMBER OF GUESTS
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group price">
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor"
                          onClick={decNum}
                        >
                          -
                        </span>
                        <input
                          type=""
                          autoComplete="off"
                          className="form-control inpt-border-trans text-center"
                          value={getData.noOfGuests}
                          onChange={(e) =>
                            setNum(parseInt(e.target.value) || 0)
                          }
                          readOnly={true}
                        />
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor "
                          onClick={incNum}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : getEventType == "Corporate" ? (
                <>
                  <h3 className="mb-4 mt-0 mt-sm-3 fw-bold  pt-3 pt-sm-0">
                    If the event is Corporate, Please fill below
                  </h3>
                  <div className="row mb-3 pt-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        Company Name
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt  inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.compneyName}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        Company Id
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt  inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.compneyId}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3 ">
                    <div className="col-lg-4 col-12 ">
                      <label
                        for="basic-url"
                        className="form-label d-block text-start label"
                      >
                        NUMBER OF GUESTS
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group price">
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor"
                          onClick={decNum}
                        >
                          -
                        </span>
                        <input
                          type=""
                          autoComplete="off"
                          className="form-control inpt-border-trans text-center"
                          // value={num}
                          onChange={(e) =>
                            setNum(parseInt(e.target.value) || 0)
                          }
                          value={getData.noOfGuests}
                          readOnly={true}
                        />
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor "
                          onClick={incNum}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : getEventType == "Concert" ? (
                <>
                  <h3 className="mb-4 mt-0 mt-sm-3 fw-bold  pt-3 pt-sm-0">
                    If the event is Concert, Please fill below
                  </h3>
                  <div className="row mb-3 pt-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        Company Name
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt  inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.compneyName}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        Company Id
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt  inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.compneyId}
                        readOnly={true}
                      />
                    </div>
                  </div>

                
                    <div className="row mb-3 ">
                      <div className="col-lg-4 col-12 ">
                        <label
                          for="basic-url"
                          className="form-label label mt-3"
                        >
                          SINGERS NAMES
                        </label>
                      </div>
                      <div className="col-lg-8 col-12 ps-3">
                        {/* <div className="row "> */}
                        {/* <div className=" col-12 mb-3 mb-lg-0"> */}{" "}
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control inpt mb-3  inpt-768"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="singersNames1"
                          value={getData.singersNames1}
                          readOnly={true}
                          //   value={eventData.singersNames1}
                          //   onChange={handleChange2}
                        />
                        {/* </div> */}
                        {/* <div className=" mb-3 md-lg-0"> */}{" "}
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control inpt mb-3  inpt-768"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="singersNames2"
                          value={getData.singersNames2}
                          readOnly={true}
                          //   value={eventData.singersNames2}
                          //   onChange={handleChange2}
                        />
                        {/* </div> */}
                        {/* <div className=" col-12 mb-3 mb-lg-0"> */}{" "}
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control inpt mb-3  inpt-768"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="singersNames3"
                          value={getData.singersNames3}
                          readOnly={true}
                          //   value={eventData.singersNames3}
                          //   onChange={handleChange2}
                        />
                        {/* </div> */}
                        {/* </div> */}
                      </div>
                    </div>
               
                  <div className="row mb-3 ">
                    <div className="col-lg-4 col-12 ">
                      <label
                        for="basic-url"
                        className="form-label d-block text-start label"
                      >
                        NUMBER OF GUESTS
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group price">
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor"
                          onClick={decNum}
                        >
                          -
                        </span>
                        <input
                          type=""
                          autoComplete="off"
                          className="form-control inpt-border-trans text-center"
                          // value={num}
                          onChange={(e) =>
                            setNum(parseInt(e.target.value) || 0)
                          }
                          value={getData.noOfGuests}
                          readOnly={true}
                        />
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor "
                          onClick={incNum}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : getEventType == "Private" ? (
                <>
                  <h3 className="mb-4 mt-0 mt-sm-3 fw-bold  pt-3 pt-sm-0">
                    If the event is Private, Please fill below
                  </h3>
                  <div className="row mb-3 pt-3">
                    <div className="col-lg-4 col-12 ">
                      <label for="basic-url" className="form-label  label">
                        Event Category
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control inpt  inpt-768"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={getData.eventCategory}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-lg-4 col-12 ">
                      <label
                        for="basic-url"
                        className="form-label d-block text-start label"
                      >
                        NUMBER OF GUESTS
                      </label>
                    </div>
                    <div className="col-lg-8 col-12">
                      <div className="input-group price">
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor"
                          onClick={decNum}
                        >
                          -
                        </span>
                        <input
                          type=""
                          autoComplete="off"
                          className="form-control inpt-border-trans text-center"
                          // value={num}
                          onChange={(e) =>
                            setNum(parseInt(e.target.value) || 0)
                          }
                          value={getData.noOfGuests}
                          readOnly={true}
                        />
                        <span
                          className="input-group-text inpt-border-trans inpt-lable justify-content-center cursor-pontor "
                          onClick={incNum}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5 next_btn me-md-5 pe-m d-5 centerText bottom-section position-relative next-btn-768">
              <button
                type="button"
                className="btn btn-b me-0 me-lg-2 mb-2"
                onClick={() => setselectedMenu(1)}
              >
                NEXT <RiArrowRightDoubleLine className="fs-5" />
              </button>
            </div>
          </form>
        </div>

        <div
          className={`next-btn mt-5 fixed-bottom ${
            hideAndShow ? "next-btn-false" : "next-btn-true"
          }`}
        >
          <div
            className=" d-flex justify-content-between p-2 "
            style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
          >
            <div className="d-flex">
              <RiArrowLeftSLine className=" fs-2 mt-1" />
              <p
                className="  text-dark pt-1 mb-0"
                onClick={() => setHideAndShow(false)}
              >
                PREVIOUS
              </p>
            </div>
            <div className="d-flex">
              <p
                className=" text-dark pt-1 mb-0"
                onClick={() => setselectedMenu(1)}
              >
                NEXT
              </p>
              <RiArrowRightSLine className="fs-2 mt-1" />
            </div>
          </div>
        </div>
        <div
          className={`next-btn fixed-bottom ${
            hideAndShow ? "next-btn-true" : "next-btn-false"
          }`}
        >
          <div
            className=" d-flex justify-content-end p-2 "
            style={{ cursor: "pointer",backgroundColor:"#e5e5e5" }}
          >
            {/* <div> */}
            <p
              className="  text-dark pt-1 mb-0"
              onClick={() => setHideAndShow(true)}
            >
              NEXT
            </p>
            <RiArrowRightSLine className=" fs-2 mt-1" />
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFirstEvent;