import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import Loader from "../../../Loader";
import { discountReportsChartData, getToken } from "../../../Store/Action/useFetchData";


ChartJS.register(ArcElement, Tooltip, Legend);

const DiscountReport = ({ dispatch, res_chart }) => {
  const [selectedMenu, setselectedMenu] = useState(0);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartValue, setChartValue] = useState("day");
  const [chartShowData, setChartShowData] = useState({});
  useEffect(() => {
    dispatch(getToken());
    handleChartData("day");
  }, []);
  useEffect(() => {
    if (res_chart && res_chart?.data?.status === true) {
      setChartData(res_chart?.data?.data);
    }
  }, [res_chart, chartValue]);
  const staticData = [
    {
      label: "Red",
      per: 12,
    },
    {
      label: "Blue",
      per: 19,
    },
    {
      label: "Yellow",
      per: 3,
    },
    {
      label: "Green",
      per: 5,
    },
    {
      label: "Purple",
      per: 2,
    },
    {
      label: "Orange",
      per: 3,
    },
  ];
  useEffect(() => {
    const setPer = [];
    const setLabel = [];
    const setColor = [];
    // const setBorderColor = [];
    const color = () => {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      return "rgb(" + r + "," + g + "," + b + ")";
    };
    chartData?.map((e) => {
      setPer.push(e?.payment);
      setLabel.push(e?.Date);
      setColor.push(color());
      // setBorderColor.push("rgb(0, 0, 0)")
    });
    const data = {
      labels: setLabel,
      datasets: [
        {
          data: setPer,
          label: "JOD",
          backgroundColor: setColor,
          borderColor: setColor,
          borderWidth: 1,
        },
      ],
    };
    setChartShowData(data);
  }, [chartData]);
  const handleChartData = (data) => {
    dispatch(discountReportsChartData(data, setLoading));
  };
  return (
    <>
    <div className="">
      {/* <Toaster /> */}
      {res_chart.loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className=" form-margin-768 padding-left-21 form-margin">
            <div className=" ">
              <div className={`d-grid gap-2 d-md-block `}>
                <div className="mt-3 btn_center">
                  <button
                    className={`btn ${
                      selectedMenu === 0
                        ? "large-black-btn"
                        : " large-gray-btn"
                    } large-btn-768-1  ms-1 `}
                    type="button"
                    onClick={() => {
                      setselectedMenu(0);
                      // setChartValue("day");
                      handleChartData("day");
                    }}
                  >
                    DAILY
                  </button>
                  <button
                    className={`btn  btn_outsource large-btn-768-1  ${
                      selectedMenu === 1
                        ? "large-black-btn"
                        : " large-gray-btn"
                    }  ms-1`}
                    type="button"
                    onClick={() => {
                      setselectedMenu(1);
                      // setChartValue("month");
                      handleChartData("month");
                    }}
                  >
                    MONTHLY
                  </button>
                  <button
                    className={`btn  btn_outsource large-btn-768-1  ${
                      selectedMenu === 2
                        ? "large-black-btn"
                        : " large-gray-btn"
                    }  ms-1`}
                    type="button"
                    onClick={() => {
                      setselectedMenu(2);
                      // setChartValue("year")
                      handleChartData("year");
                    }}
                  >
                    YEARLY
                  </button>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-lg-8">
                    {Object.keys(chartShowData).length > 0 ? (
                      <Doughnut data={chartShowData} className="chart" />
                    ) : (
                      <>
                        {" "}
                        <div
                          className="container"
                          style={{ margin: "59px 0px", maxWidth: "100%" }}
                        >
                          <div className="row text-center">
                            <h2>No Data Found</h2>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </>
  )
}
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_chart: state?.get_Discount_reports_chart_Data_reducer,
});

export default connect(mapStateToProps)(DiscountReport)