import React, { useEffect } from "react";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { getToken } from "../../../../Store/Action/useFetchData";

const RegenerateModal = ({
  dispatch,
  setShowModal,
  handleFormSubmit,
  eventId,
  showModal,
  setLoading,
  loading,
}) => {
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    toast.dismiss();
  }, []);
  const handleClose = () => {
    const notify = () =>
      toast.error("Update Failed", {
        style: {
          padding: "10px",
          borderRadius: "30px",
        },
      });
    notify();
    setShowModal(false);
    window.location.reload();
  };
  //   useEffect(() => {
  //     if (res_menu?.data?.status === true) {
  //       const notify = () =>
  //         toast.success(res_menu?.data?.message, {
  //           style: {
  //             padding: "10px",
  //             borderRadius: "30px",
  //           },
  //         });
  //       notify();
  //       setTimeout(() => {
  //         setDeleteMenuModal(false);
  //         res_menu.loading = true;
  //         res_menu.data = {};
  //       }, 1500);
  //     } else if (res_menu?.data?.status === false) {
  //       const notify = () =>
  //         toast.error(res_menu?.data?.message, {
  //           style: {
  //             padding: "10px",
  //             borderRadius: "30px",
  //           },
  //         });
  //       notify();
  //       setTimeout(() => {
  //         res_menu.loading = true;
  //         res_menu.data = {};
  //       }, 1500);
  //     }
  //   }, [res_menu,setDeleteMenuModal]);
  return (
    <div>
      <Toaster />
      <div
        className={`modal${showModal ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-xl-role">
          <div className="modal-content">
            {/* <div className="modal-header border-0 justify-content-end">
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div> */}
            <div className="modal-body modal-padd px-4">
              <h5 className="fw-bold">
                {" "}
                Are you sure you want to delete the previous bill?
              </h5>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-g me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                NO
              </button>
              {loading ? (
                <button
                  type="button"
                  className="btn btn-b me-2  mb-4 btn-768 "
                  disabled
                >
                  <span className="spinner-border disable-spinner me-2"></span>{" "}
                  YES
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-b me-2  mb-4 btn-768 "
                  onClick={() => handleFormSubmit(eventId)}
                >
                  YES
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});
export default connect(mapStateToProps)(RegenerateModal);
