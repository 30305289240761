import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MiniLoader from "../../../MiniLoader";
import {
  getToken,
  getViewSoundAndLightData,
} from "../../../Store/Action/useFetchData";

const ViewSoundAndLight = ({
  dispatch,
  res_get_food,
  viewSoundAndLight,
  setViewSoundAndLight,
  viewType,
}) => {
  const [Whitehall, setWhiteHall] = useState({
    package_name: "",
    package_price: "",
  });
  const [outsource, setOutsource] = useState({
    c_name: "",
    sl_cost: "",
    e_sl_cost: "",
    sl_notes: "",
  });
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getViewSoundAndLightData(viewType));
  }, [viewType,dispatch]);
  useEffect(() => {
    if (viewType && res_get_food?.data?.status === true) {
      setWhiteHall({
        package_name: res_get_food?.data?.data?.package_name,
        package_price: res_get_food?.data?.data?.package_price,
      });
      setOutsource({
        c_name: res_get_food?.data?.data?.company_name,
        sl_cost: res_get_food?.data?.data?.sound_light_cost,
        e_sl_cost: res_get_food?.data?.data?.extra_sound_light_cost,
        sl_notes: res_get_food?.data?.data?.sound_light_notes,
      });
    }
  }, [viewType, res_get_food]);
  const handleClose = () => {
    setViewSoundAndLight(false);
    res_get_food.loading = true;
    res_get_food.data = {};
  };
  return (
    <>
      <div
        className={`modal${
          viewSoundAndLight ? " fade show d-block " : " d-none"
        }`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">
                 Sound and Light
              </h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_get_food?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                {/* <h3 className="mb-4  fw-bold">Sound and Light</h3> */}
                {res_get_food?.data?.data?.company_type === "0" && (
                  <>
                    <div className="row mb-2 mt-3 ">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          {" "}
                          PACKAGE NAME
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <input
                          type="text"
                          className="form-control inpt inpt-768"
                          aria-label="Username"
                          name="package_name"
                          value={Whitehall?.package_name}
                          readOnly
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          PACKAGE PRICE
                        </label>
                      </div>

                      <div className="col-lg-8 col-12">
                        <div className="input-group mb-2 price-jod price-jod-768">
                          <input
                            type="number"
                            className="form-control inpt-border-trans"
                            name="package_price"
                            value={Whitehall?.package_price}
                            readOnly
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {res_get_food?.data?.data?.company_type === "1" && (
                  <>
                    <div className="row mb-2 mt-3 ">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          COMPANY NAME
                        </label>
                      </div>
                      <div className="col-lg-8 col-12">
                        <input
                          type="text"
                          name="c_name"
                          value={outsource?.c_name}
                          readOnly
                          className="form-control inpt inpt-768"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          SOUND & LIGHT COST
                        </label>
                      </div>

                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            name="sl_cost"
                            value={outsource?.sl_cost}
                            readOnly
                            className="form-control inpt-border-trans"
                          />
                          <span className="input-group-text input-group-text-768 inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          EXTRA SOUND & LIGHT COST
                        </label>
                      </div>

                      <div className="col-lg-8 col-12">
                        <div className="input-group price-jod price-jod-768">
                          <input
                            type="number"
                            name="e_sl_cost"
                            value={outsource?.e_sl_cost}
                            readOnly
                            className="form-control inpt-border-trans"
                          />
                          <span className="input-group-text input-group-text-768  inpt-border-trans inpt-lable  justify-content-center">
                            JOD
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2  ">
                      <div className="col-lg-4 col-12">
                        <label for="basic-url" className="form-label  label">
                          SOUND & LIGHT NOTES
                        </label>
                      </div>

                      <div className="col-lg-8 col-12">
                        <div className="input-group  price-textarea text_area">
                          <textarea
                            className=" form-control inpt-border-trans"
                            autoComplete="off"
                            id="exampleFormControlTextarea1"
                            name="sl_notes"
                            value={outsource?.sl_notes}
                            readOnly
                            rows="6"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_get_food: state?.Get_view_sound_and_light_reducer,
});

export default connect(mapStateToProps)(ViewSoundAndLight);
