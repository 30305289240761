import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import {
  MainKitchenPdfDownload,
  getKitchenEvent,
  getToken,
} from "../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import pink_img from "../../../images/pink-triangle.png";
import green_img from "../../../images/greentriangle.png";
import { MdArrowBackIos } from "react-icons/md";
import { GrNext } from "react-icons/gr";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import Pusher from "pusher-js";
import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import EventSummary from "../Forms/View/EventSummary";
import KitchenGetEventsPDF from "../../PDF/KitchenGetEventsPDF";

const localizer = momentLocalizer(moment);
const KitchenDetailTable = ({
  dispatch,
  res_token,
  res_pdf_data,
  res_calendar_event,
}) => {
  const [events, setEvents] = useState([]);
  const [eventsTemp, setEventsTemp] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);

  const [secondEvent, setSecondEvent] = useState(null);
  const [eventType, setEventType] = useState("Wedding");
  const [eventTypePhone, setEventTypePhone] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [deleteEventId, setDeleteEventID] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditEvent, setShowEditEvent] = useState(false);
  const [editEventData, setEditEventData] = useState(false);
  const [editEventType, setEditEventType] = useState("");
  const [editFile, setEditFile] = useState([]);
  const [showTransferForm, setShowTransferForm] = useState(false);
  const [eventIdTransfer, setEventIdTransfer] = useState();
  const [showEventSummary, setEventSummary] = useState(false);
  const [summaryEventId, setSummaryEventId] = useState();
  const [selectedMonth, setSelectedMonth] = useState(new Date()); // Assuming selectedMonth is stored in state
  const [length, setLength] = useState(0);
  const [tableInitialized, setTableInitialized] = useState(false);
  const [socket, setSocket] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (!tableInitialized) {
      TableDatatablesManaged.init();
      setTableInitialized(true);
    }
    dispatch(getToken());
    dispatch(getKitchenEvent());
  }, []);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    // console.log(channel,"channel");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log(socket,"socket");
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  // console.log(tableInitialized, "tableiii");
  // useEffect(() => {
  //   dispatch(getKitchenEvent())

  // }, [res_calendar_event]);

  const rbc_dates = document.querySelectorAll(".rbc-date-cell");

  useEffect(() => {
    if (rbc_dates.length > 0) {
      rbc_dates.forEach((date) => {
        date.style.setProperty("margin-top", "26px");
        // date.style.marginTop = '26px';
      });
    }
  }, [rbc_dates]);

 
  useEffect(() => {
    // if (res_calendar_event?.data?.status === true) {
    //   const events_arr = res_calendar_event.data.data;
    //   const transformedEvents = events_arr.map((event, index, array) => {
    //     const { eventID, eventType, startDate, endDate, reservationStatus } = event;
    //     const isSecond = array.slice(0, index).some(prevEvent => prevEvent.startDate === startDate
    //       );

    //     // Adjusting the time to 00:00:00
    //     const startDateTime = new Date(startDate);
    //     startDateTime.setHours(0, 0, 0, 0);

    //     const endDateTime = new Date(endDate);
    //     endDateTime.setHours(0, 0, 0, 0);

    //     return {
    //       end: endDateTime,
    //       id: eventID,
    //       isSecond: isSecond ? 1 : 0,
    //       start: startDateTime,
    //       type: reservationStatus,
    //     };
    //   });

    //   setEvents([...events, ...transformedEvents] )
    //   //   const notify = () =>
    //   //   toast.success(res_calendar_event?.data?.message, {
    //   //     style: {
    //   //       padding: "10px",
    //   //       borderRadius: "30px",
    //   //     },
    //   //   });
    //   // notify();

    // }

    if (res_calendar_event?.data?.status === true) {
      var tax_mode_final = res_calendar_event?.data?.tax?.tax_mode
      const events_arr = res_calendar_event.data.data;
      let transformedEvents = [];

      if (tax_mode_final === "0") {
        transformedEvents = events_arr.map((event, index, array) => {
          const {
            eventID,
            eventType,
            startDate,
            endDate,
            reservationStatus,
            tax_mode,
          } = event;
          if (tax_mode === "0") {
            const isSecond = array
              .slice(0, index)
              .some((prevEvent) => prevEvent.startDate === startDate);
            // Adjusting the time to 00:00:00
            const startDateTime = new Date(startDate);
            startDateTime.setHours(0, 0, 0, 0);

            const endDateTime = new Date(endDate);
            endDateTime.setHours(0, 0, 0, 0);

            // Check if eventID already exists in transformedEvents
            const isDuplicate = transformedEvents.some(
              (prevTransformedEvent) => prevTransformedEvent.id === eventID
            );

            // Return null if it's a duplicate eventID
            if (isDuplicate) {
              return null;
            }

            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: true,
            };
          } else {
            return null;
          }
        });
        if (transformedEvents) {
          setEvents([...transformedEvents.filter((event) => event !== null && event !== undefined)]);
      }
      } else if (tax_mode_final === "1") {
        transformedEvents = events_arr.map((event, index, array) => {
          const {
            eventID,
            eventType,
            startDate,
            endDate,
            reservationStatus,
            tax_mode,
          } = event;
          const isSecond = array
            .slice(0, index)
            .some((prevEvent) => prevEvent.startDate === startDate);

          // Adjusting the time to 00:00:00
          const startDateTime = new Date(startDate);
          startDateTime.setHours(0, 0, 0, 0);

          const endDateTime = new Date(endDate);
          endDateTime.setHours(0, 0, 0, 0);

          // Check if eventID already exists in transformedEvents
          const isDuplicate = transformedEvents.some(
            (prevTransformedEvent) => prevTransformedEvent.id === eventID
          );

          // Return null if it's a duplicate eventID
          if (isDuplicate) {
            return null;
          }
          if (tax_mode === "0") {
            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: true,
            };
          } else if (tax_mode === "1") {
            return {
              end: endDateTime,
              id: eventID,
              isSecond: isSecond ? 1 : 0,
              start: startDateTime,
              type: reservationStatus,
              show_details: false,
            };
          }
        });
        if (transformedEvents) {
          setEvents([...transformedEvents.filter((event) => event !== null && event !== undefined)]);
      }
      }
    } else if (res_calendar_event?.data.status === false) {
    }
  }, [res_calendar_event]);

  // console.log(res_token, "res_token");
  const api_token = sessionStorage.getItem("api_token");
  // console.log(res_token, "res_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showBill");
      var table1 = $("#showBill").DataTable({
        columnDefs: [{ orderable: false, targets: 7 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_event_kitchen_data",
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          // data:
          //   searchData === ""
          //     ? { submit: "" }
          //     : { submit: "custom", status: searchData },
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "eventID",
            width: "10%",
          },
          {
            data: "clientName",
            width: "15%",
          },
          {
            data: "startDate",
            width: "15%",
          },
          {
            data: "eventType",
            width: "15%",
          },
          {
            data: "noOfGuests",
            width: "15%",
          },
          {
            data: "m_name",
            width: "15%",
          },
          // {
          //   data: "s_name",
          //   width: "15%",
          // },
          {
            data: null,
            width: "15%",
            render: function (data) {
              // console.log(data.eventID, "data");
              if (data?.kitchen_notes === null) {
                return `-`;
              } else {
                return data?.kitchen_notes;
              }
            },
          },

          {
            data: null,
            width: "10%",
            render: function (data) {
              // console.log(data.eventID, "data");

              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-download fs-4 ps-2 me-0 fw-bold cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },

          // {
          //   data: null,
          //   width: "18%",
          //   render: function (data) {
          //     //   console.log(data, "data");

          //     return (
          //       '<div class="d-flex"><span  id="viewMenu" value="' +
          //       data.id +
          //       '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editMenu" value="' +
          //       data.id +
          //       '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteMenu" value="' +
          //       data.id +
          //       '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
          //     );
          //   },
          // },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          // console.log("Table length:", tableLength);
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showBill tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        // setViewRoleModel(true)
        // console.log(rowData,'rowdata');
        if (rowData && rowData.eventID) {
          // handleDownload(rowData.eventID);
          dispatch(MainKitchenPdfDownload(rowData.eventID));
          // rowData.eventID && navigate(`/create_billing/${rowData.eventID}`);
        }
      });
      $("#showBill_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // setRemove(!remove)
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  // const handleDownload = async (id) => {
  //   try {
  //     const res = await fetch(`/api/getEventPDF/${id}`, {
  //       method: "GET",
  //       headers: {
  //         "X-CSRF-TOKEN": res_token?.data,
  //         Authorization: `Bearer ${api_token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (!res.ok) {
  //       throw new Error(`HTTP error! Status: ${res.status}`);
  //     }
  //     const data = await res.json();
  //     console.log(data, "data");

  //     if (data?.status === true) {
  //       const pdfUrl = data?.url;
  //       const link = document.createElement("a");
  //       link.href = pdfUrl;
  //       link.target = "_blank";
  //       // link.download = "document.pdf"; // specify the filename
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     } else if (data?.status === false) {
  //       const notify = () =>
  //         toast.error(data?.message, {
  //           style: {
  //             padding: "10px",
  //             borderRadius: "30px",
  //           },
  //         });
  //       notify();
  //     }

  //   } catch (error) {
  //     const notify = () =>
  //       toast.error("Something went wrong", {
  //         style: {
  //           padding: "10px",
  //           borderRadius: "30px",
  //         },
  //       });
  //     notify();
  //   }
  // };

  useEffect(() => {
    if (res_pdf_data?.data?.status === true) {
      const pdfUrl = res_pdf_data?.data?.url;
      window.open(pdfUrl);
      // const getData = res_pdf_data?.data?.data;
      // downloadPDFData(getData);
      // const fileName = "report.pdf";

      // axios
      //   .get(res_pdf_data?.data?.url, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     fileDownload(res.data, fileName);
      //   })
      //   .catch((error) => {
      //     console.error("Error downloading the file:", error);
      //   });

      // const link = document.createElement("a");
      // link.href = pdfUrl;
      // link.target = "_blank";
      // // link.download = "document.pdf"; // specify the filename
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
        // navigate("/user-role");
      }, 1500);
    } else if (res_pdf_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    }
  }, [res_pdf_data]);

  const CustomToolbar = ({ label, onNavigate }) => {
    const labelRef = useRef(label); // Store label without causing re-renders

    useEffect(() => {
      const selectedDate = new Date(labelRef.current);
      const selectedMonthStart = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        1
      );

      // Only update state if selectedMonth is different to prevent infinite loops
      if (
        selectedMonth === null ||
        selectedMonth.getTime() !== selectedMonthStart.getTime()
      ) {
        setSelectedMonth(selectedMonthStart);
      }
    }, [labelRef, selectedMonth]);

    return (
      <>
        <div
          style={{ backgroundColor: "black", padding: "10px" }}
          className="rbc-toolbar "
        >
          <div>
            <MdArrowBackIos
              className="cursor-pointer"
              onClick={() => onNavigate("PREV")}
            />
          </div>
          <div>
            <div className="rbc-toolbar-label ">{label}</div>
          </div>
          <div>
            <GrNext
              className="cursor-pointer"
              onClick={() => onNavigate("NEXT")}
            />
          </div>
        </div>
        <div className="note-above-calendar-header d-sm-flex my-3 justify-content-around d-block mx-sm-0 mx-xs-5 mx-4">
          <div>
            {" "}
            <span>NOTE:</span>
            <img src={pink_img} alt="123" className="ms-4 me-2 mb-2" />
            <span>Final Reservation</span>
          </div>
          <div className="ms-sm-0 ms-5 ps-sm-0 ps-4 ">
            {" "}
            <img src={green_img} alt="123" className="me-2 mb-2" />
            <span>Tentative (one only)</span>
          </div>
          <div className="ms-sm-0 ms-5 ps-sm-0 ps-4 ">
            {" "}
            <img src={green_img} alt="123" className="me-1 mb-2" />
            <img src={green_img} alt="123" className="me-2 mb-2" />
            <span>Tentative (two only)</span>
          </div>
        </div>
      </>
    );
  };

  const currentMonthStart = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth(),
    1
  );

  const isFirstDayFridayOrSaturday =
    currentMonthStart.getDay() === 5 || currentMonthStart.getDay() === 6;

  const isSaturday = currentMonthStart.getDay() === 6;

  const lastDayOfCurrentMonth = new Date(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth() + 1,
    0
  );

  const has31Days = lastDayOfCurrentMonth.getDate() === 31;

  const has30Days = lastDayOfCurrentMonth.getDate() === 30;

  let rowSix = false; // Initialize rowSix to false

  if (isFirstDayFridayOrSaturday && has31Days) {
    rowSix = true;
  } else if (isSaturday && has30Days) {
    rowSix = true;
  }
  // console.log(rowSix, 'row');

  const eventStyleGetter = (event, start, end, isSelected) => {
    const differenceInMilliseconds = end.getTime() - start.getTime();

    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24); // 1000ms  60s  60min * 24h
    // console.log(differenceInDays,'ddddsssss');

    const isFirstDay = moment(event.start).isSame(start, "day");

    // Check if the start day of the event is Saturday
    const isFirstSaturday = moment(event.start).format("dddd") === "Saturday";
    const isFirstFriday = moment(event.start).format("dddd") === "Friday";
    const isFirstThursday = moment(event.start).format("dddd") === "Thursday";
    // console.log(isFirstSaturday,'isssss');

    //------------------------------------------------------- 6 row -----------------------------------------------------------

    const monthStart = new Date(start.getFullYear(), start.getMonth(), 1);

    const isFirstDayFridayOrSaturday =
      monthStart.getDay() === 5 || monthStart.getDay() === 6;
    const isSaturday = monthStart.getDay() === 6;

    const lastDayOfMonth = new Date(
      start.getFullYear(),
      start.getMonth() + 1,
      0
    );
    const has31Days = lastDayOfMonth.getDate() === 31;

    const has30Days = lastDayOfMonth.getDate() === 30;

    if (isFirstDayFridayOrSaturday && has31Days) {
      rowSix = true;
    } else if (isSaturday && has30Days) {
      rowSix = true;
    }

    //-----------------------------------------------------------------------------------------------------------------------------------

    let style = {
      position:
        hoveredEvent === event.id && isFirstSaturday ? "relative" : "relative",
      top:
        isFirstSaturday && hoveredEvent === event.id
          ? "-50px"
          : hoveredEvent === event.id
          ? "-50px"
          : "-50px",
      backgroundColor: event.type === "FinalReserve" ? "#eecde0" : "#ceede7",
      borderRadius: "0px",
      opacity: hoveredEvent === event.id ? 1 : 0.8,
      color: "white",

      display: "block",
      height: rowSix
        ? hoveredEvent === event.id
          ? "88px"
          : "88px"
        : hoveredEvent === event.id && isFirstSaturday
        ? "88px"
        : hoveredEvent === event.id
        ? "88px"
        : "88px",
      // border:
      //   hoveredEvent === event.id ? " 1px solid #000000" : "1px solid #000000",

      zIndex: hoveredEvent === event.id ? 8 : 5,
      width:
        isFirstSaturday && hoveredEvent === event.id
          ? "100%"
          : hoveredEvent === event.id && differenceInDays === 1
          ? "100%"
          : "100%",
      transition: "width 0.1s ease ",
      boxShadow: hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none",
      right: isFirstSaturday && hoveredEvent === event.id ? "0%" : "",
    };

    if (window.innerWidth <= 1440) {
      style.position =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "relative"
          : "relative";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "100%"
          : hoveredEvent === event.id && differenceInDays === 1
          ? event.type === "FinalReserve"
            ? "100%"
            : "100%"
          : "100%";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "-50px"
          : "-50px";

      // style.right =
      //   (isFirstFriday && hoveredEvent === event.id) ||
      //     (isFirstSaturday && hoveredEvent === event.id)
      //     ? "0%"
      //     : "";
    }
    if (window.innerWidth <= 768) {
      // style.width =
      //   hoveredEvent === event.id && differenceInDays === 1 ? "170%" : "auto";
      style.height = rowSix ? "95px" : "95px";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "100%"
          : hoveredEvent === event.id && differenceInDays <= 2
          ? event.type === "FinalReserve"
            ? "150%"
            : "150%"
          : "auto";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id)
          ? "-58px"
          : hoveredEvent === event.id
          ? "-58px"
          : "-58px";
    }
    if (window.innerWidth <= 576) {
      style.position =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "relative"
          : "relative";
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "150%"
          : hoveredEvent === event.id && differenceInDays <= 3
          ? event.type === "FinalReserve"
            ? "150%"
            : "150%"
          : hoveredEvent === event.id
          ? "150%"
          : "auto";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "84px"
          : "84px"
        : "84px";

      // style.right =
      //   (isFirstFriday && hoveredEvent === event.id) ||
      //     (isFirstSaturday && hoveredEvent === event.id) ||
      //     (isFirstThursday && hoveredEvent === event.id)
      //     ? "0%"
      //     : "";
      style.top =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "-58px"
          : "-58px";
      style.right = isFirstSaturday && hoveredEvent === event.id ? "50%" : "";
    }
    if (window.innerWidth <= 375) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "150%"
          : hoveredEvent === event.id && differenceInDays <= 3
          ? event.type === "FinalReserve"
            ? "200%"
            : "200%"
          : "100%";
      style.height = rowSix
        ? (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id) ||
          (isFirstThursday && hoveredEvent === event.id)
          ? "84px"
          : "84px"
        : "84px";

      style.right = isFirstSaturday && hoveredEvent === event.id ? "50%" : "";
    }
    if (window.innerWidth <= 320) {
      style.width =
        (isFirstFriday && hoveredEvent === event.id) ||
        (isFirstSaturday && hoveredEvent === event.id) ||
        (isFirstThursday && hoveredEvent === event.id)
          ? "70%"
          : hoveredEvent === event.id && differenceInDays <= 3
          ? event.type === "FinalReserve"
            ? "500%"
            : "420%"
          : "auto";
      style.height = rowSix ? "52px" : "52px";
    }

    const otherEventsOnSameDay = events.filter(
      (e) =>
        e.id !== event.id &&
        moment(e.start).isSame(event.start, "day") &&
        moment(e.end).isSame(event.end, "day")
    );

    //     if (otherEventsOnSameDay.length > 0) {
    //       style.position =
    //         event.isSecond === 0
    //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "absolute"
    //             : "relative"
    //           : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "absolute"
    //             : "relative";
    //       style.top = rowSix
    //         ? event.isSecond === 0
    //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "0px"
    //             : "-50px"
    //           : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "-2px"
    //             : "-139px"
    //         : event.isSecond === 0
    //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "0px"
    //             : "-50px"
    //           : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "-2px"
    //             : "-139px";
    //       style.backgroundColor =
    //         event.type === "FinalReserve" ? "#eecde0" : "#ceede7";
    //       style.borderRadius = "0px";
    //       style.opacity = hoveredEvent === event.id ? 1 : 0.8;
    //       style.color = "white";
    //       style.display = "block";
    //       style.height =
    //         event.isSecond === 0
    //           ? rowSix
    //             ? hoveredEvent === event.id
    //               ? "88px"
    //               : "88px"
    //             : hoveredEvent === event.id
    //               ? "88px"
    //               : "88px"
    //           : rowSix
    //             ? hoveredEvent === event.id
    //               ? "88px"
    //               : "88px"
    //             : hoveredEvent === event.id
    //               ? "89px"
    //               : "88px";

    //       style.zIndex = hoveredEvent === event.id ? 8 : 5;
    //       style.transition = "width 0.1s ease";
    //       style.boxShadow =
    //         hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none";

    //       style.left = event.isSecond === 0 ? "" : "55%";

    //       style.width =
    //         event.isSecond === 0
    //           ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //             ? "30%"
    //             : hoveredEvent === event.id
    //               ? differenceInDays < 3
    //                 ? "140%"
    //                 : "100%"
    //               : "50%"
    //           : hoveredEvent === event.id
    //             ? differenceInDays < 3
    //               ? "140%"
    //               : "100%"
    //             : "45%";

    //       if (window.innerWidth <= 1440) {
    //         style.width =
    //           event.isSecond === 0
    //             ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //               ? "30%"
    //               : hoveredEvent === event.id
    //                 ? differenceInDays < 3
    //                   ? "200%"
    //                   : "100%"
    //                 : "50%"
    //             : hoveredEvent === event.id
    //               ? differenceInDays < 3
    //                 ? "200%"
    //                 : "100%"
    //               : "45%";

    //         // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
    //       }

    //       if (window.innerWidth <= 768) {
    //         style.height = rowSix
    //           ? event.isSecond === 0
    //             ? "68px"
    //             : "70px"
    //           : event.isSecond === 0
    //             ? "65px"
    //             : "63px";
    //         style.top =
    //           (isFirstFriday && hoveredEvent === event.id) ||
    //             (isFirstSaturday && hoveredEvent === event.id)
    //             ? "0px"
    //             : rowSix
    //               ? event.isSecond === 0
    //                 ? "-57px"
    //                 : "-128px"
    //               : event.isSecond === 0
    //                 ? "-52px"
    //                 : "-116px";

    //         style.width =
    //           event.isSecond === 0
    //             ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //               ? "30%"
    //               : hoveredEvent === event.id
    //                 ? differenceInDays < 3
    //                   ? "220%"
    //                   : "100%"
    //                 : "50%"
    //             : hoveredEvent === event.id
    //               ? differenceInDays < 3
    //                 ? "220%"
    //                 : "100%"
    //               : "45%";
    //         // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
    //       }
    //       if (window.innerWidth <= 425) {
    //         style.height = rowSix
    //           ? event.isSecond === 0
    //             ? "59px"
    //             : "59px"
    //           : event.isSecond === 0
    //             ? "55px"
    //             : "55px";
    //         // style.height = event.isSecond === 0
    //         // ? rowSix
    //         //   ? "78px"
    //         //   : hoveredEvent === event.id
    //         //   ? "55px"
    //         //   : "55px"
    //         // :rowSix
    //         //   ?"78px"
    //         //   : hoveredEvent === event.id
    //         //     ? "55px"
    //         //     : "55px";

    //         style.top =
    //           (isFirstFriday && hoveredEvent === event.id) ||
    //             (isFirstSaturday && hoveredEvent === event.id)
    //             ? "0px"
    //             : rowSix
    //               ? event.isSecond === 0
    //                 ? "-58px"
    //                 : "-118px"
    //               : event.isSecond === 0
    //                 ? "-52px"
    //                 : "-108px";

    //         style.width =
    //           event.isSecond === 0
    //             ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
    //               ? "60%"
    //               : hoveredEvent === event.id
    //                 ? differenceInDays < 3
    //                   ? "350%"
    //                   : "100%"
    //                 : "50%"
    //             : hoveredEvent === event.id
    //               ? differenceInDays < 3
    //                 ? "350%"
    //                 : "100%"
    //               : "45%";
    //       }

    // //       useEffect(() => {
    // //   if (hoveredEvent === event.id) {
    // //     if (event.isSecond === 0) {
    // //       if (rbc_dates) {
    // //         console.log(rbc_dates, 'rbcccc');
    // //       }
    // //       rbc_dates.forEach((date, index) => {
    // //         if (index % 2 === 0) {
    // //           date.style.setProperty('width', '50%');
    // //           date.style.setProperty('top', '-50px');
    // //         } else {
    // //           date.style.setProperty('width', '45%');
    // //           date.style.setProperty('top', '-139px');
    // //         }
    // //       });
    // //     }
    // //     if (event.isSecond === 1) {
    // //       console.log(rbc_dates, 'rbc___1');
    // //       rbc_dates.forEach((date, index) => {
    // //         if (index % 2 === 0) {
    // //           date.style.setProperty('width', '50%');
    // //           date.style.setProperty('top', '-50px');
    // //         } else {
    // //           date.style.setProperty('width', '45%');
    // //           date.style.setProperty('top', '-139px');
    // //         }
    // //       });
    // //     }
    // //   }
    // // }, [rbc_dates]);

    //     }

    if (otherEventsOnSameDay.length > 0) {
      style.position =
        event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "relative"
            : "relative"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "relative"
          : "relative";
      style.top = rowSix
        ? event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "-50px"
            : "-50px"
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "-139px"
          : "-139px"
        : event.isSecond === 0
        ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? "-50px"
          : "-50px"
        : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
        ? "-139px"
        : "-139px";
      style.backgroundColor =
        event.type === "FinalReserve" ? "#eecde0" : "#ceede7";
      style.borderRadius = "0px";
      style.opacity = hoveredEvent === event.id ? 1 : 0.8;
      style.color = "white";
      style.display = "block";
      style.height =
        event.isSecond === 0
          ? rowSix
            ? hoveredEvent === event.id
              ? "88px"
              : "88px"
            : hoveredEvent === event.id
            ? "88px"
            : "88px"
          : rowSix
          ? hoveredEvent === event.id
            ? "88px"
            : "88px"
          : hoveredEvent === event.id
          ? "89px"
          : "88px";

      style.zIndex = hoveredEvent === event.id ? 8 : 5;
      style.transition = "width 0.1s ease";
      style.boxShadow =
        hoveredEvent === event.id ? "0px 0px 12px 0px black" : "none";

      style.left =
        event.isSecond === 0
          ? ""
          : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
          ? ""
          : "55%";

      style.width =
        event.isSecond === 0
          ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "100%"
            : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "140%"
              : "100%"
            : "50%"
          : hoveredEvent === event.id
          ? differenceInDays < 3
            ? "140%"
            : "100%"
          : "45%";

      if (window.innerWidth <= 1440) {
        // style.width =
        //   event.isSecond === 0
        //     ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
        //       ? "30%"
        //       : hoveredEvent === event.id
        //         ? differenceInDays < 3
        //           ? "200%"
        //           : "100%"
        //         : "140%"
        //     : hoveredEvent === event.id
        //       ? differenceInDays < 3
        //         ? "200%"
        //         : "100%"
        //       : "45%";
        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "100%"
              : hoveredEvent === event.id
              ? differenceInDays < 3
                ? "140%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "100%"
            : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "140%"
              : "100%"
            : "45%";
        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-50%"
              : hoveredEvent === event.id && isFirstFriday
              ? ""
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-0%"
            : hoveredEvent === event.id && isFirstFriday
            ? ""
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";

        // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
      }
      if (window.innerWidth <= 768) {
        style.height = rowSix
          ? event.isSecond === 0
            ? "95px"
            : "95px"
          : event.isSecond === 0
          ? "95px"
          : "95px";
        style.top =
          (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : rowSix
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : event.isSecond === 0
            ? "-58px"
            : "-154px";

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "100%"
              : hoveredEvent === event.id
              ? differenceInDays < 3
                ? "220%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "100%"
              : "100%"
            : "45%";
        // style.top = (isFirstFriday && hoveredEvent === event.id) || (isFirstSaturday && hoveredEvent === event.id) ? "-0px" : hoveredEvent === event.id ? "-51px" : "-51px";
      }
      if (window.innerWidth <= 576) {
        // style.height = event.isSecond === 0
        // ? rowSix
        //   ? "78px"
        //   : hoveredEvent === event.id
        //   ? "55px"
        //   : "55px"
        // :rowSix
        //   ?"78px"
        //   : hoveredEvent === event.id
        //     ? "55px"
        //     : "55px";

        style.top =
          (isFirstFriday && hoveredEvent === event.id) ||
          (isFirstSaturday && hoveredEvent === event.id)
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : rowSix
            ? event.isSecond === 0
              ? "-58px"
              : "-154px"
            : event.isSecond === 0
            ? "-58px"
            : "-154px";

        style.width =
          event.isSecond === 0
            ? hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
              ? "140%"
              : hoveredEvent === event.id
              ? differenceInDays < 3
                ? "350%"
                : "100%"
              : "50%"
            : hoveredEvent === event.id && (isFirstSaturday || isFirstFriday)
            ? "140%"
            : hoveredEvent === event.id
            ? differenceInDays < 3
              ? "350%"
              : "100%"
            : "45%";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-50%"
              : hoveredEvent === event.id && isFirstFriday
              ? "-50%"
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-0%"
            : hoveredEvent === event.id && isFirstFriday
            ? "-0%"
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";
      }

      if (window.innerWidth <= 375) {
        // style.height = event.isSecond === 0
        // ? rowSix
        //   ? "78px"
        //   : hoveredEvent === event.id
        //   ? "55px"
        //   : "55px"
        // :rowSix
        //   ?"78px"
        //   : hoveredEvent === event.id
        //     ? "55px"
        //     : "55px";

        style.left =
          event.isSecond === 0
            ? hoveredEvent === event.id && isFirstSaturday
              ? "-50%"
              : hoveredEvent === event.id && isFirstFriday
              ? "-50%"
              : ""
            : hoveredEvent === event.id && isFirstSaturday
            ? "-10%"
            : hoveredEvent === event.id && isFirstFriday
            ? "-10%"
            : hoveredEvent === event.id && isFirstThursday
            ? "0px"
            : "55%";
        style.right = "0%";
      }

      //       useEffect(() => {
      //   if (hoveredEvent === event.id) {
      //     if (event.isSecond === 0) {
      //       if (rbc_dates) {
      //         console.log(rbc_dates, 'rbcccc');
      //       }
      //       rbc_dates.forEach((date, index) => {
      //         if (index % 2 === 0) {
      //           date.style.setProperty('width', '50%');
      //           date.style.setProperty('top', '-50px');
      //         } else {
      //           date.style.setProperty('width', '45%');
      //           date.style.setProperty('top', '-139px');
      //         }
      //       });
      //     }
      //     if (event.isSecond === 1) {
      //       console.log(rbc_dates, 'rbc___1');
      //       rbc_dates.forEach((date, index) => {
      //         if (index % 2 === 0) {
      //           date.style.setProperty('width', '50%');
      //           date.style.setProperty('top', '-50px');
      //         } else {
      //           date.style.setProperty('width', '45%');
      //           date.style.setProperty('top', '-139px');
      //         }
      //       });
      //     }
      //   }
      // }, [rbc_dates]);
    }

    return {
      style: style,
    };
  };

  const handleEventSummary = (eventId) => {
    setSummaryEventId(eventId);
    setShowForm(false);
    setShowEditEvent(false);
    setEventSummary(true);
  };

  // const downloadPDFData = async (getData) => {
  //   // Create an off-screen container
  //   try {

  //     const offScreenContainer = document.createElement("div");
  //     offScreenContainer.style.position = "absolute";
  //     offScreenContainer.style.left = "-9999px";
  //     document.body.appendChild(offScreenContainer);
  //     offScreenContainer.style.width = "768px";

  //     createRoot(offScreenContainer).render(<KitchenGetEventsPDF getData={getData} />);

  //     // Wait for rendering to finish before generating PDF
  //     await new Promise((resolve) => setTimeout(resolve, 0));

  //     const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

  //     if (receipts.length === 0) {
  //       return;
  //     }

  //     const doc = new jsPDF("p", "mm", "a4");
  //     let yOffset = 0;

  //     for (let index = 0; index < receipts.length; index++) {
  //       const receipt = receipts[index];
  //       await new Promise((resolve) => {
  //         setTimeout(() => {
  //           html2canvas(receipt, { scale: 2, logging: false, useCORS: true }).then(
  //             (canvas) => {
  //               const imgData = canvas.toDataURL("image/png");
  //               const width = doc.internal.pageSize.getWidth();
  //               const height = doc.internal.pageSize.getHeight();

  //               if (index > 0) {
  //                 doc.addPage();
  //                 yOffset = 0;
  //               }

  //               doc.addImage(imgData, "PNG", 0, yOffset, width, height);
  //               yOffset += height;

  //               resolve();
  //             }
  //           );
  //         }, 100);
  //       });
  //     }

  //     doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
  //     document.body.removeChild(offScreenContainer);
  //   } catch (error) {
  //     console.error(error)
  //   }
  // };
  return (
    <>
      <Toaster />
      {showEventSummary &&(
          <EventSummary
            setEventSummary={setEventSummary}
            summaryEventId={summaryEventId}
            showEventSummary={showEventSummary}
            setShowForm={setShowForm}
          />
        )}
      <div>
      <style>
          {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "Name of Reserver";
          }
          td:nth-of-type(3):before {
            content: "Event Date";
          }
          td:nth-of-type(4):before {
            content: "Event Type";
          }
          td:nth-of-type(5):before {
            content: "Number of Guest";
          }
          td:nth-of-type(6):before {
            content: "Menu Type";
          }
          td:nth-of-type(7):before {
            content: "Notes";
          }
          td:nth-of-type(8):before {
            content: "Action";
          }
          }
        `}
        </style>
        <div className="container-fluid">
          <div className="form-margin">
            <div className="form-margin-768  padding-left-21 ">
              <div className="col-12 col-xl-8 ms-xl-5 ms-0 ">
                <div
                  style={{
                    height: `${rowSix ? "738px" : "650px"}`,
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                  className="kitchen-detail-table"
                >
                  <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    // selectable
                    // onSelectSlot={handleSelectSlot}
                    // onSelectEvent={handleEventClick}
                    style={{ margin: "" }}
                    eventPropGetter={eventStyleGetter}
                    defaultDate={defaultDate}
                    onNavigate={(date) => {
                      setDefaultDate(date);
                    }}
                    // components={{
                    //   event: ({ event: calendarEvent, start, end }) => (
                    //     <>
                    //       <div
                    //         onMouseOver={() => {
                    //           setHoveredEvent(calendarEvent.id);
                    //         }}
                    //         onMouseOut={() => setHoveredEvent(null)}
                    //         onClick={() => {}}
                    //         className=""
                    //         style={{
                    //           height: "51px",
                    //           width: "100%",
                    //           zIndex: 100,
                    //         }}
                    //       >
                    //         {calendarEvent && (
                    //           <div className="" key={calendarEvent.id}>
                    //             {hoveredEvent === calendarEvent.id && (
                    //               <div className="">
                    //                 {hoveredEvent === calendarEvent.id && (
                    //                   <>
                    //                     <div
                    //                       className={`d-flex justify-content-center  mt-1
                    //                           }`}
                    //                     >
                    //                       <span className="   date-reservation me-sm-3 me-2 ">
                    //                         {calendarEvent.start.getDate() < 10
                    //                           ? "0" +
                    //                             calendarEvent.start.getDate()
                    //                           : calendarEvent.start.getDate()}
                    //                       </span>
                    //                       {/* <div
                    //                           className={`${calendarEvent.type === "Tentative"
                    //                             ? "mt-2 "
                    //                             : ""
                    //                             } btn-group d-block`}
                    //                         >
                    //                           <div
                    //                             className={`${calendarEvent.type === "Tentative"
                    //                               ? "d-none"
                    //                               : "d-block"
                    //                               }`}
                    //                           >
                    //                             <button className="btn text-center btn-calender  ">
                    //                               Reserve |
                    //                             </button>
                    //                             <button className="btn text-center btn-calender ">
                    //                               Transfer
                    //                             </button>
                    //                           </div>
                    //                           <button className="btn text-center btn-calender pb-1 "
                    //                           // onClick={()=>handleEdit(calendarEvent.id)}

                    //                           >
                    //                             Edit |
                    //                           </button>
                    //                           <button
                    //                             onClick={() => {
                    //                               handleDeleteEvent(
                    //                                 calendarEvent.id
                    //                               );

                    //                             }}
                    //                             className="btn text-center btn-calender pb-1 ms-1"
                    //                           >
                    //                             Delete
                    //                           </button>
                    //                         </div> */}
                    //                     </div>
                    //                   </>
                    //                 )}
                    //               </div>
                    //             )}
                    //           </div>
                    //         )}
                    //       </div>
                    //     </>
                    //   ),
                    //   // month: {
                    //   //   dateCellWrapper: (props) => {
                    //   //     const { date, range } = props;

                    //   //     // Check if the current date is the first day of the month
                    //   //     const isFirstDayOfMonth = moment(date).isSame(range.start, 'month') && moment(date).date() === 1;

                    //   //     return isFirstDayOfMonth ? props.children : null;
                    //   //   },
                    //   // },

                    //   toolbar: CustomToolbar,
                    // }}
                    components={{
                      event: ({ event: calendarEvent, start, end }) => (
                        <>
                          <div
                            onMouseOver={() => {
                              setHoveredEvent(calendarEvent.id);
                            }}
                            onMouseOut={() => setHoveredEvent(null)}
                            onClick={() => {
                              if (calendarEvent.type === "Tentative") {
                              }
                              if (calendarEvent.type === "FinalReserve") {
                                handleEventSummary(calendarEvent.id);
                                setShowForm(false);
                                setShowEditEvent(false);
                                setEventSummary(true);
                              }
                            }}
                            
                            style={{
                              height: "51px",
                              width: "100%",
                              zIndex: 100,
                            }}
                          >
                            {calendarEvent && (
                              <div className="" key={calendarEvent.id}>
                                {hoveredEvent === calendarEvent.id && (
                                  <div className="">
                                    {hoveredEvent === calendarEvent.id && (
                                      <>
                                        <div
                                          className={`d-flex justify-content-center  mt-3
                                              }`}
                                        >
                                          <span className="   date-reservation me-sm-3 me-2 ">
                                            {calendarEvent.start.getDate() < 10
                                              ? "0" +
                                                calendarEvent.start.getDate()
                                              : calendarEvent.start.getDate()}
                                          </span>
                                          {/* <div
                                              className={`${calendarEvent.type === "Tentative"
                                                ? "mt-2 "
                                                : ""
                                                } btn-group d-block`}
                                            >
                                              <div
                                                className={`${calendarEvent.type === "Tentative"
                                                  ? "d-none"
                                                  : "d-block"
                                                  }`}
                                              >
                                                <button className="btn text-center btn-calender  ">
                                                  Reserve |
                                                </button>
                                                <button className="btn text-center btn-calender ">
                                                  Transfer
                                                </button>
                                              </div>
                                              <button className="btn text-center btn-calender pb-1 " 
                                              // onClick={()=>handleEdit(calendarEvent.id)}
                                              
                                              >
                                                Edit |
                                              </button>
                                              <button
                                                onClick={() => {
                                                  handleDeleteEvent(
                                                    calendarEvent.id
                                                  );
                                                
                                                }}
                                                className="btn text-center btn-calender pb-1 ms-1"
                                              >
                                                Delete
                                              </button>
                                            </div> */}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      ),
                      // month: {
                      //   dateCellWrapper: (props) => {
                      //     const { date, range } = props;

                      //     // Check if the current date is the first day of the month
                      //     const isFirstDayOfMonth = moment(date).isSame(range.start, 'month') && moment(date).date() === 1;

                      //     return isFirstDayOfMonth ? props.children : null;
                      //   },
                      // },

                      toolbar: CustomToolbar,
                    }}
                  />
                </div>
              </div>

              <div className="role-table mt-5 mb-5">
                {/* <h2 className="fw-bold h2-768 mb-0">Info</h2> */}
                <table
                  id="showBill"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Name of Reserver
                      </th>
                      <th className="thead-color" scope="col">
                        Event Date
                      </th>

                      <th className="thead-color" scope="col">
                        Event Type
                      </th>
                      <th className="thead-color" scope="col">
                        Number of Guest
                      </th>
                      <th className="thead-color" scope="col">
                        Menu Type
                      </th>
                      <th className="thead-color" scope="col">
                        Notes
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf_data: state?.Main_Kitchen_pdf_data_reducer,
  res_calendar_event: state?.get_kitchen_event_calendar_reducer,
});

export default connect(mapStateToProps)(KitchenDetailTable);
