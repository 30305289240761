import React, { useEffect, useState } from "react";
import layer from "../images/bg-image.png";
import layer1 from "../images/footer-logo.png";
import { IoNotificationsOutline } from "react-icons/io5";
import { FaListUl } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import {
  ReadNotification,
  getNotification,
  getToken,
} from "./Store/Action/useFetchData";
const Header = ({
  dispatch,
  res_notification_data,
  read_notification_data,
}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [socket, setSocket] = useState();
  const [addData, setAddData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [count, setCount] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const navigate = useNavigate();
  const role = sessionStorage.getItem("role");
  const toggleSidebar = () => {
    setSidebarOpen(true);
  };
  const location = useLocation();
  const handleNavigate = () => {
    if (role === "0") {
      navigate("/calender");
    }
    if (role === "1") {
      navigate("/kitchen_main");
    }
    if (role === "3") {
      navigate("/accounting_main");
    }
    if (role === "2") {
      navigate("/sales_main");
    }
  };
  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    setSocket(channel);
    dispatch(getToken());
    dispatch(getNotification());
  }, []);
  useEffect(() => {
    if (res_notification_data?.data?.status === true) {
      setNotificationData(res_notification_data?.data?.data);
    }
  }, [res_notification_data]);
  useEffect(() => {
    if (socket) {
      if (location?.pathname !== "/notification") {
        var isFirstResponseProcessed = false;

        var handleAddChallenge = async function (data) {
          if (!isFirstResponseProcessed) {
            const newObject = await data[0];
            if (newObject && !addData?.includes(newObject)) {
              setAddData((prevArray) => [newObject, ...prevArray]);
            }
            isFirstResponseProcessed = true;
          }
        };
        socket.bind("add_menu", handleAddChallenge);
        socket.bind("add_station", handleAddChallenge);
        socket.bind("sound_and_light", handleAddChallenge);
        socket.bind("added_supplier", handleAddChallenge);
        socket.bind("login", handleAddChallenge);
        socket.bind("password", handleAddChallenge);
        socket.bind("create_user", handleAddChallenge);
        socket.bind("add_event", handleAddChallenge);
        socket.bind("update_event", handleAddChallenge);
        socket.bind("add_subdetails", handleAddChallenge);
        socket.bind("update_subdetails", handleAddChallenge);
        socket.bind("add_bill", handleAddChallenge);
        socket.bind("update_bill", handleAddChallenge);
        socket.bind("event_reminder", handleAddChallenge);
      }
    }
    if (
      res_notification_data &&
      res_notification_data.data &&
      res_notification_data.data.data
    ) {
      const concatData1 = addData.concat(res_notification_data?.data?.data);
      setNotificationData(concatData1);
    }
  }, [addData, socket]);
  useEffect(() => {
    if (notificationData) {
      const countData = [];

      for (let i = 0; i < notificationData.length; i++) {
        const data = notificationData[i];
        if (data?.note === String(role) && data?.read === "0") {
          countData.push(data);
        }
      }
      setCount(countData?.length);
      setFilterData(countData);
    }
  }, [notificationData]);
  useEffect(() => {
    if (location?.pathname === "/notification") {
      dispatch(getNotification());
      for (let i = 0; i < filterData.length; i++) {
        setCount(0);
        const item = filterData[i];
        const updatedItem = {
          read: item.read === "0" ? "1" : item.read,
        };
        dispatch(ReadNotification(item?.id, updatedItem));
      }
      setAddData([]);
    }
  }, [location]);
  return (
    <>
      <header className="fixed-top">
        <div className="header-show">
          <div className="row d-flex mt-4 header px-4 px-lg-5">
            <div className="col-3">
              <img
                src={layer}
                className="header-img"
                alt="profile_image"
                style={{ cursor: "pointer" }}
                onClick={handleNavigate}
              ></img>
            </div>
            <div className="col-6 text-center">
              <h1 className="mb-0 header-chnage">
                {location?.pathname === "/add_user"
                  ? "Add User"
                  : location?.pathname === "/billing"
                  ? "Billing"
                  : location?.pathname === "/user"
                  ? "All User"
                  : location?.pathname === "/kitchen_main"
                  ? "Main Page"
                  : location?.pathname === "/accounting_main"
                  ? "Main Page"
                  : location?.pathname === "/sales_main"
                  ? "Main Page"
                  : location?.pathname === "/add_user_role"
                  ? "Add User Roles"
                  : location?.pathname === "/user_role"
                  ? "User Roles"
                  : location?.pathname === "/income_reports"
                  ? "Income Reports"
                  : location?.pathname === "/discount_reports"
                  ? "Discount Reports"
                  : location?.pathname === "/calender_filter"
                  ? "Calender Filter"
                  : location?.pathname === "/kcalender_filter"
                  ? "Calender Filter"
                  : location?.pathname === "/menu_type"
                  ? "Menu"
                  : location?.pathname === "/create_menu"
                  ? "Menu"
                  : location?.pathname === "/food_drink"
                  ? "Menu"
                  : location?.pathname === "/add_food_drink"
                  ? "Menu"
                  : location?.pathname === "/add_menu"
                  ? " Menu"
                  : location?.pathname === "/menu"
                  ? "Menu"
                  : location?.pathname === "/station"
                  ? "Menu"
                  : location?.pathname === "/sound_light"
                  ? "Sound & Light"
                  : location?.pathname === "/add_sound_light"
                  ? "Sound & Light"
                  : location?.pathname === "/profile"
                  ? "Profile"
                  : location?.pathname === "/Create_event_type"
                  ? "Check List Event"
                  : location?.pathname === "/supplires"
                  ? "Check List Event"
                  : location?.pathname === "/add_suppliers"
                  ? "Check List Event"
                  : location?.pathname === "/"
                  ? "Reservation Page"
                  : location?.pathname === "/calender"
                  ? "Reservation Page"
                  : location?.pathname === "/extras"
                  ? "Check List Event"
                  : location?.pathname === "/setting"
                  ? "Settings"
                  : location?.pathname === "/notification"
                  ? "Notification"
                  : location?.pathname === "/reporting"
                  ? "Reporting"
                  : location?.pathname === "/kitchen_reporting"
                  ? "Reporting"
                  : location?.pathname === "/sales_reporting"
                  ? "Reporting"
                  : location?.pathname === "/accounting_reporting"
                  ? "Reporting"
                  : location?.pathname === "/account_statement"
                  ? "Reporting"
                  : location?.pathname === "/add_extras"
                  ? "Check List Event"
                  : location?.pathname === "/invoice_history"
                  ? "Invoice History"
                  : location?.pathname === "/change_password"
                  ? "Change Password"
                  : location?.pathname.startsWith("/add_payment")
                  ? "Add Payment"
                  : location?.pathname === "/history_invoice"
                  ? "Reporting"
                  : location?.pathname === "/history_payment"
                  ? "Reporting"
                  : location?.pathname.startsWith("/edit_user_role")
                  ? "Edit User Role"
                  : location?.pathname===
                      "/account_sub_invoice_history"
                 
                  ? "Invoice History"
                  : location?.pathname.startsWith("/sub_history_payment")
                  ? "Reporting"
                  : location?.pathname.startsWith("/sub_invoice_history")
                  ? "Invoice History"
                  : location?.pathname.startsWith("/edit_extras")
                  ? "Edit Extras"
                  : location?.pathname.startsWith("/edit_all_menu")
                  ? " Menu"
                  : location?.pathname.startsWith("/edit_menu_type")
                  ? " Menu"
                  : location?.pathname.startsWith("/create_station")
                  ? " Menu"
                  : location?.pathname.startsWith("/edit_station")
                  ? " Menu"
                  : location?.pathname.startsWith("/edit_suppliers")
                  ? " Check List Event"
                  : location?.pathname.startsWith("/create_billing")
                  ? "Billing"
                  : location?.pathname.startsWith("/sale_edit_billing")
                  ? "Billing"
                  : location?.pathname.startsWith("/edit_billing")
                  ? "Billing"
                  : location?.pathname.startsWith("/transction")
                  ? "Transction"
                  : ""}
              </h1>
              <p className="text-black">
                {location?.pathname === "/add_menu"
                  ? "(Add Menu)"
                  : location?.pathname === "/menu_type"
                  ? "(Create Menu Type)"
                  : location?.pathname === "/create_menu"
                  ? "(Create Menu Type)"
                  : location?.pathname === "/station"
                  ? "(Create Station)"
                  : location?.pathname === "/food_drink"
                  ? "(Food Menu)"
                  : location?.pathname === "/add_food_drink"
                  ? "(Food Menu)"
                  : location?.pathname === "/Create_event_type"
                  ? "(Create Event Types)"
                  : location?.pathname === "/supplires"
                  ? "(Suppliers)"
                  : location?.pathname === "/extras"
                  ? "(Extras)"
                  : location?.pathname === "/add_suppliers"
                  ? "(Suppliers)"
                  : location?.pathname === "/add_extras"
                  ? "(Extras)"
                  : location?.pathname === "/history_invoice"
                  ? "(History of Invoice)"
                  : location?.pathname.startsWith("/edit_extras")
                  ? "(Extras)"
                  : location?.pathname===
                      "/account_sub_invoice_history"
                  
                  ? "(History of Invoice)"
                  : location?.pathname === "/history_payment"
                  ? "(History of Payment)"
                  : location?.pathname.startsWith("/sub_history_payment")
                  ? "(History of Payment)"
                  : location?.pathname === "/account_statement"
                  ? "(Account Statement)"
                  : location?.pathname === "/menu"
                  ? "(Add Menu)"
                  : location?.pathname.startsWith("/edit_all_menu")
                  ? "(Edit Menu)"
                  : location?.pathname.startsWith("/edit_menu_type")
                  ? "(Edit Event Types)"
                  : location?.pathname.startsWith("/create_station")
                  ? "(Create Station)"
                  : location?.pathname.startsWith("/edit_station")
                  ? "(Edit Station)"
                  : location?.pathname.startsWith("/edit_suppliers")
                  ? "(Edit Supplier)"
                  : ""}
              </p>
            </div>
            <div className="col-3 icon d-flex d-lg-block justify-content-end position-relative">
              <Link to="/notification">
                <span className="col-6 col-sm-3 col-md-3">
                  <IoNotificationsOutline className="fs-1 ps-2 icon cursor-pointer text-black" />
                  {count !== 0 && (
                    <div className="notification-count">{count}</div>
                  )}
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="header-show1">
          <div className="row d-flex mt-4 header px-4 px-lg-5 mb-2">
            <div className="col-3">
              <FaListUl
                className={`line_sort fs-2 ms-2`}
                onClick={toggleSidebar}
              />
            </div>
            <div className="col-6 text-center">
              <img
                src={layer1}
                className="header-img"
                alt="profile_image"
                style={{ cursor: "pointer" }}
                onClick={handleNavigate}
              ></img>
            </div>
            <div className="col-3 icon d-flex d-lg-block justify-content-end">
              <Link to="/notification">
                <span className="">
                  <IoNotificationsOutline className="fs-1 icon cursor-pointer text-black" />
                  {count !== 0 && (
                    <div className="notification-count1">{count}</div>
                  )}
                </span>
              </Link>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <h1 className="mb-0 header-chnage">
              {location?.pathname === "/add_user"
                ? "Add User"
                : location?.pathname === "/user"
                ? "All User"
                : location?.pathname === "/billing"
                ? "Billing"
                : location?.pathname === "/kitchen_main"
                ? "Main Page"
                : location?.pathname === "/income_reports"
                ? "Income Reports"
                : location?.pathname === "/discount_reports"
                ? "Discount Reports"
                : location?.pathname === "/sales_main"
                ? "Main Page"
                : location?.pathname === "/accounting_main"
                ? "Main Page"
                : location?.pathname === "/add_user_role"
                ? "Add User Roles"
                : location?.pathname === "/user_role"
                ? "User Roles"
                : location?.pathname === "/calender_filter"
                ? "Calender Filter"
                : location?.pathname === "/kcalender_filter"
                ? "Calender Filter"
                : location?.pathname === "/menu_type"
                ? "Menu"
                : location?.pathname === "/create_menu"
                ? "Menu"
                : location?.pathname === "/food_drink"
                ? "Menu"
                : location?.pathname === "/add_food_drink"
                ? "Menu"
                : location?.pathname === "/add_menu"
                ? " Menu"
                : location?.pathname === "/menu"
                ? "Menu"
                : location?.pathname === "/station"
                ? "Menu"
                : location?.pathname === "/sound_light"
                ? "Sound & Light"
                : location?.pathname === "/add_sound_light"
                ? "Sound & Light"
                : location?.pathname === "/profile"
                ? "Profile"
                : location?.pathname === "/Create_event_type"
                ? "Check List Event"
                : location?.pathname === "/supplires"
                ? "Check List Event"
                : location?.pathname === "/add_suppliers"
                ? "Check List Event"
                : location?.pathname === "/"
                ? "Reservation Page"
                : location?.pathname === "/calender"
                ? "Reservation Page"
                : location?.pathname === "/extras"
                ? "Check List Event"
                : location?.pathname === "/setting"
                ? "Settings"
                : location?.pathname === "/notification"
                ? "Notification"
                : location?.pathname === "/reporting"
                ? "Reporting"
                : location?.pathname === "/kitchen_reporting"
                ? "Reporting"
                : location?.pathname === "/sales_reporting"
                ? "Reporting"
                : location?.pathname === "/accounting_reporting"
                ? "Reporting"
                : location?.pathname === "/add_extras"
                ? "Check List Event"
                : location?.pathname === "/change_password"
                ? "Change Password"
                : location?.pathname === "/invoice_history"
                ? "Invoice History"
                : location?.pathname.startsWith("/add_payment")
                ? "Add Payment"
                : location?.pathname === "/history_invoice"
                ? "Reporting"
                : location?.pathname === "/account_statement"
                ? "Reporting"
                : location?.pathname === "/history_payment"
                ? "Reporting"
                : location?.pathname.startsWith("/sub_history_payment")
                ? "History of Payment"
                : location?.pathname.startsWith("/sub_invoice_history")
                ? "Invoice History"
                : location?.pathname==="/account_sub_invoice_history"
                ? "Invoice History"
                : location?.pathname.startsWith("/edit_user_role")
                ? "Edit User Role"
                : location?.pathname.startsWith("/create_billing")
                ? "Billing"
                : location?.pathname.startsWith("/edit_billing")
                ? "Billing"
                : location?.pathname.startsWith("/sale_edit_billing")
                ? "Billing"
                : location?.pathname.startsWith("/edit_extras")
                ? "Edit Extras"
                : location?.pathname.startsWith("/edit_all_menu")
                ? " Menu"
                : location?.pathname.startsWith("/edit_menu_type")
                ? " Menu"
                : location?.pathname.startsWith("/create_station")
                ? " Menu"
                : location?.pathname.startsWith("/edit_station")
                ? " Menu"
                : location?.pathname.startsWith("/edit_suppliers")
                ? " Check List Event"
                : location?.pathname.startsWith("/transction")
                ? " Transction"
                : ""}
            </h1>
            <p className="text-black">
              {location?.pathname === "/add_menu"
                ? "(Add Menu)"
                : location?.pathname === "/menu_type"
                ? "(Create Menu Type)"
                : location?.pathname === "/create_menu"
                ? "(Create Menu Type)"
                : location?.pathname === "/station"
                ? "(Create Station)"
                : location?.pathname === "/food_drink"
                ? "(Food Menu)"
                : location?.pathname === "/add_food_drink"
                ? "(Food Menu)"
                : location?.pathname === "/Create_event_type"
                ? "(Create Event Types)"
                : location?.pathname === "/supplires"
                ? "(Suppliers)"
                : location?.pathname === "/extras"
                ? "(Extras)"
                : location?.pathname === "/add_suppliers"
                ? "(Suppliers)"
                : location?.pathname === "/add_extras"
                ? "(Extras)"
                : location?.pathname.startsWith("/edit_extras")
                ? "(Extras)"
                : location?.pathname==="/account_sub_invoice_history"
                ? "(History of Invoice)"
                : location?.pathname === "/menu"
                ? "(Add Menu)"
                : location?.pathname === "/history_invoice"
                ? "(History of Invoice)"
                : location?.pathname === "/account_statement"
                ? "(Account Statement)"
                : location?.pathname.startsWith("/edit_all_menu")
                ? "(Edit Menu)"
                : location?.pathname.startsWith("/edit_menu_type")
                ? "(Edit Event Types)"
                : location?.pathname.startsWith("/create_station")
                ? "(Create Station)"
                : location?.pathname.startsWith("/edit_station")
                ? "(Edit Station)"
                : location?.pathname.startsWith("/edit_suppliers")
                ? "(Edit Supplier)"
                : location?.pathname === "/history_payment"
                ? "(History of Payment)"
                : location?.pathname.startsWith("/sub_history_payment")
                ? "(History of Payment)"
                : ""}
            </p>
          </div>
        </div>
        <div className="">
          <Sidebar
            setSidebarOpen={setSidebarOpen}
            isSidebarOpen={isSidebarOpen}
          />
        </div>
      </header>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_notification_data: state?.Get_Notification_data_reducer,
  read_notification_data: state?.Read_Notification_Data_reducer,
});

export default connect(mapStateToProps)(Header);
