import React, { useState, useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken, SingleAccountPayPdf } from "../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import Pusher from "pusher-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AccountSubInvoiceHistory = ({ dispatch, res_token, res_pdf }) => {
  const api_token = sessionStorage.getItem("api_token");
  const id = useParams();
  const location = useLocation();
  const [length, setLength] = useState(0);
  const [socket, setSocket] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    res_pdf.loading = true;
    res_pdf.data = {};
    dispatch(getToken());
    TableDatatablesManaged.init();
  }, []);

  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
   // console.log(channel, "channel");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
     // console.log(socket, "socket");
      socket.bind("tax_mode_on", async (data) => {
        window.location.reload();
      });
    }
  }, [socket]);
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showReporting");
      var table1 = $("#showReporting").DataTable({
        columnDefs: [{ orderable: false, targets: 5 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: `/api/acc_pay_his?event_id=0&bill_id=0`,
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          dataSrc: "user_data",
        },

        columns: [
          {
            data: "eventID",
            width: "10%",
          },
          {
            data: "date",
            width: "25%",
          },
          {
            data: null,
            width: "25%",
            render: function (data) {
              if (data.paid === null) {
                return `-`;
              } else {
                return data.paid;
              }
            },
          },
          {
            data: null,
            width: "25%",
            render: function (data) {
              if (data.grand_total === null) {
                return `-`;
              } else {
                return data.grand_total;
              }
            },
          },
          {
            data: null,
            width: "25%",
            render: function (data) {
              if (data.unpaid === null) {
                return `-`;
              } else {
                return data.unpaid;
              }
            },
          },
          {
            data: null,
            width: "10%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-eye fs-4 ps-2 me-0 fw-bold  text-success cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
         // console.log("Table length:", tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showReporting tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        //console.log(rowData, "rowData");
        if (rowData && rowData.instalment_id) {
          dispatch(SingleAccountPayPdf(rowData.instalment_id));
        }
      });
      $("#showReporting_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });

  //console.log(res_pdf, "res_pdf");
  useEffect(() => {
    if (res_pdf?.data?.status === true) {
      const pdfUrl = res_pdf?.data?.url;
      window.open(pdfUrl);
    } else if (res_pdf?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf.loading = true;
        res_pdf.data = {};
      }, 1500);
    }
  }, [res_pdf]);
  return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ? `padding-left:9px!important;` : ``}
  }
  .table td:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(3) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
  }

  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }
  .table td:nth-child(6) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }
        
td:nth-of-type(1):before {
  ${length !== 0 ? `content: "#";` : ``}
 
}

td:nth-of-type(2):before {
  content: "Date";
}
td:nth-of-type(3):before {
  content: "Payment";
}
td:nth-of-type(4):before {
  content: "Total Amount";
}
td:nth-of-type(5):before {
  content: "Rest Amount";
}
td:nth-of-type(6):before {
  content: "Action";
}
}
`}
      </style>
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 position-relative">
              <div className="role-table">
                <table
                  id="showReporting"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Date
                      </th>
                      <th className="thead-color" scope="col">
                        Payment
                      </th>
                      <th className="thead-color" scope="col">
                        Total Amount
                      </th>
                      <th className="thead-color" scope="col">
                        Rest Amount
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf: state?.Single_account_pay_PDF_data_reducer,
});

export default connect(mapStateToProps)(AccountSubInvoiceHistory);
