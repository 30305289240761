import React from "react";
import AddUser from "./Components/Pages/Forms/Add/AddUser";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Billing from "./Components/Pages/Forms/Add/Billing";

import AddUserRole from "./Components/Pages/Forms/Add/AddUserRole";
import Login from "./Components/AuthForms/Login";
import ForgetPassword from "./Components/AuthForms/ForgetPassword";
import CalenderFilter from "./Components/Pages/Forms/Add/CalenderFilter";
import CreateMenu from "./Components/Pages/Forms/Add/CreateMenu";
import CreateStation from "./Components/Pages/Forms/Add/CreateStation";
import FoodDrink from "./Components/Pages/Forms/Add/FoodDrink";
import SoundLight from "./Components/Pages/Forms/Add/SoundLight";
import AddMenu from "./Components/Pages/Forms/Add/AddMenu";
import CreateEventType from "./Components/Pages/Forms/Add/CreateEventType";
import Profile from "./Components/Pages/Forms/Add/Profile";
import Supplires from "./Components/Pages/Forms/Add/Supplires";
import Extras from "./Components/Pages/Forms/Add/Extras";
import Settings from "./Components/Pages/Forms/Add/Settings";
import Calander from "./Components/Pages/Forms/Add/Calander";
import Notification from "./Components/Pages/Tables/Notification";
import Reporting from "./Components/Pages/Tables/Reporting";
import UserRoles from "./Components/Pages/Tables/UserRoles";
import Header from "./Components/Header";
import Home from "./Components/Home";
import EditUserRole from "./Components/Pages/Forms/Edit/EditUserRole";
import ExtraTable from "./Components/Pages/Tables/ExtraTable";
import MenuTable from "./Components/Pages/Tables/MenuTable";
import SuppliersTable from "./Components/Pages/Tables/SuppliersTable";
import EditExtra from "./Components/Pages/Forms/Edit/EditExtra";
import EditSuppliers from "./Components/Pages/Forms/Edit/EditSuppliers";
import EditAllMenu from "./Components/Pages/Forms/Edit/EditAllMenu";
import MenuTypeTable from "./Components/Pages/Tables/MenuTypeTable";
import StationTable from "./Components/Pages/Tables/StationTable";
import EditManuType from "./Components/Pages/Forms/Edit/EditManuType";
import EditStation from "./Components/Pages/Forms/Edit/EditStation";
import FoodAndDrinkTable from "./Components/Pages/Tables/FoodAndDrinkTable";
import SoundAndLightTable from "./Components/Pages/Tables/SoundAndLightTable";
import ChangePassword from "./Components/AuthForms/ChangePassword";
import BillingTable from "./Components/Pages/Tables/BillingTable";
import KitchenDetailTable from "./Components/Pages/Tables/KitchenDetailTable";
import KitchenCalenderFilter from "./Components/Pages/Tables/KitchenCalenderFilter";
import KitchenReportingTable from "./Components/Pages/Tables/KitchenReportingTable";
import SalesReportingTable from "./Components/Pages/Tables/SalesReportingTable";
import AccountantReportingTable from "./Components/Pages/Tables/AccountantReportingTable";
import AccountantDetailTable from "./Components/Pages/Tables/AccountantDetailTable";
import AccountantSetting from "./Components/Pages/Forms/Add/AccountantSetting";
import EditBilling from "./Components/Pages/Forms/Edit/EditBilling";
import SalesCalendar from "./Components/Pages/Forms/Add/SalesCalendar";
import UserTable from "./Components/Pages/Tables/UserTable";
import IncomeReports from "./Components/Pages/Forms/Add/IncomeReports";
import DiscountReport from "./Components/Pages/Forms/Add/DiscountReport";
import Transction from "./Components/Pages/Tables/Transction";
import CatchReceiptPDF from "./Components/PDF/CatchReceiptPDF";
import MainInvoiceHistory from "./Components/Pages/Tables/MainInvoiceHistory";
import SubInvoiceHistory from "./Components/Pages/Tables/SubInvoiceHistory";
import AddPayment from "./Components/Pages/Forms/Add/Reservation/AddPayment";
import AccountSubInvoiceHistory from "./Components/Pages/Tables/AccountSubInvoiceHistory";
import AccountStatement from "./Components/Pages/Tables/AccountStatement";
import HistoryForPayment from "./Components/Pages/Tables/HistoryForPayment";
import SubHistoryPayment from "./Components/Pages/Tables/SubHistoryPayment";
import EditSalesBilling from "./Components/Pages/Forms/Edit/EditSalesBilling";

export const MainRoute = () => {
  const id = sessionStorage.getItem("member_id");
  const role = sessionStorage.getItem("role");
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          exact
          path="/"
          element={
            !id ? (
              <Home />
            ) : (
              <Navigate
                to={
                  role === "0"
                    ? "/calender"
                    : role === "1"
                    ? "/kitchen_main"
                    : role === "2"
                    ? "/sales_main"
                    : role === "3"
                    ? "accounting_main"
                    : "/login"
                }
              />
            )
          }
        />
      </Routes>
      <div
        className="scrollbar-thin scroll-table scroll-main"
        style={{ height: "100vh" }}
      >
        {/* {!id ? ( */}
        <>
          <Routes>
            <Route
              exact
              path="/login"
              element={
                !id ? (
                  <Login />
                ) : (
                  <Navigate
                    to={
                      role === "0"
                        ? "/calender"
                        : role === "1"
                        ? "/kitchen_main"
                        : role === "2"
                        ? "/sales_main"
                        : role === "3"
                        ? "/accounting_main"
                        : "/login"
                    }
                  />
                )
              }
            />
            <Route
              exact
              path="/forget_password"
              element={
                !id ? (
                  <ForgetPassword />
                ) : (
                  <Navigate
                    to={
                      role === "0"
                        ? "/calender"
                        : role === "1"
                        ? "/kitchen_main"
                        : role === "2"
                        ? "/sales_main"
                        : role === "3"
                        ? "/accounting_main"
                        : "/calender"
                    }
                  />
                )
              }
            />
          </Routes>
        </>
        {/* ) : ( */}
        <>
          {id ? <Header /> : ""}
          <Routes>
            <Route
              exact
              path="/kitchen_main"
              element={id ? <KitchenDetailTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/sales_main"
              element={id ? <SalesCalendar /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/accounting_setting"
              element={id ? <AccountantSetting /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/accounting_main"
              element={
                id ? <AccountantDetailTable /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/income_reports"
              element={id ? <IncomeReports /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/discount_reports"
              element={id ? <DiscountReport /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/calender"
              element={id ? <Calander /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/user"
              element={id ? <UserTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/kitchen_reporting"
              element={
                id ? <KitchenReportingTable /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/sales_reporting"
              element={id ? <SalesReportingTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/accounting_reporting"
              element={
                id ? <AccountantReportingTable /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/add_user"
              element={id ? <AddUser /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/billing"
              element={id ? <BillingTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/create_billing/:id"
              element={id ? <Billing /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_billing/:id"
              element={id ? <EditSalesBilling /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/sale_edit_billing/:id"
              element={id ? <EditSalesBilling /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_user_role"
              element={id ? <AddUserRole /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/user_role"
              element={id ? <UserRoles /> : <Navigate to="/login" />}
            />

            <Route
              exact
              path="/calender_filter"
              element={id ? <CalenderFilter /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/kcalender_filter"
              element={
                id ? <KitchenCalenderFilter /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/menu_type"
              element={id ? <MenuTypeTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/create_menu"
              element={id ? <CreateMenu /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/station"
              element={id ? <StationTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/create_station"
              element={id ? <CreateStation /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_menu_type/:id"
              element={id ? <EditManuType /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_station/:id"
              element={id ? <EditStation /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/food_drink"
              element={id ? <FoodAndDrinkTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_food_drink"
              element={id ? <FoodDrink /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/sound_light"
              element={id ? <SoundAndLightTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_sound_light"
              element={id ? <SoundLight /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_menu"
              element={id ? <AddMenu /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_all_menu/:id"
              element={id ? <EditAllMenu /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/Create_event_type"
              element={id ? <CreateEventType /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/profile"
              element={id ? <Profile /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/supplires"
              element={id ? <SuppliersTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_suppliers"
              element={id ? <Supplires /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_suppliers/:id"
              element={id ? <EditSuppliers /> : <Navigate to="/login" />}
            />

            <Route
              exact
              path="/menu"
              element={id ? <MenuTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_extras"
              element={id ? <Extras /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/extras"
              element={id ? <ExtraTable /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_extras/:id"
              element={id ? <EditExtra /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/setting"
              element={id ? <Settings /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/notification"
              element={id ? <Notification /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/reporting"
              element={id ? <Reporting /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/edit_user_role/:id"
              element={id ? <EditUserRole /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/change_password"
              element={id ? <ChangePassword /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/transction"
              element={id ? <Transction /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/invoice_history"
              element={id ? <MainInvoiceHistory /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/sub_invoice_history/:id"
              element={id ? <SubInvoiceHistory /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/add_payment/:id"
              element={id ? <AddPayment /> : <Navigate to="/login" />}
            />
              <Route
              exact
              path="/history_invoice"
              element={id ? <MainInvoiceHistory /> : <Navigate to="/login" />}
            />
             <Route
              exact
              path="/account_sub_invoice_history"
              element={id ? <AccountSubInvoiceHistory /> : <Navigate to="/login" />}
            />
              <Route
              exact
              path="/account_statement"
              element={id ? <AccountStatement /> : <Navigate to="/login" />}
            />
              <Route
              exact
              path="/history_payment"
              element={id ? <HistoryForPayment /> : <Navigate to="/login" />}
            />
              <Route
              exact
              path="/sub_history_payment/:id"
              element={id ? <SubHistoryPayment /> : <Navigate to="/login" />}
            />
            {/* <Route
              exact
              path="/pdf"
              element={<CatchReceiptPDF />}
            /> */}
          </Routes>
        </>
        {/* )} */}
      </div>
    </BrowserRouter>
  );
};
