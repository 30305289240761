import React, { useEffect, useState } from "react";
import image from "../../images/main_logo.png";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  SendEmail,
  SendNewPass,
  SendOtp,
  getToken,
} from "../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
const ForgetPassword = ({
  dispatch,
  res_email,
  res_otp,
  res_token,
  res_new_pass,
}) => {
  const [forgetPasswordState, setForgetPasswordState] = useState("forgot");

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [newPassword, setNewPassword] = useState();
  const [ConfirmPassword, setConfirmPassword] = useState();
  const [seconds, setSeconds] = useState(59);
  const [resend, setResend] = useState(false);

  const navigate = useNavigate();
  const [emailAdd, setEmailAdd] = useState({ email: "" });

  const handleChange = (e) => {
    setEmailAdd({ email: e.target.value });
  };
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    dispatch(getToken());
    setSeconds(59);
  }, []);

  const handleSendEmail = () => {
    if (!emailAdd.email) {
      const notify = () =>
        toast.error("Email address is require!! ", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setForgetPasswordState("forgot");
      return;
    }
    dispatch(SendEmail(emailAdd, setLoading));
  };

  useEffect(() => {
    if (res_email?.data?.status === true) {
      const notify = () =>
        toast.success(res_email?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();

      setForgetPasswordState("otp");
      setTimeout(() => {
        res_email.loading = true;
        res_email.data = {};
      }, 1500);
    } else if (res_email?.data?.status === false) {
      const notify = () =>
        toast.error(res_email?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  }, [res_email]);

  useEffect(() => {
    if (res_otp?.data?.status === true) {
      const notify = () =>
        toast.success(res_otp?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setForgetPasswordState("new");
      setTimeout(() => {
        res_otp.loading = true;
        res_otp.data = {};
      }, 1500);
    } else if (res_otp?.data?.status === false) {
      const notify = () =>
        toast.error(res_otp?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
    }
  }, [res_otp]);

  const handleOtp = (OtpInput) => {
    setEmailAdd({ ...emailAdd, otp: OtpInput });
    setOtp(OtpInput);
  };

  const handleOtpSend = () => {
    if (!otp) {
      const notify = () =>
        toast.error("OTP is require!! ", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setForgetPasswordState("otp");
      return;
    }

    dispatch(SendOtp(emailAdd, setLoading));
  };

  const handleNewPass = () => {
    setLoading(true)
    if (newPassword !== ConfirmPassword) {
      setLoading(false)
      const notify = () =>
        toast.error("Password doesn't match!!", {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setForgetPasswordState("new");

      return;
    }
    const submitData = {
      email: emailAdd.email,
      password: newPassword,
      password_confirmation: ConfirmPassword,
    };
    dispatch(SendNewPass(submitData, setLoading));
  };

  useEffect(() => {
    if (res_new_pass?.data?.status === true) {
      const notify = () =>
        toast.success(res_new_pass?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();

      setTimeout(() => {
        res_new_pass.loading = true;
        res_new_pass.data = {};
        setForgetPasswordState("forgot");
        navigate("/login");
      }, 1500);
    } else if (res_new_pass?.data?.status === false) {
      const notify = () =>
        toast.error(res_new_pass?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_new_pass.loading = true;
        res_new_pass.data = {};
        // setForgetPasswordState("forgot")
        // navigate("/login");
      }, 1500);
    }
  }, [res_new_pass]);

  useEffect(() => {
    if (forgetPasswordState === "otp" && seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (seconds === 0) {
      // setSeconds(59);
      setResend(true);
    }
  }, [seconds, forgetPasswordState]);

  const handleResend = () => {
    dispatch(SendEmail(emailAdd, setLoading));
    setSeconds(59);
    setResend(false);
  };
  return (
    <>
      <Toaster />
      <div className="g-0 row " style={{ minHeight: "100vh" }}>
        <div className="col-lg-6  home-image text-center">
          <div className="container  h-100">
            {/* {/ <div className=""> /} */}

            <Link to="/">
              <img
                src={image}
                alt="login form"
                className="rounded-start card-image card-login"
              />
            </Link>

            <span className="h1 d-block d-lg-none  mb-0 text-white">
              Forget Password
            </span>
            {/* {/ </div> /} */}
          </div>
        </div>
        <div className="col-lg-6 bg-white">
          <div className="container login">
            <div className="d-flex flex-column card-body forget-body px-0 px-md-5 px-lg-0 ">
              <div className="d-flex flex-row my-5 justify-content-center  d-none d-lg-block ">
                <span className="h1  mb-0 lh-1">Forget Password</span>
              </div>
              {/* <h5
                  className="fw-normal my-4 pb-3 text-start"
                  style={{ letterSpacing: "1px" }}
                >
                  Sign into your account
                </h5> */}
              {forgetPasswordState === "forgot" ? (
                <>
                  <div>
                    <p className=" my-5 p-color" style={{ fontWeight: "300" }}>
                      We will send a one time OTP on your email address{" "}
                    </p>
                  </div>
                  <div className="">
                    <input
                      placeholder="EMAIL ADDRESS"
                      type="email"
                      className="login-input ps-4 form-control mx-auto "
                      required
                      onChange={handleChange}
                      value={emailAdd.email}
                    />
                  </div>
                  <div className="mt-5 mb-3">
                    <button
                      className="mb-4 text-light login-btn"
                      onClick={handleSendEmail}
                    >
                      SEND
                    </button>
                  </div>
                </>
              ) : forgetPasswordState === "otp" ? (
                <>
                  <div>
                    <p className="my-5 p-color" style={{ fontWeight: "300" }}>
                      An OTP was sent to your email address
                    </p>
                  </div>
                  <div className=" ps-4 form-control otp-field login-input mx-auto">
                    {/* {/ <input className="inpt ps-4 form-control  " required> /} */}
                    <OtpInput
                      value={otp}
                      onChange={handleOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                    {/* {/ </input> /} */}
                  </div>
                  <div className="mt-5 mb-3">
                    <button
                      className="mb-4 text-light login-btn"
                      onClick={handleOtpSend}
                    >
                      VERIFY
                    </button>
                  </div>
                  <div>
                   
                    <p className="my-2 p-color" style={{ fontWeight: "300" }}>
                      Did not receive the verification{" "}
                      <span className="fw-bold">OTP</span> ?
                      {resend ? (
                        <button onClick={handleResend} className="btn fw-1 ">
                          Resend OTP
                        </button>
                      ) : (
                        <span className="text-primary ms-2">
                          Resend in{" "}
                          {`00:${seconds < 10 ? `0${seconds}` : seconds}`}
                        </span>
                      )}
                    </p>
                  </div>
                </>
              ) : forgetPasswordState === "new" ? (
                <>
                  <div>
                    <p className="my-5 p-color" style={{ fontWeight: "300" }}>
                      An OTP was sent to your email address
                    </p>
                  </div>
                  <div className="mb-3">
                    <input
                      placeholder="NEW PASSWORD"
                      type="password"
                      className="login-input ps-4 form-control mx-auto "
                      required
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <input
                      placeholder="CONFIRM NEW PASSWORD"
                      type="password"
                      className="login-input ps-4 form-control mx-auto "
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                  <div className="mt-5 mb-3">
                    {loading ? (
                      <button className="mb-4 text-light login-btn">
                         <span className="spinner-border disable-spinner me-2"></span>
                        SAVE
                      </button>
                    ) : (
                      <button
                        className="mb-4 text-light login-btn"
                        onClick={handleNewPass}
                      >
                        SAVE
                      </button>
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state.CrcToken_reducer,
  res_email: state?.Send_email_reducer,
  res_otp: state?.Send_otp_reducer,
  res_new_pass: state?.Send_password_reducer,
});

export default connect(mapStateToProps)(ForgetPassword);