import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GetUserRole } from "../../../Store/Action/useFetchData";
import toast, { Toaster } from "react-hot-toast";
import MiniLoader from "../../../MiniLoader";

const UseRoleView = ({
  viewRoleModal,
  setViewRoleModel,
  viewRoleModalId,
  dispatch,
  res_role_data,
}) => {
  const [permission, setPermisstion] = useState([
    { id: "0", role: "Billing" },
    { id: "1", role: "Menu" },
    { id: "2", role: "Sound and Light" },
    { id: "3", role: "Check List Event" },
    { id: "4", role: "Send Notification" },
    { id: "5", role: "Show Tax Mode" },
  ]);
  const [roleData, setRoleData] = useState({
    role_name: "",
    status: "",
  });
  useEffect(() => {
    dispatch(GetUserRole(viewRoleModalId));
  }, [dispatch,viewRoleModalId]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  useEffect(() => {
    if (res_role_data?.data?.status === true) {
      setRoleData({
        ...roleData,
        role_name: res_role_data?.data?.data.role_name,
        status: res_role_data?.data?.data?.status,
      });
      const permissionNumbers = res_role_data?.data?.data?.permission;
      const updatedPermissions = permission.map((item) => ({
        ...item,
        isChecked: permissionNumbers.includes(item.id),
      }));
      setPermisstion(updatedPermissions);
    } else if (res_role_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_role_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_role_data.loading = true;
        res_role_data.data = {};
      }, 1500);
    }
  }, [res_role_data,permission,roleData]);

  const handleClose = () => {
    setViewRoleModel(false);
    res_role_data.loading = true;
    res_role_data.data = {};
  };

  return (
    <>
    <Toaster/>
      <div
        className={`modal${viewRoleModal ? " fade show d-block " : " d-none"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg modal-xl-role">
          <div className="modal-content">
            <div className="modal-header modal-header-role">
              <h4 className="modal-title text-center fw-bold">
                View User Roles
              </h4>
              <button
                type="button"
                className="btn-close ms-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            {res_role_data?.loading ? (
              <MiniLoader />
            ) : (
              <div className="modal-body modal-padd">
                <div className="row mb-4  ">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url " className="form-label label">
                      NAME
                    </label>
                  </div>
                  <div className="col-lg-8 col-12 ">
                    <input
                      value={roleData.role_name}
                      type="text"
                      className=" inpt inpt-768 form-control"
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mb-4 ">
                  <div className="col-lg-4 col-12">
                    <label for="basic-url" className="form-label  label">
                      PERMISSION
                    </label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <div className="card width bg-transparant model-card">
                      <div className="card-header header_color p-1">
                        <div className="form-check my-2 p-0 ">
                          <input
                            className="form-check-input bg-transparant"
                            type="checkbox"
                            name="checkall"
                            checked={
                              permission.filter(
                                (user) => user.isChecked !== true
                              ).length < 1
                            }
                            readOnly
                            id="headerCheckbox"
                          />
                          <label
                            className="form-check-label form_check_size "
                            for="headerCheckbox"
                          >
                            All
                          </label>
                        </div>
                      </div>
                      <ul className="list-group list-group-flush bg-transparant mb-4">
                        {permission.map((e) => {
                          return (
                            <li className="list-group-item border-0 p-1 bg-transparant">
                              <div className="form-check p-0">
                                <input
                                  className="form-check-input "
                                  type="checkbox"
                                  name={e.id}
                                  checked={e?.isChecked || false}
                                  id="checkbox2"
                                  readOnly
                                />
                                <label
                                  className="form-check-label form_check_size"
                                  for="checkbox2"
                                >
                                  {e.role}
                                </label>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row mb-3 ">
                  <div className="col-lg-4 col-12 ">
                    <label for="basic-url" className="form-label label mt-3">
                      STATUS
                    </label>
                  </div>
                  <div className="col-lg-8 col-12 ">
                    <div className="my-2">
                      <div className="form-check form-check-inline ps-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          id="inlineRadio1"
                          checked={roleData?.status === "0"}
                          value="0"
                          readOnly
                        />
                        <label
                          className="form-check-label cash_lable"
                          for="inlineRadio1"
                        >
                          Active
                        </label>
                      </div>
                      <div className="form-check form-check-inline ms-sm-4 ms-0 ms-lg-auto ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="status"
                          id="inlineRadio1"
                          checked={roleData?.status === "1"}
                          value="1"
                          readOnly
                        />
                        <label
                          className="form-check-label cash_lable "
                          for="inlineRadio1"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-b me-2  mb-4 btn-768 "
                onClick={handleClose}
              >
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_role_data: state?.Get_user_role_reducer,
});

export default connect(mapStateToProps)(UseRoleView);
