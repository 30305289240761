import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { AccountReportsPdfDownload, MainKitchenPdfDownload, getToken } from "../../Store/Action/useFetchData";
import { FaCalendarDays } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import { createRoot } from "react-dom/client";
import jsPDF from "jspdf";
import KitchenGetEventsPDF from "../../PDF/KitchenGetEventsPDF";
import Pusher from "pusher-js";
const KitchenCalenderFilter = ({ dispatch, res_token,res_pdf_data }) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [finalDate, setFinalDate] = useState();
  const [state, setState] = useState(false);
  const [selection, setSelection] = useState("EVENT TYPE");
  const sidebarRef = useRef(null);
  const [length, setLength] = useState(0);
  const [socket, setSocket] = useState();
  const [filterData, setFilterData] = useState({
    startDate: "",
    eventType: "",
  });
  const options= [
    { value: "Wedding", label: "Wedding" },
    { value: "Corporate", label: "Corporate" },
    { value: "Concert", label: "Concert" },
    { value: "Private", label: "Private" },
    { value: "Charity", label: "Charity" },
  ]
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setState(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setState]);
  useEffect(() => {
    TableDatatablesManaged.init();
    //   dispatch(getToken());
  }, [filterData]);

  useEffect(() => {
    // Initialize Pusher here once when the component mounts
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");
    // console.log(channel,"channel");
    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
      // console.log(socket,"socket");
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  useEffect(()=>{
     toast.dismiss();
  },[])
  const handleClose = (e) => {
    setState(false);
    if (e.label !== selection && e.label !== "") {
      setSelection(e?.label);
      setFilterData({ ...filterData, eventType: e?.label });
    }
  };
  // console.log(filterData, "filterData");
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $('<div class="row justify-content-center "><span class="loader"></span></div>').appendTo("#showBill");
      var table1 = $("#showBill").DataTable({
        columnDefs: [{ orderable: false, targets: 7 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_event_user_data",
          headers: {
            Authorization: "Bearer " + api_token,
          },
          type: "GET",
          data: filterData,
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "eventID",
            width: "6%",
          },
          {
            data: "name",
            width: "15%",
          },
          {
            data: "startDate",
            width: "15%",
          },
          {
            data: "eventType",
            width: "15%",
          },
          {
            data: "m_name",
            width: "15%",
          },
          // {
          //   data: "s_name",
          //   width: "15%",
          // },
          {
            data: null,
            width: "15%",
            render: function (data) {
              if (data) {
                if (data?.softDrinksID !== null) {
                  return `${data?.softDrinksID}`;
                } else return "-";
              }
            },
          },
          {
            data: null,
            width: "15%",
            render: function (data) {
              if (data) {
                if (data?.hardDrinksID !== null) {
                  return `${data?.hardDrinksID}`;
                } else return "-";
              }
            },
          },
          // {
          //   data: null,
          //   width: "13%",
          //   render: function (data) {
          //     if (data) {
          //       if (data?.food_menu_name !== null) {
          //         return `${data?.food_menu_name}`;
          //       } else return "-";
          //     }
          //   },
          // },

          {
            data: null,
            width: "20%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.eventID +
                '"><i class="bi bi-download fs-4 ps-2 me-0 fw-bold cursor-pointer"></i><i class="fa-sharp fa-thin fa-file-invoice "></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          // console.log("Table length:", tableLength);
          setLength(tableLength);
        },
      });
      table1.on('xhr.dt', function () {
        customLoader.css("display", "none");
      });
      $("#showBill tbody").on("click", "#createBill", function () {
        const row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.eventID) {
          dispatch(MainKitchenPdfDownload(rowData.eventID));
        }
      });
      $("#showBill_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  const handleDateChange = (e) => {
    const dateString = e;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setSelectedDate(date);
    setFilterData({ ...filterData, startDate: `${year}-${month}-${day}` });
    setFinalDate(`${day}-${month}-${year}`);
  };

  useEffect(() => {
    if (res_pdf_data?.data?.status === true) {
      const pdfUrl = res_pdf_data?.data?.url;
      window.open(pdfUrl);

      // const getData = res_pdf_data?.data?.data;
      // downloadPDFData(getData);

      // const fileName = "report.pdf";

      // axios
      //   .get(res_pdf_data?.data?.url, {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     fileDownload(res.data, fileName);
      //   })
      //   .catch((error) => {
      //     console.error("Error downloading the file:", error);
      //   });

      // const link = document.createElement("a");
      // link.href = pdfUrl;
      // link.target = "_blank";
      // link.download = "document.pdf";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    } else if (res_pdf_data?.data?.status === false) {
      const notify = () =>
        toast.error(res_pdf_data?.data?.message, {
          style: {
            padding: "10px",
            borderRadius: "30px",
          },
        });
      notify();
      setTimeout(() => {
        res_pdf_data.loading = true;
        res_pdf_data.data = {};
      }, 1500);
    }
  }, [res_pdf_data]);

  // const downloadPDFData = async (getData) => {
  //   // Create an off-screen container
  //   try {

  //     const offScreenContainer = document.createElement("div");
  //     offScreenContainer.style.position = "absolute";
  //     offScreenContainer.style.left = "-9999px";
  //     document.body.appendChild(offScreenContainer);
  //     offScreenContainer.style.width = "768px";

  //     createRoot(offScreenContainer).render(<KitchenGetEventsPDF getData={getData} />);

  //     // Wait for rendering to finish before generating PDF
  //     await new Promise((resolve) => setTimeout(resolve, 0));

  //     const receipts = offScreenContainer.querySelectorAll(".actual-receipt");

  //     if (receipts.length === 0) {
  //       return;
  //     }

  //     const doc = new jsPDF("p", "mm", "a4");
  //     let yOffset = 0;

  //     for (let index = 0; index < receipts.length; index++) {
  //       const receipt = receipts[index];
  //       await new Promise((resolve) => {
  //         setTimeout(() => {
  //           html2canvas(receipt, { scale: 2, logging: false, useCORS: true }).then(
  //             (canvas) => {
  //               const imgData = canvas.toDataURL("image/png");
  //               const width = doc.internal.pageSize.getWidth();
  //               const height = doc.internal.pageSize.getHeight();

  //               if (index > 0) {
  //                 doc.addPage();
  //                 yOffset = 0;
  //               }

  //               doc.addImage(imgData, "PNG", 0, yOffset, width, height);
  //               yOffset += height;

  //               resolve();
  //             }
  //           );
  //         }, 100);
  //       });
  //     }

  //     doc.save("UAN_ONLINE_REGISTRATION_FORM.pdf");
  //     document.body.removeChild(offScreenContainer);
  //   } catch (error) {
  //     console.error(error)
  //   }
  // };
  return (
    <>
    <Toaster/>
      <div>
      <style>
          {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
           
          }
          
          td:nth-of-type(2):before {
            content: "User Name";
           
          }
          td:nth-of-type(3):before {
            content: "Event Date";
          }
          td:nth-of-type(4):before {
            content: "Event Type";
          }
          td:nth-of-type(5):before {
            content: "Menu Type";
          }
          td:nth-of-type(6):before {
            content: "Soft Drinks";
          }
          td:nth-of-type(7):before {
            content: "Hard Drinks";
          }
          td:nth-of-type(8):before {
            content: "Action";
          }
          }
        `}
        </style>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="container">
                <div className="row d-flex row-7">
                  <div className="col-12 col-sm-6 col-xl-4 mb-2 mb-xl-4">
                    <div className="row d-flex">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label d-none d-lg-block"
                        >
                          EVENT DATE
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div
                          className="input-group mb-3 jod_width price price-768 cal_width w-auto kitchen-calender"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            type=""
                            className="form-control inpt-border-trans "
                            value={finalDate}
                            placeholder="EVENT DATE"
                            readOnly
                          />
                          <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            className="form-control inpt-border-trans "
                            showPopperArrow={false}
                            dateFormat="yyyy-MM-dd"
                            //   value={finalDate ? finalDate : "aaa"}
                            customInput={
                              <span className="cursor-pointer">
                                <FaCalendarDays />
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6 col-xl-4 mb-2 mb-xl-4">

            <div className="row d-flex">
                <div className="col-12 col-lg-5 text-start text-lg-end">
                  <label for="basic-url" className="form-label  label">
                    END DATE
                  </label>
                </div>
                <div className="col-12 col-lg-7 ">
                  <div className="input-group mb-3 jod_width price price-768 cal_width w-auto kitchen-calender" style={{borderRadius: "8px"}}>
                    <input
                      type=""
                      className="form-control inpt-border-trans "
                      value={finalDate}
                    />
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      className="form-control inpt-border-trans "
                      showPopperArrow={false}
                      dateFormat="yyyy-MM-dd"
                      //   value={finalDate ? finalDate : "aaa"}
                      customInput={
                        <span className="cursor-pointer">
                          <FaCalendarDays />
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            </div> */}
                  <div className="col-12 col-sm-6 col-xl-4 mb-2 mb-xl-4">
                    <div className="row d-flex">
                      <div className="col-12 col-lg-5 text-start text-lg-end">
                        <label
                          for="basic-url"
                          className="form-label  label  d-none d-lg-block"
                        >
                          EVENT TYPE
                        </label>
                      </div>
                      <div className="col-12 col-lg-7 ">
                        <div className="" ref={sidebarRef}>
                          <div
                            className="form-select calender-select inpt inpt-768 w-auto"
                            onClick={() => setState(true)}
                            style={{ cursor: "pointer" }}
                          >
                            <option
                              className="overflow-hidden"
                              style={{ padding: "4px" }}
                            >
                              {selection}
                            </option>
                          </div>
                          <div
                            className={`${
                              state
                                ? "d-block select-box m-1 scrollbar-thin scroll-table scroll-main"
                                : "d-none"
                            }`}
                          >
                            {options?.map((item, index) => {
                              return (
                                <div key={index} className="p-1 py-0">
                                  <div
                                    onClick={(e) => handleClose(item)}
                                    className="select-box-option text-white"
                                  >
                                    {item.label}
                                  </div>
                                  <div className="" />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="role-table">
                <table
                  id="showBill"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        User Name
                      </th>
                      <th className="thead-color" scope="col">
                        Event Date
                      </th>
                      <th className="thead-color" scope="col">
                        Event Type
                      </th>
                      <th className="thead-color" scope="col">
                        Menu Type
                      </th>
                      <th className="thead-color" scope="col">
                        Soft Drinks
                      </th>
                      <th className="thead-color" scope="col">
                        Hard Drinks
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
  res_pdf_data: state?.Main_Kitchen_pdf_data_reducer,
});

export default connect(mapStateToProps)(KitchenCalenderFilter);