import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import Pusher from "pusher-js";
const SubInvoiceHistory = ({ dispatch, res_token }) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [length, setLength] = useState(0);
  const [socket, setSocket] = useState();
  useEffect(() => {
    dispatch(getToken());
  }, []);
  useEffect(() => {
    if (res_token?.loading === false) {
      TableDatatablesManaged.init();
    }
  }, [res_token]);
  useEffect(() => {
 
    const pusher = new Pusher("62c633a7c7dec6833874", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("useful-coast-393");

    setSocket(channel);
  }, []);

  useEffect(() => {
    if (socket) {
        socket.bind("tax_mode_on", async (data) => {
         window.location.reload();
        });
    }
  }, [socket]);
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showBill");
      var table1 = $("#showBill").DataTable({
        columnDefs: [{ orderable: false, targets: [] }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/get_invoice_byname",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "POST",
          data: {
            client_name: id,
          },
          dataSrc: "invoice_data",
        },
        columns: [
          {
            data: "invoice_id",
            width: "15%",
          },
          {
            data: "invoice_number",
            width: "30%",
          },
          {
            data: "invoice_name",
            width: "30%",
          },
          {
            data: "client_name",
            width: "30%",
          },
          {
            data: null,
            width: "10%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="createBill" value="' +
                data.invoice_id +
                '"><i class="bi bi-download fs-4 ps-2 me-0 fw-bold cursor-pointer"></i></span> </div>'
              );
            },
            sorting: false,
          },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showBill tbody").on("click", "#createBill", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
if (rowData && rowData?.invoice_name) {
  const url = `http://whitehall.website/api/uploads/invoice/${rowData?.invoice_name}`
  window.open(url,"_blank")

}
      });
      $("#showBill_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (
    <>
      <style>
        {`


          @media only screen and (max-width: 767px) {
         
          .table td:nth-child(1) {
            background-color: #cccccc !important;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: bold;
            ${length === 0 ? `padding-left:9px!important;` : ``}
        }
       
       

          td:nth-of-type(1):before {
            ${
              length !== 0
                ? `content: "#";
                      font-weight: bold;`
                : ``
            }
          }
          
          td:nth-of-type(2):before {
            content: "Invoice Number";
          }
          td:nth-of-type(3):before {
            content: "Invoice Name";
          }
          td:nth-of-type(4):before {
            content: "Client Name";
          }
          }
        `}
      </style>
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="role-table">
                <table
                  id="showBill"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Invoice Number
                      </th>
                      <th className="thead-color" scope="col">
                        Invoice Name
                      </th>
                      <th className="thead-color" scope="col">
                        Client Name
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>

                      {/* <th className="thead-color" scope="col">
                        Event Date
                      </th>
                      <th className="thead-color" scope="col">
                        Event Type
                      </th>
                      <th className="thead-color" scope="col">
                        No. of Guests
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(SubInvoiceHistory);
