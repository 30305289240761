import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import ViewSuppliersData from "../Forms/View/ViewSuppliersData";
import DeleteSupplier from "../Forms/Delete/DeleteSupplier";

const SuppliersTable = ({ dispatch, res_token }) => {
  const [viewSupplierModal, setViewSupplierModel] = useState(false);
  const [viewSupplierModalId, setViewSupplierModelId] = useState();
  const [deleteSupplierModal, setDeleteSupplierModel] = useState(false);
  const [deleteSupplierModalId, setDeleteSupplierModelId] = useState();
  const [length, setLength] = useState(0)

  const navigate = useNavigate();
  useEffect(() => {
    TableDatatablesManaged.init();
    dispatch(getToken());
  }, []);
  const api_token = sessionStorage.getItem("api_token");

  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $('<div class="row justify-content-center "><span class="loader"></span></div>').appendTo("#showsupplierstable");
      var table1 = $("#showsupplierstable").DataTable({
        columnDefs: [{ orderable: false, targets: 9 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_supplier_data",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          dataSrc: "suppliers_data",
        },
        columns: [
          {
            data: "id",
            width: "6%",
          },
          {
            data: "planner_name",
            width: "10%",
          },
          {
            data: "planner_price",
            width: "10%",
          },
          {
            data: "photographer_name",
            width: "10%",
          },
          {
            data: "photographer_price",
            width: "10%",
          },
          {
            data: "flower_planner_name",
            width: "10%",
          },
          {
            data: "flower_planner_price",
            width: "10%",
          },
          {
            data: "furniture_package_name",
            width: "10%",
          },
          {
            data: "furniture_package_price",
            width: "10%",
          },
          {
            data: null,
            width: "14%",
            render: function (data) {
              return (
                '<div class="d-flex line-height"><span  id="viewSuppliers" value="' +
                data.id +
                '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editSuppliers" value="' +
                data.id +
                '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
              );
            },
            sorting: false,
          },
         
        ],
        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength)
        },
      });
      table1.on('xhr.dt', function () {
        customLoader.css("display", "none");
      });
      $("#showsupplierstable tbody").on("click", "#viewSuppliers", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.id) {
          setViewSupplierModel(true);
          setViewSupplierModelId(rowData.id);
        }
      });
      $("#showsupplierstable tbody").on(
        "click",
        "#deleteSuppliers",
        function () {
          var row = $(this).closest("tr");
          const rowData = table1.row(row).data();
          if (rowData && rowData.id) {
            setDeleteSupplierModel(true);
            setDeleteSupplierModelId(rowData.id);
          }
        }
      );
      $("#showsupplierstable tbody").on("click", "#editSuppliers", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row) && table1.row(row).data();
        if (id && id.id) {
          id && navigate(`/edit_suppliers/${id.id}`);
        }
      });
      $("#showsupplierstable_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    // setRemove(!remove)
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (

    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ?
            `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
  .table td:nth-child(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #cccccc;
    }
    .table td:nth-child(5) {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      }
      .table td:nth-child(6) {
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #cccccc;
        }
        .table td:nth-child(7) {
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid #cccccc;
          }
          .table td:nth-child(8) {
            overflow: hidden;
            text-overflow: ellipsis;
            border-bottom: 1px solid #cccccc;
            }
            .table td:nth-child(9) {
              overflow: hidden;
              text-overflow: ellipsis;
              border-bottom: 1px solid #cccccc;
              }
td:nth-of-type(1):before {
  ${length !== 0 ?
            `content: "#";` :
            ``
          }
 
}
td:nth-of-type(2):before {
  content: "Planner Name";
 
}
td:nth-of-type(3):before {
  content: "Planner Price";
}
td:nth-of-type(4):before {
  content: "Photographer Name";
}
td:nth-of-type(5):before {
  content: "Photographer Price";
}td:nth-of-type(6):before {
  content: "Flower Planner Name";
}td:nth-of-type(7):before {
  content: "Flower Planner Price;
}td:nth-of-type(8):before {
  content: "Furniture Package Name";
}td:nth-of-type(9):before {
  content: "Furniture Package Price";
}td:nth-of-type(10):before {
  content: "Action";
}
}
`}
      </style>
      {viewSupplierModal && (
        <ViewSuppliersData
          viewSupplierModal={viewSupplierModal}
          setViewSupplierModel={setViewSupplierModel}
          viewSupplierModalId={viewSupplierModalId}
        />
      )}
      {deleteSupplierModal && (
        <DeleteSupplier
          deleteSupplierModal={deleteSupplierModal}
          setDeleteSupplierModel={setDeleteSupplierModel}
          deleteSupplierModalId={deleteSupplierModalId}
        />
      )}
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="float-right add-role-btn-768 mt-4">
                <Link to="/add_suppliers">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                  >
                    + ADD SUPPLIERS
                  </button>
                </Link>
              </div>
              <div className="role-table">
                <table
                  id="showsupplierstable"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Planner Name
                      </th>

                      <th className="thead-color" scope="col">
                        Planner Price
                      </th>
                      <th className="thead-color" scope="col">
                        Photographer Name
                      </th>
                      <th className="thead-color" scope="col">
                        Photographer Price
                      </th>
                      <th className="thead-color" scope="col">
                        Flower Planner Name
                      </th>
                      <th className="thead-color" scope="col">
                        Flower Planner Price
                      </th>
                      <th className="thead-color" scope="col">
                        Furniture Package Name
                      </th>
                      <th className="thead-color" scope="col">
                        Furniture Package Price
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});
export default connect(mapStateToProps)(SuppliersTable);