import React from "react";
import logo from "../../images/pdf_logo.png";
const GetEventSubContractPDF = ({ getData }) => {
  const WeddingData = [
    {
      key: "Date of the event:",
      value: getData?.startDate || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Event Category:",
      value:
        getData?.eventCategory === "" || getData?.eventCategory === null
          ? "-"
          : getData?.eventCategory || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Name of the reserve:",
      value: getData?.clientName || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Wedding type:",
      value: getData?.eventType || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Phone number:",
      value: getData?.clientPhone || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Groom name:",
      value: getData?.groomName || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Bride name:",
      value: getData?.brideName || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Number of guests:",
      value: getData?.noOfGuests || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Number of Free guests:",
      value: getData?.FreeGuests || "-",
      eventType: ["Wedding"],
    },
    {
      key: "Mixed/not mixed:",
      value: getData?.typeOfWedding || "-",
      eventType: ["Wedding"],
    },
  ];
  const EventDetailsData = [
    {
      key: "Time to start the event:",
      value:
        getData?.timeToStart === "" || getData?.timeToStart === null
          ? "-"
          : getData?.timeToStart || "-",
    },
    {
      key: "Time of the arrival:",
      value:
        getData?.timeOfArrival === "" || getData?.timeOfArrival === null
          ? "-"
          : getData?.timeOfArrival || "-",
    },
    {
      key: "Time of Zaffa:",
      value:
        getData?.zaffaTime === "" || getData?.zaffaTime === null
          ? "-"
          : getData?.zaffaTime || "-",
    },
    {
      key: "Gate of first entry:",
      value:
        getData?.firstGateEntryTime === "" ||
        getData?.firstGateEntryTime === null
          ? "-"
          : getData?.firstGateEntryTime || "-",
    },
    {
      key: "Name of the Zaffa artist:",
      value:
        getData?.zaffaArtistName === "" || getData?.zaffaArtistName === null
          ? "-"
          : getData?.zaffaArtistName || "-",
    },
    {
      key: "Time to enter the hall:",
      value: "-",
    },
    {
      key: "Time of the first slow dance:",
      value: "-",
    },
    {
      key: "Time of The first Cake show:",
      value:
        getData?.cakeShowTime === "" || getData?.cakeShowTime === null
          ? "-"
          : getData?.cakeShowTime || "-",
    },
    {
      key: "Time of the second cake show:",
      value: "-",
    },
    {
      key: "Buffet time:",
      value:
        getData?.buffetTime === "" || getData?.buffetTime === null
          ? "-"
          : getData?.buffetTime || "-",
    },
    {
      key: "Gate of second entry:",
      value:
        getData?.secondGateEntryTime === "" ||
        getData?.secondGateEntryTime === null
          ? "-"
          : getData?.secondGateEntryTime || "-",
    },
    {
      key: "Time of the second event:",
      value: "-",
    },
    {
      key: "Time to finish the event:",
      value: "-",
    },
  ];
  const FoodAndDrinkData = [
    {
      key: "Food source:",
      value: "White Hall",
    },
    {
      key: "Drinks Source:",
      value: "White Hall",
    },
    {
      key: "Food and drinks Notes:",
      value:
        getData?.foodnotes === "" || getData?.foodnotes === null
          ? "-"
          : getData?.foodnotes || "-",
    },
  ];
  const SoundAndLightData = [
    {
      key: "Sound and light :",
      value: "White Hall",
    },
    {
      key: "Sound and light package name:",
      value: "White Hall",
    },
    {
      key: "Sound and light Notes:",
      value:
        getData?.soundnotes === "" || getData?.soundnotes === null
          ? "-"
          : getData?.soundnotes || "-",
    },
  ];
  const SupplierData = [
    {
      key: "Wedding planner:",
      value:
        getData?.weddingPlannerID === "" || getData?.weddingPlannerID === null
          ? "-"
          : getData?.weddingPlannerID || "-",
    },
    {
      key: "Photography:",
      value:
        getData?.photographerID === "" || getData?.photographerID
          ? "-"
          : getData?.photographerID || "-",
    },
    {
      key: "Flowers:",
      value:
        getData?.flowersPlannerID === "" || getData?.flowersPlannerID === null
          ? "-"
          : getData?.flowersPlannerID || "-",
    },
    {
      key: "Furniture:",
      value:
        getData?.furnitureID === "" || getData?.furnitureID === null
          ? "-"
          : getData?.furnitureID || "-",
    },
    {
      key: "Supplier notes:",
      value:
        getData?.suppliersNotes === "" || getData?.suppliersNotes === null
          ? "-"
          : getData?.suppliersNotes || "-",
    },
  ];
  const ExtrasData = [
    {
      key: "Napkins:",
      value:
        getData?.napkins === "" || getData?.napkins === null
          ? "-"
          : getData?.napkins || "-",
    },
    {
      key: "Waiters:",
      value:
        getData?.waiters === "" || getData?.waiters === null
          ? "-"
          : getData?.waiters || "-",
    },
    {
      key: "Content of screens:",
      value:
        getData?.contentOfScreens === "" || getData?.contentOfScreens === null
          ? "-"
          : getData?.contentOfScreens || "-",
    },
    {
      key: "Live stream:",
      value:
        getData?.liveStream === "" || getData?.liveStream === null
          ? "-"
          : getData?.liveStream || "-",
    },
    {
      key: "Band/singer:",
      value:
        getData?.band === "" || getData?.band === null
          ? "-"
          : getData?.band || "-",
    },
    {
      key: "Ushers:",
      value:
        getData?.ushers === "" || getData?.ushers === null
          ? "-"
          : getData?.ushers || "-",
    },
  ];
  const BillDetailsData = [
    {
      key: "Grand Total:",
      value: Number(getData?.grand_total) + Number(getData?.paid) || "-",
    },
    {
      key: "Paid:",
      value: getData?.paid || "-",
    },
  ];
  return (
    <>
      <style>
        {`
      .wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        background-color: white !important;
        font-family: auto !important;
      }     
      .receipt-box {
        width: 760px;
        display: flex;
        flex-direction: column;
        margin: 0 35px;
      }      
     .th,.td{
        width:50%;
      }      
      .p{
        margin :0 !important;
        color: black !important;
        padding:2px 5px!important;
        // font-size:15px;
      }
      .p-td{
        margin :0 !important;
        padding:2px 5px!important;
        // font-size:15px;
      }

    .home-image {
        background-color: #ffffff;
      
        background-repeat: no-repeat;
        background-size: 93% 45% !important;
        overflow: hidden;
        /* min-height: 44vh; */
        background-position: center;
        /* opacity: 0.2; */
      }
      h6{
        font-size:18px;
      }
    `}
      </style>
      <div className="wrapper">
        <div className="receipt-box">
          <div className="actual-receipt" style={{ height: "1050px" }}>
            <div
              className="home-image m-4"
              style={{
                height: "100%",
                backgroundImage: `linear-gradient(rgba(255, 255, 255,0.930),rgb(255, 255, 255,0.930)),url(${logo})`,
              }}
            >
              <div className="container position-relative">
                <div className="row pt-4 pb-2  border-3 border-bottom border-black">
                  <div className=" p-0 col-6 text-start">
                    <img src={logo} style={{ width: "112px" }} />
                  </div>
                  <div className="col-6 text-end">
                    {" "}
                    <p className="text-black position-absolute bottom-0 end-0 mb-1">
                      Date: {getData?.startDate}
                    </p>
                  </div>
                </div>
              </div>

              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Client information
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {WeddingData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Event information
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {EventDetailsData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="actual-receipt" style={{ height: "850px" }}>
            <div
              className="home-image m-4"
              style={{
                height: "100%",
                backgroundImage: `linear-gradient(rgba(255, 255, 255,0.930),rgb(255, 255, 255,0.930)),url(${logo})`,
              }}
            >
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Food and drinks
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {FoodAndDrinkData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Sound and light
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {SoundAndLightData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Suppliers
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {SupplierData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="actual-receipt " style={{ height: "1050px" }}>
            <div
              className="home-image m-4 position-relative"
              style={{
                height: "100%",
                backgroundImage: `linear-gradient(rgba(255, 255, 255,0.930),rgb(255, 255, 255,0.930)),url(${logo})`,
              }}
            >
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Extras
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {ExtrasData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {(getData?.e_notes !== "" || getData?.e_notes !== null) && (
                <div className="container p-2">
                  <table
                    className="tables mt-5"
                    style={{ padding: "25px", width: "90%", margin: "auto" }}
                  >
                    <tbody>
                      <tr>
                        <td className="th">
                          <h6 className="text-black fw-bold mb-1">
                            General notes:
                          </h6>
                        </td>
                        <td className="td">
                          <p className="p-td text-muted">
                            {getData?.e_notes === "" ? "-" : getData?.e_notes}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              <div className="container p-2">
                <h6
                  className="text-black fw-bold mb-1 mt-5"
                  style={{ paddingLeft: "35px" }}
                >
                  Billing Details
                </h6>

                <table
                  className="tables "
                  style={{ padding: "25px", width: "90%", margin: "auto" }}
                >
                  <tbody>
                    {BillDetailsData?.map((data) => {
                      return (
                        <tr>
                          <td className="th">
                            <p className="p">
                              {" "}
                              <span>{data?.key}</span>{" "}
                            </p>
                          </td>
                          <td className="td">
                            <p className="p-td text-muted">{data?.value}</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container p-2 ">
                <table
                  className="tables border-top border-3 position-absolute start-0 w-100 border-black mb-3"
                  style={{ margin: "auto", bottom: "34px" }}
                >
                  <tbody>
                    <tr>
                      <td className="th">
                        <p className="p">
                          {" "}
                          <span>Name:</span>{" "}
                        </p>
                      </td>
                      <td className="td">
                        <p className="p-td text-muted"></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="th">
                        <p className="p">
                          {" "}
                          <span>Phone number:</span>{" "}
                        </p>
                      </td>
                      <td className="td">
                        <p className="p-td text-muted"></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="th">
                        <p className="p">
                          {" "}
                          <span>Signature:</span>{" "}
                        </p>
                      </td>
                      <td className="td">
                        <p className="p-td text-muted"></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetEventSubContractPDF;
