import React, { useState } from "react";
import { Link } from "react-router-dom";
import WhiteHallSL from "./WhiteHallSL";
import OutSourceSL from "./OutSourceSL";
import EditSoundAndLight from "../Forms/Edit/EditSoundAndLight";
import ViewSoundAndLight from "../Forms/View/ViewSoundAndLight";
import DeleteSoundAndLight from "../Forms/Delete/DeleteSoundAndLight";
const SoundAndLightTable = () => {
  const [selectedMenu, setselectedMenu] = useState(0);
  const [editSoundAndLight, setEditSoundAndLight] = useState(false);
  const [viewSoundAndLight, setViewSoundAndLight] = useState(false);
  const [deleteSoundLight, setDeleteSoundLight] = useState(false);
  const [viewType, setViewType] = useState("");
  const [editType, setEditType] = useState("");
  const [deleteType, setDeleteType] = useState("");
  return (
    <>
      {editSoundAndLight && (
        <EditSoundAndLight
          editSoundAndLight={editSoundAndLight}
          setEditSoundAndLight={setEditSoundAndLight}
          editType={editType}
        />
      )}
      {viewSoundAndLight && (
        <ViewSoundAndLight
          viewSoundAndLight={viewSoundAndLight}
          setViewSoundAndLight={setViewSoundAndLight}
          viewType={viewType}
          />
        )}
      {deleteSoundLight && (
        <DeleteSoundAndLight
        deleteSoundLight={deleteSoundLight}
        setDeleteSoundLight={setDeleteSoundLight}
        deleteType={deleteType}
        />
      )}
      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className=" form-margin-768">
                <div className={`d-grid gap-2 d-md-block `}>
                  <div className="mt-3 btn_center">
                    <button
                      className={`btn ${
                        selectedMenu === 0
                          ? "large-black-btn"
                          : " large-gray-btn"
                      } large-btn-768  ms-1 `}
                      type="button"
                      onClick={() => setselectedMenu(0)}
                    >
                      WHITE HALL
                    </button>
                    <button
                      className={`btn  btn_outsource large-btn-768  ${
                        selectedMenu === 1
                          ? "large-black-btn"
                          : " large-gray-btn"
                      }  ms-1`}
                      type="button"
                      onClick={() => setselectedMenu(1)}
                    >
                      OUTSOURCE
                    </button>
                  </div>
                </div>
                <div className="float-right add-role-btn-768">
                  <Link to="/add_sound_light">
                    <button
                      type="button"
                      className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                      style={{ width: "203px" }}
                    >
                      + ADD SOUND & LIGHT
                    </button>
                  </Link>
                </div>
              </div>
              {selectedMenu === 0 && (
                <WhiteHallSL
                  setEditSoundAndLight={setEditSoundAndLight}
                  setViewSoundAndLight={setViewSoundAndLight}
                  setDeleteSoundLight={setDeleteSoundLight}
                  deleteSoundLight={deleteSoundLight}
                  setDeleteType={setDeleteType}
                  setViewType={setViewType}
                  setEditType={setEditType}
                  editSoundAndLight={editSoundAndLight}
                />
              )}
              {selectedMenu === 1 && (
                <OutSourceSL
                  setEditSoundAndLight={setEditSoundAndLight}
                  setViewSoundAndLight={setViewSoundAndLight}
                  setDeleteSoundLight={setDeleteSoundLight}
                  deleteSoundLight={deleteSoundLight}
                  setDeleteType={setDeleteType}
                  setViewType={setViewType}
                  setEditType={setEditType}
                  editSoundAndLight={editSoundAndLight}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoundAndLightTable;