import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getAllSoundLightsData,
  getAllSuppliersData,
  getToken,
} from "../../../../Store/Action/useFetchData";
import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightDoubleLine } from "react-icons/ri";
const EditSupplierEvent = ({
  setselectedMenu,
  setGetData,
  getData,
  res_get_suppliers,
  dispatch,
  res_get_sound,
}) => {
  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setGetData((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };
  const handleReset = () => {
    setGetData({
      ...getData,
      weddingPlannerID: "",
      photographerID: "",
      flowersPlannerID: "",
      furnitureID: "",
      suppliersNotes: "",
    });
  };
  return (
    <>
      <div className="  form-margin padding-left-21 position-relative form-margin-768 mb-5 pb-1">
        <div className="  ">
          <h3 className="mb-4 mt-3 fw-bold pt-sm-0">Suppliers</h3>
            <div className="row mb-3 mt-3 ">
              <div className="col-12 col-lg-4">
                <label for="basic-url" className="form-label  label">
                  WEDDING PLANNER
                </label>
              </div>
              <div className="col-12 col-lg-8">
                <input
                  type="text"
                  className="form-control inpt inpt-768 mb-lg-0 mb-3"
                  name="weddingPlannerID"
                  value={getData?.weddingPlannerID}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
            </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                PHOTOGRAPHER
              </label>
            </div>

            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="photographerID"
                value={getData?.photographerID}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                FLOWERS PLANNER
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="flowersPlannerID"
                value={getData?.flowersPlannerID}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                FURNITURE
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control inpt inpt-768 mb-lg-0 mb-3"
                name="furnitureID"
                value={getData?.furnitureID}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row mb-3  ">
            <div className="col-12 col-lg-4">
              <label for="basic-url" className="form-label  label">
                NOTES
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <div className="input-group price-textarea">
              <textarea
                  className=" form-control inpt-border-trans"
                  id="exampleFormControlTextarea1"
                  rows="6"
                  name="suppliersNotes"
                  autoComplete="off"
                  value={getData?.suppliersNotes}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mt-xxl-3 mt-5 pt-xxl-0 pt-5 mb-5  me-lg-5 centerText bottom-section  display-above-768 position-relative">
            <button
              type="button"
              className="btn btn-g me-2  mb-4 btn-768"
              onClick={() => setselectedMenu(1)}
            >
              BACK
            </button>
            <button
              type="button"
              className="btn btn-g me-2  mb-4 btn-768"
              onClick={handleReset}
            >
              RESET
            </button>
            <button
              type="button"
              className="btn btn-b me-2 mb-4 btn-768"
              onClick={() => setselectedMenu(4)}
            >
              NEXT
              <RiArrowRightDoubleLine className="fs-5" />
            </button>
          </div>
        </div>
      </div>
      <div className={`next-btn mt-5  fixed-bottom`}>
        <div
          className=" d-flex justify-content-between p-2 "
          style={{ cursor: "pointer", backgroundColor: "#e5e5e5" }}
        >
          <div className="d-flex">
            <RiArrowLeftSLine className=" fs-2 mt-1" />
            <p
              className=" text-dark pt-1 mb-0 "
              onClick={() => setselectedMenu(1)}
            >
              PREVIOUS
            </p>
          </div>
          <div className="d-flex">
            <p
              className=" text-dark pt-1 mb-0 "
              onClick={() => setselectedMenu(4)}
            >
              NEXT
            </p>
            <RiArrowRightSLine className=" fs-2 mt-1" />
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(EditSupplierEvent);
