import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import { getToken } from "../../Store/Action/useFetchData";
import DeleteStation from "../Forms/Delete/DeleteStation";
import ViewStation from "../Forms/View/ViewStation";

const StationTable = ({ dispatch, res_token }) => {
  const [viewMenuModal, setViewMenuModal] = useState(false);
  const [viewMenuModalId, setViewMenuModalId] = useState();
  const [deleteMenuModal, setDeleteMenuModal] = useState(false);
  const [deleteMenuModalId, setDeleteMenuModalId] = useState();
  const [length, setLength] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getToken());
  }, []);
  useEffect(() => {
    TableDatatablesManaged.init();
  }, [deleteMenuModal]);
  const role = sessionStorage.getItem("role");
  const api_token = sessionStorage.getItem("api_token");
  var TableDatatablesManaged = (function () {
    var initTable1 = function () {
      var customLoader = $(
        '<div class="row justify-content-center "><span class="loader"></span></div>'
      ).appendTo("#showmenu");
      var table1 = $("#showmenu").DataTable({
        // drawCallback: function (settings) {
        //   var api = this.api();
        //   if (api.rows().count() === 0) {
        //     // If no data found, display "No data found" inside the table header
        //     $('#showmenu thead').html('<tr><th colspan="4" class="text-center">No data found</th></tr>');
        //   }
        // },
        columnDefs: [{ orderable: false, targets: 3 }],
        autoWidth: false,
        processing: false,
        serverSide: true,
        destroy: true,
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            next:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-right-fill ps-2"></i>'
                : '<span class="fw-bold text-black n-float">NEXT ></span>',
            previous:
              window.innerWidth > 768
                ? '<i class="bi bi-caret-left-fill"></i>'
                : '<span class="fw-bold p-float">< PREVIOUS</span>',
          },
        },
        ajax: {
          url: "/api/all_station_data",
          headers: {
            Authorization: "Bearer " + api_token,
            "X-CSRF-TOKEN": res_token?.data,
          },
          type: "GET",
          dataSrc: "user_data",
        },
        columns: [
          {
            data: "s_id",
            width: "6%",
          },
          {
            data: "s_name",
            width: "45%",
          },
          {
            data: null,
            width: "45%",
            render: function (data) {
              return `${Number(data.s_price).toFixed(5)} JOD`;
            },
          },
          // {
          //   data: "s_price",
          //   width: "45%",
          // },
          {
            data: null,
            width: "18%",
            render: function (data) {
              if (role === "0") {
                return (
                  '<div class="d-flex line-height"><span  id="viewStation" value="' +
                  data.s_id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editStation" value="' +
                  data.s_id +
                  '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteStation" value="' +
                  data.s_id +
                  '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
                );
              } else {
                return (
                  '<div class="d-flex line-height"><span  id="viewStation" value="' +
                  data.s_id +
                  '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editStation" value="' +
                  data.s_id +
                  '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span></div>'
                );
              }
            },
            sorting: false,
          },
          // {
          //   data: null,
          //   width: "18%",
          //   render: function (data) {
          //     return (
          //       '<div class="d-flex"><span  id="viewStation" value="' +
          //       data.s_id +
          //       '"><i class="bi bi-eye fs-4 ps-2 me-0 text-success cursor-pointer"></i></span><span  id="editStation" value="' +
          //       data.s_id +
          //       '"><i i class="bi bi-pencil-square fs-4 ps-2 me-0 text-warning cursor-pointer" ></i></span><span  id="deleteStation" value="' +
          //       data.s_id +
          //       '"><i class="bi bi-trash fs-4 ps-2 me-0 text-danger cursor-pointer"></i> </span></div>'
          //     );
          //   },
          // },
        ],

        select: {
          style: "multi",
          selector: "td:first-child",
        },
        order: [[0, "desc"]],
        drawCallback: function () {
          var tableLength = table1.rows().count();
          setLength(tableLength);
        },
      });
      table1.on("xhr.dt", function () {
        customLoader.css("display", "none");
      });
      $("#showmenu tbody").on("click", "#viewStation", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.s_id) {
          setViewMenuModal(true);
          setViewMenuModalId(rowData.s_id);
        }
      });
      $("#showmenu tbody").on("click", "#deleteStation", function () {
        var row = $(this).closest("tr");
        const rowData = table1.row(row).data();
        if (rowData && rowData.s_id) {
          setDeleteMenuModal(true);
          setDeleteMenuModalId(rowData.s_id);
        }
      });

      $("#showmenu tbody").on("click", "#editStation", function () {
        var row = $(this).parents("tr")[0];
        const id = table1.row(row) && table1.row(row).data();
        if (id && id.s_id) {
          id && navigate(`/edit_station/${id.s_id}`);
        }
      });
      $("#showmenu_processing").css("display", "none");
    };
    return {
      init: function () {
        if (!$().dataTable) {
          return;
        }
        initTable1();
      },
    };
  })();
  $(document).ready(function () {
    $.fn.dataTableExt.sErrMode = "throw";
    // TableDatatablesManaged.init();
  });
  return (
    <>
      <style>
        {`


@media only screen and (max-width: 767px) {
  
  .table td:nth-child(1) {
    background-color: #cccccc !important;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: bold;
    ${length === 0 ? `padding-left:9px!important;` : ``}
}
.table td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }
.table td:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  }

td:nth-of-type(1):before {
  ${length !== 0 ? `content: "#";` : ``}
 
}
td:nth-of-type(2):before {
  content: "Station Name";
 
}
td:nth-of-type(3):before {
  content: "Station Price";
}
td:nth-of-type(4):before {
  content: "Action";
}
}
`}
      </style>

      {deleteMenuModal && (
        <DeleteStation
          deleteMenuModal={deleteMenuModal}
          setDeleteMenuModal={setDeleteMenuModal}
          deleteMenuModalId={deleteMenuModalId}
        />
      )}
      {viewMenuModal && (
        <ViewStation
          viewMenuModal={viewMenuModal}
          setViewMenuModal={setViewMenuModal}
          viewMenuModalId={viewMenuModalId}
        />
      )}

      <div>
        <div className="container-fluid">
          <div className="">
            <div className="justify-content-center  padding-left-21 ">
              <div className="float-right add-role-btn-768  mt-3 mt-lg-5 mt-xl-0">
                <Link to="/create_station">
                  <button
                    type="button"
                    className="btn btn-add-user add-btn mb-auto mb-md-4 mt-3 mt-md-auto"
                  >
                    + ADD STATION
                  </button>
                </Link>
              </div>
              <div className="role-table">
                <table
                  id="showmenu"
                  className="table table-bordered table-bor table-responsive scroll-table scrollbar-thin w-100"
                >
                  <thead>
                    <tr>
                      <th className="thead-color" scope="col">
                        #
                      </th>
                      <th className="thead-color" scope="col">
                        Station Name
                      </th>
                      <th className="thead-color" scope="col">
                        Station Price
                      </th>
                      <th className="thead-color" scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body"></tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  res_token: state?.CrcToken_reducer,
});

export default connect(mapStateToProps)(StationTable);
